import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { a11yProps, StyledTab1, TabPanel1 } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import { TabContext } from '@mui/lab'
import { sxTypoGraphy2 } from '../../Utils/MUITheme/MUITheme'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import SearchIcon from '@mui/icons-material/Search'
import HomeLocalLegis from './HomeLocalLegis'
import HomeLocalAdministration from './HomeLocalAdministration'
import HomeLocalJudiciary from './HomeLocalJudiciary'

function HomeTabPanelForLocal() {
   const theme = useTheme()
   const [value, setValue] = useState(0)

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   // const [divisionsList, setDivisionsList] = useState('')

   const [statesList, setStatesList] = useState('')
   const [selectedStateId, setSelectedStateId] = useState('')
   const [individualState, setIndividualState] = useState('')
   const [fullStateObjectsList, setFullStateObjectsList] = useState([])

   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrictId, setSelectedDistrictId] = useState('')
   const [individualDistrict, setIndividualDistrict] = useState('')
   const [fullDistrictObjectsList, setFullDistrictObjectsList] = useState([])

   const [subDistrictsList, setSubDistrictsList] = useState('')
   const [selectedSubDistrictId, setSelectedSubDistrictId] = useState('')
   const [individualSubDistrict, setIndividualSubDistrict] = useState('')
   const [fullSubDistrictObjectsList, setFullSubDistrictObjectsList] = useState([])

   const [blocksList, setBlocksList] = useState('')
   // const [showBlockButton, setShowBlockButton] = useState(false)
   const [selectedBlockId, setSelectedBlockId] = useState('')
   const [individualBlock, setIndividualBlock] = useState('')
   const [fullBlockObjectsList, setFullBlockObjectsList] = useState([])

   const [villagesList, setVillagesList] = useState('')
   console.log('villagesList', villagesList)
   const [selectedVillageId, setSelectedVillageId] = useState('')
   const [individualVillage, setIndividualVillage] = useState('')
   const [fullVillageObjectsList, setFullVillageObjectsList] = useState([])

   const [legisList, setLegisList] = useState('')
   const [textName, setTextName] = useState('')
   const [textLevel, setTextLevel] = useState('')

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeIndex = (index) => {
      setValue(index)
   }

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchCountry()
         setFirstLoadStatus('success')
      }
   }, [])

   const [individualCountry, setIndividualCountry] = useState('')

   const fetchCountry = async () => {
      const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualCountry({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualCountry) {
         const sortedListStates = [...individualCountry.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setStatesList(sortedListStates)
      }
   }, [individualCountry])

   // states

   const handleChangeState = (e) => {
      e.preventDefault()
      setLegisList('')
      setTextLevel('')
      setTextName('')

      setDistrictsList('')
      setSubDistrictsList('')
      setBlocksList('')
      setVillagesList('')

      setIndividualState('')
      setSelectedDistrictId('')
      setIndividualDistrict('')
      setSelectedSubDistrictId('')
      setIndividualSubDistrict('')
      setSelectedBlockId('')
      setIndividualBlock('')
      setSelectedVillageId('')
      setIndividualVillage('')

      setSelectedStateId(e.target.value)
   }

   useEffect(() => {
      if (selectedStateId) {
         if (fullStateObjectsList.length > 0) {
            const thisState = fullStateObjectsList.find((item) => item.id === selectedStateId)
            if (thisState) {
               // const sortedListDivisions = [...thisState.Divisions].sort((a, b) => {
               //    if (b.Name.trim() > a.Name.trim()) {
               //       return -1
               //    } else if (b.Name.trim() < a.Name.trim()) {
               //       return 1
               //    } else {
               //       return 0
               //    }
               // })
               // setDivisionsList(sortedListDivisions)

               const sortedListDistricts = [...thisState.Districts].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setDistrictsList(sortedListDistricts)
            } else {
               getIndividualState()
            }
         } else {
            getIndividualState()
         }
      } else {
         setIndividualState('')
      }
   }, [selectedStateId])

   const getIndividualState = async () => {
      const docRef = doc(db, 'States', selectedStateId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualState({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualState) {
         setFullStateObjectsList([...fullStateObjectsList, individualState])

         // const sortedListDivisions = [...individualState.Divisions].sort((a, b) => {
         //    if (b.Name.trim() > a.Name.trim()) {
         //       return -1
         //    } else if (b.Name.trim() < a.Name.trim()) {
         //       return 1
         //    } else {
         //       return 0
         //    }
         // })
         // setDivisionsList(sortedListDivisions)

         const sortedListDistricts = [...individualState.Districts].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setDistrictsList(sortedListDistricts)
      } else {
         // setDivisionsList('')
         setDistrictsList('')
      }
   }, [individualState])

   // districts

   const handleChangeDistrict = (e) => {
      e.preventDefault()
      setLegisList('')
      setTextLevel('')
      setTextName('')

      setSubDistrictsList('')
      setSelectedSubDistrictId('')
      setIndividualSubDistrict('')

      setBlocksList('')
      setSelectedBlockId('')
      setIndividualBlock('')

      setVillagesList('')
      setSelectedVillageId('')
      setIndividualVillage('')

      setIndividualDistrict('')
      setSelectedDistrictId(e.target.value)
   }

   const handleRevisitDistrict = (e) => {
      e.preventDefault()
      setLegisList('')
      setTextLevel('')
      setTextName('')

      setSelectedSubDistrictId('')
      setIndividualSubDistrict('')

      setSelectedBlockId('')
      setIndividualBlock('')

      setVillagesList('')
      setSelectedVillageId('')
      setIndividualVillage('')

      if (fullDistrictObjectsList.length > 0) {
         const thisDistrict = fullDistrictObjectsList.find((item) => item.id === selectedDistrictId)
         console.log('here thisDistrict', thisDistrict)
         if (thisDistrict) {
            console.log('here inside thisDistrict', thisDistrict)
            const sortedListSubDistricts = [...thisDistrict.SubDistricts].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            console.log('here inside thisDistrict sortedListSubDistricts', sortedListSubDistricts)
            setSubDistrictsList(sortedListSubDistricts)

            const sortedListBlocks = [...thisDistrict.Blocks].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            console.log('here inside thisDistrict sortedListBlocks', sortedListBlocks)
            setBlocksList(sortedListBlocks)

            const sortedListLegis = [...thisDistrict.Legislatures].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setLegisList(sortedListLegis)
            setTextLevel('District / ज़िला')

            if (thisDistrict.NameLocal) {
               setTextName(`${thisDistrict.Name} ${thisDistrict.NameLocal}`)
            } else {
               setTextName(`${thisDistrict.Name} ${thisDistrict.NameHindi}`)
            }
         } else {
            console.log('here outside thisDistrict')
            getIndividualDistrict()
         }
      }
   }

   useEffect(() => {
      if (selectedDistrictId) {
         if (fullDistrictObjectsList.length > 0) {
            const thisDistrict = fullDistrictObjectsList.find((item) => item.id === selectedDistrictId)
            if (thisDistrict) {
               const sortedListSubDistricts = [...thisDistrict.SubDistricts].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setSubDistrictsList(sortedListSubDistricts)

               const sortedListBlocks = [...thisDistrict.Blocks].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setBlocksList(sortedListBlocks)

               const sortedListLegis = [...thisDistrict.Legislatures].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setLegisList(sortedListLegis)
               setTextLevel('District / ज़िला')

               if (thisDistrict.NameLocal) {
                  setTextName(`${thisDistrict.Name} ${thisDistrict.NameLocal}`)
               } else {
                  setTextName(`${thisDistrict.Name} ${thisDistrict.NameHindi}`)
               }
            } else {
               getIndividualDistrict()
            }
         } else {
            getIndividualDistrict()
         }
      } else {
         setIndividualDistrict('')
         setLegisList('')
         setTextLevel('')
         setTextName('')
      }
   }, [selectedDistrictId])

   const getIndividualDistrict = async () => {
      const docRef = doc(db, 'Districts', selectedDistrictId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualDistrict({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualDistrict) {
         setFullDistrictObjectsList([...fullDistrictObjectsList, individualDistrict])

         const sortedListSubDistricts = [...individualDistrict.SubDistricts].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setSubDistrictsList(sortedListSubDistricts)

         const sortedListBlocks = [...individualDistrict.Blocks].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setBlocksList(sortedListBlocks)

         const sortedListLegis = [...individualDistrict.Legislatures].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setLegisList(sortedListLegis)
         setTextLevel('District / ज़िला')
         if (individualDistrict.NameLocal) {
            setTextName(`${individualDistrict.Name} ${individualDistrict.NameLocal}`)
         } else {
            setTextName(`${individualDistrict.Name} ${individualDistrict.NameHindi}`)
         }
      } else {
         // setDivisionsList('')
         setSubDistrictsList('')
         setBlocksList('')
         setLegisList('')
         setTextLevel('')
         setTextName('')
      }
   }, [individualDistrict])

   // sub-districts

   const handleChangeSubDistrict = (e) => {
      e.preventDefault()
      setLegisList('')
      setTextLevel('')
      setTextName('')

      setSelectedBlockId('')
      setIndividualBlock('')

      setVillagesList('')
      setSelectedVillageId('')
      setIndividualVillage('')

      setIndividualSubDistrict('')
      setSelectedSubDistrictId(e.target.value)
   }

   useEffect(() => {
      if (selectedSubDistrictId) {
         if (fullSubDistrictObjectsList.length > 0) {
            const thisSubDistrict = fullSubDistrictObjectsList.find((item) => item.id === selectedSubDistrictId)
            if (thisSubDistrict) {
               const sortedListVillages = [...thisSubDistrict.Villages].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setVillagesList(sortedListVillages)

               const sortedListLegis = [...thisSubDistrict.Legislatures].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setLegisList(sortedListLegis)
               setTextLevel('Sub-District / उप-जिला')
               if (thisSubDistrict.NameLocal) {
                  setTextName(`${thisSubDistrict.Name} ${thisSubDistrict.NameLocal}`)
               } else {
                  setTextName(`${thisSubDistrict.Name} ${thisSubDistrict.NameHindi}`)
               }
            } else {
               getIndividualSubDistrict()
            }
         } else {
            getIndividualSubDistrict()
         }
      } else {
         setIndividualSubDistrict('')
         setLegisList('')
         setTextLevel('')
         setTextName('')
      }
   }, [selectedSubDistrictId])

   const getIndividualSubDistrict = async () => {
      const docRef = doc(db, 'SubDistricts', selectedSubDistrictId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualSubDistrict({ ...docSnap.data(), id: docSnap.id })
         console.log('getIndividualSubDistrict: ', docSnap.data())
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualSubDistrict) {
         setFullSubDistrictObjectsList([...fullSubDistrictObjectsList, individualSubDistrict])
         console.log('individualSubDistrict -> Villages: ', individualSubDistrict.Villages)
         const sortedListVillages = [...individualSubDistrict.Villages].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setVillagesList(sortedListVillages)
         console.log('individualSubDistrict -> Legislatures: ', individualSubDistrict.Legislatures)
         const sortedListLegis = [...individualSubDistrict.Legislatures].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setLegisList(sortedListLegis)
         setTextLevel('Sub-District / उप-जिला')
         if (individualSubDistrict.NameLocal) {
            setTextName(`${individualSubDistrict.Name} ${individualSubDistrict.NameLocal}`)
         } else {
            setTextName(`${individualSubDistrict.Name} ${individualSubDistrict.NameHindi}`)
         }
      } else {
         // setDivisionsList('')
         setVillagesList('')
         setSelectedVillageId('')

         if (fullDistrictObjectsList.length > 0) {
            const thisDistrict = fullDistrictObjectsList.find((item) => item.id === selectedDistrictId)
            if (thisDistrict) {
               const sortedListLegis = [...thisDistrict.Legislatures].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setLegisList(sortedListLegis)
               setTextLevel('District / ज़िला')

               if (thisDistrict.NameLocal) {
                  setTextName(`${thisDistrict.Name} ${thisDistrict.NameLocal}`)
               } else {
                  setTextName(`${thisDistrict.Name} ${thisDistrict.NameHindi}`)
               }
            }
         }
      }
   }, [individualSubDistrict])

   // blocks

   const handleChangeBlock = (e) => {
      e.preventDefault()

      setLegisList('')
      setTextLevel('')
      setTextName('')

      setSelectedSubDistrictId('')
      setIndividualSubDistrict('')

      setIndividualBlock('')
      setSelectedBlockId(e.target.value)
   }

   useEffect(() => {
      if (selectedBlockId) {
         if (fullBlockObjectsList.length > 0) {
            const thisBlock = fullBlockObjectsList.find((item) => item.id === selectedBlockId)
            if (thisBlock) {
               const sortedListLegis = [...thisBlock.Legislatures].sort((a, b) => {
                  return parseInt(a.Priority) - parseInt(b.Priority)
               })
               setLegisList(sortedListLegis)
               setTextLevel('Block / खंड')
               if (thisBlock.NameLocal) {
                  setTextName(`${thisBlock.Name} ${thisBlock.NameLocal}`)
               } else {
                  setTextName(`${thisBlock.Name} ${thisBlock.NameHindi}`)
               }
            } else {
               getIndividualBlock()
            }
         } else {
            getIndividualBlock()
         }
      } else {
         setIndividualBlock('')
         setLegisList('')
         setTextLevel('')
         setTextLevel('')
      }
   }, [selectedBlockId])

   const getIndividualBlock = async () => {
      const docRef = doc(db, 'Blocks', selectedBlockId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualBlock({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualBlock) {
         setFullBlockObjectsList([...fullBlockObjectsList, individualBlock])

         const sortedListLegis = [...individualBlock.Legislatures].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setLegisList(sortedListLegis)
         setTextLevel('Block / खंड')
         if (individualBlock.NameLocal) {
            setTextName(`${individualBlock.Name} ${individualBlock.NameLocal}`)
         } else {
            setTextName(`${individualBlock.Name} ${individualBlock.NameHindi}`)
         }
      } else {
         if (fullDistrictObjectsList.length > 0) {
            const thisDistrict = fullDistrictObjectsList.find((item) => item.id === selectedDistrictId)
            if (thisDistrict) {
               const sortedListLegis = [...thisDistrict.Legislatures].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setLegisList(sortedListLegis)
               setTextLevel('District / ज़िला')

               if (thisDistrict.NameLocal) {
                  setTextName(`${thisDistrict.Name} ${thisDistrict.NameLocal}`)
               } else {
                  setTextName(`${thisDistrict.Name} ${thisDistrict.NameHindi}`)
               }
            }
         }
      }
   }, [individualBlock])

   // villages

   const handleChangeVillage = (e) => {
      e.preventDefault()
      setLegisList('')
      setTextLevel('')
      setTextName('')

      setIndividualVillage('')

      setSelectedVillageId(e.target.value)
   }

   useEffect(() => {
      if (selectedVillageId) {
         if (fullVillageObjectsList.length > 0) {
            const thisVillage = fullVillageObjectsList.find((item) => item.id === selectedVillageId)
            if (thisVillage) {
               const sortedListLegis = [...thisVillage.Legislatures].sort((a, b) => {
                  return parseInt(a.Priority) - parseInt(b.Priority)
               })
               setLegisList(sortedListLegis)
               setTextLevel('Village / ग्राम')
               if (thisVillage.NameLocal) {
                  setTextName(`${thisVillage.Name} ${thisVillage.NameLocal}`)
               } else {
                  setTextName(`${thisVillage.Name} ${thisVillage.NameHindi}`)
               }
            } else {
               getIndividualVillage()
            }
         } else {
            getIndividualVillage()
         }
      } else {
         setIndividualVillage('')
         setLegisList('')
         setTextLevel('')
         setTextName('')
      }
   }, [selectedVillageId])

   const getIndividualVillage = async () => {
      const docRef = doc(db, 'Villages', selectedVillageId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualVillage({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualVillage) {
         setFullVillageObjectsList([...fullVillageObjectsList, individualVillage])

         const sortedListLegis = [...individualVillage.Legislatures].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setLegisList(sortedListLegis)
         setTextLevel('Village / ग्राम')
         if (individualVillage.NameLocal) {
            setTextName(`${individualVillage.Name} ${individualVillage.NameLocal}`)
         } else {
            setTextName(`${individualVillage.Name} ${individualVillage.NameHindi}`)
         }
      } else {
         if (fullSubDistrictObjectsList.length > 0) {
            const thisSubDistrict = fullSubDistrictObjectsList.find((item) => item.id === selectedSubDistrictId)
            if (thisSubDistrict) {
               const sortedListLegis = [...thisSubDistrict.Legislatures].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setLegisList(sortedListLegis)
               setTextLevel('Sub-District / ज़िला')

               if (thisSubDistrict.NameLocal) {
                  setTextName(`${thisSubDistrict.Name} ${thisSubDistrict.NameLocal}`)
               } else {
                  setTextName(`${thisSubDistrict.Name} ${thisSubDistrict.NameHindi}`)
               }
            }
         }
      }
   }, [individualVillage])

   return (
      <Box sx={{ pb: 0.5 }}>
         <Box
            sx={{
               // Love Couple from uiGradient
               background: 'linear-gradient(to right, #3a6186, #89253e)',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: 50,
               mb: 1,
            }}
         >
            <Typography sx={sxTypoGraphy2}>
               Local Bodies{' - '}
               <span style={{ fontSize: 15 }}>
                  <strong>स्थानीय निकाय</strong>
               </span>
            </Typography>
         </Box>
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: '80%', backgroundColor: '#ffffff' }} size="small">
               <InputLabel>State / UT - राज्य / केंद्र शासित प्रदेश</InputLabel>
               <Select
                  id="xxx"
                  value={selectedStateId}
                  label="State / UT - राज्य / केंद्र शासित प्रदेश"
                  onChange={(e) => {
                     handleChangeState(e)
                  }}
                  sx={{ padding: 0 }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {statesList &&
                     statesList.length > 0 &&
                     statesList.map((item) => (
                        <MenuItem key={item.State_id} value={item.State_id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            <IconButton
               edge="end"
               aria-label="age"
               sx={{
                  height: '35px',
                  width: '35px',
                  backgroundColor: '#03a9f4',
                  color: '#FFFFFF',
                  '&:hover': { backgroundColor: '#01579b' },
               }}
               disabled
            ></IconButton>
         </Box>

         <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: '80%', backgroundColor: '#ffffff' }} size="small">
               <InputLabel>District - ज़िला</InputLabel>
               <Select
                  id="xxx"
                  value={selectedDistrictId}
                  label="District - ज़िला"
                  onChange={(e) => {
                     handleChangeDistrict(e)
                  }}
                  sx={{ padding: 0 }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {districtsList &&
                     districtsList.length &&
                     districtsList.map((item) => (
                        <MenuItem key={item.District_id} value={item.District_id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            <IconButton
               edge="end"
               aria-label="age"
               onClick={(e) => {
                  handleRevisitDistrict(e)
               }}
               sx={{
                  height: '35px',
                  width: '35px',
                  backgroundColor: '#03a9f4',
                  color: '#FFFFFF',
                  '&:hover': { backgroundColor: '#01579b' },
               }}
               disabled={!selectedDistrictId || (!selectedSubDistrictId && !selectedBlockId)}
            >
               <SearchIcon fontSize="small" />
            </IconButton>
         </Box>

         {selectedDistrictId && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
               <FormControl sx={{ my: 1, mx: 0.5, width: '80%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Sub-District - उप-जिला</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedSubDistrictId}
                     label="Sub-District - उप-जिला"
                     onChange={(e) => {
                        handleChangeSubDistrict(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {subDistrictsList &&
                        subDistrictsList.length > 0 &&
                        subDistrictsList.map((item) => (
                           <MenuItem key={item.SubDistrict_id} value={item.SubDistrict_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>

               <Box
                  sx={{
                     display: 'flex',
                     border: 1,
                     borderRadius: 1,
                     backgroundColor: '#000000',
                     color: '#FFFFFF',
                     justifyContent: 'center',
                     alignItems: 'center',
                     my: 0.5,
                     px: 0.5,
                  }}
               >
                  OR
               </Box>
               <FormControl sx={{ my: 1, mx: 0.5, width: '80%', backgroundColor: '#ffffff' }} size="small">
                  <InputLabel>Block - ब्लॉक</InputLabel>
                  <Select
                     id="xxx"
                     value={selectedBlockId}
                     label="Block - ब्लॉक"
                     onChange={(e) => {
                        handleChangeBlock(e)
                     }}
                     sx={{ padding: 0 }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {blocksList &&
                        blocksList.length > 0 &&
                        blocksList.map((item) => (
                           <MenuItem key={item.Block_id} value={item.Block_id}>
                              {item.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Box>
         )}

         {selectedSubDistrictId && (
            <FormControl sx={{ m: 1, width: '80%', backgroundColor: '#ffffff' }} size="small">
               <InputLabel>Choose Village</InputLabel>
               <Select
                  id="xxx"
                  value={selectedVillageId}
                  label="Choose Village"
                  onChange={(e) => {
                     handleChangeVillage(e)
                  }}
                  sx={{ padding: 0 }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {villagesList &&
                     villagesList.length > 0 &&
                     villagesList.map((item) => (
                        <MenuItem key={item.Village_id} value={item.Village_id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         )}

         <TabContext value={value}>
            <Box sx={{ width: '100%', mb: 1 }}>
               <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  //   centered
                  variant="scrollable"
                  scrollButtons="false"
                  sx={{
                     minHeight: 25,
                     width: '100%',
                     mr: 0,
                  }}
               >
                  <StyledTab1 label={`Local Body \n स्थानीय निकाय`} {...a11yProps(0)} />
                  <StyledTab1 label={`Executive \n (कार्यपालिका)`} {...a11yProps(1)} />
                  <StyledTab1 label={`Administration \n (प्रशासन)`} {...a11yProps(2)} />
                  <StyledTab1 label={`Judiciary \n (न्यायपालिका)`} {...a11yProps(3)} />
               </Tabs>
            </Box>
            <SwipeableViews
               axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
               index={value}
               onChangeIndex={handleChangeIndex}
            >
               <TabPanel1 value={value} index={0} dir={theme.direction}>
                  <HomeLocalLegis
                     legisList={legisList}
                     textLevel={textLevel}
                     textName={textName}
                     selectedStateId={selectedStateId}
                     selectedDistrictId={selectedDistrictId}
                  ></HomeLocalLegis>
               </TabPanel1>
               <TabPanel1 value={value} index={1} dir={theme.direction}>
                  Executive
               </TabPanel1>
               <TabPanel1 value={value} index={2} dir={theme.direction}>
                  <HomeLocalAdministration></HomeLocalAdministration>
               </TabPanel1>
               <TabPanel1 value={value} index={3} dir={theme.direction}>
                  <HomeLocalJudiciary></HomeLocalJudiciary>
               </TabPanel1>
            </SwipeableViews>
         </TabContext>
      </Box>
   )
}

export default HomeTabPanelForLocal
