import {
   arrayRemove,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import {
   Avatar,
   Badge,
   Box,
   Button,
   Card,
   CardContent,
   CardMedia,
   Divider,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Tabs,
   Typography,
   useTheme,
} from '@mui/material'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import { v4 } from 'uuid'
// import { styled } from '@mui/material/styles'

import { TabContext } from '@mui/lab'
import { a11yProps, StyledTab1, TabPanel2 } from '../../../Components/TabPanelSystem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SwipeableViews from 'react-swipeable-views'
import ElecCandiBasicInfo from '../ElecCandiBasicInfo/ElecCandiBasicInfo'
import ElecCandiCred from '../ElecCandiCred/ElecCandiCred'
import ElecCandiWorks from '../ElecCandiWorks/ElecCandiWorks'
import {
   SmallAvatar,
   stringAvatar150,
   stringAvatar150Single,
   stringAvatar50,
   stringAvatar50Single,
   styleModalBox,
   styleModalTypography,
} from '../../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'
import ElecCandiInteract from '../ElecCandiInteract/ElecCandiInteract'
import { Helmet } from 'react-helmet'
import ElecCandiNews from '../ElecCandiNews/ElecCandiNews'
import ReactShareButtons from '../../../Utils/CustomButtons/ReactShareButtons'

// import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Microlink from '@microlink/react'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import AddWeblinkWithPriority from '../../../Components/Edits/AddWeblinkWithPriority'
import AddImageOnly from '../../../Components/Edits/AddImageOnly'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import EditPriorityAndDescr from '../../../Components/Edits/EditPriorityAndDescr'
// import CarouselForWeblinks from '../../../Components/Carousels/CarouselForWeblinks'
// import CandidateStamp from '../../../Images/Candidate.png'

function ElecCandi() {
   const { candiId, candiName } = useParams()
   const navigate = useNavigate()

   const user = useSelector(selectUsers)
   const ip = user.ip

   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const userProfile = useSelector(selectUserProfile)
   const [nameOfUser, setNameOfUser] = useState('')

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         // console.log('userProfile use state: ', userProfile, 'strata', consti.Strata)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
         console.log('Strata: ', consti.Strata)
      }
   }, [userProfile])

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const theme = useTheme()
   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [candi, setCandi] = useState('')
   const [weblinksFromGallery, setWeblinksFromGallery] = useState([])
   const [imagesFromGallery, setImagesFromGallery] = useState([])
   const [elec, setElec] = useState('')
   const [consti, setConsti] = useState('')
   const [candisList, setCandisList] = useState([])
   const [weblinksListRoot, setWeblinksListRoot] = useState([])
   const [weblinksList, setWeblinksList] = useState([])
   const [petitionsList, setPetitionsList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchCandidate(candiId)
         fetchPetitionCategories()
      }
   }, [])

   const fetchCandidate = async (candiId) => {
      try {
         const docRef = doc(db, 'ElectionCandidates', candiId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setCandi({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }

         setFirstLoadStatus('success')
      } catch (err) {
         console.log('error', err)
         setFirstLoadStatus('error')
      }
   }

   useEffect(() => {
      if (candi !== '') {
         if (candiId !== candi.id) {
            navigate('/', { replace: true })
         } else {
            fetchGallery()
            fetchElection(candi.Election_id)
            fetchConstituency(candi.Constituency_id)
            fetchWeblinks(candi.Election_id)
         }
      }
   }, [candi])

   // fetch lists

   const [petitionCategoriesList, setPetitionCategoriesList] = useState('')
   const fetchPetitionCategories = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'PetitionCategories')
         const docSnap = await getDoc(docRef)
         setPetitionCategoriesList([...docSnap.data().Categories])
      } catch (err) {
         console.log('error fetching categories', err)
      }
   }

   const fetchGallery = async () => {
      const docRefGallery = doc(db, 'ElectionCandidates', candiId, 'Gallery', '001')
      const docSnapGallery = await getDoc(docRefGallery)
      if (docSnapGallery.exists()) {
         if (docSnapGallery.data().WebLinksObj) {
            console.log('list 1: ', docSnapGallery.data().WebLinksObj)
            const list = [...docSnapGallery.data().WebLinksObj].filter((item) => !item.Removed)
            console.log('list 2: ', list)
            const sortedList = [...list].sort((a, b) => a.Priority - b.Priority)
            setWeblinksFromGallery(sortedList)
            console.log('list 3: ', sortedList)
         }
         setImagesFromGallery(
            docSnapGallery.data().ImagesFromGallery ? [...docSnapGallery.data().ImagesFromGallery] : [],
         )
      }
   }

   const fetchElection = async (ElecId) => {
      try {
         const docRef = doc(db, 'Elections', ElecId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setElec({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      if (elec !== '') {
         fetchCandidatesOfThisConsti()
      }
   }, [elec])

   const [attachedToConsti, setAttachedToConsti] = useState('')

   useEffect(() => {
      if ((candi !== '', elec !== '' && userProfile !== '')) {
         //
         fetchPetitions()
         //
         switch (elec.Strata) {
            case 'Central':
               if (!userProfile || (userProfile && userProfile.AtCentralConstituency === '')) {
                  setAttachedToConsti({ Attached: 'Nowhere', Strata: 'Central', Constituency: '' })
               } else if (userProfile && userProfile.AtCentralConstituency !== '') {
                  if (userProfile.AtCentralConstituency.id === candi.Constituency_id) {
                     setAttachedToConsti({
                        Attached: 'Here',
                        Strata: 'Central',
                        Constituency: userProfile.AtCentralConstituency.NameLocal
                           ? userProfile.AtCentralConstituency.Name +
                             ' (' +
                             userProfile.AtCentralConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtCentralConstituency.NameLocal
                           : userProfile.AtCentralConstituency.Name +
                             ' (' +
                             userProfile.AtCentralConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtCentralConstituency.NameHindi,
                     })
                  } else {
                     setAttachedToConsti({
                        Attached: 'There',
                        Strata: 'Central',
                        Constituency: userProfile.AtCentralConstituency.Name,
                     })
                  }
               }
               break
            case 'State':
               console.log('test 1', userProfile)
               if (!userProfile || (userProfile && userProfile.AtStateConstituency === '')) {
                  console.log('test 2')
                  setAttachedToConsti({ Attached: 'Nowhere', Strata: 'State', Constituency: '' })
               } else if (userProfile && userProfile.AtStateConstituency !== '') {
                  console.log('test 3', userProfile)
                  if (userProfile.AtStateConstituency.id === candi.Constituency_id) {
                     setAttachedToConsti({
                        Attached: 'Here',
                        Strata: 'State',
                        Constituency: userProfile.AtStateConstituency.NameLocal
                           ? userProfile.AtStateConstituency.Name +
                             ' (' +
                             userProfile.AtStateConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtStateConstituency.NameLocal
                           : userProfile.AtStateConstituency.Name +
                             ' (' +
                             userProfile.AtStateConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtStateConstituency.NameHindi,
                     })
                  } else {
                     setAttachedToConsti({
                        Attached: 'There',
                        Strata: 'State',
                        Constituency: userProfile.AtStateConstituency.Name,
                     })
                  }
               }
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            case 'SubDistrict':
               //
               break
            case 'Block':
               //
               break
            case 'Village':
               //
               break
         }

         console.log('test 4')
      }
   }, [elec, candi, userProfile])

   const fetchConstituency = async (id) => {
      try {
         const docRef = doc(db, 'LegisConstituencies', id)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setConsti({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchWeblinks = async (ElecId) => {
      try {
         const querryWeblinks = query(collection(db, 'Elections', ElecId, 'WeblinksLists'))
         const querySnapshot = await getDocs(querryWeblinks)
         if (querySnapshot && querySnapshot.length < 1) {
            setWeblinksList([])
         } else {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Weblinks)
            })
            const filteredList = listFetched.filter(
               (item) => item.Candidate_id === candi.id || item.Constituency_id === candi.Constituency_id,
            )
            const sortedList = [...filteredList].sort((a, b) => {
               // bigger date first
               if (moment(a.Date.toDate()).isAfter(moment(b.Date.toDate()))) {
                  return -1
               } else if (moment(a.Date.toDate()).isBefore(moment(b.Date.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setWeblinksListRoot(listFetched)
            setWeblinksList(sortedList)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const fetchCandidatesOfThisConsti = async () => {
      try {
         let candisListRoot = []
         if (elec.id === 'lfoOBVgQSifbNXkzh07j' || elec.id === 'eHt7k2NNw54Xaq2T8kAK') {
            // these are jk and haryana elections 2024
            // in these cases the CandidateCoreLists docs were placed inside the election collection
            // later the structure changed ...
            if (elec.CandidatesCoreLists.length > 0) {
               const listFetched = []

               elec.CandidatesCoreLists.forEach(async (item) => {
                  const docRefCore = doc(db, 'ElectionCandidates', item)
                  const docSnapCore = await getDoc(docRefCore)
                  listFetched.push(...docSnapCore.data().Candidates)
               })
               candisListRoot = listFetched
            }
         } else {
            //
            const querryCandis = query(
               collection(db, 'Elections', elec.id, 'CoreLists'),
               where('DocType', '==', 'CandidatesCoreList'),
            )
            const querySnapshot = await getDocs(querryCandis)

            const listFetched = []

            // if (querySnapshot.exists()) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Candidates)
            })
            // }

            candisListRoot = listFetched
         }

         const candisListByConsti = candisListRoot.filter((item) => item.Constituency_id === candi.Constituency_id)

         const listForOnlineSupport = candisListByConsti.filter(
            (item) =>
               item.Rejected !== 'Rejected' &&
               item.Refused !== 'Refused' &&
               item.Withdrawn !== 'Withdrawn' &&
               item.AnnounceCancelled !== 'AnnounceCancelled',
         )

         const sortedListForOnlineSupport = [...listForOnlineSupport].sort((a, b) => b.OnlineSupport - a.OnlineSupport)

         setCandisList(sortedListForOnlineSupport)
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchPetitions = async () => {
      console.log('frtching petitions at first fetch: ')
      try {
         console.log('frtching petitions at first fetch 2: ', candi.id)
         const querry = query(collection(db, 'ElectionCandidates', candi.id, 'Petitions'))
         const querySnapshot = await getDocs(querry)

         const listFetched = []

         if (!querySnapshot.empty) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Petitions)
            })
         }
         console.log('petitions at first fetch: ', listFetched)
         setPetitionsList(listFetched)
      } catch (err) {
         console.log('error fetching petitions', err)
      }
   }

   const handlePetitionsListUpdate = (obj) => {
      petitionsList.push(obj)
   }

   const sortCandisList = (list) => {
      setCandisList(list)
   }

   const handleWeblinksUpdate = (obj) => {
      weblinksListRoot.push(obj)
   }

   const title = 'Track Democracy -' + elec.Name + ' - Candidate: ' + candiName
   const hashTag = '#democracy'
   const hashTags = ['DelhiElections', 'Elections', 'Candidate', 'India', 'BJP', 'AAP', 'Congress']
   const image = candi.Image
   //

   // const settings = {
   //    dots: false,
   //    speed: 500,
   //    infinite: true,
   //    slidesToShow: 1,
   //    slidesToScroll: 1,
   //    autoplay: true,
   //    autoplaySpeed: 2500,
   //    rtl: false,
   // }

   // related to Weblink from gallery
   const [openModalWeblink, setOpenModalWeblink] = useState(false)

   const handleOpenModalWeblink = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            setOpenModalWeblink(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWeblink = () => setOpenModalWeblink(false)

   const handleAddWeblinkWithPriority = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrByName = nameOfUser
            weblinkDetails.CrByImage = userProfile.Image.Image
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip
            weblinkDetails.ModDt = ''
            //
            if (candi.User_id === thisUser) {
               weblinkDetails.Uploader = 'Owner'
            } else if (candi.Helpers.includes(thisUser)) {
               weblinkDetails.Uploader = 'Helper'
            } else {
               weblinkDetails.Uploader = 'SiteAdmin'
            }
            const docRefCandiGallery = doc(db, 'ElectionCandidates', candiId, 'Gallery', '001')
            const docSnapCandiGallery = await getDoc(docRefCandiGallery)

            if (docSnapCandiGallery.exists()) {
               await updateDoc(docRefCandiGallery, {
                  WebLinks: arrayUnion(weblinkDetails.Weblink),
                  WebLinksObj: arrayUnion(weblinkDetails),
               })
            } else {
               setDoc(
                  docRefCandiGallery,
                  { WebLinks: [weblinkDetails.Weblink], WebLinksObj: [weblinkDetails] },
                  { merge: true },
               )
            }

            // update the website display
            weblinksFromGallery.push(weblinkDetails)

            // close modal
            // setOpenModalWeblink(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleRemoveWeblink = async (e, item) => {
      e.preventDefault()
      console.log('item; ', item.Description)
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            if (confirm('Are you sure you want to remove this web link?')) {
               try {
                  const newItem = {
                     ...item,
                     Removed: true,
                     RemovedBy: auth.currentUser.uid,
                     RemovedByName: nameOfUser,
                     RemovedByImage: userProfile.Image.Image,
                     RemovedDt: new Date(),
                     RemovedByIP: ip.data.ip,
                  }
                  const docRefCandiGallery = doc(db, 'ElectionCandidates', candiId, 'Gallery', '001')
                  await updateDoc(docRefCandiGallery, {
                     WebLinksObj: arrayRemove(item),
                  })
                  await updateDoc(docRefCandiGallery, {
                     WebLinksObj: arrayUnion(newItem),
                  })

                  const list = weblinksFromGallery.filter((item) => item !== selectedItem)
                  setWeblinksFromGallery(list)
               } catch (error) {
                  console.log('error removing link', error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const [selectedItem, setSelectedItem] = useState('')

   const [openModalEditGalleryWeblink, setOpenModalEditGalleryWeblink] = useState(false)

   const handleCloseModalEditGalleryWeblink = () => setOpenModalEditGalleryWeblink(false)

   const handleEditGalleryWeblink = async (e, item) => {
      e.preventDefault()
      console.log('item; ', item.Description)
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            setSelectedItem(item)
            setOpenModalEditGalleryWeblink(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleEditGalleryWeblinkSubmit = async (details) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            const newItem = { ...selectedItem, Priority: details.Priority, Description: details.Description }
            const docRefCandiGallery = doc(db, 'ElectionCandidates', candiId, 'Gallery', '001')
            await updateDoc(docRefCandiGallery, {
               WebLinksObj: arrayRemove(selectedItem),
            })
            await updateDoc(docRefCandiGallery, {
               WebLinksObj: arrayUnion(newItem),
            })
            // setSelectedItem('')
            setOpenModalEditGalleryWeblink(false)

            // update local state
            const list = weblinksFromGallery.filter((item) => item !== selectedItem)
            list.push(newItem)
            const sortedList = [...list].sort((a, b) => a.Priority - b.Priority)
            setWeblinksFromGallery(sortedList)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            //
            // console.log('stage xxx 1')
            const imageRef = ref(dbStorage, `ElectionCandidates/${candi.Election_id}/${imageDetails.image.name + v4()}`)
            // console.log('stage xxx 2')
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const obj = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     ModDt: '',
                  }
                  if (candi.User_id === thisUser) {
                     obj.Uploader = 'Owner'
                  } else if (candi.Helpers.includes(thisUser)) {
                     obj.Uploader = 'Helper'
                  } else {
                     obj.Uploader = 'SiteAdmin'
                  }
                  //
                  // console.log('stage xxx 3')
                  const docRefCandiGallery = doc(db, 'ElectionCandidates', candiId, 'Gallery', '001')
                  const docSnapCandiGallery = await getDoc(docRefCandiGallery)

                  if (docSnapCandiGallery.exists()) {
                     await updateDoc(docRefCandiGallery, {
                        ImagesFromGallery: arrayUnion(url),
                        ImagesFromGalleryObj: arrayUnion(obj),
                     })
                  } else {
                     setDoc(
                        docRefCandiGallery,
                        { ImagesFromGallery: [url], ImagesFromGalleryObj: [obj] },
                        { merge: true },
                     )
                  }

                  imagesFromGallery.push(url)
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   //

   return (
      <Box sx={{ minHeight: 600, px: 0.5, pb: 20 }}>
         <ReactShareButtons title={title} hashTags={hashTags} hashTag={hashTag} image={image} />
         <div>
            <Helmet>
               <title>{`Track Democracy - Candidate - ${candiName} - ${elec.Name}`}</title>
               <meta name="og:image" property="og:image" content={image} />
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 1,
               px: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #545159, #002244, #545159)',
            }}
         >
            {/* <CardMedia component="img" sx={{ width: 151 }} image={CandidateStamp} /> */}
            <Box>
               <Box
                  sx={{
                     backgroundColor: '#EDC970',
                     borderRadius: 1,
                     fontWeight: 800,
                     p: 0.5,
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >
                  Candidate {'\n'} प्रत्याशी
               </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto' }}>
                  {candi && (
                     <div>
                        {candi.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 800, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.NameLocal}
                           </Typography>
                        )}
                        {candi.NameHindi && candi.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 800, fontSize: 30, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.NameHindi}
                           </Typography>
                        )}
                        {candi.NameHindi && candi.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 800, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.NameHindi}
                           </Typography>
                        )}
                        {candi.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 800, fontSize: 30, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.Name}
                           </Typography>
                        )}
                        {candi.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 800, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {candi.Name}
                           </Typography>
                        )}
                        <Box sx={{ display: 'flex', direction: 'row' }}>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                              }}
                           >
                              {candi.ElectionNameLocal !== '' && (
                                 <Box>
                                    <Link
                                       sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Montserrat' }}
                                       href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {candi.ElectionNameLocal}
                                    </Link>
                                 </Box>
                              )}
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Montserrat' }}
                                    href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {candi.ElectionNameHindi}
                                 </Link>
                              </Box>
                              <Box>
                                 <Link
                                    sx={{ color: '#ffffff', fontWeight: 700, fontFamily: 'Montserrat' }}
                                    href={`/election/${candi.ElectionName}/${candi.Election_id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {candi.ElectionName}{' '}
                                 </Link>
                              </Box>
                              {elec && elec.DateStart && (
                                 <Box
                                    sx={{
                                       display: 'inline',
                                       mx: 0.5,
                                       fontSize: 14,
                                       fontWeight: 400,
                                       color: '#ffffff',
                                    }}
                                 >
                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY')}

                                    {moment(elec.DateStart.toDate()).format('Do MMM YYYY') !==
                                       moment(elec.DateFinish.toDate()).format('Do MMM YYYY') && (
                                       <> - {moment(elec.DateFinish.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                 </Box>
                              )}
                           </Box>
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia>
               {candi.Name && (
                  <Badge
                     overlap="circular"
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                     badgeContent={
                        countWordsUsingReplace(candi.Name) > 1 ? (
                           <SmallAvatar
                              {...stringAvatar50(candi.Name)}
                              alt={candi.Name}
                              src={candi.PartyFlag ? candi.PartyFlag : candi.AllottedSymbol}
                           />
                        ) : (
                           <SmallAvatar
                              {...stringAvatar50Single(candi.Name)}
                              src={candi.PartyFlag ? candi.PartyFlag : candi.AllottedSymbol}
                           />
                        )
                     }
                  >
                     {countWordsUsingReplace(candi.Name) > 1 ? (
                        <Avatar {...stringAvatar150(candi.Name)} alt="Travis Howard" src={candi.Image} />
                     ) : (
                        <Avatar {...stringAvatar150Single(candi.Name)} alt="Travis Howard" src={candi.Image} />
                     )}
                  </Badge>
               )}

               {/* <Avatar alt="An apple" sx={{ height: 150, width: 150 }} src={candi.Image} /> */}
            </CardMedia>
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Credentials \n प्रामाणिकताएँ`} {...a11yProps(1)} />
                  <StyledTab1 label={`News / Statements \n समाचार / वक्तव्य`} {...a11yProps(2)} />
                  <StyledTab1 label={`Works \n कार्य`} {...a11yProps(3)} />
                  <StyledTab1 label={`Interact \n संवाद करें`} {...a11yProps(4)} />
               </Tabs>
            </div>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <SwipeableViews
                     axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                     index={value}
                     onChangeIndex={handleChangeNavTabIndex}
                  >
                     <TabPanel2 value={value} index={0} dir={theme.direction}>
                        {candi && (
                           <ElecCandiBasicInfo
                              candi={candi}
                              elec={elec}
                              attachedToConsti={attachedToConsti}
                              candisList={candisList}
                              sortCandisList={sortCandisList}
                              consti={consti}
                           />
                        )}
                     </TabPanel2>
                     <TabPanel2 value={value} index={1} dir={theme.direction}>
                        {candi && <ElecCandiCred candi={candi} elec={elec} />}
                     </TabPanel2>
                     <TabPanel2 value={value} index={2} dir={theme.direction}>
                        {candi && (
                           <ElecCandiNews
                              candi={candi}
                              elec={elec}
                              weblinksListRoot={weblinksListRoot}
                              weblinksList={weblinksList}
                              handleWeblinksUpdate={handleWeblinksUpdate}
                           />
                        )}
                     </TabPanel2>
                     <TabPanel2 value={value} index={3} dir={theme.direction}>
                        {candi && <ElecCandiWorks candi={candi} elec={elec} />}
                     </TabPanel2>
                     <TabPanel2 value={value} index={4} dir={theme.direction}>
                        {candi && (
                           <ElecCandiInteract
                              candi={candi}
                              elec={elec}
                              attachedToConsti={attachedToConsti}
                              petitionCategoriesList={petitionCategoriesList}
                              petitionsList={petitionsList}
                              handlePetitionsListUpdate={handlePetitionsListUpdate}
                           />
                        )}
                     </TabPanel2>
                  </SwipeableViews>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4} sx={{ px: 1 }}>
                  {(isDataEditor ||
                     isSuper ||
                     (candi.User_id && candi.User_id === thisUser) ||
                     (candi.Helpers && candi.Helpers.includes(thisUser))) && (
                     <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Button
                           variant="outlined"
                           sx={{
                              textTransform: 'none',
                              py: 0.5,
                              minWidth: 0,
                              px: 1,
                              // backgroundColor: '#03a9f4',
                              whiteSpace: 'pre-line',
                              lineHeight: 1.2,
                              '&:hover': { backgroundColor: '#01579b', color: '#FFFFFF' },
                              // mr: 2,
                              // display: 'none',
                           }}
                           onClick={(e) => {
                              handleOpenModalWeblink(e)
                           }}
                        >
                           Add video link
                        </Button>
                        <Button
                           variant="outlined"
                           sx={{
                              textTransform: 'none',
                              py: 0.5,
                              minWidth: 0,
                              px: 1,
                              // backgroundColor: '#03a9f4',
                              whiteSpace: 'pre-line',
                              lineHeight: 1.2,
                              '&:hover': { backgroundColor: '#01579b', color: '#FFFFFF' },
                              display: 'none',
                           }}
                           onClick={(e) => {
                              handleOpenModalImage(e)
                           }}
                        >
                           Add image
                        </Button>
                     </Box>
                  )}
                  <List dense>
                     {weblinksFromGallery &&
                        weblinksFromGallery.length > 0 &&
                        weblinksFromGallery.map((item, index) => (
                           <div key={index}>
                              <ListItem sx={{ pl: 0, ml: 0 }} key={index}>
                                 <ListItemAvatar>
                                    <Microlink url={item.Weblink} fetchData lazy={{ threshold: 0.5 }} />
                                 </ListItemAvatar>
                                 <ListItemText
                                    sx={{ ml: 1 }}
                                    secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                    primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
                                    // primary={<>{item.Description}</>}
                                 />
                              </ListItem>
                              <Typography variant="body2" align="left">
                                 {item.Description}
                              </Typography>
                              {(isDataEditor ||
                                 isSuper ||
                                 (candi.User_id && candi.User_id === thisUser) ||
                                 (candi.Helpers && candi.Helpers.includes(thisUser))) && (
                                 <>
                                    <IconButton
                                       edge="end"
                                       aria-label="editIntro"
                                       onClick={(e) => {
                                          handleEditGalleryWeblink(e, item)
                                       }}
                                       sx={{ mx: 1, height: '30px', width: '30px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleRemoveWeblink(e, item)
                                       }}
                                       sx={{ mx: 1, height: '30px', width: '30px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    P: {item.Priority}
                                 </>
                              )}
                              <Divider />
                           </div>
                        ))}
                  </List>
               </Grid2>
            </Grid2>
            <Box id="boxModals">
               <Modal
                  open={openModalWeblink}
                  onClose={handleCloseModalWeblink}
                  aria-labelledby="modal-modal-title-website"
                  aria-describedby="modal-modal-description-website"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-website"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add a video web link
                     </Typography>
                     <AddWeblinkWithPriority
                        handleAddWeblinkWithPriority={handleAddWeblinkWithPriority}
                     ></AddWeblinkWithPriority>
                  </Box>
               </Modal>
               <Modal
                  open={openModalEditGalleryWeblink}
                  onClose={handleCloseModalEditGalleryWeblink}
                  aria-labelledby="modal-modal-title-EditGalleryWeblink"
                  aria-describedby="modal-modal-description-EditGalleryWeblink"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-EditGalleryWeblink"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Edit
                     </Typography>
                     <EditPriorityAndDescr
                        handleEditPriorityAndDescr={handleEditGalleryWeblinkSubmit}
                        item={selectedItem}
                     ></EditPriorityAndDescr>
                  </Box>
               </Modal>
               <Modal
                  open={openModalImage}
                  onClose={handleCloseModalImage}
                  aria-labelledby="modal-modal-title-logo"
                  aria-describedby="modal-modal-description-logo"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-logo"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add image
                     </Typography>
                     <AddImageOnly handleAddImage={handleAddImage}></AddImageOnly>
                  </Box>
               </Modal>
            </Box>
         </TabContext>
      </Box>
   )
}

export default ElecCandi
