import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import { auth, db } from '../../../FirebaseConfig'
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, increment, query, updateDoc } from 'firebase/firestore'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   Link,
   List,
   ListItem,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import AddScam from './AddScam'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function ScamsAll() {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')

   const userProfile = useSelector(selectUserProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.TitleFirst + ' ' + userProfile.TitleMiddle + ' ' + userProfile.TitleLast
         setNameOfUser(name)
      }
   }, [userProfile])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [scamsList, setScamsList] = useState([])

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         fetchScams()
         console.log('ffff')
         setFirstLoadStatus('success')
      }
   }, [])

   const fetchScams = async () => {
      console.log('fetching scams at first fetch: ')
      try {
         console.log('fetching scams at first fetch 2: ')
         const querry = query(collection(db, 'ScamsBaseLists', '2025-India', 'CoreLists'))
         const querySnapshot = await getDocs(querry)

         const listFetched = []

         if (!querySnapshot.empty) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Scams)
            })
         }
         console.log('scams at first fetch: ', listFetched)
         const sortedList = listFetched.sort((a, b) => {
            // bigger date first
            if (moment(a.DatePublic.toDate()).isAfter(moment(b.DatePublic.toDate()))) {
               return -1
            } else if (moment(a.DatePublic.toDate()).isBefore(moment(b.DatePublic.toDate()))) {
               return 1
            } else {
               return 0
            }
         })
         console.log('sorted scams at first fetch: ', sortedList)
         setScamsList(sortedList)
      } catch (err) {
         console.log('error fetching petitions', err)
      }
   }

   // related to form filling and submission

   const [openModalAddScam, setOpenModalAddScam] = useState(false)
   const handleOpenModalAddScam = (e) => {
      e.preventDefault()
      setOpenModalAddScam(true)
   }
   const handleCloseModalAddScam = () => setOpenModalAddScam(false)

   const [lastScam, setLastScam] = useState('')
   const [uploadingScam, setUploadingScam] = useState(false)
   const [uploadedScam, setUploadedScam] = useState(false)
   const [uploadingScamFailed, setUploadingScamFailed] = useState(false)

   const handleAddScam = async (scamDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (scamDetails.Title.length >= 5 && scamDetails.DatePublic !== '') {
               scamDetails.Title = scamDetails.Title.trim()
               scamDetails.TitleHindi = scamDetails.TitleHindi.trim()
               scamDetails.TitleLocal = scamDetails.TitleLocal.trim()

               scamDetails.CrBy = auth.currentUser.uid
               scamDetails.CrByName = nameOfUser
               scamDetails.CrByImage = userProfile.Image.Image
               scamDetails.CrDt = new Date()
               scamDetails.CrIP = ip.data.ip

               scamDetails.Country_ids = []
               scamDetails.Country_Objects = []
               scamDetails.State_ids = []
               scamDetails.State_Objects = []
               scamDetails.Division_ids = []
               scamDetails.Division_Objects = []
               scamDetails.District_ids = []
               scamDetails.District_Objects = []
               scamDetails.SubDistrict_ids = []
               scamDetails.SubDistrict_Objects = []
               scamDetails.Block_ids = []
               scamDetails.Block_Objects = []
               scamDetails.Village_ids = []
               scamDetails.Village_Objects = []

               scamDetails.TitleOld = [
                  {
                     Title: scamDetails.Title,
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     Reason: 'Fresh entry',
                  },
               ]
               if (scamDetails.TitleHindi !== '') {
                  scamDetails.TitleHindiOld = [
                     {
                        Title: scamDetails.TitleHindi,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        Reason: 'Fresh entry',
                     },
                  ]
               }
               if (scamDetails.TitleLocal !== '') {
                  scamDetails.TitleLocalOld = [
                     {
                        Title: scamDetails.TitleLocal,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        Reason: 'Fresh entry',
                     },
                  ]
               }
               scamDetails.DatePublicOld = [
                  {
                     Date: scamDetails.DatePublic,
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     Reason: 'Fresh entry',
                  },
               ]
               if (scamDetails.DateStart !== '') {
                  scamDetails.DateStartOld = [
                     {
                        Date: scamDetails.DateStart,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        Reason: 'Fresh entry',
                     },
                  ]
               }

               const record = {
                  Scam_id: '',
                  Title: scamDetails.Title,
                  TitleHindi: scamDetails.TitleHindi,
                  TitleLocal: scamDetails.TitleLocal,

                  ImageMain: scamDetails.ImageMain,

                  Country_ids: [],
                  Country_Objects: [],
                  State_ids: [],
                  State_Objects: [],
                  Division_ids: [],
                  Division_Objects: [],
                  District_ids: [],
                  District_Objects: [],
                  SubDistrict_ids: [],
                  SubDistrict_Objects: [],
                  Block_ids: [],
                  Block_Objects: [],
                  Village_ids: [],
                  Village_Objects: [],

                  DatePublic: new Date(scamDetails.DatePublic),
               }

               console.log('scamDetails 1: ', scamDetails)

               setLastScam(scamDetails.Title)
               setUploadingScam(true)
               console.log('scamDetails 2: ', scamDetails, ' uploadingScam: ', uploadingScam)
               try {
                  let newRecord = {}
                  const docRef = await addDoc(collection(db, 'Scams'), scamDetails)
                  scamDetails.id = docRef.id
                  newRecord = { ...record, Scam_id: docRef.id }

                  // console.log('record 1: ', record)
                  console.log('Date: ', new Date(scamDetails.DatePublic).toISOString())

                  const docRefScamBase = doc(db, 'ScamsBaseLists', '2025-India')
                  await updateDoc(docRefScamBase, {
                     ScamsCount: increment(1),
                  })

                  const docRefScamBaseCoreList = doc(db, 'ScamsBaseLists', '2025-India', 'CoreLists', '00001')
                  await updateDoc(docRefScamBaseCoreList, {
                     Scams: arrayUnion(newRecord),
                  })

                  // Note:
                  // setScamsList([...scamsList, record]) was causing problem / error for date adjustment in the list
                  // hence, on insert, we fetch the scam data afresh, as follows

                  const docRefForList = doc(db, 'ScamsBaseLists', '2025-India', 'CoreLists', '00001')
                  const docSnap = await getDoc(docRefForList)
                  setScamsList(docSnap.data().Scams)

                  setUploadingScam(false)
                  setUploadedScam(true)
                  setUploadingScamFailed(false)
               } catch (error) {
                  alert('Error adding scam: ', error)
                  console.log('error adding scam: ', error.message)
                  setUploadingScam(false)
                  setUploadedScam(false)
                  setUploadingScamFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Scams`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.2,
            }}
         >
            Scams: {'\n'} घोटाले:
         </Typography>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box>
               {(isDataEditor || isSuper) && (
                  <Box sx={{ display: 'flex', px: 1, mt: 1 }}>
                     <Button
                        onClick={(e) => {
                           handleOpenModalAddScam(e)
                        }}
                        size="small"
                        variant="outlined"
                        sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0 }}
                     >
                        Add a scam
                     </Button>
                  </Box>
               )}
               <Box sx={{ mx: 1, mt: 1 }}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper', my: 1, p: 0 }}>
                     {scamsList &&
                        scamsList.length > 0 &&
                        scamsList.map((item) => (
                           <div key={item.Scam_id}>
                              <ListItem alignItems="flex-start" key={item.Scam_id} secondaryAction={null}>
                                 <ListItemText
                                    secondaryTypographyProps={{ align: 'left' }}
                                    primary=<Link
                                       href={`/scam/${item.Title}/${item.Scam_id}`}
                                       rel="noreferrer"
                                       target="_blank"
                                    >
                                       {item.Title}
                                    </Link>
                                    secondary={
                                       <React.Fragment>
                                          {item.TitleHindi !== '' && (
                                             <>
                                                <Link
                                                   href={`/scam/${item.Title}/${item.Scam_id}`}
                                                   rel="noreferrer"
                                                   target="_blank"
                                                >
                                                   {item.TitleHindi}
                                                </Link>
                                                <br />
                                             </>
                                          )}
                                          {item.TitleLocal !== '' && (
                                             <>
                                                <Link
                                                   href={`/scam/${item.Title}/${item.Scam_id}`}
                                                   rel="noreferrer"
                                                   target="_blank"
                                                >
                                                   {item.TitleLocal}
                                                </Link>
                                                <br />
                                             </>
                                          )}
                                          {'Made pubic on: '}
                                          <Typography
                                             sx={{ display: 'inline' }}
                                             component="span"
                                             variant="body2"
                                             color="text.primary"
                                          >
                                             {moment(item.DatePublic.toDate()).format('Do MMMM YYYY')}
                                          </Typography>
                                       </React.Fragment>
                                    }
                                 />
                              </ListItem>
                              <Divider />
                           </div>
                        ))}
                  </List>
                  {firstLoadStatus !== 'idle' && scamsList && scamsList.length < 1 && (
                     <Alert variant="outlined" severity="warning" sx={{ mx: 5, mt: 5 }}>
                        No scam is available for display.
                     </Alert>
                  )}
               </Box>
            </Box>
         )}
         <Box id="boxModals">
            <Modal
               open={openModalAddScam}
               onClose={handleCloseModalAddScam}
               aria-labelledby="modal-modal-title-addScam"
               aria-describedby="modal-modal-description-addScam"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addScam"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a SCAM
                  </Typography>
                  <AddScam
                     lastScam={lastScam}
                     uploadingScam={uploadingScam}
                     uploadedScam={uploadedScam}
                     uploadingScamFailed={uploadingScamFailed}
                     handleAddScam={handleAddScam}
                  ></AddScam>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

export default ScamsAll
