import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import { doc, getDoc } from 'firebase/firestore'

import styles from './President.module.css'
import { Box, Card, CardContent, CardMedia, Grid, Link, Tabs, Typography } from '@mui/material'
import { TabContext } from '@mui/lab'

import SwipeableViews from 'react-swipeable-views'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import { db } from '../../../FirebaseConfig'

import logoKia from '../../../Images/Adverts/Kia-01.jpeg'
import logoKotak from '../../../Images/Adverts/Kotak-01.jpeg'
import logoMaxLife from '../../../Images/Adverts/MaxLife-01.jpeg'

import PresidentBasicInfo from '../PresidentBasicInfo/PresidentBasicInfo'
import PresidentElecs from '../PresidentElecs/PresidentElecs'
import PresidentPetition from '../PresidentPetition/PresidentPetition'

function President() {
   const { legisId, legisName } = useParams('legisId')
   console.log('parameters from link: ', legisId, '-', legisName)

   const navigate = useNavigate()

   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [legis, setLegis] = useState({})
   const [fetchStatus, setFetchStatus] = useState('idle')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchLegis(legisId)
      }
   }, [])

   const fetchLegis = async (legisId) => {
      try {
         const docRef = doc(db, 'Legislatures', legisId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            if (docSnap.data().Name !== 'President' && docSnap.data().Name !== 'Vice President') {
               navigate('/', { replace: true })
            } else {
               setLegis({ ...docSnap.data(), id: docSnap.id })
            }
         } else {
            navigate('/', { replace: true })
         }

         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   return (
      <Box sx={{ minHeight: 600, px: 0.5, pb: 20 }}>
         <Card
            sx={{
               my: 1,
               py: 0,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundImage: 'linear-gradient(to right, #403b4a, #d6d6d6)',
            }}
         >
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                  {legis && (
                     <div>
                        {legis.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {legis.NameLocal}
                           </Typography>
                        )}
                        {legis.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {legis.NameHindi}
                           </Typography>
                        )}
                        {legis.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {legis.NameHindi}
                           </Typography>
                        )}
                        {legis.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {legis.Name}
                           </Typography>
                        )}
                        {legis.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {legis.Name}
                           </Typography>
                        )}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ maxWidth: 150, maxHeight: 70, borderRadius: 1, mr: 1, objectFit: 'contain' }}
               image={
                  legis.Image
                     ? legis.Image
                     : 'https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
               }
               alt={legis.Name}
            />
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Send Petitions \n याचिकाएँ भेजें`} {...a11yProps(1)} />
                  <StyledTab1 label={`Elections \n चुनाव`} {...a11yProps(2)} />
               </Tabs>
            </div>
            <Grid container spacing={1} sx={{ mt: 1 }} id="vBoxGridContainer">
               <Grid item xs={12} sm={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                        id="swipeableee"
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {legis && <PresidentBasicInfo props={legis} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {legis && <PresidentPetition legis={legis} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {legis && <PresidentElecs legis={legis} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Grid container spacing={1} sx={{ my: 0.5 }} disableEqualOverflow>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.kia.com/in/our-vehicles/seltos/showroom.html?utm_source=msan&utm_medium=cpm&utm_campaign=seltos-sustenance-2&utm_content=banner&dclid=CN7ZnMGm-YcDFY-O2AUd0BIuZA"
                           >
                              <img src={logoKia} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.kotak.com/en/personal-banking/insurance/life-insurance/protection-plans/kotak-e-term.html"
                           >
                              <img src={logoKotak} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.maxlifeinsurance.com/investment-plans/investment-calculator?utmCode=14230408&utm_source=Impact&utm_medium=Bing_Native_Savings&utm_campaign=May24_MLI_BingNative_Savings_English_Domestic_ROI_Misc_PR&utm_term=1Cr_FatherSon1English_ULIP_15032024&utm_content={{site_source_name}}&utm_theme=ULIP"
                           >
                              <img src={logoMaxLife} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.kia.com/in/our-vehicles/seltos/showroom.html?utm_source=msan&utm_medium=cpm&utm_campaign=seltos-sustenance-2&utm_content=banner&dclid=CN7ZnMGm-YcDFY-O2AUd0BIuZA"
                           >
                              <img src={logoKia} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                     <Grid xs={6} sm={12} md={12}>
                        <Box
                           sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 1,
                           }}
                        >
                           <Link
                              target="_blank"
                              rel="noopener"
                              href="https://www.maxlifeinsurance.com/investment-plans/investment-calculator?utmCode=14230408&utm_source=Impact&utm_medium=Bing_Native_Savings&utm_campaign=May24_MLI_BingNative_Savings_English_Domestic_ROI_Misc_PR&utm_term=1Cr_FatherSon1English_ULIP_15032024&utm_content={{site_source_name}}&utm_theme=ULIP"
                           >
                              <img src={logoMaxLife} className={styles.imgAd} />
                           </Link>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </TabContext>
      </Box>
   )
}

export default President
