import {
   Box,
   Card,
   CardContent,
   CardMedia,
   Divider,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Tabs,
   Typography,
   useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import moment from 'moment'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import ElecBasicInfo from '../ElecBasicInfo/ElecBasicInfo'
import ElecConstis from '../ElecConstis/ElecConstis'
import ElecCandis from '../ElecCandis/ElecCandis'
import ElecManifestoes from '../ElecManifestoes/ElecManifestoes'
import ElecStarCampaigners from '../ElecStarCampaigners/ElecStarCampaigners'
import ElecFilters from '../ElecFilters/ElecFilters'
import { Helmet } from 'react-helmet'
import ElecNews from '../ElecNews/ElecNews'
import ReactShareButtons from '../../../Utils/CustomButtons/ReactShareButtons'

import Microlink from '@microlink/react'
import ElecExitPolls from '../ElecExitPolls/ElecExitPolls'

function Elec() {
   const { elecId, elecName } = useParams()
   const navigate = useNavigate()

   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
      setConstituencyId('')
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
      setConstituencyId('')
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [elec, setElec] = useState('')
   const [legis, setLegis] = useState({})
   const [candidatesListRoot, setCandidatesListRoot] = useState([])
   const [constisListFromElec, setConstisListFromElec] = useState([])
   const [constisListFromLegis, setConstisListFromLegis] = useState([])
   const [weblinksListRoot, setWeblinksListRoot] = useState([])
   const [weblinksList, setWeblinksList] = useState([])

   const [exitPollLinksListRoot, setExitPollLinksListRoot] = useState([])
   const [exitPollLinksList, setExitPollLinksList] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchElec(elecId)
      }
   }, [])

   const fetchElec = async (elecId) => {
      try {
         const docRef = doc(db, 'Elections', elecId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setElec({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      if (elec !== '') {
         if (elecId !== elec.id || elecName !== elec.Name) {
            navigate('/', { replace: true })
         } else {
            fetchConstisFromElec(elec.id)
            fetchLegis(elec.Legislature_id)
            fetchConstisFromLegis(elec.Legislature_id)
            fetchCadidates()
            fetchWeblinks(elec.id)
            fetchExitPollLinks(elec.id)
         }
      }
   }, [elec])

   const fetchConstisFromElec = async (elecId) => {
      try {
         const docRef = doc(db, 'Elections', elecId, 'CoreLists', 'ConstituencyCoreList')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Constituencies].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setConstisListFromElec(sortedList)
         }
      } catch (err) {
         console.log('error fetching constis: ', err)
      }
   }

   const fetchConstisFromLegis = async (legisId) => {
      try {
         const docRef = doc(db, 'Legislatures', legisId, 'CoreLists', 'ConstituenciesCoreList')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Constituencies].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setConstisListFromLegis(sortedList)
         }
      } catch (err) {
         console.log('error fetching constis from legis: ', err)
      }
   }

   const fetchLegis = async (LegislatureId) => {
      try {
         const docRef = doc(db, 'Legislatures', LegislatureId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setLegis({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error fetchinf elec', err)
      }
   }

   const fetchCadidates = async () => {
      try {
         if (elec.id === 'lfoOBVgQSifbNXkzh07j' || elec.id === 'eHt7k2NNw54Xaq2T8kAK') {
            // these are jk and haryana elections 2024
            // in these cases the CandidateCoreLists docs were placed inside the election collection
            // later the structure changed ...
            if (elec.CandidatesCoreLists.length > 0) {
               const candisFromCoreListFetched = []

               elec.CandidatesCoreLists.forEach(async (item) => {
                  const docRefCore = doc(db, 'ElectionCandidates', item)
                  const docSnapCore = await getDoc(docRefCore)
                  candisFromCoreListFetched.push(...docSnapCore.data().Candidates)
               })
               setCandidatesListRoot(candisFromCoreListFetched)
            }
         } else {
            const querryCandis = query(
               collection(db, 'Elections', elec.id, 'CoreLists'),
               where('DocType', '==', 'CandidatesCoreList'),
            )
            const querySnapshot = await getDocs(querryCandis)

            const listFetched = []

            // if (querySnapshot.exists()) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Candidates)
            })
            // }
            console.log('condidates at first fetch: ', listFetched)
            setCandidatesListRoot(listFetched)
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   const fetchWeblinks = async (ElecId) => {
      try {
         const querryWeblinks = query(collection(db, 'Elections', ElecId, 'WeblinksLists'))
         const querySnapshot = await getDocs(querryWeblinks)
         if (querySnapshot && querySnapshot.length < 1) {
            setWeblinksList([])
         } else {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Weblinks)
            })
            // const filteredList = listFetched.filter((item) => item.Election_id === elec.id)
            const sortedList = [...listFetched].sort((a, b) => {
               // bigger date first
               if (moment(a.Date.toDate()).isAfter(moment(b.Date.toDate()))) {
                  return -1
               } else if (moment(a.Date.toDate()).isBefore(moment(b.Date.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setWeblinksListRoot(listFetched)
            setWeblinksList(sortedList)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const handleWeblinksUpdate = (obj) => {
      weblinksListRoot.push(obj)
   }

   // exit poll links
   const fetchExitPollLinks = async (ElecId) => {
      try {
         const querryWeblinks = query(collection(db, 'Elections', ElecId, 'ExitPollLinksLists'))
         const querySnapshot = await getDocs(querryWeblinks)
         if (querySnapshot && querySnapshot.length < 1) {
            setExitPollLinksList([])
         } else {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().ExitPollLinks)
            })
            // const filteredList = listFetched.filter((item) => item.Election_id === elec.id)
            const sortedList = [...listFetched].sort((a, b) => {
               // bigger date first
               if (moment(a.Date.toDate()).isAfter(moment(b.Date.toDate()))) {
                  return -1
               } else if (moment(a.Date.toDate()).isBefore(moment(b.Date.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setExitPollLinksListRoot(listFetched)
            setExitPollLinksList(sortedList)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const handleExitPollLinksListUpdate = (obj) => {
      exitPollLinksListRoot.push(obj)
   }

   // constituencies
   const [constituencyId, setConstituencyId] = useState('')

   const handleConstituencyCandidatesLink = async (constituencyId) => {
      setConstituencyId('')
      setConstituencyId(constituencyId)
   }

   const handleConstisListRootUpdate = async (newList) => {
      setConstisListFromElec(newList)
   }

   // candidates

   const handleCandidatesListRootUpdate = async (newList) => {
      setCandidatesListRoot(newList)
   }

   const handleCandidatesListRootUpdateForConsti = async (oldCandidate, newCandidate) => {
      const root = candidatesListRoot.filter((item) => item.Candidate_id !== oldCandidate.Candidate_id)
      root.push(newCandidate)
      setCandidatesListRoot(root)
   }

   useEffect(() => {
      if (fetchStatus !== 'idle' && constituencyId !== '') {
         setValue(2)
         window.scrollTo({ top: 0, left: 0 })
      }
   }, [constituencyId])

   const theme = useTheme()

   const title = 'Track Democracy -' + elec.Name
   const hashTag = '#democracy'
   const hashTags = ['DelhiElections', 'Elections', 'India', 'BJP', 'AAP', 'Congress']
   const image = elec.Image
   //
   const shortsVideos = [
      {
         sources: 'https://www.youtube.com/shorts/td-bgKs2CsI',
         title: 'Delhi Elections 2025 | Seelampur | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/65CBbzZB0FU',
         title: 'Delhi Elections 2025 | Sangam Vihar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/FpZAIE2GzcY',
         title: 'Delhi Elections 2025 | Shakur Basti | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/bQPeFn1r7Tw',
         title: 'Delhi Elections 2025 | Sadar Bazar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/U22m08XezF4',
         title: 'Delhi Elections 2025 | Rohtas Nagar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/_aEcXh4qpQ0',
         title: 'Delhi Elections 2025 | Rohini | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/QCXidbFwtRo',
         title: 'Delhi Elections 2025 | Rithala | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/HHBSePT2MO8',
         title: 'Delhi Elections 2025 | Rajouri Garden | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/_-eDxVxaf84',
         title: 'Delhi Elections 2025 |  Gandhi Nagar | Candidates | Online Support',
      },
      {
         sources: 'https://www.youtube.com/shorts/uWP5G0q4b0Q',
         title: 'Delhi Elections 2025 | Krishna Nagar | Candidates | Online Support',
      },
   ]

   return (
      <Box sx={{ minHeight: 800, px: 0.5, pb: 20 }}>
         <ReactShareButtons title={title} hashTags={hashTags} hashTag={hashTag} image={image} />
         <div>
            <Helmet>
               <title>{`Track Democracy - ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 1,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               // backgroundColor: '#000000',
               backgroundImage: 'linear-gradient(to right, #005900, #7f0000, #00007f, #000000, #ccc000, #cc8400)',
            }}
         >
            <Box
               sx={{
                  // display: 'flex',
                  // flexDirection: 'column',
                  px: 1,
                  py: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <CardContent sx={{ flex: '1 0 auto', p: 0 }}>
                  {elec && (
                     <div>
                        {elec.NameLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, lineHeight: 1.4, fontFamily: 'Montserrat' }}
                              color="#FFFFFF"
                           >
                              {elec.NameLocal}
                           </Typography>
                        )}
                        {elec.NameHindi && elec.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, lineHeight: 1.4, fontFamily: 'Montserrat' }}
                              color="#FFFFFF"
                           >
                              {elec.NameHindi}
                           </Typography>
                        )}
                        {elec.NameHindi && elec.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, lineHeight: 1.4, fontFamily: 'Montserrat' }}
                              color="#FFFFFF"
                           >
                              {elec.NameHindi}
                           </Typography>
                        )}
                        {(!elec.NameLocal || elec.NameLocal === '') && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 30, lineHeight: 1.4, fontFamily: 'Montserrat' }}
                              color="#FFFFFF"
                           >
                              {elec.Name}
                           </Typography>
                        )}
                        {elec.NameLocal && elec.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, lineHeight: 1.4, fontFamily: 'Montserrat' }}
                              color="#FFFFFF"
                           >
                              {elec.Name}
                           </Typography>
                        )}
                        <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#D0D3D5',
                              }}
                           >
                              <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateStart.toDate()).format('Do MMMM YYYY')}

                              {moment(elec.DateStart.toDate()).format('Do MMMM YYYY') !==
                                 moment(elec.DateFinish.toDate()).format('Do MMMM YYYY') && (
                                 <> - {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}</>
                              )}
                           </Box>
                        </Box>
                        {/* <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#b3bdbd',
                              }}
                           >
                              Poll end:
                           </Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(elec.DateFinish.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box> */}
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1 }}
               image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
               alt="Live from space album cover"
            />
         </Card>
         <TabContext value={value}>
            <Box>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  // centered
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Constituencies \n निर्वाचन क्षेत्र`} {...a11yProps(1)} />
                  <StyledTab1 label={`Candidates \n प्रत्याशी`} {...a11yProps(2)} />
                  <StyledTab1 label={`Filter \n निस्यंदन`} {...a11yProps(3)} />
                  <StyledTab1 label={`Manifestoes \n घोषणा पत्र`} {...a11yProps(4)} />
                  <StyledTab1 label={`News / Statements \n समाचार / वक्तव्य`} {...a11yProps(5)} />
                  {/* <StyledTab1 label={`Orders \n आदेश`} {...a11yProps(6)} /> */}
                  <StyledTab1 label={`Star Campaigners \n स्टार प्रचारक`} {...a11yProps(6)} />
                  <StyledTab1 label={`Exit Polls \n निर्गम मतदान`} {...a11yProps(7)} />
               </Tabs>
            </Box>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {elec && <ElecBasicInfo props={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {elec && (
                              <ElecConstis
                                 elec={elec}
                                 legis={legis}
                                 constisListFromLegis={constisListFromLegis}
                                 constisListFromElec={constisListFromElec}
                                 candidatesListRoot={candidatesListRoot}
                                 handleConstituencyCandidatesLink={handleConstituencyCandidatesLink}
                                 handleConstisListRootUpdate={handleConstisListRootUpdate}
                                 handleCandidatesListRootUpdateForConsti={handleCandidatesListRootUpdateForConsti}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {elec && (
                              <ElecCandis
                                 legis={legis}
                                 elec={elec}
                                 constituencyId={constituencyId}
                                 constisListFromElec={constisListFromElec}
                                 candidatesListRoot={candidatesListRoot}
                                 handleCandidatesListRootUpdate={handleCandidatesListRootUpdate}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {elec && <ElecFilters legis={legis} elec={elec} candidatesListRoot={candidatesListRoot} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {elec && <ElecManifestoes elec={elec} candidatesListRoot={candidatesListRoot} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={5} dir={theme.direction}>
                           {elec && (
                              <ElecNews
                                 elec={elec}
                                 weblinksList={weblinksList}
                                 weblinksListRoot={weblinksListRoot}
                                 handleWeblinksUpdate={handleWeblinksUpdate}
                                 handleConstituencyCandidatesLink={handleConstituencyCandidatesLink}
                              />
                           )}
                        </TabPanel2>
                        {/* <TabPanel2 value={value} index={6} dir={theme.direction}>
                           {elec && <ElecOrders props={elec} />}
                        </TabPanel2> */}
                        <TabPanel2 value={value} index={6} dir={theme.direction}>
                           {elec && <ElecStarCampaigners props={elec} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={7} dir={theme.direction}>
                           {elec && (
                              <ElecExitPolls
                                 elec={elec}
                                 candidatesListRoot={candidatesListRoot}
                                 exitPollLinksList={exitPollLinksList}
                                 exitPollLinksListRoot={exitPollLinksListRoot}
                                 handleExitPollLinksUpdate={handleExitPollLinksListUpdate}
                              />
                           )}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4} sx={{ px: 1 }}>
                  <List dense>
                     {shortsVideos &&
                        shortsVideos.length > 0 &&
                        shortsVideos.map((item, index) => (
                           <div key={index}>
                              <ListItem sx={{ pl: 0, ml: 0 }} key={index}>
                                 <ListItemAvatar>
                                    <Microlink url={item.sources} fetchData lazy={{ threshold: 0.5 }} />
                                 </ListItemAvatar>
                                 <ListItemText
                                    sx={{ ml: 1 }}
                                    secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                    primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
                                    // primary={<>{item.Description}</>}
                                 />
                              </ListItem>
                              <Typography variant="body2" align="left">
                                 {item.Description}
                              </Typography>
                              {/* {(isDataEditor ||
                                 isSuper ||
                                 (candi.User_id && candi.User_id === thisUser) ||
                                 (candi.Helpers && candi.Helpers.includes(thisUser))) && (
                                 <>
                                    <IconButton
                                       edge="end"
                                       aria-label="editIntro"
                                       onClick={(e) => {
                                          handleEditGalleryWeblink(e, item)
                                       }}
                                       sx={{ mx: 1, height: '30px', width: '30px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleRemoveWeblink(e, item)
                                       }}
                                       sx={{ mx: 1, height: '30px', width: '30px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    P: {item.Priority}
                                 </>
                              )} */}
                              <Divider />
                           </div>
                        ))}
                  </List>
               </Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Elec
