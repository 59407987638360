import React, { useEffect, useState } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { Box, Card, CardContent, CardMedia, Tabs, Typography, useTheme } from '@mui/material'
import { Helmet } from 'react-helmet'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import moment from 'moment'
import ScamBasicInfo from '../ScamBasicInfo/ScamBasicInfo'
import ScamNews from '../ScamNews/ScamNews'
import ScamStamp from '../../../Images/ScamStamp.png'

function Scam() {
   const { scamId } = useParams()
   const navigate = useNavigate()

   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [scam, setScam] = useState('')
   const [weblinksList, setWeblinksList] = useState([])
   const [weblinksListRoot, setWeblinksListRoot] = useState([])

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchScam(scamId)
      }
   }, [])

   const fetchScam = async (scamId) => {
      try {
         const docRef = doc(db, 'Scams', scamId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setScam({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      if (scam !== '') {
         fetchWeblinks(scam.id)
      }
   }, [scam])

   useEffect(() => {
      console.log('weblinksListRoot', weblinksListRoot)
   }, [weblinksListRoot])

   const fetchWeblinks = async (ElecId) => {
      try {
         const querry = query(collection(db, 'Scams', scamId, 'WeblinksLists'))
         const querySnapshot = await getDocs(querry)
         if (querySnapshot && !querySnapshot.empty) {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Weblinks)
            })
            // const filteredList = listFetched.filter((item) => item.Election_id === elec.id)
            const sortedList = [...listFetched].sort((a, b) => {
               // bigger date first
               if (moment(a.Date.toDate()).isAfter(moment(b.Date.toDate()))) {
                  return -1
               } else if (moment(a.Date.toDate()).isBefore(moment(b.Date.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setWeblinksListRoot(listFetched)
            setWeblinksList(sortedList)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const handleWeblinksUpdate = (obj) => {
      // weblinksListRoot.push(obj)
      setWeblinksListRoot([...weblinksListRoot, obj])
   }

   const theme = useTheme()
   return (
      <Box sx={{ minHeight: 800, px: 0.5, pb: 20 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - ${scam.Title}`}</title>
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               py: 2,
               pr: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               //    backgroundColor: '#7a0000',
               backgroundImage: 'linear-gradient(to right, #8e0000, #7a0000, #660000)',
            }}
         >
            <Box
               sx={{
                  // display: 'flex',
                  // flexDirection: 'column',
                  px: 1,
                  py: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <CardContent sx={{ flex: '1 0 auto', p: 0 }}>
                  {scam && (
                     <div>
                        {scam.TitleLocal && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, lineHeight: 1.4, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {scam.TitleLocal}
                           </Typography>
                        )}
                        {scam.TitleHindi && scam.TitleLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, lineHeight: 1.4, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {scam.TitleHindi}
                           </Typography>
                        )}
                        {scam.TitleHindi && scam.TitleLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, lineHeight: 1.4, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {scam.TitleHindi}
                           </Typography>
                        )}
                        {(!scam.TitleLocal || scam.TitleLocal === '') && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 20, lineHeight: 1.4, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {scam.Title}
                           </Typography>
                        )}
                        {scam.TitleLocal && scam.TitleLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, lineHeight: 1.4, fontFamily: 'Poppins' }}
                              color="#FFFFFF"
                           >
                              {scam.Title}
                           </Typography>
                        )}
                        <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 400,
                                 color: '#ffffff',
                              }}
                           >
                              {moment(scam.DatePublic.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box>
                     </div>
                  )}
               </CardContent>
            </Box>
            <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1, ml: 2 }}
               image={ScamStamp}
               alt="Live from space album cover"
            />
         </Card>
         <TabContext value={value}>
            <Box>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#f6db87',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Links \n लिंक`} {...a11yProps(1)} />
               </Tabs>
            </Box>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {scam && <ScamBasicInfo scam={scam} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {scam && (
                              <ScamNews
                                 scam={scam}
                                 weblinksList={weblinksList}
                                 weblinksListRoot={weblinksListRoot}
                                 handleWeblinksUpdate={handleWeblinksUpdate}
                              />
                           )}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4} sx={{ px: 1 }}></Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Scam
