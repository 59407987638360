/* eslint-disable no-case-declarations */
import {
   Alert,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   Link,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Stack,
   TextField,
   Typography,
   Box,
   Checkbox,
} from '@mui/material'

import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import React, { useEffect, useState } from 'react'

import styles from './LegisAllAdmin.module.css'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   orderBy,
   query,
   updateDoc,
   where,
} from 'firebase/firestore'
import { auth, db } from '../../../FirebaseConfig'
import Header from '../../../Components/Header/Header'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'

function LegisAllAdmin() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')
   // const [nameOfUserHindi, setNameOfUserHindi] = useState('')
   // const [nameOfUserLocal, setNameOfUserLocal] = useState('')

   const userProfile = useSelector(selectUserProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.NameFirst + ' ' + userProfile.NameLast
         // const nameHindi = userProfile.NameHindiFirst + ' ' + ' ' + userProfile.NameHindiLast
         // const nameLocal = userProfile.NameLocalFirst + ' ' + userProfile.NameLocalLast
         setNameOfUser(name)
         // setNameOfUserHindi(nameHindi)
         // setNameOfUserLocal(nameLocal)
      }
   }, [userProfile])

   useEffect(() => {
      console.log('legis all admin - first use effect entered')

      if (user.currentUser !== null) {
         console.log('legis all admin - found user state not-null')
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
            setThisUser(user.currentUser.id)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         console.log('legis all admin - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      fetchNaturesList()
   }, [])

   const [legisDetails, setLegisDetails] = useState({
      Name: '',
      NameOld: [],
      NameAlt: [],
      NameAltOld: [],
      NameHindi: '',
      NameHindiOld: [],
      NameLocal: '',
      NameLocalOld: [],
      Strata: '',
      Nature: '',
      Type: '',
      Duration: '',
      SingularMem: '',
      HasHouses: '',
      Houses: [],
      HouseNumbers: [],
      HasConstituencies: '',
      ParentLegislature_id: '',
      ParentLegislatureName: '',
      ElectiveSeats: 0,
      ElectiveSeatsOld: [],
      NominatedSeats: 0,
      NominatedSeatsOld: [],
      NominatingAuthorities: '',
      NominatingAuthoritiesOld: [],
      Constituencies: [],
      Image: null,
      ImagesOld: [],
      Flag: null,
      FlagOld: [],
      Symbol: null,
      SymbolOld: [],
      Ministry_Under_Legis_id: '',
      Ministry_Under_LegisName: '',
      Priority: 0,
      ForLegis: false,

      CountryName: '',
      Country_id: '',
      StateName: '',
      State_id: '',
      DivisionName: '',
      Division_id: '',
      DistrictName: '',
      District_id: '',
      SubDistrictName: '',
      SubDistrict_id: '',
      BlockName: '',
      Block_id: '',
      VillageName: '',
      Village_id: '',

      GovernmentKnownAs: '',
      GovernmentKnownAsHindi: '',
      GovernmentKnownAsLocal: '',

      ConstituencyZones: false,
      ConstituencyZonesTotal: 0,
      ConstituencyZoneName: '',
      ConstituencyZoneNameHindi: '',
      ConstituencyZoneNameLocal: '',

      Helpers: [],
      HelperObjects: [],
      Helpers_Que: [],
      HelperObjects_Que: [],
      HelperRequestsCount: 0,
      HelpersRejectedCount: 0,
      HelpersRemovedCount: 0,
      HelpersAcceptedCount: 0,

      AllowHelpers: false,
      HelperRequests: [],
      Intro: [],

      Weblinks: [],
      WeblinksSocial: [],
      Emails: [],
      AddressMain: '',
      AddressesSub: [],
      Phones: [],

      CrBy: '',
      CrByName: '',
      CrByImage: '',
      CrDt: '',
      CrDtSys: '',
      CrIP: '',

      IsRemoved: false,
      IsRemovedObj: [],
   })

   useEffect(() => {
      // just for testing
      console.log('Legis details on change of its value: ', legisDetails)
   }, [legisDetails])

   const [showStrata, setShowStrata] = useState(false)
   const [showStates, setShowStates] = useState(false)
   const [showDivisions, setShowDivisions] = useState(false)
   const [showDistricts, setShowDistricts] = useState(false)
   const [showSubDistricts, setShowSubDistricts] = useState(false)
   const [showBlocks, setShowBlocks] = useState(false)
   const [showVillages, setShowVillages] = useState(false)
   const [showParentLegisSelect, setShowParentLegisSelect] = useState(false)
   const [parentLegisList, setParentLegisList] = useState('')
   const [showType, setShowType] = useState(false)
   const [showForm, setShowForm] = useState(false)
   const [showLegisList, setShowLegisList] = useState(false)

   const [priority, setPriority] = useState(false)

   // related to countries
   const [selectedCountry, setSelectedCountry] = useState('')
   const countries = useSelector(selectCountries).countriesList

   const handleCountryChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const selectedCountryComplete = countries.find((item) => item.id === event.target.value)
            setSelectedCountry(selectedCountryComplete)
            setLegisDetails({ ...legisDetails, CountryName: selectedCountryComplete.Name })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      // on selction change of country
      setLegisDetails({
         ...legisDetails,
         Country_id: selectedCountry.id || '',
         Strata: '',
         StateName: '',
         State_id: '',
         DivisionName: '',
         Division_id: '',
         DistrictName: '',
         District_id: '',
         SubDistrictName: '',
         SubDistrict_id: '',
         BlockName: '',
         Block_id: '',
         VillageName: '',
         Village_id: '',

         Type: '',
         ParentLegislature_id: '',
         ParentLegislatureName: '',
      })

      setSelectedStrata('')

      setShowStates(false)
      setSelectedState('')

      setShowDivisions(false)
      setSelectedDivision('')

      setShowDistricts(false)
      setSelectedDistrict('')

      setShowSubDistricts(false)
      setSelectedSubDistrict('')

      setShowBlocks(false)
      setSelectedBlock('')

      setShowVillages(false)
      setSelectedVillage('')

      setShowType(false)
      setShowForm(false)

      setParentLegisList('')
      setShowParentLegisSelect(false)
      setLegisList('')
      setShowLegisList(false)
      if (selectedCountry) {
         // a country is selected
         setShowStrata(true)
      } else {
         // no country selected
         setShowStrata(false)
      }
   }, [selectedCountry])

   // related to strata
   const [selectedStrata, setSelectedStrata] = useState('')
   const handleStrataChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedStrata(event.target.value)

            setLegisDetails({
               ...legisDetails,
               Strata: '',
               StateName: '',
               State_id: '',
               DivisionName: '',
               Division_id: '',
               DistrictName: '',
               District_id: '',
               SubDistrictName: '',
               SubDistrict_id: '',
               BlockName: '',
               Block_id: '',
               VillageName: '',
               Village_id: '',

               Type: '',
               ParentLegislature_id: '',
               ParentLegislatureName: '',
            })
            setShowStates(false)
            setSelectedState('')

            setShowDivisions(false)
            setSelectedDivision('')

            setShowDistricts(false)
            setSelectedDistrict('')

            setShowSubDistricts(false)
            setSelectedSubDistrict('')

            setShowBlocks(false)
            setSelectedBlock('')

            setShowVillages(false)
            setSelectedVillage('')

            setShowForm(false)
            setShowType(false)

            setParentLegisList('')
            setShowParentLegisSelect(false)
            setLegisList('')
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedStrata) {
         console.log('entered selected strata', selectedStrata)

         setLegisDetails({
            ...legisDetails,
            Strata: selectedStrata,
         })

         switch (selectedStrata) {
            case 'Central':
               console.log('entered selected strata case central')
               setShowType(true)
               setShowForm(true)
               fetchLegisList()
               setShowLegisList(true)
               break
            case 'State':
               fetchStatesList()
               setShowStates(true)

               fetchParentLegisList()
               // setShowParentLegisSelect(true)
               break
            case 'Division':
               fetchStatesList()
               setShowStates(true)
               break
            case 'District':
               fetchStatesList()
               setShowStates(true)
               break
            case 'SubDistrict':
               fetchStatesList()
               setShowStates(true)
               break
            case 'Block':
               fetchStatesList()
               setShowStates(true)
               break
            case 'Village':
               fetchStatesList()
               setShowStates(true)
               break
         }
      }
   }, [selectedStrata])

   // related to states
   const [statesList, setStatesList] = useState('')
   const [selectedState, setSelectedState] = useState('')

   const fetchStatesList = async () => {
      try {
         const q = query(
            collection(db, 'States'),
            where('Country_id', '==', selectedCountry.id),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const statesListFetched = []
         querySnapshot.forEach((doc) => {
            statesListFetched.push({ id: doc.id, ...doc.data() })
         })

         if (selectedStrata === 'Division') {
            const list = statesListFetched.filter((item) => item.DownLevel === 'Divisions')
            setStatesList(list)
         } else {
            setStatesList(statesListFetched)
         }
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }
   const handleStateChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedState(statesList.find((item) => item.id === event.target.value))

            setLegisDetails({
               ...legisDetails,
               StateName: '',
               State_id: '',
               DivisionName: '',
               Division_id: '',
               DistrictName: '',
               District_id: '',
               SubDistrictName: '',
               SubDistrict_id: '',
               BlockName: '',
               Block_id: '',
               VillageName: '',
               Village_id: '',

               Type: '',
               ParentLegislature_id: '',
               ParentLegislatureName: '',
            })

            setShowDivisions(false)
            setSelectedDivision('')

            setShowDistricts(false)
            setSelectedDistrict('')

            setShowSubDistricts(false)
            setSelectedSubDistrict('')

            setShowBlocks(false)
            setSelectedBlock('')

            setShowVillages(false)
            setSelectedVillage('')

            setShowForm(false)
            setShowType(false)
            // setParentLegisList('')
            setShowParentLegisSelect(false)
            setLegisList('')
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedState) {
         setLegisDetails({ ...legisDetails, State_id: selectedState.id, StateName: selectedState.Name })

         switch (selectedStrata) {
            case 'State':
               setShowParentLegisSelect(true)
               setShowType(true)
               fetchLegisList()
               setShowLegisList(true)
               break
            case 'Division':
               fetchParentLegisList()
               fetchDivisionsList()
               break
            case 'District':
               fetchParentLegisList()
               fetchDistrictsList()
               break
            case 'SubDistrict':
               fetchDistrictsList()
               break
            case 'Block':
               fetchDistrictsList()
               break
            case 'Vilage':
               fetchDistrictsList()
               break
            default:
         }
      }
   }, [selectedState])

   // related to Divisions
   const [divisionsList, setDivisionsList] = useState('')
   const [selectedDivision, setSelectedDivision] = useState('')
   const fetchDivisionsList = async () => {
      try {
         const q = query(collection(db, 'Divisions'), where('State_id', '==', selectedState.id), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         const divisionsListFetched = []
         querySnapshot.forEach((doc) => {
            divisionsListFetched.push({ id: doc.id, ...doc.data() })
         })
         console.log('divisions list fetched: ', divisionsListFetched)
         setDivisionsList(divisionsListFetched)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }
   useEffect(() => {
      if (divisionsList) {
         setShowDivisions(true)
      }
   }, [divisionsList])
   const handleDivisionChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const selectedDivision = divisionsList.find((item) => item.id === event.target.value)
            setSelectedDivision(selectedDivision)

            setLegisDetails({
               ...legisDetails,
               DivisionName: '',
               Division_id: '',
               DistrictName: '',
               District_id: '',
               SubDistrictName: '',
               SubDistrict_id: '',
               BlockName: '',
               Block_id: '',
               VillageName: '',
               Village_id: '',

               ParentLegislature_id: '',
               ParentLegislatureName: '',
            })

            setShowDistricts(false)
            setSelectedDistrict('')

            setShowSubDistricts(false)
            setSelectedSubDistrict('')

            setShowBlocks(false)
            setSelectedBlock('')

            setShowVillages(false)
            setSelectedVillage('')

            setShowForm(false)
            setShowType(false)

            // setParentLegisList('')
            // setShowParentLegisSelect(false)
            setLegisList('')
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedDivision) {
         setLegisDetails({
            ...legisDetails,
            Division_id: selectedDivision.id,
            DivisionName: selectedDivision.Name,
         })
         setShowType(true)
         fetchLegisList()
         setShowLegisList(true)
      }
   }, [selectedDivision])

   // related to Districts
   const [districtsList, setDistrictsList] = useState('')
   const [selectedDistrict, setSelectedDistrict] = useState('')

   const fetchDistrictsList = async () => {
      try {
         const q = query(collection(db, 'Districts'), where('State_id', '==', selectedState.id), orderBy('Name', 'asc'))
         const querySnapshot = await getDocs(q)
         const districtsListFetched = []
         querySnapshot.forEach((doc) => {
            districtsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setDistrictsList(districtsListFetched)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }

   useEffect(() => {
      if (districtsList) {
         setShowDistricts(true)
      }
   }, [districtsList])

   const handleDistrictChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const selectedDistrict = districtsList.find((item) => item.id === event.target.value)
            setSelectedDistrict(selectedDistrict)

            setLegisDetails({
               ...legisDetails,
               DistrictName: '',
               District_id: '',
               SubDistrictName: '',
               SubDistrict_id: '',
               BlockName: '',
               Block_id: '',
               VillageName: '',
               Village_id: '',

               ParentLegislature_id: '',
               ParentLegislatureName: '',
            })

            setShowSubDistricts(false)
            setSelectedSubDistrict('')

            setShowBlocks(false)
            setSelectedBlock('')

            setShowVillages(false)
            setSelectedVillage('')

            setShowForm(false)
            setShowType(false)

            // setParentLegisList('')
            // setShowParentLegisSelect(false)
            setLegisList('')
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedDistrict) {
         setLegisDetails({
            ...legisDetails,
            DistrictName: selectedDistrict.Name,
            District_id: selectedDistrict.id,
         })
         if (selectedStrata === 'District') {
            setShowForm(true)
            fetchLegisList()
            setShowLegisList(true)
            setShowType(true)
         } else if (selectedStrata === 'SubDistrict') {
            fetchParentLegisList()
            setShowParentLegisSelect(true)

            setShowForm(false)
            setLegisList('')
            setShowLegisList(false)

            fetchSubDistrictsList()
            setShowSubDistricts(true)
         } else if (selectedStrata === 'Block') {
            fetchParentLegisList()
            setShowParentLegisSelect(true)

            setShowForm(false)
            setLegisList('')
            setShowLegisList(false)

            fetchBlocksList()
            setShowBlocks(true)
         } else if (selectedStrata === 'Village') {
            setShowForm(false)
            setLegisList('')
            setShowLegisList(false)

            fetchSubDistrictsList()
            setShowSubDistricts(true)
         } else {
            setShowForm(false)
            setLegisList('')
            setShowLegisList(false)

            setShowSubDistricts(false)
            setShowBlocks(false)
            setShowSubDistricts(false)
         }
      } else {
         //  district is not selected at all
         setShowForm(false)
         setLegisList('')
         setShowLegisList(false)
         setShowSubDistricts(false)
         setShowBlocks(false)
         setShowSubDistricts(false)
      }
   }, [selectedDistrict])

   // related to SubDistricts
   const [subDistrictsList, setSubDistrictsList] = useState('')
   const [selectedSubDistrict, setSelectedSubDistrict] = useState('')

   const fetchSubDistrictsList = async () => {
      try {
         const q = query(
            collection(db, 'SubDistricts'),
            where('District_id', '==', selectedDistrict.id),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const subDistrictsListFetched = []
         querySnapshot.forEach((doc) => {
            subDistrictsListFetched.push({ id: doc.id, ...doc.data() })
         })
         setSubDistrictsList(subDistrictsListFetched)
      } catch (err) {
         console.log('error fetching sub districts list: ', err)
      }
   }

   const handleSubDistrictChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const selectedSubDistrict = subDistrictsList.find((item) => item.id === event.target.value)
            setSelectedSubDistrict(selectedSubDistrict)

            setLegisDetails({
               ...legisDetails,
               SubDistrictName: '',
               SubDistrict_id: '',
               BlockName: '',
               Block_id: '',
               VillageName: '',
               Village_id: '',

               ParentLegislature_id: '',
               ParentLegislatureName: '',
            })

            setShowBlocks(false)
            setSelectedBlock('')

            setShowVillages(false)
            setSelectedVillage('')

            setShowForm(false)
            setShowType(false)

            // setParentLegisList('')
            // setShowParentLegisSelect(false)
            setLegisList('')
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (selectedSubDistrict) {
         setLegisDetails({
            ...legisDetails,
            SubDistrict_id: selectedSubDistrict.id,
            SubDistrictName: selectedSubDistrict.Name,
         })
         if (selectedStrata === 'SubDistrict') {
            setShowForm(true)
            fetchLegisList()
            setShowLegisList(true)
         } else if (selectedStrata === 'Village') {
            fetchParentLegisList()
            setShowParentLegisSelect(true)

            setShowForm(false)
            fetchVillagesList()
            setShowLegisList(false)
         }
      } else {
         //  sub-district is not selected at all
         setShowForm(false)
         setLegisList('')
         setShowLegisList(false)
      }
   }, [selectedSubDistrict])

   // related to Blocks
   const [blocksList, setBlocksList] = useState('')
   const [selectedBlock, setSelectedBlock] = useState('')

   const fetchBlocksList = async () => {
      try {
         const q = query(
            collection(db, 'Blocks'),
            where('District_id', '==', selectedDistrict.id),
            orderBy('Name', 'asc'),
         )
         const querySnapshot = await getDocs(q)
         const blocksListFetched = []
         querySnapshot.forEach((doc) => {
            blocksListFetched.push({ id: doc.id, ...doc.data() })
         })
         setBlocksList(blocksListFetched)
      } catch (err) {
         console.log('error fetching blocks list: ', err)
      }
   }
   const handleBlockChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedBlock(event.target.value)
            const selectedBlock = blocksList.find((item) => item.id === event.target.value)
            setSelectedBlock(selectedBlock)

            setLegisDetails({
               ...legisDetails,
               BlockName: '',
               Block_id: '',

               ParentLegislature_id: '',
               ParentLegislatureName: '',
            })

            setShowForm(false)
            setShowType(false)

            setParentLegisList('')
            setShowParentLegisSelect(false)
            setLegisList('')
            setShowLegisList(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedBlock) {
         setLegisDetails({ ...legisDetails, Block_id: selectedBlock.id, BlockName: selectedBlock.Name })

         setShowType(true)
         fetchLegisList()
         setShowLegisList(true)
      } else {
         //  district is not selected at all
         setShowType(false)
         setLegisList('')
         setShowLegisList(false)
      }
   }, [selectedBlock])

   // related to Villages
   const [villagesList, setVillagesList] = useState('')
   const [selectedVillage, setSelectedVillage] = useState('')

   const fetchVillagesList = async () => {
      try {
         let q = ''
         if (selectedSubDistrict) {
            q = query(
               collection(db, 'Villages'),
               where('SubDistrict_id', '==', selectedSubDistrict.id),
               orderBy('Name', 'asc'),
            )
         } else {
            q = query(
               collection(db, 'Villages'),
               where('District_id', '==', selectedDistrict.id),
               orderBy('Name', 'asc'),
            )
         }

         const querySnapshot = await getDocs(q)
         const villagesListFetched = []
         querySnapshot.forEach((doc) => {
            villagesListFetched.push({ id: doc.id, ...doc.data() })
         })
         setVillagesList(villagesListFetched)
      } catch (err) {
         console.log('error fetching Villages list: ', err)
      }
   }
   const handleVillageChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const selectedVillage = villagesList.find((item) => item.id === event.target.value)
            setSelectedVillage(selectedVillage)
            setLegisDetails({
               ...legisDetails,
               Village_id: selectedVillage.id,
               VillageName: selectedVillage.Name,
            })
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (selectedVillage) {
         if (selectedStrata === 'Village') {
            setShowForm(true)
            fetchLegisList()
            setShowLegisList(true)
         } else {
            // /
         }
      } else {
         //  district is not selected at all
         setShowForm(false)
         setLegisList('')
         setShowLegisList(false)
      }
   }, [selectedVillage])

   // related to parent legislature lists

   const fetchParentLegisList = async () => {
      try {
         let q = ''

         switch (selectedStrata) {
            case 'State':
               q = query(
                  collection(db, 'Legislatures'),
                  where('Country_id', '==', selectedCountry.id),
                  where('Strata', '==', 'Central'),
                  where('Type', '==', 'Legislative'),
                  orderBy('Priority', 'asc'),
               )
               console.log('fetchParentLegisList - entered case state ')
               break
            case 'Division':
               q = query(
                  collection(db, 'Legislatures'),
                  where('State_id', '==', selectedState.id),
                  where('Strata', '==', 'State'),
                  where('Type', '==', 'Legislative'),
                  orderBy('Priority', 'asc'),
               )
               console.log('fetchParentLegisList - entered case division ')
               break
            case 'District':
               q = query(
                  collection(db, 'Legislatures'),
                  where('State_id', '==', selectedState.id),
                  where('Strata', '==', 'State'),
                  where('Type', '==', 'Legislative'),
                  orderBy('Priority', 'asc'),
               )
               console.log('fetchParentLegisList - entered case district ')
               break
            case 'SubDistrict':
               q = query(
                  collection(db, 'Legislatures'),
                  where('District_id', '==', selectedDistrict.id),
                  where('Strata', '==', 'District'),
                  where('Type', '==', 'Local Body'),
                  orderBy('Priority', 'asc'),
               )
               console.log('fetchParentLegisList - entered case district ')
               break
            case 'Block':
               q = query(
                  collection(db, 'Legislatures'),
                  where('District_id', '==', selectedDistrict.id),
                  where('Strata', '==', 'District'),
                  where('Type', '==', 'Local Body'),
                  orderBy('Priority', 'asc'),
               )
               console.log('fetchParentLegisList - entered case block ')
               break
            case 'Vilage':
               q = query(
                  collection(db, 'Legislatures'),
                  where('SubDistrict_id', '==', selectedSubDistrict.id),
                  where('Strata', '==', 'SubDistrict'),
                  where('Type', '==', 'Local Body'),
                  orderBy('Priority', 'asc'),
               )
               console.log('fetchParentLegisList - entered case village ')
               break
            default:
         }
         const querySnapshot = await getDocs(q)
         const legisListFetched = []
         querySnapshot.forEach((doc) => {
            legisListFetched.push({ id: doc.id, ...doc.data() })
         })
         console.log('fetchParentLegisList - ', legisListFetched)
         setParentLegisList(legisListFetched)
      } catch (err) {
         console.log('error fetching parent legis list: ', err)
      }
   }

   useEffect(() => {
      if (parentLegisList) {
         console.log('set ParentLegisList - ', parentLegisList)
         setShowParentLegisSelect(true)
      }
   }, [parentLegisList])

   const handleParentLegislatureChange = (event) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (event.target.value !== '') {
               const selectedParentLegisComplete = parentLegisList.find((item) => item.id === event.target.value)
               setLegisDetails({
                  ...legisDetails,
                  ParentLegislature_id: event.target.value,
                  ParentLegislatureName: selectedParentLegisComplete.Name,
               })
            } else {
               setLegisDetails({
                  ...legisDetails,
                  ParentLegislature_id: '',
                  ParentLegislatureName: '',
               })
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Legislature list
   const [legisList, setLegisList] = useState('')
   const [legisListRoot, setLegisListRoot] = useState('')
   const fetchLegisList = async () => {
      try {
         let q = ''
         switch (selectedStrata) {
            case 'Central':
               console.log('fetchLegisList - entered case central ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('Country_id', '==', selectedCountry.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
            case 'State':
               console.log('fetchLegisList - entered case state ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('State_id', '==', selectedState.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
            case 'Division':
               console.log('fetchLegisList - entered case Division ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('Division_id', '==', selectedDivision.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
            case 'District':
               console.log('fetchLegisList - entered case District ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('District_id', '==', selectedDistrict.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
            case 'SubDistrict':
               console.log('fetchLegisList - entered case sub-district ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('SubDistrict_id', '==', selectedSubDistrict.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
            case 'Block':
               console.log('fetchLegisList - entered case block ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('Block_id', '==', selectedBlock.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
            case 'Village':
               console.log('fetchLegisList - entered case village ')
               q = query(
                  collection(db, 'Legislatures'),
                  where('Village_id', '==', selectedVillage.id),
                  where('Strata', '==', selectedStrata),
                  orderBy('Priority', 'asc'),
               )
               break
         }

         const querySnapshot = await getDocs(q)
         const legisListFetched = []
         querySnapshot.forEach((doc) => {
            legisListFetched.push({ id: doc.id, ...doc.data() })
         })
         setLegisList(legisListFetched)
         setLegisListRoot(legisListFetched)
         console.log('fetchLegisList - ', legisListFetched)
      } catch (err) {
         console.log('error', err)
      }
   }

   // related to natures
   const [selectedNature, setSelectedNature] = useState('')
   const [naturesList, setNaturesList] = useState('')
   const fetchNaturesList = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'LegislatureNatures')
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const list = docSnap.data().Natures

            const sortedList = [...list].sort((a, b) => {
               if (b.Name > a.Name) {
                  return -1
               } else if (b.Name < a.Name) {
                  return 1
               } else {
                  return 0
               }
            })
            setNaturesList(sortedList)
         } else {
            // docSnap.data() will be undefined in this case
            console.log('No such document!')
         }
      } catch (err) {
         console.log('error fetching natures list: ', err)
      }
   }

   const handleNatureChange = (event) => {
      event.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedNature(event.target.value)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      setLegisDetails({ ...legisDetails, Nature: selectedNature })
   }, [selectedNature])

   // related to form filling and submission
   function handleLegisDetails(e) {
      e.preventDefault()
      setLegisDetails({ ...legisDetails, [e.target.name]: e.target.value })
      if (e.target.name === 'Priority') {
         setPriority(e.target.value)
      }
   }

   const [checkedHasZone, setCheckedHasZone] = useState(false)

   function handleChangeHasZone(e) {
      setLegisDetails({ ...legisDetails, ConstituencyZones: !!e.target.checked })
      setCheckedHasZone(e.target.checked)
      setLegisDetails({
         ...legisDetails,

         ConstituencyZones: !!e.target.checked,
         ConstituencyZonesTotal: 0,
         ConstituencyZoneName: '',
         ConstituencyZoneNameHindi: '',
         ConstituencyZoneNameLocal: '',
      })
      console.log('Checked for HasZone here: ', e.target.checked)
      console.log('Checked for HasZone: ', checkedHasZone)
   }

   const [selectedType, setSelectedType] = useState('')

   function handleChangeType(e) {
      e.preventDefault()
      setLegisDetails({
         ...legisDetails,
         [e.target.name]: e.target.value,
         GovernmentKnownAs: '',
         GovernmentKnownAsHindi: '',
         GovernmentKnownAsLocal: '',

         ConstituencyZones: false,
         ConstituencyZonesTotal: '',
         ConstituencyZoneName: '',
         ConstituencyZoneNameHindi: '',
         ConstituencyZoneNameLocal: '',
      })
      setSelectedType(e.target.value)

      if (e.target.value) {
         setShowForm(true)

         if (legisListRoot && legisListRoot.length > 0) {
            const newList = legisListRoot.filter((item) => item.Type === e.target.value)

            const newListSorted = newList.sort(function (x, y) {
               return x.Priority - y.Priority || x.Name - y.Name
            })

            setLegisList(newListSorted)
         } else {
            setLegisList(legisListRoot)
         }
      } else {
         setShowForm(false)
         setLegisList(legisListRoot)
      }
   }

   const [lastLegis, setLastLegis] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleCreateLegis = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (legisDetails.Name.length >= 3 && legisDetails.Type.length >= 3) {
               legisDetails.CrBy = auth.currentUser.uid
               legisDetails.CrByName = nameOfUser
               legisDetails.CrByImage = userProfile.Image.Image
               legisDetails.CrDt = new Date()
               legisDetails.CrIP = ip.data.ip
               console.log('legisDetails 1: ', legisDetails)
               setLastLegis(legisDetails.Name)

               setUploading(true)
               console.log('legisDetails 2: ', legisDetails, ' uploading: ', uploading)
               try {
                  const docRef = await addDoc(collection(db, 'Legislatures'), legisDetails)
                  legisDetails.id = docRef.id
                  console.log('created record ')

                  const thisDocRef = doc(db, 'Legislatures', docRef.id)
                  await updateDoc(thisDocRef, {
                     NameOld: arrayUnion({
                        Name: legisDetails.Name,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NameAltOld: arrayUnion({
                        NameAlt: legisDetails.NameAlt,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NameHindiOld: arrayUnion({
                        NameHindi: legisDetails.NameHindi,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NameLocalOld: arrayUnion({
                        NameLocal: legisDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     ElectiveSeatsOld: arrayUnion({
                        ElectiveSeats: legisDetails.ElectiveSeats,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NominatedSeatsOld: arrayUnion({
                        NominatedSeats: legisDetails.NominatedSeats,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     NominatingAuthoritiesOld: arrayUnion({
                        NominatingAuthorities: legisDetails.NominatingAuthorities,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                     ImagesOld: arrayUnion({
                        Image: legisDetails.Image,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  console.log('updated the newly created record ')

                  console.log('created the doc ref s ')

                  const detailsForInsertUpdate = {
                     Legislature_id: docRef.id,
                     Name: legisDetails.Name,
                     NameHindi: legisDetails.NameHindi,
                     NameLocal: legisDetails.NameLocal,
                     NameAlt: legisDetails.NameAlt,
                     Strata: legisDetails.Strata,
                     Nature: legisDetails.Nature,
                     Type: legisDetails.Type,

                     ForLegis: false,

                     HasHouses: legisDetails.HasHouses,
                     HasConstituencies: legisDetails.HasConstituencies,

                     Ministry_Under_Legis_id: legisDetails.Ministry_Under_Legis_id,
                     Ministry_Under_LegisName: legisDetails.Ministry_Under_LegisName,

                     Image: legisDetails.Image,
                     Priority: legisDetails.Priority,
                     IsRemoved: legisDetails.IsRemoved,

                     GovernmentKnownAs: legisDetails.GovernmentKnownAs,
                     GovernmentKnownAsHindi: legisDetails.GovernmentKnownAsHindi,
                     GovernmentKnownAsLocal: legisDetails.GovernmentKnownAsLocal,

                     ConstituencyZones: legisDetails.ConstituencyZones,
                     ConstituencyZonesTotal: legisDetails.ConstituencyZonesTotal,
                     ConstituencyZoneName: legisDetails.ConstituencyZoneName,
                     ConstituencyZoneNameHindi: legisDetails.ConstituencyZoneNameHindi,
                     ConstituencyZoneNameLocal: legisDetails.ConstituencyZoneNameLocal,
                  }

                  console.log('initialized the update object record ')

                  switch (legisDetails.Strata) {
                     case 'Central':
                        console.log('entered case central ')
                        const docRefCentral = doc(db, 'Countries', legisDetails.Country_id)
                        await updateDoc(docRefCentral, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'State':
                        console.log('entered case state ')
                        const docRefState = doc(db, 'States', legisDetails.State_id)
                        await updateDoc(docRefState, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'Division':
                        console.log('entered case Division ')
                        // eslint-disable-next-line no-case-declarations
                        const docRefDivision = doc(db, 'Divisions', legisDetails.Division_id)
                        await updateDoc(docRefDivision, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'District':
                        console.log('entered case District ')
                        const docRefDistrict = doc(db, 'Districts', legisDetails.District_id)
                        await updateDoc(docRefDistrict, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'SubDistrict':
                        console.log('entered case sub-district ')
                        const docRefSubDistrict = doc(db, 'SubDistricts', legisDetails.SubDistrict_id)
                        await updateDoc(docRefSubDistrict, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'Block':
                        console.log('entered case block ')
                        const docRefBlock = doc(db, 'Blocks', legisDetails.Block_id)
                        await updateDoc(docRefBlock, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                     case 'Village':
                        console.log('entered case village ')
                        const docRefVillage = doc(db, 'Villages', legisDetails.Village_id)
                        await updateDoc(docRefVillage, {
                           Legislatures: arrayUnion(detailsForInsertUpdate),
                        })
                        break
                  }

                  resetLegisDetails()

                  console.log('reached end ')
                  setLegisList([...legisList, legisDetails])
                  setLegisListRoot([...legisListRoot, legisDetails])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding legislature')
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const resetLegisDetails = async () => {
      setLegisDetails({
         Name: '',
         NameOld: [],
         NameAlt: [],
         NameAltOld: [],
         NameHindi: '',
         NameHindiOld: [],
         NameLocal: '',
         NameLocalOld: [],

         Strata: selectedStrata,
         Nature: '',
         Type: selectedType,
         Duration: '',
         SingularMem: '',
         HasHouses: '',
         Houses: [],
         HouseNumbers: [],
         HasConstituencies: '',

         ParentLegislature_id: '',
         ParentLegislatureName: '',

         ElectiveSeats: 0,
         ElectiveSeatsOld: [],
         NominatedSeats: 0,
         NominatedSeatsOld: [],
         NominatingAuthorities: '',
         NominatingAuthoritiesOld: [],

         Constituencies: [],

         Image: null,
         ImagesOld: [],
         Flag: null,
         FlagOld: [],
         Symbol: null,
         SymbolOld: [],

         Ministry_Under_Legis_id: '',
         Ministry_Under_LegisName: '',
         Priority: priority,
         ForLegis: false,
         CountryName: selectedCountry.Name,
         Country_id: selectedCountry.id,
         StateName: selectedState ? selectedState.Name : '',
         State_id: selectedState ? selectedState.id : '',
         DivisionName: selectedDivision ? selectedDivision.Name : '',
         Division_id: selectedDivision ? selectedDivision.id : '',
         DistrictName: selectedDistrict ? selectedDistrict.Name : '',
         District_id: selectedDistrict ? selectedDistrict.id : '',
         SubDistrictName: selectedSubDistrict ? selectedSubDistrict.Name : '',
         SubDistrict_id: selectedSubDistrict ? selectedSubDistrict.id : '',
         BlockName: selectedBlock ? selectedBlock.Name : '',
         Block_id: selectedBlock ? selectedBlock.id : '',
         VillageName: selectedVillage ? selectedVillage.Name : '',
         Village_id: selectedVillage ? selectedVillage.id : '',

         GovernmentKnownAs: legisDetails.GovernmentKnownAs,
         GovernmentKnownAsHindi: legisDetails.GovernmentKnownAsHindi,
         GovernmentKnownAsLocal: legisDetails.GovernmentKnownAsLocal,

         ConstituencyZones: false,
         ConstituencyZonesTotal: 0,
         ConstituencyZoneName: '',
         ConstituencyZoneNameHindi: '',
         ConstituencyZoneNameLocal: '',

         Helpers: [],
         HelperObjects: [],
         Helpers_Que: [],
         HelperObjects_Que: [],
         HelperRequestsCount: 0,
         HelpersRejectedCount: 0,
         HelpersRemovedCount: 0,
         HelpersAcceptedCount: 0,

         AllowHelpers: false,
         HelperRequests: [],
         Intro: [],

         Weblinks: [],
         WeblinksSocial: [],
         Emails: [],
         AddressMain: '',
         AddressesSub: [],
         Phones: [],

         CrBy: '',
         CrByName: '',
         CrByImage: '',
         CrDt: '',
         CrDtSys: '',
         CrIP: '',

         IsRemoved: false,
         IsRemovedObj: [],
      })
   }

   // Related to edits
   const [editStatus, setEditStatus] = useState(false)
   const [forLegis, setForLegis] = useState({ id: '', ForLegis: false })

   const handleOperation = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            const stateRef = doc(db, 'Legislatures', item.id)
            try {
               await updateDoc(stateRef, {
                  ForLegis: !item.ForLegis,
               })

               const record = {
                  Legislature_id: item.id,
                  Name: item.Name,
                  NameHindi: item.NameHindi,
                  NameLocal: item.NameLocal,
                  NameAlt: item.NameAlt,
                  Strata: item.Strata,
                  Nature: item.Nature,
                  Type: item.Type,

                  ForLegis: item.ForLegis,

                  HasHouses: item.HasHouses,
                  HasConstituencies: item.HasConstituencies,

                  Ministry_Under_Legis_id: item.Ministry_Under_Legis_id,
                  Ministry_Under_LegisName: item.Ministry_Under_LegisName,

                  Image: item.Image,
                  Priority: item.Priority,
                  IsRemoved: item.IsRemoved,

                  GovernmentKnownAs: item.GovernmentKnownAs,
                  GovernmentKnownAsHindi: item.GovernmentKnownAsHindi,
                  GovernmentKnownAsLocal: item.GovernmentKnownAsLocal,

                  ConstituencyZones: item.ConstituencyZones,
                  ConstituencyZonesTotal: item.ConstituencyZonesTotal,
                  ConstituencyZoneName: item.ConstituencyZoneName,
                  ConstituencyZoneNameHindi: item.ConstituencyZoneNameHindi,
                  ConstituencyZoneNameLocal: item.ConstituencyZoneNameLocal,
               }

               const recordNew = { ...record, ForLegis: !item.ForLegis }

               switch (item.Strata) {
                  case 'Central':
                     const docRefCentral = doc(db, 'Countries', item.Country_id)
                     await updateDoc(docRefCentral, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefCentral, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
                  case 'State':
                     const docRefState = doc(db, 'States', item.State_id)

                     await updateDoc(docRefState, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefState, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
                  case 'Division':
                     // eslint-disable-next-line no-case-declarations
                     const docRefDivision = doc(db, 'Divisions', item.Division_id)
                     await updateDoc(docRefDivision, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefDivision, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
                  case 'District':
                     const docRefDistrict = doc(db, 'Districts', item.District_id)
                     await updateDoc(docRefDistrict, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefDistrict, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
                  case 'SubDistrict':
                     const docRefSubDistrict = doc(db, 'SubDistricts', item.SubDistrict_id)
                     await updateDoc(docRefSubDistrict, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefSubDistrict, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
                  case 'Block':
                     const docRefBlock = doc(db, 'Blocks', item.Block_id)
                     await updateDoc(docRefBlock, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefBlock, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
                  case 'Village':
                     const docRefVillage = doc(db, 'Villages', item.Village_id)
                     await updateDoc(docRefVillage, {
                        Legislatures: arrayRemove(record),
                     })
                     await updateDoc(docRefVillage, {
                        Legislatures: arrayUnion(recordNew),
                     })
                     break
               }

               setEditStatus(true)
               setForLegis({ id: item.id, forLegis: item.ForLegis })
            } catch (error) {
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (editStatus) {
         // eslint-disable-next-line array-callback-return
         legisList.map((item) => {
            if (item.id === forLegis.id) {
               item.ForLegis = !forLegis.ForLegis
            }
         })
         setEditStatus(false)
      }
   }, [forLegis, editStatus])

   const handleDelete = async (e, item) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to erase this legislature?')) {
               try {
                  const docRefLegis = doc(db, 'Legislatures', item.id)
                  await updateDoc(docRefLegis, {
                     IsRemoved: true,
                     IsRemovedObj: arrayUnion({
                        IsRemoved: true,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  const record = {
                     Legislature_id: item.id,
                     Name: item.Name,
                     NameHindi: item.NameHindi,
                     NameLocal: item.NameLocal,
                     NameAlt: item.NameAlt,
                     Strata: item.Strata,
                     Nature: item.Nature,
                     Type: item.Type,

                     ForLegis: false,

                     HasHouses: item.HasHouses,
                     HasConstituencies: item.HasConstituencies,

                     Ministry_Under_Legis_id: item.Ministry_Under_Legis_id,
                     Ministry_Under_LegisName: item.Ministry_Under_LegisName,

                     Image: item.Image,
                     Priority: item.Priority,
                     IsRemoved: item.IsRemoved,

                     GovernmentKnownAs: item.GovernmentKnownAs,
                     GovernmentKnownAsHindi: item.GovernmentKnownAsHindi,
                     GovernmentKnownAsLocal: item.GovernmentKnownAsLocal,

                     ConstituencyZones: item.ConstituencyZones,
                     ConstituencyZonesTotal: item.ConstituencyZonesTotal,
                     ConstituencyZoneName: item.ConstituencyZoneName,
                     ConstituencyZoneNameHindi: item.ConstituencyZoneNameHindi,
                     ConstituencyZoneNameLocal: item.ConstituencyZoneNameLocal,
                  }

                  const recordNew = { ...record, IsRemoved: true }

                  switch (item.Strata) {
                     case 'Central':
                        const docRefCentral = doc(db, 'Countries', item.Country_id)
                        await updateDoc(docRefCentral, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefCentral, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                     case 'State':
                        const docRefState = doc(db, 'States', item.State_id)

                        await updateDoc(docRefState, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefState, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                     case 'Division':
                        // eslint-disable-next-line no-case-declarations
                        const docRefDivision = doc(db, 'Divisions', item.Division_id)
                        await updateDoc(docRefDivision, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefDivision, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                     case 'District':
                        const docRefDistrict = doc(db, 'Districts', item.District_id)
                        await updateDoc(docRefDistrict, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefDistrict, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                     case 'SubDistrict':
                        const docRefSubDistrict = doc(db, 'SubDistricts', item.SubDistrict_id)
                        await updateDoc(docRefSubDistrict, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefSubDistrict, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                     case 'Block':
                        const docRefBlock = doc(db, 'Blocks', item.Block_id)
                        await updateDoc(docRefBlock, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefBlock, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                     case 'Village':
                        const docRefVillage = doc(db, 'Villages', item.Village_id)
                        await updateDoc(docRefVillage, {
                           Legislatures: arrayRemove(record),
                        })
                        await updateDoc(docRefVillage, {
                           Legislatures: arrayRemove(recordNew),
                        })
                        break
                  }

                  const obj = legisList.find((listItem) => listItem.id === item.id)
                  const objNew = { ...obj, IsRemoved: true }
                  const list = legisList.filter((listItem) => listItem.id !== item.id)
                  const listNew = { ...list, objNew }

                  setLegisList(listNew)
                  setLegisListRoot(listNew)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error deleting district')
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // copy to clipboard
   const [indexOfCopy, setIndexOfCopy] = useState('') // The text you want to copy
   const [copyStatus, setCopyStatus] = useState(false) // To indicate if the text was copied
   const onCopyText = (index) => {
      setCopyStatus(true)
      setIndexOfCopy(index)
      setTimeout(() => setCopyStatus(false), 2000) // Reset status after 2 seconds
   }

   const onClick = (e) => {
      e.preventDefault()
   }

   return (
      <Box sx={{ minHeight: 900, mx: 2, pb: 50 }}>
         {(isDataEditor || isSuper) && (
            <Box>
               <Box>
                  <Stack spacing={1} direction="row" justifyContent="center" sx={{ mt: 0.5 }}>
                     <Link href="/geo-political-Boxisions/countries/admin" className={styles.link}>
                        Countries
                     </Link>
                     <Link href="/geo-political-Boxisions/states/admin" className={styles.link}>
                        States
                     </Link>
                     <Link href="/geo-political-Boxisions/Boxisions/admin" className={styles.link}>
                        Divisions
                     </Link>
                     <Link href="/geo-political-Boxisions/districts/admin" className={styles.link}>
                        Districts
                     </Link>
                     <Link href="/geo-political-Boxisions/subDistricts/admin" className={styles.link}>
                        Sub-Districts
                     </Link>
                     <Link href="/geo-political-Boxisions/blocks/admin" className={styles.link}>
                        Blocks
                     </Link>
                     <Link href="/geo-political-Boxisions/villages/admin" className={styles.link}>
                        Villages
                     </Link>
                     <Link
                        href="/legislatures/admin"
                        color="#ffffff"
                        onClick={(e) => onClick(e)}
                        className={styles.link2}
                     >
                        Legislatures
                     </Link>
                     <Link href="/geo-political-Boxisions/pin-codes/admin" className={styles.link}>
                        PinCodes
                     </Link>
                  </Stack>
               </Box>
               <Box
                  sx={{
                     border: 1,
                     my: 1,
                     mx: 5,
                     pb: 1,
                     px: 2,
                     borderRadius: 2,
                     display: 'flex',
                     flexDirection: 'column',
                     '& .MuiButton-root': { width: '15ch' },
                     '& .MuiTextField-root': { width: '50ch' },
                     '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <Typography
                     variant="h6"
                     sx={{
                        fontWeight: 600,
                        textAlign: 'center',
                        fontFamily: [
                           '-apple-system',
                           'BlinkMacSystemFont',
                           '"Segoe UI"',
                           'Roboto',
                           '"Helvetica Neue"',
                           'Arial',
                           'sans-serif',
                           '"Apple Color Emoji"',
                           '"Segoe UI Emoji"',
                           '"Segoe UI Symbol"',
                        ],
                     }}
                  >
                     List a legislature
                  </Typography>
                  <Box>
                     <FormControl sx={{ m: 1 }} size="small">
                        <InputLabel id="demo-select-small-label">Country</InputLabel>
                        <Select
                           labelId="demo-select-small-label"
                           id="demo-select-small"
                           value={selectedCountry.id}
                           label="Country"
                           onChange={handleCountryChange}
                        >
                           <MenuItem value="">
                              <em>None</em>
                           </MenuItem>
                           {countries.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                 {item.Name}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </Box>
                  <Box>
                     {showStrata && (
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Strata</InputLabel>
                           <Select value={legisDetails.Strata} label="Strata" onChange={handleStrataChange}>
                              <MenuItem value="">
                                 <em>-- select --</em>
                              </MenuItem>
                              <MenuItem value="Central">Central</MenuItem>
                              <MenuItem value="State">State</MenuItem>
                              <MenuItem value="Division">Division</MenuItem>
                              <MenuItem value="District">District</MenuItem>
                              <MenuItem value="SubDistrict">SubDistrict</MenuItem>
                              <MenuItem value="Block">Block</MenuItem>
                              <MenuItem value="Village">Village</MenuItem>
                           </Select>
                        </FormControl>
                     )}
                  </Box>
                  <Box>
                     {showStates && (
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>State</InputLabel>
                           <Select value={legisDetails.State_id} label="State" onChange={handleStateChange}>
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {statesList &&
                                 statesList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     )}
                  </Box>
                  <Box>
                     {showDivisions && (
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Division</InputLabel>
                           <Select value={selectedDivision.id} label="Division" onChange={handleDivisionChange}>
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {divisionsList &&
                                 divisionsList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     )}
                  </Box>
                  {showDistricts && (
                     <Box>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>District</InputLabel>
                           <Select value={selectedDistrict.id} label="District" onChange={handleDistrictChange}>
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {districtsList &&
                                 districtsList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                  )}
                  {showSubDistricts && (
                     <Box>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Sub-District</InputLabel>
                           <Select
                              value={selectedSubDistrict.id}
                              label="Sub-District"
                              onChange={handleSubDistrictChange}
                           >
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {subDistrictsList &&
                                 subDistrictsList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                  )}
                  {showBlocks && (
                     <Box>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Block</InputLabel>
                           <Select value={selectedBlock.id} label="Block" onChange={handleBlockChange}>
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {blocksList &&
                                 blocksList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                  )}
                  {showVillages && (
                     <Box>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel>Village</InputLabel>
                           <Select value={selectedVillage.id} label="District" onChange={handleVillageChange}>
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {villagesList &&
                                 villagesList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                  )}
                  {showParentLegisSelect && (
                     <Box>
                        <FormControl sx={{ m: 1 }} size="small">
                           <InputLabel id="demo-select-small-label">Parent legislature</InputLabel>
                           <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={legisDetails.ParentLegislature_id}
                              label="Parent legislature"
                              onChange={handleParentLegislatureChange}
                           >
                              <MenuItem value="">
                                 <em>None</em>
                              </MenuItem>
                              {parentLegisList &&
                                 parentLegisList.length > 0 &&
                                 parentLegisList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                       {item.Name}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </FormControl>
                     </Box>
                  )}

                  {showType && (
                     <Box sx={{ border: 1, borderRadius: 1, p: 1, backgroundColor: '#d1d1d1', mb: 1 }}>
                        <FormControl>
                           <FormLabel id="demo-controlled-radio-buttons-group">Type:</FormLabel>
                           <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="Type"
                              value={legisDetails.Type}
                              onChange={(e) => {
                                 handleChangeType(e)
                              }}
                           >
                              {(selectedStrata === 'Central' || selectedStrata === 'State') && (
                                 <FormControlLabel
                                    value="Legislative"
                                    control={<Radio size="small" />}
                                    label="Legislative"
                                 />
                              )}
                              <FormControlLabel value="Executive" control={<Radio size="small" />} label="Executive" />
                              {(selectedStrata === 'Central' || selectedStrata === 'State') && (
                                 <FormControlLabel value="Ministry" control={<Radio size="small" />} label="Ministry" />
                              )}
                              <FormControlLabel
                                 value="Independent Department"
                                 control={<Radio size="small" />}
                                 label="Independent Department"
                              />
                              {/* <FormControlLabel value="Department" control={<Radio size="small" />} label="Department" /> */}
                              <FormControlLabel
                                 value="Apex / Independent Body"
                                 control={<Radio size="small" />}
                                 label="Apex / Independent Body"
                              />
                              {selectedStrata !== 'Central' && selectedStrata !== 'State' && (
                                 <FormControlLabel
                                    value="Local Body"
                                    control={<Radio size="small" />}
                                    label="Local Body"
                                 />
                              )}
                           </RadioGroup>
                        </FormControl>
                     </Box>
                  )}

                  {showForm && (
                     <Box>
                        {legisDetails.Type === 'Ministry' && (
                           <Box sx={{ border: 1, borderRadius: 1, p: 1, backgroundColor: '#d1d1d1' }}>
                              <Typography variant="h6">Government known as:</Typography>
                              <Box>
                                 <TextField
                                    onBlur={(event) => event}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                    value={legisDetails.GovernmentKnownAs}
                                    name="GovernmentKnownAs"
                                    id="governmentKnownAs"
                                    label="English"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    size="small"
                                    sx={{ paddingBottom: 0, backgroundColor: '#ffffff' }}
                                 ></TextField>
                              </Box>
                              <Box>
                                 <TextField
                                    onBlur={(event) => event}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                    value={legisDetails.GovernmentKnownAsHindi}
                                    name="GovernmentKnownAsHindi"
                                    id="governmentKnownAsHindi"
                                    label="Hindi"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    sx={{ paddingBottom: 0, backgroundColor: '#ffffff' }}
                                 ></TextField>
                              </Box>
                              <Box>
                                 <TextField
                                    onBlur={(event) => event}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                    value={legisDetails.GovernmentKnownAsLocal}
                                    name="GovernmentKnownAsLocal"
                                    id="governmentKnownAsLocal"
                                    label="Local"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    sx={{ paddingBottom: 0, backgroundColor: '#ffffff' }}
                                 ></TextField>
                              </Box>
                           </Box>
                        )}
                        {legisDetails.Type === 'Local Body' && (
                           <Box
                              sx={{ border: 1, borderRadius: 1, p: 1, backgroundColor: '#d1d1d1', overflow: 'hidden' }}
                           >
                              <Typography variant="h6">Constituency Zones:</Typography>
                              <Box>
                                 <Checkbox
                                    checked={checkedHasZone}
                                    onChange={(e) => {
                                       handleChangeHasZone(e)
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                 />
                              </Box>
                              <Box sx={{ overflow: 'hidden' }}>
                                 <TextField
                                    onBlur={(event) => event}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                    value={legisDetails.ConstituencyZoneName}
                                    name="ConstituencyZoneName"
                                    id="hasConstituencyZoneName"
                                    label="Zone in English"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    size="small"
                                    sx={{ paddingBottom: 0, backgroundColor: '#ffffff' }}
                                    disabled={!checkedHasZone}
                                 ></TextField>
                              </Box>
                              <Box>
                                 <TextField
                                    onBlur={(event) => event}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                    value={legisDetails.ConstituencyZoneNameHindi}
                                    name="ConstituencyZoneNameHindi"
                                    id="hasConstituencyZoneNameHindi"
                                    label="Zone in Hindi"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    sx={{ paddingBottom: 0, backgroundColor: '#ffffff' }}
                                    disabled={!checkedHasZone}
                                 ></TextField>
                              </Box>
                              <Box>
                                 <TextField
                                    onBlur={(event) => event}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                    value={legisDetails.ConstituencyZoneNameLocal}
                                    name="ConstituencyZoneNameLocal"
                                    id="hasConstituencyZoneNameLocal"
                                    label="Zone in Local"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    sx={{ paddingBottom: 0, backgroundColor: '#ffffff' }}
                                    disabled={!checkedHasZone}
                                 ></TextField>
                              </Box>
                           </Box>
                        )}
                        <Box>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                              value={legisDetails.Name}
                              name="Name"
                              id="name"
                              label="Name"
                              variant="outlined"
                              margin="dense"
                              required
                              fullWidth
                              size="small"
                              sx={{ paddingBottom: 0 }}
                           ></TextField>
                        </Box>
                        <Box>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                              value={legisDetails.NameHindi}
                              name="NameHindi"
                              id="nameHindi"
                              label="Name (in HIndi)"
                              variant="outlined"
                              margin="dense"
                              size="small"
                              fullWidth
                           ></TextField>
                        </Box>
                        <Box>
                           <TextField
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                              value={legisDetails.NameLocal}
                              name="NameLocal"
                              id="nameLocal"
                              label="Name (in Local language)"
                              variant="outlined"
                              margin="dense"
                              size="small"
                              fullWidth
                           ></TextField>
                        </Box>
                        {(legisDetails.Type === 'Legislative' ||
                           legisDetails.Type === 'Executive' ||
                           legisDetails.Type === 'Local Body') && (
                           <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <FormControl sx={{ m: 1, width: '100%' }} size="small">
                                 <InputLabel id="demo-select-small-label">Nature</InputLabel>
                                 <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={legisDetails.Nature}
                                    label="Nature"
                                    onChange={handleNatureChange}
                                 >
                                    <MenuItem value="">
                                       <em>None</em>
                                    </MenuItem>
                                    {naturesList &&
                                       naturesList.map((item) => (
                                          <MenuItem key={item.id} value={item.Name}>
                                             {item.Name}
                                          </MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>

                              <TextField
                                 type="number"
                                 onBlur={(event) => event}
                                 onChange={(e) => {
                                    handleLegisDetails(e)
                                 }}
                                 value={legisDetails.Duration}
                                 name="Duration"
                                 id="duration"
                                 label="Duration (years)"
                                 variant="outlined"
                                 margin="dense"
                                 size="small"
                                 fullWidth
                              ></TextField>

                              <FormControl>
                                 <FormLabel id="demo-controlled-radio-buttons-group">Is singular member?</FormLabel>
                                 <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="SingularMem"
                                    value={legisDetails.SingularMem}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                 >
                                    <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                                 </RadioGroup>
                              </FormControl>

                              <FormControl>
                                 <FormLabel id="demo-controlled-radio-buttons-group">Has houses?</FormLabel>
                                 <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="HasHouses"
                                    value={legisDetails.HasHouses}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                 >
                                    <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                                 </RadioGroup>
                              </FormControl>

                              <FormControl>
                                 <FormLabel id="demo-controlled-radio-buttons-group">Has constituencies?</FormLabel>
                                 <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="HasConstituencies"
                                    value={legisDetails.HasConstituencies}
                                    onChange={(e) => {
                                       handleLegisDetails(e)
                                    }}
                                 >
                                    <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
                                 </RadioGroup>
                              </FormControl>

                              <TextField
                                 type="number"
                                 onBlur={(event) => event}
                                 onChange={(e) => {
                                    handleLegisDetails(e)
                                 }}
                                 value={legisDetails.ElectiveSeats}
                                 name="ElectiveSeats"
                                 id="electiveSeats"
                                 label="Elective Seats"
                                 variant="outlined"
                                 margin="dense"
                                 size="small"
                                 fullWidth
                              ></TextField>

                              <TextField
                                 type="number"
                                 onBlur={(event) => event}
                                 onChange={(e) => {
                                    handleLegisDetails(e)
                                 }}
                                 value={legisDetails.NominatedSeats}
                                 name="NominatedSeats"
                                 id="nominatedSeats"
                                 label="Nominated Seats"
                                 variant="outlined"
                                 margin="dense"
                                 size="small"
                                 fullWidth
                              ></TextField>

                              <TextField
                                 onBlur={(event) => event}
                                 onChange={(e) => {
                                    handleLegisDetails(e)
                                 }}
                                 value={legisDetails.NominatingAuthorities}
                                 name="NominatingAuthorities"
                                 id="NominatingAuthorities"
                                 label="Nominating / Appointing Authorities"
                                 variant="outlined"
                                 margin="dense"
                                 size="small"
                                 fullWidth
                              ></TextField>
                           </Box>
                        )}

                        <Box>
                           <TextField
                              type="number"
                              onBlur={(event) => event}
                              onChange={(e) => {
                                 handleLegisDetails(e)
                              }}
                              value={legisDetails.Priority}
                              name="Priority"
                              id="priority"
                              label="Priority"
                              variant="outlined"
                              margin="dense"
                              size="small"
                              fullWidth
                           ></TextField>
                        </Box>
                        <Box className={styles.alertDiv}>
                           {uploading && (
                              <MyLoaderCircularGradient
                                 title={'Listing the legislature ...'}
                              ></MyLoaderCircularGradient>
                           )}
                           {uploaded && (
                              <Alert variant="outlined" severity="success">
                                 <strong>{lastLegis}</strong> listed successfully.
                              </Alert>
                           )}
                           {uploadingFailed && (
                              <Alert variant="outlined" severity="error">
                                 Error occured! <strong>{lastLegis}</strong> could not be listed.
                              </Alert>
                           )}
                        </Box>
                        <Box>
                           <Button
                              type="submit"
                              align="center"
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={(e) => {
                                 handleCreateLegis(e)
                              }}
                              disabled={legisDetails.Name.length < 3 || legisDetails.Type.length < 3}
                           >
                              Submit
                           </Button>
                        </Box>
                     </Box>
                  )}
               </Box>
               <Header title="List of legislatures" />
               <Box className={styles.Box200}>
                  {showLegisList &&
                     legisList &&
                     legisList.map((item, index) => (
                        <Box
                           key={item.id}
                           sx={{
                              bgcolor: '#fff',
                              borderRadius: 2,
                              p: 2,
                              minWidth: 300,
                              my: 0.5,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              backgroundImage: `linear-gradient(
                           to bottom,
                           rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.7) 100%
                           )`,
                           }}
                        >
                           <Box>
                              <Box sx={{ color: '#FF5F1F', fontSize: 14 }}>
                                 <CopyToClipboard text={item.id} onCopy={() => onCopyText(index)}>
                                    {/* <button className={styles.button_orange}>id: {item.id}</button> */}
                                    <Button variant="contained" color="success" size="small">
                                       id: {item.id}
                                    </Button>
                                 </CopyToClipboard>
                                 {copyStatus && indexOfCopy === index && <p>id copied!</p>}
                                 {/* {copyStatus && <Box className={`alert alert-success`}>copied</Box>} */}
                              </Box>
                              <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>{item.NameAlt}</Box>
                              {/* <Box sx={{ color: '#173A5E', fontSize: 24, fontWeight: 'medium' }}>{item.Name}</Box> */}
                              <Box sx={{ color: '#ffffff', fontSize: 18, fontWeight: 'medium' }}>{item.Name}</Box>
                              <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>Country: {item.CountryName}</Box>
                              {item.StateName && (
                                 <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>State: {item.StateName}</Box>
                              )}
                              {item.DistrictName && (
                                 <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>
                                    District: {item.DistrictName.join(', ')}
                                 </Box>
                              )}
                              {item.Type && <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>Type: {item.Type}</Box>}
                              {item.Priority && (
                                 <Box sx={{ color: '#FFFFFF', fontSize: 14 }}>Priority: {item.Priority}</Box>
                              )}
                              <Box
                                 sx={{
                                    display: 'inline',
                                    mx: 0.5,
                                    fontSize: 14,
                                 }}
                              >
                                 <Link
                                    href={`/legislature/${item.Name}/${item.id}`}
                                    color="#00FFFF"
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    <InfoSharpIcon />
                                 </Link>
                              </Box>
                              {item.ForLegis ? (
                                 <Box sx={{ color: '#0FFF50', display: 'inline', fontSize: 14 }}>
                                    Open for operation
                                 </Box>
                              ) : (
                                 <Box sx={{ color: '#FF7F50', display: 'inline', fontSize: 14 }}>
                                    Not ready for operation
                                 </Box>
                              )}
                           </Box>
                           <Box sx={{ fontSize: 12, display: 'flex', flexDirection: 'column' }}>
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={item.ForLegis ? 'error' : 'success'}
                                 size="small"
                                 sx={{
                                    textTransform: 'none',
                                    fontWeight: '400',
                                    marginBottom: 0.5,
                                    px: 1,
                                    py: 0,
                                    minWidth: 0,
                                 }}
                                 onClick={(e) => {
                                    handleOperation(e, item)
                                 }}
                              >
                                 {item.ForLegis ? 'Stop operation' : 'Start operation'}
                              </Button>
                              <Button
                                 type="submit"
                                 align="center"
                                 variant="contained"
                                 color={'error'}
                                 size="small"
                                 sx={{ textTransform: 'none', fontWeight: '400', px: 1, py: 0, minWidth: 0 }}
                                 onClick={(e) => {
                                    handleDelete(e, item)
                                 }}
                              >
                                 Delete
                              </Button>
                           </Box>
                        </Box>
                     ))}
               </Box>
            </Box>
         )}
      </Box>
   )
}

export default LegisAllAdmin
