import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'

import { v4 } from 'uuid'
import {
   Divider,
   Box,
   Button,
   Card,
   CardContent,
   Grid,
   IconButton,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Tabs,
   Typography,
   Avatar,
} from '@mui/material'
import { TabContext } from '@mui/lab'

import SwipeableViews from 'react-swipeable-views'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Microlink from '@microlink/react'

import MinistryBasicInfo from '../MinistryBasicInfo/MinistryBasicInfo'
import MinistryOB from '../MinistryOfficeBearers/MinistryOB'
import MinistrySchemes from '../MinistrySchemes/MinistrySchemes'
import MinistryCampaigns from '../MinistryCampaigns/MinistryCampaigns'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import {
   arrayRemove,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import EditPriorityAndDescr from '../../../Components/Edits/EditPriorityAndDescr'
import AddImageOnly from '../../../Components/Edits/AddImageOnly'
import AddWeblinkWithPriority from '../../../Components/Edits/AddWeblinkWithPriority'
import MinistryInteract from '../MinistryInteract/MinistryInteract'

function Ministry() {
   const { legisId } = useParams('legisId')
   const navigate = useNavigate()

   const user = useSelector(selectUsers)
   const ip = user.ip

   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const userProfile = useSelector(selectUserProfile)
   const [nameOfUser, setNameOfUser] = useState('')
   const [record, setRecord] = useState('')

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
      }
   }, [userProfile])

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const [legis, setLegis] = useState({})
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [weblinksFromGallery, setWeblinksFromGallery] = useState('')
   const [imagesFromGallery, setImagesFromGallery] = useState('')
   const [sourceImageMain, setSourceImageMain] = useState('')

   const [petitionsList, setPetitionsList] = useState([])

   console.log('sourceImageMain', sourceImageMain)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchLegis(legisId)
         fetchPetitionCategories()
      }
   }, [])

   const fetchLegis = async (legisId) => {
      try {
         const docRef = doc(db, 'Legislatures', legisId)
         const docSnap = await getDoc(docRef)
         console.log('fetch legis started')
         if (docSnap.exists()) {
            console.log('fetch legis found', docSnap.data())
            setLegis({ ...docSnap.data(), id: docSnap.id })
         } else {
            console.log('fetch legis NOT Found')
            navigate('/', { replace: true })
         }

         setFirstLoadStatus('success')
      } catch (err) {
         console.log('error', err)
         setFirstLoadStatus('error')
      }
   }

   useEffect(() => {
      if (legis !== '') {
         setSourceImageMain(legis.Image)
         fetchRecordFromRelatedArea()
         fetchGallery()
      }
   }, [legis])

   // petitions

   const today = new Date()
   // const quarter = Math.floor((today.getMonth() + 3) / 3)
   // const month = String(today.getMonth() + 1).padStart(2, '0')
   const year = today.getFullYear()
   const docIdPetitions = 'Petitions-' + year

   const [petitionCategoriesList, setPetitionCategoriesList] = useState('')
   const fetchPetitionCategories = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'PetitionCategories')
         const docSnap = await getDoc(docRef)
         setPetitionCategoriesList([...docSnap.data().Categories])
      } catch (err) {
         console.log('error fetching categories', err)
      }
   }

   const [attachedToConsti, setAttachedToConsti] = useState('')

   useEffect(() => {
      if (legis !== '' && userProfile !== '') {
         //
         fetchPetitions()
         //
         switch (legis.Strata) {
            case 'Central':
               if (!userProfile || (userProfile && userProfile.AtCentralConstituency === '')) {
                  setAttachedToConsti({ Attached: 'Nowhere', Strata: 'Central', Constituency: '' })
               } else if (userProfile && userProfile.AtCentralConstituency !== '') {
                  if (userProfile.AtCentralConstituency.Legislature_id === legis.ParentLegislature_id) {
                     setAttachedToConsti({
                        Attached: 'Here',
                        Strata: 'Central',
                        Constituency: userProfile.AtCentralConstituency.NameLocal
                           ? userProfile.AtCentralConstituency.Name +
                             ' (' +
                             userProfile.AtCentralConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtCentralConstituency.NameLocal
                           : userProfile.AtCentralConstituency.Name +
                             ' (' +
                             userProfile.AtCentralConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtCentralConstituency.NameHindi,
                     })
                  } else {
                     setAttachedToConsti({
                        Attached: 'There',
                        Strata: 'Central',
                        Constituency: userProfile.AtCentralConstituency.Name,
                     })
                  }
               }
               break
            case 'State':
               console.log('test 1', userProfile)
               if (!userProfile || (userProfile && userProfile.AtStateConstituency === '')) {
                  console.log('test 2')
                  setAttachedToConsti({ Attached: 'Nowhere', Strata: 'State', Constituency: '' })
               } else if (userProfile && userProfile.AtStateConstituency !== '') {
                  console.log('test 3', userProfile)
                  if (userProfile.AtStateConstituency.Legislature_id === legis.ParentLegislature_id) {
                     setAttachedToConsti({
                        Attached: 'Here',
                        Strata: 'State',
                        Constituency: userProfile.AtStateConstituency.NameLocal
                           ? userProfile.AtStateConstituency.Name +
                             ' (' +
                             userProfile.AtStateConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtStateConstituency.NameLocal
                           : userProfile.AtStateConstituency.Name +
                             ' (' +
                             userProfile.AtStateConstituency.ConstituencyNumber +
                             ') ' +
                             userProfile.AtStateConstituency.NameHindi,
                     })
                  } else {
                     setAttachedToConsti({
                        Attached: 'There',
                        Strata: 'State',
                        Constituency: userProfile.AtStateConstituency.Name,
                     })
                  }
               }
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            case 'SubDistrict':
               //
               break
            case 'Block':
               //
               break
            case 'Village':
               //
               break
         }

         console.log('test 4')
      }
   }, [legis, userProfile])

   const fetchPetitions = async () => {
      console.log('frtching petitions at first fetch: ')
      try {
         console.log('fetching petitions at first fetch 2: ', legis.id)
         const querry = query(
            collection(db, 'LegisMembers', legis.id, docIdPetitions),
            where('Type', '==', 'PetitionsCoreList'),
         )
         const querySnapshot = await getDocs(querry)

         const listFetched = []

         if (!querySnapshot.empty) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Petitions)
            })
         }
         console.log('petitions at first fetch: ', listFetched)
         setPetitionsList(listFetched)
      } catch (err) {
         console.log('error fetching petitions', err)
      }
   }

   const handlePetitionsListUpdate = (obj) => {
      petitionsList.push(obj)
   }

   // splinter details
   const fetchRecordFromRelatedArea = async () => {
      try {
         const docRefCentral = doc(db, 'Countries', legis.Country_id)
         const docSnapCentral = await getDoc(docRefCentral)
         let docRefState = ''
         let docSnapState = ''
         if (legis.State_id) {
            docRefState = doc(db, 'States', legis.State_id)
            docSnapState = await getDoc(docRefState)
         }
         let docRefDivision = ''
         let docSnapDivision = ''
         if (legis.Division_id) {
            docRefDivision = doc(db, 'Divisions', legis.Division_id)
            docSnapDivision = await getDoc(docRefDivision)
         }
         let docRefDistrict = ''
         let docSnapDistrict = ''
         if (legis.District_id) {
            docRefDistrict = doc(db, 'Districts', legis.District_id)
            docSnapDistrict = await getDoc(docRefDistrict)
         }
         let docRefSubDistrict = ''
         let docSnapSubDistrict = ''
         if (legis.SubDistrict_id) {
            docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
            docSnapSubDistrict = await getDoc(docRefSubDistrict)
         }
         let docRefBlock = ''
         let docSnapBlock = ''
         if (legis.Block_id) {
            docRefBlock = doc(db, 'Blocks', legis.Block_id)
            docSnapBlock = await getDoc(docRefBlock)
         }
         let docRefVillage = ''
         let docSnapVillage = ''
         if (legis.Village_id) {
            docRefVillage = doc(db, 'Villages', legis.Village_id)
            docSnapVillage = await getDoc(docRefVillage)
         }

         switch (legis.Strata) {
            case 'Central':
               console.log('entered case central ')
               if (docSnapCentral.exists()) {
                  const list = docSnapCentral.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  console.log('thisItem: ', thisItem)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
            case 'State':
               console.log('entered case state ')
               if (docSnapState.exists()) {
                  const list = docSnapState.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
            case 'Division':
               console.log('entered case Division ')
               if (docSnapDivision.exists()) {
                  const list = docSnapDivision.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
            case 'District':
               console.log('entered case District ')
               if (docSnapDistrict.exists()) {
                  const list = docSnapDistrict.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
            case 'SubDistrict':
               console.log('entered case sub-district ')
               if (docSnapSubDistrict.exists()) {
                  const list = docSnapSubDistrict.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
            case 'Block':
               console.log('entered case block ')
               if (docSnapBlock.exists()) {
                  const list = docSnapBlock.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
            case 'Village':
               console.log('entered case village ')
               if (docSnapVillage.exists()) {
                  const list = docSnapVillage.data().Legislatures
                  const thisItem = list.find((item) => item.Legislature_id === legis.id)
                  setRecord(thisItem)
               } else {
                  navigate('/', { replace: true })
               }
               break
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleUpdateRecordLocal = (newRecord) => {
      setRecord(newRecord)
   }

   const handleSourceImageMain = (url) => {
      setSourceImageMain(url)
   }

   const fetchGallery = async () => {
      const docRefGallery = doc(db, 'Legislatures', legisId, 'Gallery', 'Weblinks-001')
      const docSnapGallery = await getDoc(docRefGallery)
      const docRefGalleryImages = doc(db, 'Legislatures', legisId, 'Gallery', 'Images-001')
      const docSnapGalleryImages = await getDoc(docRefGalleryImages)
      if (docSnapGallery.exists()) {
         if (docSnapGallery.data().WebLinksObj) {
            const list = [...docSnapGallery.data().WebLinks].filter((item) => !item.Removed)
            const sortedList = [...list].sort((a, b) => a.Priority - b.Priority)
            setWeblinksFromGallery(sortedList)
         }
      }
      if (docSnapGalleryImages.exists()) {
         setImagesFromGallery(docSnapGalleryImages.data().Images ? [...docSnapGallery.data().Images] : [])
      }
   }

   // related to Weblink from gallery
   const [openModalGalleryWeblink, setOpenModalGalleryWeblink] = useState(false)

   const handleOpenModalGalleryWeblink = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalGalleryWeblink(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalGalleryWeblink = () => setOpenModalGalleryWeblink(false)

   const handleAddWeblinkToGallery = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrByName = nameOfUser
            weblinkDetails.CrByImage = userProfile.Image.Image
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip
            weblinkDetails.ModDt = ''

            weblinkDetails.CoreListId = 'Images-000001'
            //
            if (legis.Helpers.includes(thisUser)) {
               weblinkDetails.Uploader = 'Helper'
            } else {
               weblinkDetails.Uploader = 'SiteAdmin'
            }
            const docRefGalleryWeblinks = doc(db, 'Legislatures', legis.id, 'Gallery', 'Weblinks-000001')
            const docSnapGalleryWeblinks = await getDoc(docRefGalleryWeblinks)

            if (docSnapGalleryWeblinks.exists()) {
               await updateDoc(docRefGalleryWeblinks, {
                  WebLinks: arrayUnion(weblinkDetails.Weblink),
                  WebLinksObj: arrayUnion(weblinkDetails),
               })
            } else {
               setDoc(
                  docRefGalleryWeblinks,
                  { WebLinks: [weblinkDetails.Weblink], WebLinksObj: [weblinkDetails] },
                  { merge: true },
               )
            }

            // update the website display
            weblinksFromGallery.push(weblinkDetails)

            // close modal
            // setOpenModalGalleryWeblink(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleRemoveWeblinkFromGallery = async (e, item) => {
      e.preventDefault()
      console.log('item; ', item.Description)
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            if (confirm('Are you sure you want to remove this web link?')) {
               try {
                  const newItem = {
                     ...item,
                     Removed: true,
                     RemovedBy: auth.currentUser.uid,
                     RemovedByName: nameOfUser,
                     RemovedByImage: userProfile.Image.Image,
                     RemovedDt: new Date(),
                     RemovedByIP: ip.data.ip,
                  }
                  const docRefGalleryWeblinks = doc(db, 'Legislatures', legis.id, 'Gallery', 'Weblinks-000001')
                  await updateDoc(docRefGalleryWeblinks, {
                     WebLinksObj: arrayRemove(item),
                  })
                  await updateDoc(docRefGalleryWeblinks, {
                     WebLinksObj: arrayUnion(newItem),
                  })

                  const list = weblinksFromGallery.filter((item) => item !== selectedWeblinkFromGallery)
                  setWeblinksFromGallery(list)
               } catch (error) {
                  console.log('error removing link', error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const [selectedWeblinkFromGallery, setSelectedWeblinkFromGallery] = useState('')

   const [openModalEditGalleryWeblink, setOpenModalEditGalleryWeblink] = useState(false)

   const handleCloseModalEditGalleryWeblink = () => setOpenModalEditGalleryWeblink(false)

   const handleEditGalleryWeblink = async (e, item) => {
      e.preventDefault()
      console.log('item; ', item.Description)
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setSelectedWeblinkFromGallery(item)
            setOpenModalEditGalleryWeblink(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleEditGalleryWeblinkSubmit = async (details) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            const newItem = {
               ...selectedWeblinkFromGallery,
               Priority: details.Priority,
               Description: details.Description,
            }
            const docRefCandiGallery = doc(db, 'Legislatures', legis.id, 'Gallery', 'Weblinks-000001')
            await updateDoc(docRefCandiGallery, {
               WebLinksObj: arrayRemove(selectedWeblinkFromGallery),
            })
            await updateDoc(docRefCandiGallery, {
               WebLinksObj: arrayUnion(newItem),
            })
            // setSelectedWeblinkFromGallery('')
            setOpenModalEditGalleryWeblink(false)

            // update local state
            const list = weblinksFromGallery.filter((item) => item !== selectedWeblinkFromGallery)
            list.push(newItem)
            const sortedList = [...list].sort((a, b) => a.Priority - b.Priority)
            setWeblinksFromGallery(sortedList)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            //
            // console.log('stage xxx 1')
            const imageRef = ref(dbStorage, `Legislatures/${legis.id}/${imageDetails.image.name + v4()}`)
            // console.log('stage xxx 2')
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const obj = {
                     Image: url,
                     CoreListId: 'Images-001',
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     ModDt: '',
                  }
                  if (legis.Helpers.includes(thisUser)) {
                     obj.Uploader = 'Helper'
                  } else {
                     obj.Uploader = 'SiteAdmin'
                  }
                  //
                  // console.log('stage xxx 3')
                  const docRefGalleryImages = doc(db, 'Legislatures', legis.id, 'Gallery', 'Images-001')
                  const docSnapGalleryImages = await getDoc(docRefGalleryImages)

                  if (docSnapGalleryImages.exists()) {
                     await updateDoc(docRefGalleryImages, {
                        Images: arrayUnion(url),
                        ImagesObj: arrayUnion(obj),
                     })
                  } else {
                     setDoc(docRefGalleryImages, { Images: [url], ImagesObj: [obj] }, { merge: true })
                  }

                  imagesFromGallery.push(url)
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ minHeight: 600, px: 0.5, pb: 20 }}>
         <Card
            sx={{
               my: 1,
               // mx: 5,
               py: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundImage: 'linear-gradient(to right, #002242, #00162A)',
            }}
         >
            <Box
               sx={{
                  backgroundColor: '#EDC970',
                  fontWeight: 800,
                  p: 0.5,
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                  borderRadius: 1,
               }}
            >
               Ministry {'\n'} मंत्रालय
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
               <CardContent sx={{ flex: '1 0 auto', fontWeight: 700 }}>
                  {legis && (
                     <div>
                        {legis.NameLocal && (
                           <>
                              <Typography
                                 component="div"
                                 variant="h5"
                                 sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins', color: '#EDC970' }}
                                 color="#FFFFFF"
                              >
                                 {legis.NameLocal}
                              </Typography>
                              <Typography
                                 component="div"
                                 sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins', color: '#EDC970' }}
                                 color="#FFFFFF"
                              >
                                 {legis.GovernmentKnownAsLocal}
                              </Typography>
                           </>
                        )}
                        {legis.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins', mt: 1, color: '#EDC970' }}
                           >
                              {legis.NameHindi}
                           </Typography>
                        )}
                        {legis.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins', mt: 1 }}
                              color="#FFFFFF"
                           >
                              {legis.NameHindi}
                           </Typography>
                        )}
                        <Typography
                           component="div"
                           sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              fontFamily: 'Poppins',
                              color: legis.NameLocal === '' ? '#EDC970' : '#FFFFFF',
                           }}
                        >
                           {legis.GovernmentKnownAsHindi}
                        </Typography>
                        {legis.NameLocal === '' && (
                           <Typography
                              component="div"
                              variant="h5"
                              sx={{ fontWeight: 700, fontSize: 22, fontFamily: 'Poppins', mt: 1 }}
                              color="#FFFFFF"
                           >
                              {legis.Name}
                           </Typography>
                        )}
                        {legis.NameLocal !== '' && (
                           <Typography
                              component="div"
                              sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins', mt: 1 }}
                              color="#FFFFFF"
                           >
                              {legis.Name}
                           </Typography>
                        )}
                        <Typography
                           component="div"
                           sx={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins' }}
                           color="#FFFFFF"
                        >
                           {legis.GovernmentKnownAs}
                        </Typography>
                     </div>
                  )}
               </CardContent>
            </Box>
            <Avatar
               src={
                  sourceImageMain ||
                  'https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
               }
               sx={{ width: 150, height: 150 }}
            ></Avatar>
         </Card>
         <TabContext value={value}>
            <div>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#f6db87',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Send Petitions \n याचिकाएँ भेजें`} {...a11yProps(1)} />
                  <StyledTab1 label={`Officers \n पदाधिकारी`} {...a11yProps(2)} />
                  <StyledTab1 label={`Schemes \n योजनाएं`} {...a11yProps(3)} />
                  <StyledTab1 label={`Campaigns \n अभियान`} {...a11yProps(4)} />
               </Tabs>
            </div>
            <Grid container spacing={1} sx={{ mt: 1 }} id="vBoxGridContainer">
               <Grid item xs={12} sm={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                        id="swipeableee"
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {legis && (
                              <MinistryBasicInfo
                                 legis={legis}
                                 record={record}
                                 handleUpdateRecordLocal={handleUpdateRecordLocal}
                                 handleSourceImageMain={handleSourceImageMain}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {legis && (
                              <MinistryInteract
                                 legis={legis}
                                 attachedToConsti={attachedToConsti}
                                 petitionCategoriesList={petitionCategoriesList}
                                 petitionsList={petitionsList}
                                 handlePetitionsListUpdate={handlePetitionsListUpdate}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {legis && <MinistryOB legis={legis} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {legis && <MinistrySchemes legis={legis} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           {legis && <MinistryCampaigns legis={legis} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid>
               <Grid item xs={12} sm={4}>
                  {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                     <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Button
                           variant="outlined"
                           sx={{
                              textTransform: 'none',
                              py: 0.5,
                              minWidth: 0,
                              px: 1,
                              whiteSpace: 'pre-line',
                              lineHeight: 1.2,
                              '&:hover': { backgroundColor: '#01579b', color: '#FFFFFF' },
                           }}
                           onClick={(e) => {
                              handleOpenModalGalleryWeblink(e)
                           }}
                        >
                           Add video link
                        </Button>
                        <Button
                           variant="outlined"
                           sx={{
                              textTransform: 'none',
                              py: 0.5,
                              minWidth: 0,
                              px: 1,
                              whiteSpace: 'pre-line',
                              lineHeight: 1.2,
                              '&:hover': { backgroundColor: '#01579b', color: '#FFFFFF' },
                              display: 'none',
                           }}
                           onClick={(e) => {
                              handleOpenModalImage(e)
                           }}
                        >
                           Add image
                        </Button>
                     </Box>
                  )}
                  <List dense>
                     {weblinksFromGallery &&
                        weblinksFromGallery.length > 0 &&
                        weblinksFromGallery.map((item, index) => (
                           <div key={index}>
                              <ListItem sx={{ pl: 0, ml: 0 }} key={index}>
                                 <ListItemAvatar>
                                    <Microlink url={item.Weblink} fetchData lazy={{ threshold: 0.5 }} />
                                 </ListItemAvatar>
                                 <ListItemText
                                    sx={{ ml: 1 }}
                                    secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                    primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
                                    // primary={<>{item.Description}</>}
                                 />
                              </ListItem>
                              <Typography variant="body2" align="left">
                                 {item.Description}
                              </Typography>
                              {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                                 <>
                                    <IconButton
                                       edge="end"
                                       aria-label="editIntro"
                                       onClick={(e) => {
                                          handleEditGalleryWeblink(e, item)
                                       }}
                                       sx={{ mx: 1, height: '30px', width: '30px' }}
                                    >
                                       <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleRemoveWeblinkFromGallery(e, item)
                                       }}
                                       sx={{ mx: 1, height: '30px', width: '30px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    P: {item.Priority}
                                 </>
                              )}
                              <Divider />
                           </div>
                        ))}
                  </List>
               </Grid>
            </Grid>
            <Box id="boxModals">
               <Modal
                  open={openModalGalleryWeblink}
                  onClose={handleCloseModalGalleryWeblink}
                  aria-labelledby="modal-modal-title-website"
                  aria-describedby="modal-modal-description-website"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-website"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add a video web link
                     </Typography>
                     <AddWeblinkWithPriority
                        handleAddWeblinkToGallery={handleAddWeblinkToGallery}
                     ></AddWeblinkWithPriority>
                  </Box>
               </Modal>
               <Modal
                  open={openModalEditGalleryWeblink}
                  onClose={handleCloseModalEditGalleryWeblink}
                  aria-labelledby="modal-modal-title-EditGalleryWeblink"
                  aria-describedby="modal-modal-description-EditGalleryWeblink"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-EditGalleryWeblink"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Edit
                     </Typography>
                     <EditPriorityAndDescr
                        handleEditPriorityAndDescr={handleEditGalleryWeblinkSubmit}
                        item={selectedWeblinkFromGallery}
                     ></EditPriorityAndDescr>
                  </Box>
               </Modal>
               <Modal
                  open={openModalImage}
                  onClose={handleCloseModalImage}
                  aria-labelledby="modal-modal-title-logo"
                  aria-describedby="modal-modal-description-logo"
                  disableScrollLock
               >
                  <Box sx={styleModalBox}>
                     <Typography
                        id="modal-modal-title-logo"
                        variant="h6"
                        component="h2"
                        align="center"
                        sx={styleModalTypography}
                     >
                        Add image
                     </Typography>
                     <AddImageOnly handleAddImage={handleAddImage}></AddImageOnly>
                  </Box>
               </Modal>
            </Box>
         </TabContext>
      </Box>
   )
}

export default Ministry
