import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   Box,
   Button,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormLabel,
   InputLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Stack,
   TextField,
} from '@mui/material'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'

function AddArticle({ mainCats, publicCats, handleAddArticle, uploading, uploaded, uploadingFailed }) {
   const [articleDetails, setArticleDetails] = useState({
      Article: '',
      ArticleHindi: '',
      ArticleLocal: '',
      Priority: 0,

      MainCat: '',
      MainCat_id: '',
      PublicCats: [],
      PublicCats_id: [],

      IsFree: '',
      IsSubsidy: '',

      IsRemoved: '',
      IsRemovedReason: '',
   })

   function handleArticleDetails(e) {
      e.preventDefault()
      setArticleDetails({ ...articleDetails, [e.target.name]: e.target.value })
   }

   function handleFree(e) {
      e.preventDefault()
      setArticleDetails({ ...articleDetails, [e.target.name]: e.target.value === 'true' })
   }

   function handleSubsidy(e) {
      e.preventDefault()
      setArticleDetails({ ...articleDetails, [e.target.name]: e.target.value === 'true' })
   }

   function handleChangeMainCat(e) {
      e.preventDefault()
      try {
         if (e.target.value !== '') {
            const cat = mainCats.find((item) => item.Id === e.target.value)
            // console.log('party flag', party.Flag)
            // console.log('party name', party.Name)
            setArticleDetails({
               ...articleDetails,
               MainCat: { Id: cat.Id, Name: cat.Name },
               MainCat_id: cat.Id,
            })
         } else {
            setArticleDetails({
               ...articleDetails,
               MainCat: { Id: '', Name: '' },
               MainCat_id: '',
            })
         }
      } catch (error) {
         console.log('Error changing main cat')
      }
   }

   const [checkedList, setCheckedList] = useState([])
   const [checkedListObjects, setCheckedListObjects] = useState([])

   const handleCheckList = (e) => {
      e.preventDefault()
      //
      if (e.target.checked) {
         console.log('entered checked')
         if (!checkedList.includes(e.target.value)) {
            setCheckedList([...checkedList, e.target.value])
            const pubCat = publicCats.find((item) => item.Id === e.target.value)
            setCheckedListObjects([...checkedListObjects, pubCat])
         }
      } else {
         console.log('entered not checked')
         const checkedIdList = [...checkedList.filter((item) => item !== e.target.value)]
         setCheckedList(checkedIdList)
         const checkedObjList = [...checkedListObjects.filter((item) => item.Id !== e.target.value)]
         setCheckedListObjects(checkedObjList)
         console.log('exited not checked')
      }
   }

   useEffect(() => {
      if (checkedListObjects) {
         console.log('checked  list objects:', checkedListObjects)
         console.log('checked  list Ids:', checkedList)
         setArticleDetails({
            ...articleDetails,
            PublicCats: checkedListObjects,
            PublicCats_id: checkedList,
         })
      }
   }, [checkedListObjects])

   function handlePriorityDetails(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setArticleDetails({ ...articleDetails, Priority: newValue })
            : setArticleDetails({ ...articleDetails, Priority: 0 })
      } else {
         setArticleDetails({ ...articleDetails, Priority: 0 })
      }
   }

   const handleSubmit = async (e) => {
      e.preventDefault()
      if (
         checkedList.length > 0 &&
         articleDetails.MainCat !== '' &&
         articleDetails.Article !== '' &&
         articleDetails.Article.length > 9 &&
         articleDetails.PublicCats_id.length > 0 &&
         articleDetails.IsFree !== '' &&
         articleDetails.IsSubsidy !== ''
      ) {
         handleAddArticle(articleDetails)
         setCheckedList([])
         setCheckedListObjects([])
         setArticleDetails({
            Article: '',
            ArticleHindi: '',
            ArticleLocal: '',
            Priority: 0,

            MainCat: '',
            MainCat_id: '',
            PublicCats: [],
            PublicCats_id: [],

            IsFree: '',
            IsSubsidy: '',

            IsRemoved: '',
            IsRemovedReason: '',
         })
      }
   }

   useEffect(() => {
      console.log('article details on change: ', articleDetails)
   }, [articleDetails])

   return (
      <Box
         sx={{
            mx: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Box>
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Main Category</InputLabel>
               <Select
                  value={articleDetails.MainCat_id}
                  label="Main Category"
                  onChange={(e) => {
                     handleChangeMainCat(e)
                  }}
                  sx={{ backgroundColor: '#ffffff' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {mainCats &&
                     mainCats.map((item) => (
                        <MenuItem key={item.Party_Id} value={item.Id} sx={{ fontSize: 13 }}>
                           {item.Name} <span style={{ color: '#0288d1', marginLeft: 4 }}> {item.NameHindi} </span>
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            <Box sx={{ width: '45ch', pl: 2 }}>
               {/* <List dense>
                  {publicCats.map((item, index) => (
                     <div key={item.Id}>
                        <ListItem
                           secondaryAction={
                              <Checkbox
                                 edge="end"
                                 onChange={(e) => {
                                    handleCheckList(e)
                                 }}
                                 value={item.Id}
                                 // checked={checkedList.includes(item.Constituency_id)}
                                 inputProps={{ 'aria-labelledby': item.Name }}
                              />
                           }
                           disablePadding
                        >
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primaryTypographyProps={{
                                 whiteSpace: 'pre-line',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                              primary={`${item.Name} (${item.ConstituencyNumber})`}
                              component={'span'}
                              secondary={
                                 <>
                                    {item.Strata === 'Central' && (
                                       <>
                                          <br />
                                          {' State / UT: '}
                                          <Typography
                                             sx={{ display: 'inline' }}
                                             component="span"
                                             variant="body2"
                                             color="text.primary"
                                          >
                                             {item.StateName}
                                          </Typography>
                                       </>
                                    )}
                                 </>
                              }
                           />
                        </ListItem>
                     </div>
                  ))}
               </List> */}
            </Box>
            <Stack
               direction="row"
               spacing={2}
               sx={{ border: 1, borderRadius: 1, mb: 1, flexWrap: 'wrap', lineHeight: 1.3 }}
            >
               {publicCats.map((item, index) => (
                  <FormControlLabel
                     key={item.Id}
                     control={
                        <Checkbox
                           onChange={(e) => {
                              handleCheckList(e)
                           }}
                           value={item.Id}
                           checked={articleDetails.PublicCats_id.includes(item.Id)}
                           inputProps={{ 'aria-labelledby': item.Name }}
                        />
                     }
                     label={item.Name}
                  />
               ))}
            </Stack>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleArticleDetails(e)
               }}
               value={articleDetails.Article}
               name="Article"
               id="article"
               label="Article"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               multiline
               rows={4}
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleArticleDetails(e)
               }}
               value={articleDetails.ArticleHindi}
               name="ArticleHindi"
               id="articleHindi"
               label="Article in Hindi"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               multiline
               rows={4}
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleArticleDetails(e)
               }}
               value={articleDetails.ArticleLocal}
               name="ArticleLocal"
               id="articleLocal"
               label="Article in Local Language"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               multiline
               rows={4}
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mt: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handlePriorityDetails(e)
               }}
               value={articleDetails.Priority}
               name="Priority"
               id="priority"
               label="Paragraph number"
               variant="outlined"
               margin="dense"
               // required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box>
            <FormControl
               sx={{
                  py: 0,
                  px: 1,
               }}
            >
               <FormLabel id="demo-controlled-radio-buttons-free">Is it free:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-free"
                  name="IsFree"
                  value={articleDetails.IsFree}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleFree(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     py: 0,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="true"
                     control={<Radio size="small" />}
                     label="Yes"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="false"
                     control={<Radio size="small" />}
                     label="No"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
         </Box>
         <Box>
            <FormControl
               sx={{
                  py: 0,
                  px: 1,
               }}
            >
               <FormLabel id="demo-controlled-radio-buttons-subsidy">Is it subsidy:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-subsidy"
                  name="IsSubsidy"
                  value={articleDetails.IsSubsidy}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleSubsidy(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     py: 0,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="true"
                     control={<Radio size="small" />}
                     label="Yes"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="false"
                     control={<Radio size="small" />}
                     label="No"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
         </Box>
         <Box sx={{ mb: 4 }}>
            {uploading && <MyLoaderCircularGradient title={'Listing the Article ...'}></MyLoaderCircularGradient>}
            {uploaded && (
               <Alert variant="outlined" severity="success">
                  <strong>Article</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailed && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>Article</strong> could not be listed.
               </Alert>
            )}
         </Box>
         <Box sx={{ pl: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmit(e)
               }}
               disabled={
                  checkedList.length < 1 ||
                  articleDetails.MainCat === '' ||
                  articleDetails.Article === '' ||
                  articleDetails.Article.length < 10 ||
                  articleDetails.PublicCats_id.length < 1 ||
                  articleDetails.IsFree === '' ||
                  articleDetails.IsSubsidy === '' ||
                  uploading
               }
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

AddArticle.propTypes = {
   mainCats: PropTypes.array.isRequired,
   publicCats: PropTypes.array.isRequired,
   handleAddArticle: PropTypes.func.isRequired,

   uploading: PropTypes.bool.isRequired,
   uploaded: PropTypes.bool.isRequired,
   uploadingFailed: PropTypes.bool.isRequired,
}

export default AddArticle
