import React, { useEffect, useState } from 'react'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Divider,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
} from '@mui/material'
import PropTypes from 'prop-types'

function HomeStateLegis({ legisList, selectedStateId }) {
   //
   const [legislatures, setLegislatures] = useState('')

   useEffect(() => {
      if (legisList) {
         const listLegislative = legisList.filter((item) => item.Type === 'Legislative')
         const sortedListLegis = [...listLegislative].sort((a, b) => {
            return parseInt(a.Priority) - parseInt(b.Priority)
         })
         setLegislatures(sortedListLegis)
      }
   }, [legisList])

   return (
      <Box sx={{ p: 0.5 }}>
         {!selectedStateId && (
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               Please select a state / UT. <br /> कृपया राज्य / केंद्र शासित प्रदेश चुनें|
            </Alert>
         )}
         {legislatures && legislatures.length < 1 && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 1 }}>
                  <AlertTitle sx={{ fontSize: 12 }}>
                     No legislative body at this level. <br /> इस स्तर पर कोई विधायिका नहीं है |
                  </AlertTitle>
               </Alert>
            </div>
         )}

         <List dense>
            {legislatures &&
               legislatures.length > 0 &&
               legislatures.map((item, index) => (
                  <Link
                     key={item.Legislature_id}
                     href={`/legislature/${item.Name}/${item.Legislature_id}`}
                     sx={{ textDecoration: 'none !important', px: 0 }}
                     rel="noreferrer"
                     target="_blank"
                  >
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                           <Avatar alt="Travis Howard" src={item.Image} />
                        </ListItemAvatar>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                           primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                           primary={item.Name}
                           secondary={`${item.NameHindi} \n ${item.NameLocal}`}
                        />
                     </ListItem>
                     <Divider />
                  </Link>
               ))}
         </List>
      </Box>
   )
}

HomeStateLegis.propTypes = {
   legisList: PropTypes.array.isRequired,
   selectedStateId: PropTypes.string.isRequired,
}

export default HomeStateLegis
