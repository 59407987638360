import React, { useEffect, useState } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { Box, Card, CardContent, Tabs, Typography, useTheme } from '@mui/material'
import { Helmet } from 'react-helmet'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'
import moment from 'moment'
import PetitionBasicInfo from '../PetitionBasicInfo/PetitionBasicInfo'
import PetitionNews from '../PetitionNews/PetitionNews'
import { useSelector } from 'react-redux'
import { selectUserProfile } from '../../../redux/reducers/users-slice'
import PetitionSupports from '../PetitionSupports/PetitionSupports'
import PetitionAffectedPeople from '../PetitionAffectedPeople/PetitionAffectedPeople'

function Petition() {
   const { petitionId } = useParams()
   const navigate = useNavigate()

   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
   }

   const userProfile = useSelector(selectUserProfile)
   const [allSupports, setAllSupports] = useState('')
   const [allSigns, setAllSigns] = useState('')

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('use effect of user profile')
         fetchSupportsByUser()
         fetchSignsByUser()
      }
   }, [userProfile])

   const fetchSupportsByUser = async () => {
      const querry = query(collection(db, 'UserProfiles', userProfile.id, 'PetitionsSupported'))
      const querySnapshot = await getDocs(querry)
      const listFetched = []
      if (!querySnapshot.empty) {
         querySnapshot.forEach((docSnap) => {
            console.log('list on first fetch - docSnap', docSnap.data().Supports)
            listFetched.push(...docSnap.data().Supports)
         })
      }
      console.log('list on first fetch - listFetched', listFetched)
      if (listFetched.length > 0) {
         const listFiltered = listFetched.filter((item) => item.Petition_id === petitionId)
         console.log('supports listFiltered at 1: ', listFiltered)
         if (listFiltered.length > 1) {
            const sortedList = listFiltered.sort((a, b) => {
               // smaller date first
               if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                  return 1
               } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                  return -1
               } else {
                  return 0
               }
            })
            setSupportsByUser(sortedList)
            console.log('list on first fetch - sortedList', sortedList)
         } else {
            setSupportsByUser(listFiltered)
            console.log('list on first fetch - supports listFiltered', listFiltered)
         }

         setSupportsByUserPopulated(true)
      } else {
         setSupportsByUser([])
         setSupportsByUserPopulated(true)
         console.log('list on first fetch - when 1 or 0', listFetched)
      }
   }

   const fetchSignsByUser = async () => {
      const querry = query(collection(db, 'UserProfiles', userProfile.id, 'PetitionsSigned'))
      const querySnapshot = await getDocs(querry)
      const listFetched = []
      if (!querySnapshot.empty) {
         querySnapshot.forEach((docSnap) => {
            console.log('list on first fetch - docSnap', docSnap.data().Signs)
            listFetched.push(...docSnap.data().Signs)
         })
      }
      console.log('list on first fetch - signs listFetched', listFetched)
      if (listFetched.length > 0) {
         const listFitered = listFetched.filter((item) => item.Petition_id === petitionId)
         console.log('signs at 1: ', listFitered)
         if (listFitered.length > 1) {
            const sortedList = listFitered.sort((a, b) => {
               // smaller date first
               if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                  return 1
               } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                  return -1
               } else {
                  return 0
               }
            })
            setSignsByUser(sortedList)
            console.log('list on first fetch signs - sortedList', sortedList)
         } else {
            setSignsByUser(listFitered)
            console.log('list on first fetch signs - listFitered', listFitered)
         }

         setSupportsByUserPopulated(true)
      } else {
         setSignsByUser([])
      }
   }

   const [fetchStatus, setFetchStatus] = useState('idle')
   const [petition, setPetition] = useState('')
   const [weblinksList, setWeblinksList] = useState([])
   const [weblinksListRoot, setWeblinksListRoot] = useState([])
   const [attachedToConsti, setAttachedToConsti] = useState('')
   const [location, setLocation] = useState('')

   useEffect(() => {
      if (fetchStatus === 'idle') {
         fetchPetition(petitionId)
         fetchAllSupports()
         fetchAllSigns()
         fetchWeblinks()
      }
   }, [])

   const fetchPetition = async (petitionId) => {
      try {
         const docRef = doc(db, 'Petitions', petitionId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setPetition({ ...docSnap.data(), id: docSnap.id })
         } else {
            navigate('/', { replace: true })
         }
         setFetchStatus('success')
      } catch (err) {
         console.log('error', err)
         setFetchStatus('error')
      }
   }

   useEffect(() => {
      if (petition !== '' && userProfile !== '') {
         findIfAttached()
      }
   }, [petition, userProfile])

   const fetchAllSupports = async () => {
      try {
         const querry = query(collection(db, 'Petitions', petitionId, 'Supports'))
         const querySnapshot = await getDocs(querry)
         if (querySnapshot && !querySnapshot.empty) {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Supports)
            })
            if (listFetched.length > 1) {
               const sortedList = [...listFetched].sort((a, b) => {
                  // bigger date first
                  if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                     return -1
                  } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setAllSupports(sortedList)
            } else {
               setAllSupports(listFetched)
            }
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const fetchAllSigns = async () => {
      try {
         const querry = query(collection(db, 'Petitions', petitionId, 'Signatories'))
         const querySnapshot = await getDocs(querry)
         if (querySnapshot && !querySnapshot.empty) {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Signatories)
            })
            if (listFetched.length > 1) {
               const sortedList = [...listFetched].sort((a, b) => {
                  // bigger date first
                  if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                     return -1
                  } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setAllSigns(sortedList)
            } else {
               setAllSigns(listFetched)
            }
         }
      } catch (err) {
         console.log('error fetching all signs', err)
      }
   }

   const findIfAttached = () => {
      switch (petition.Strata) {
         case 'Central':
            if (!userProfile || (userProfile && userProfile.AtCentralConstituency === '')) {
               console.log('test 2 C')
               setAttachedToConsti({ Attached: 'Nowhere', Strata: 'Central', Constituency: '' })
            } else if (userProfile && userProfile.AtCentralConstituency !== '') {
               console.log('test 3 C', userProfile)
               if (userProfile.AtCentralConstituency.id === petition.Consti_id) {
                  setAttachedToConsti({ Attached: 'Here', Strata: 'Central' })
               } else {
                  setAttachedToConsti({
                     Attached: 'There',
                     Strata: 'Central',
                     Constituency: userProfile.AtCentralConstituency.Name,
                  })
                  setLocation({
                     Consti_id: userProfile.AtCentralConstituency.id,
                     ConstiName: userProfile.AtCentralConstituency.Name,
                     State_id: userProfile.AtCentralConstituency.State_id,
                     StateName: userProfile.AtCentralConstituency.StateName,
                  })
               }
            }
            break
         case 'State':
            if (!userProfile || (userProfile && userProfile.AtStateConstituency === '')) {
               console.log('test 2')
               setAttachedToConsti({ Attached: 'Nowhere', Strata: 'State', Constituency: '' })
            } else if (userProfile && userProfile.AtStateConstituency !== '') {
               console.log('test 3', userProfile)
               if (userProfile.AtStateConstituency.id === petition.Consti_id) {
                  setAttachedToConsti({ Attached: 'Here', Strata: 'State' })
               } else {
                  setAttachedToConsti({
                     Attached: 'There',
                     Strata: 'State',
                     Constituency: userProfile.AtStateConstituency.Name,
                  })
                  setLocation({
                     Consti_id: userProfile.AtStateConstituency.id,
                     ConstiName: userProfile.AtStateConstituency.Name,
                     State_id: userProfile.AtStateConstituency.State_id,
                     StateName: userProfile.AtStateConstituency.StateName,
                  })
               }
            }
            break
         case 'Division':
            //
            break
         case 'District':
            //
            break
         case 'SubDistrict':
            //
            break
         case 'Block':
            //
            break
         case 'Village':
            //
            break
      }
   }

   const fetchWeblinks = async () => {
      try {
         const querry = query(collection(db, 'Petitions', petitionId, 'WeblinksLists'))
         const querySnapshot = await getDocs(querry)
         if (querySnapshot && !querySnapshot.empty) {
            const listFetched = []
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Weblinks)
            })
            if (listFetched.length > 1) {
               const sortedList = [...listFetched].sort((a, b) => {
                  // bigger date first
                  if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                     return -1
                  } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setWeblinksList(sortedList)
            } else {
               setWeblinksList(listFetched)
            }
            setWeblinksListRoot(listFetched)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const handleWeblinksUpdate = (obj) => {
      weblinksListRoot.push(obj)
   }

   const [supportsByUser, setSupportsByUser] = useState([])
   const [signsByUser, setSignsByUser] = useState([])
   const [supportsByUserPopulated, setSupportsByUserPopulated] = useState(false)
   const handleSupportsByUser = () => {
      fetchSupportsByUser()
   }

   const handleSignsByUser = () => {
      fetchSignsByUser()
   }

   const theme = useTheme()
   return (
      <Box sx={{ minHeight: 800, px: 0.5, pb: 20 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - ${petition.Title}`}</title>
            </Helmet>
         </div>
         <Card
            sx={{
               my: 1,
               p: 2,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               //    backgroundColor: '#EDC970',
               backgroundImage: 'linear-gradient(to right, #002244, #bda059)',
            }}
         >
            <Box
               sx={{
                  backgroundColor: '#EDC970',
                  fontWeight: 800,
                  borderRadius: 1,
                  p: 0.5,
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
               }}
            >
               Request Petition {'\n'} अनुरोध याचिका
            </Box>
            <Box
               sx={{
                  px: 1,
                  py: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <CardContent sx={{ flex: '1 0 auto', p: 0 }}>
                  {petition && (
                     <div>
                        <Box
                           sx={{
                              display: 'inline',
                              mx: 0.5,
                              fontSize: 25,
                              fontWeight: 800,
                              color: '#FFFFFF',
                           }}
                        >
                           {petition.Title}
                        </Box>
                        <Typography
                           component="div"
                           variant="h5"
                           sx={{ fontWeight: 800, fontSize: 22, lineHeight: 1.4, fontFamily: 'Montserrat' }}
                           color="#FFFFFF"
                        >
                           {petition.TitleLocal}
                        </Typography>
                        <Box>
                           <Box
                              sx={{
                                 display: 'inline',
                                 mx: 0.5,
                                 fontSize: 14,
                                 fontWeight: 600,
                                 color: '#FFFFFF',
                              }}
                           >
                              Raised on: &nbsp; &nbsp;{moment(petition.CrDt.toDate()).format('Do MMMM YYYY')}
                           </Box>
                        </Box>
                     </div>
                  )}
               </CardContent>
            </Box>
            {/* <CardMedia
               component="img"
               sx={{ width: 151, borderRadius: 1, mr: 1, ml: 2 }}
               image="https://images.unsplash.com/photo-1622993288089-18298ec89b78?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
               alt="Live from space album cover"
            /> */}
         </Card>
         <TabContext value={value}>
            <Box>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#f6db87',
                  }}
               >
                  <StyledTab1 label={`Basic info \n मूल सूचना`} {...a11yProps(0)} />
                  <StyledTab1 label={`Links \n लिंक`} {...a11yProps(1)} />
                  <StyledTab1 label={`Supports \n समर्थन`} {...a11yProps(2)} />
                  <StyledTab1 label={`Affected People \n प्रभावित लोग`} {...a11yProps(3)} />
               </Tabs>
            </Box>
            <Grid2 container sx={{ mt: 1 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           {petition && (
                              <PetitionBasicInfo
                                 petition={petition}
                                 handleSupportsByUser={handleSupportsByUser}
                                 supportsByUser={supportsByUser}
                                 handleSignsByUser={handleSignsByUser}
                                 signsByUser={signsByUser}
                                 supportsByUserPopulated={supportsByUserPopulated}
                                 attachedToConsti={attachedToConsti}
                                 location={location}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           {petition && (
                              <PetitionNews
                                 petition={petition}
                                 weblinksList={weblinksList}
                                 handleWeblinksUpdate={handleWeblinksUpdate}
                              />
                           )}
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           {petition && <PetitionSupports petition={petition} allSupports={allSupports} />}
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           {petition && <PetitionAffectedPeople petition={petition} allSigns={allSigns} />}
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4} sx={{ px: 1 }}></Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Petition
