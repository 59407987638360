import {
   Avatar,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Slide,
   Table,
   TableCell,
   TableRow,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'
import { v4 } from 'uuid'
import myStyles from './MinistryBasicInfo.module.css'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import YouTubeIcon from '@mui/icons-material/YouTube'

import {
   stringAvatar80,
   stringAvatar80Single,
   styleModalBox,
   styleModalTypography,
} from '../../../Utils/MUITheme/MUITheme'
import AddTextMultiline from '../../../Components/Edits/AddTextMultiline'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { arrayRemove, arrayUnion, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import AddText from '../../../Components/Edits/AddText'
import AddImageOnly from '../../../Components/Edits/AddImageOnly'
import AddAddressOrganisations from '../../../Components/Edits/AddAddressOrganisations'
import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddEmail from '../../../Components/Edits/AddEmail'
import AddMinistersToMinistry from './AddMinistersToMinistry'
import { countWordsUsingReplace } from '../../../Utils/Conversions/CaseConversion'

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function MinistryBasicInfo({ legis, record, handleUpdateRecordLocal, handleSourceImageMain }) {
   //
   console.log('prelims: Legis: ', legis)
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [nameOfUser, setNameOfUser] = useState('')
   const [helperObjectsQue, setHelperObjectsQue] = useState([])
   const [helperObjects, setHelperObjects] = useState([])

   const userProfile = useSelector(selectUserProfile)
   const [thisMinisters, setThisMinisters] = useState([])
   const [allMinisters, setAllMinisters] = useState('')

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
      }
   }, [userProfile])

   useEffect(() => {
      console.log('ministry - first use effect entered')

      if (user.currentUser !== null) {
         console.log('ministry - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('ministry - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   // useEffect(() => {
   //    if (firstLoadStatus === 'idle') {
   //       //

   //       // setHelperObjectsQue(legis.HelperObjects_Que)
   //       // setHelperObjects(legis.HelperObjects)

   //       // setSourceImage(legis.Image)

   //       // if (legis.Ministers && legis.Ministers.length > 0) {
   //       //    const sortedList = [...legis.Ministers].sort((a, b) => a.LevelPriority - b.LevelPriority)
   //       //    setThisMinisters(sortedList)
   //       //    console.log('sortedList: ', sortedList)
   //       // }

   //       console.log('ministry - first use effect blank')
   //    }
   // }, [])

   useEffect(() => {
      if (legis !== '' && firstLoadStatus === 'idle') {
         //

         setHelperObjectsQue(legis.HelperObjects_Que)
         setHelperObjects(legis.HelperObjects)

         setSourceImage(legis.Image)

         if (legis.Ministers && legis.Ministers.length > 0) {
            // const sortedList = [...legis.Ministers].sort((a, b) => a.LevelPriority - b.LevelPriority)
            const listSorted = legis.Ministers.sort(function (x, y) {
               return x.LevelPriority - y.LevelPriority || x.Name - y.Name
            })
            setThisMinisters(listSorted)
            console.log('sortedList: ', listSorted)
         }
         console.log('ministry - first use effect blank')
         setFirstLoadStatus('success')
      }
   }, [legis])

   const [sourceLogo, setSourceLogo] = useState(legis.Logo)
   const [sourceImage, setSourceImage] = useState('')

   const handleUpdateRecord = async (recordNew) => {
      try {
         const docRefCentral = doc(db, 'Countries', legis.Country_id)
         let docRefState
         if (legis.State_id) {
            docRefState = doc(db, 'States', legis.State_id)
         }
         let docRefDivision
         if (legis.Division_id) {
            docRefDivision = doc(db, 'Divisions', legis.Division_id)
         }
         let docRefDistrict
         if (legis.District_id) {
            docRefDistrict = doc(db, 'Districts', legis.District_id)
         }
         let docRefSubDistrict
         if (legis.SubDistrict_id) {
            docRefSubDistrict = doc(db, 'SubDistricts', legis.SubDistrict_id)
         }
         let docRefBlock
         if (legis.Block_id) {
            docRefBlock = doc(db, 'Blocks', legis.Block_id)
         }
         let docRefVillage
         if (legis.Village_id) {
            docRefVillage = doc(db, 'Villages', legis.Village_id)
         }

         switch (legis.Strata) {
            case 'Central':
               console.log('entered case central ', record, recordNew)
               await updateDoc(docRefCentral, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefCentral, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
            case 'State':
               console.log('entered case state ')
               await updateDoc(docRefState, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefState, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
            case 'Division':
               console.log('entered case division ')
               await updateDoc(docRefDivision, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefDivision, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
            case 'District':
               console.log('entered case district ')
               await updateDoc(docRefDistrict, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefDistrict, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
            case 'SubDistrict':
               console.log('entered case SubDistrict ')
               await updateDoc(docRefSubDistrict, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefSubDistrict, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
            case 'Block':
               console.log('entered case Block ')
               await updateDoc(docRefBlock, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefBlock, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
            case 'Village':
               console.log('entered case village ')
               await updateDoc(docRefVillage, {
                  Legislatures: arrayRemove(record),
               })
               await updateDoc(docRefVillage, {
                  Legislatures: arrayUnion(recordNew),
               })
               break
         }

         handleUpdateRecordLocal(recordNew)
      } catch (err) {
         console.log('error', err)
      }
   }

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)
   const [nameToTarget, setNameToTarget] = useState('')

   const handleOpenModalName = (e, thisType) => {
      e.preventDefault()
      setNameToTarget(thisType)
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalName(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (textDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            //
            const refLegis = doc(db, 'Legislatures', legis.id)
            let recordNew = {}
            switch (nameToTarget) {
               case 'Name':
                  await updateDoc(refLegis, {
                     Name: textDetails.Text,
                     NameOld: arrayUnion({
                        Name: textDetails.Text,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  recordNew = { ...record, Name: textDetails.Text }

                  // update the name display in ocal state
                  legis.Name = textDetails.Text
                  break
               case 'NameHindi':
                  await updateDoc(refLegis, {
                     NameHindi: textDetails.Text,
                     NameHindiOld: arrayUnion({
                        NameHindi: textDetails.Text,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  recordNew = { ...record, NameHindi: textDetails.Text }

                  // update the name display in ocal state
                  legis.NameHindi = textDetails.Text
                  break
               case 'NameLocal':
                  await updateDoc(refLegis, {
                     NameLocal: textDetails.Text,
                     NameLocalOld: arrayUnion({
                        NameLocal: textDetails.Text,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  recordNew = { ...record, NameLocal: textDetails.Text }

                  // update the name display in ocal state
                  legis.NameLocal = textDetails.Text
                  break
               case 'GovernmentKnownAs':
                  await updateDoc(refLegis, {
                     GovernmentKnownAs: textDetails.Text,
                     GovernmentKnownAsOld: arrayUnion({
                        GovernmentKnownAsLocal: textDetails.Text,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  recordNew = { ...record, GovernmentKnownAs: textDetails.Text }

                  // update the name display in ocal state
                  legis.GovernmentKnownAs = textDetails.Text
                  break
               case 'GovernmentKnownAsHindi':
                  await updateDoc(refLegis, {
                     GovernmentKnownAsHindi: textDetails.Text,
                     GovernmentKnownAsHindiOld: arrayUnion({
                        GovernmentKnownAsHindi: textDetails.Text,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  recordNew = { ...record, GovernmentKnownAsHindi: textDetails.Text }

                  // update the name display in ocal state
                  legis.GovernmentKnownAsHindi = textDetails.Text
                  break
               case 'GovernmentKnownAsLocal':
                  await updateDoc(refLegis, {
                     GovernmentKnownAsLocal: textDetails.Text,
                     GovernmentKnownAsLocalOld: arrayUnion({
                        GovernmentKnownAsLocal: textDetails.Text,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrByImage: userProfile.Image.Image,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     }),
                  })

                  recordNew = { ...record, GovernmentKnownAsLocal: textDetails.Text }

                  // update the name display in ocal state
                  legis.GovernmentKnownAsLocal = textDetails.Text
                  break
            }

            handleUpdateRecord(recordNew)
            setNameToTarget('')

            // close the modal
            setOpenModalName(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   // const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   // const handleOpenModalNameHindi = (e) => {
   //    e.preventDefault()

   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
   //          setOpenModalNameHindi(true)
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }
   // const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   // const handleAddNameHindi = async (textDetails) => {
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
   //          //
   //          const docRefLegis = doc(db, 'Legislatures', legis.id)

   //          await updateDoc(docRefLegis, {
   //             NameHindi: textDetails.Text,
   //             NameHindiOld: arrayUnion({
   //                NameHindi: textDetails.Text,
   //                CrBy: auth.currentUser.uid,
   //                CrByName: nameOfUser,
   //                CrByImage: userProfile.Image.Image,
   //                CrDt: new Date(),
   //                CrIP: ip.data.ip,
   //             }),
   //          })

   //          const recordNew = { ...record, NameHindi: textDetails.Text }
   //          handleUpdateRecord(recordNew)

   //          // update the name display in loca state
   //          legis.NameHindi = textDetails.Text
   //          // close the modal
   //          setOpenModalNameHindi(false)
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   // related to NameLocal modal

   // const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   // const handleOpenModalNameLocal = (e) => {
   //    e.preventDefault()

   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
   //          setOpenModalNameLocal(true)
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }
   // const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   // const handleAddNameLocal = async (textDetails) => {
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
   //          //
   //          const refLegis = doc(db, 'Legislatures', legis.id)

   //          await updateDoc(refLegis, {
   //             NameLocal: textDetails.Text,
   //             NameLocalOld: arrayUnion({
   //                NameLocal: textDetails.Text,
   //                CrBy: auth.currentUser.uid,
   //                CrByName: nameOfUser,
   //                CrByImage: userProfile.Image.Image,
   //                CrDt: new Date(),
   //                CrIP: ip.data.ip,
   //             }),
   //          })

   //          const recordNew = { ...record, NameLocal: textDetails.Text }
   //          handleUpdateRecord(recordNew)

   //          // update the name display
   //          legis.NameLocal = textDetails.Text
   //          // close the modal
   //          setOpenModalNameLocal(false)
   //       } else {
   //          navigate('/', { replace: true })
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   // related to Address modal

   const [openModalAddress, setOpenModalAddress] = useState(false)

   const handleOpenModalAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalAddress(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAddress = () => setOpenModalAddress(false)

   const handleAddAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            if (
               addressDetails.State_id.length >= 3 &&
               // addressDetails.District_id.length >= 3 &&
               addressDetails.Address.length >= 10 &&
               addressDetails.ValueRadio !== ''
            ) {
               ///
               addressDetails.CrBy = auth.currentUser.uid
               addressDetails.CrByName = nameOfUser
               addressDetails.CrByImage = userProfile.Image.Image
               addressDetails.CrDt = new Date()
               addressDetails.CrIP = ip.data.ip
               // console.log('stage 4 submit')

               addressDetails.Address = addressDetails.Address.trim()
               // console.log('stage 5 submit')
               //
               const refLegis = doc(db, 'Legislatures', legis.id)

               if (addressDetails.ValueRadio === 'Headquarter') {
                  ///
                  await updateDoc(refLegis, {
                     AddressMain: addressDetails,
                     AddressOld: arrayUnion(addressDetails),
                  })
                  // console.log('stage 6 submit')
                  // update the local state of address display
                  legis.AddressMain = addressDetails

                  // console.log('stage 7 submit')
               } else {
                  await updateDoc(refLegis, {
                     AddressSub: arrayUnion(addressDetails),
                     AddressOld: arrayUnion(addressDetails),
                  })
                  // console.log('stage 8 submit')
                  // update the local state
                  legis.AddressSub.push(addressDetails)
               }

               // close modal
               setOpenModalAddress(false)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            //
            // console.log('stage xxx 1')
            const imageRef = ref(dbStorage, `Legislatures/${legis.id}/${imageDetails.image.name + v4()}`)
            // console.log('stage xxx 2')
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Image: url,
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  // console.log('stage xxx 3')
                  const refLegis = doc(db, 'Legislatures', legis.id)
                  // console.log('stage xxx 3A')

                  await updateDoc(refLegis, {
                     Image: url,
                  })
                  const docRefLegisImagesOld = doc(db, 'Legislatures', legis.id, 'ImagesOld', '000001')
                  const docSnapLegisImagesOld = await getDoc(docRefLegisImagesOld)
                  if (docSnapLegisImagesOld.exists()) {
                     // 2.1 means at least one record has been inserted erliear for this subcollection
                     await updateDoc(docRefLegisImagesOld, {
                        Images: arrayUnion(forOld),
                     })
                  } else {
                     // 2.2 means no record has been inserted for this subcollection
                     // create the subcollection CoreLists with new values
                     setDoc(docRefLegisImagesOld, { Images: [forOld] }, { merge: true })
                  }
                  setSourceImage(url)
                  handleSourceImageMain(url)

                  const recordNew = { ...record, Image: url }
                  console.log('recordNew at source: ', recordNew)
                  handleUpdateRecord(recordNew)
               })
            })

            // close modal
            setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Logo modal
   const [openModalLogo, setOpenModalLogo] = useState(false)

   const handleOpenModalLogo = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalLogo(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalLogo = () => setOpenModalLogo(false)

   const handleAddImageLogo = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            const imageRef = ref(dbStorage, `Legislatures/${legis.id}/${imageDetails.image.name + v4()}`)
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  //
                  const forOld = {
                     Logo: url,
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  }
                  //
                  const docRefLegis = doc(db, 'Legislatures', legis.id)
                  updateDoc(docRefLegis, {
                     Logo: url,
                  })
                  //

                  const docRefLegisLogosOld = doc(db, 'Legislatures', legis.id, 'LogosOld', '000001')
                  const docSnapLegisLogosOld = await getDoc(docRefLegisLogosOld)
                  if (docSnapLegisLogosOld.exists()) {
                     // 2.1 means at least one record has been inserted erliear for this subcollection
                     await updateDoc(docRefLegisLogosOld, {
                        Logos: arrayUnion(forOld),
                     })
                  } else {
                     // 2.2 means no record has been inserted for this subcollection
                     // create the subcollection CoreLists with new values
                     setDoc(docRefLegisLogosOld, { Logos: [forOld] }, { merge: true })
                  }

                  setSourceLogo(url)
               })
            })

            // close modal
            setOpenModalLogo(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   const [openModalPhone, setOpenModalPhone] = useState(false)

   const handleOpenModalPhone = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalPhone(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalPhone = () => setOpenModalPhone(false)

   // const [lastPhone, setLastPhone] = useState('')
   // const [uploadingPhone, setUploadingPhone] = useState(false)
   // const [uploadedPhone, setUploadedPhone] = useState(false)
   // const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   const handleAddPhone = async (phoneDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            phoneDetails.CrBy = auth.currentUser.uid
            phoneDetails.CrByName = nameOfUser
            phoneDetails.CrByImage = userProfile.Image.Image
            phoneDetails.CrDt = new Date()
            phoneDetails.CrIP = ip.data.ip

            try {
               //
               const docRefLegis = doc(db, 'Legislatures', legis.id)

               await updateDoc(docRefLegis, {
                  Phones: arrayUnion(phoneDetails.Text),
                  PhonesOld: arrayUnion(phoneDetails),
               })

               // update the phone display
               legis.Phones.push(phoneDetails.Text.toString())
            } catch (error) {
               alert('Error adding Phone: ', error.message)
               console.log('Error adding Phone', error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)
   const [websiteType, setWebsiteType] = useState('')

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setWebsiteType('General')
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   useEffect(() => {
      if (websiteType !== '') {
         console.log('ministry - websiteType: ', websiteType)
      }
   }, [websiteType])
   const handleOpenModalWebsiteSocial = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setWebsiteType('Social')
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            weblinkDetails.Type = websiteType

            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrByName = nameOfUser
            weblinkDetails.CrByImage = userProfile.Image.Image
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            //
            const docRefLegis = doc(db, 'Legislatures', legis.id)
            console.log('ministry - weblinkDetails.Weblink: ', weblinkDetails.Weblink)
            if (websiteType === 'General') {
               console.log('ministry - weblinkDetails.Weblink inside general: ', weblinkDetails.Weblink)
               await updateDoc(docRefLegis, {
                  Weblinks: arrayUnion(weblinkDetails.Weblink),
               })
               // update the website display
               legis.Weblinks.push(weblinkDetails.Weblink)
            } else if (websiteType === 'Social') {
               console.log('ministry - weblinkDetails.Weblink inside social: ', weblinkDetails.Weblink)
               await updateDoc(docRefLegis, {
                  WeblinksSocial: arrayUnion(weblinkDetails.Weblink),
               })
               // update the website display
               legis.WeblinksSocial.push(weblinkDetails.Weblink)
            }

            const docRefLegisWeblinksOld = doc(db, 'Legislatures', legis.id, 'WeblinksOld', '000001')
            const docSnapLegisWeblinksOld = await getDoc(docRefLegisWeblinksOld)
            if (docSnapLegisWeblinksOld.exists()) {
               // 2.1 means at least one record has been inserted erliear for this subcollection
               await updateDoc(docRefLegisWeblinksOld, {
                  Weblinks: arrayUnion(weblinkDetails),
               })
            } else {
               // 2.2 means no record has been inserted for this subcollection
               // create the subcollection CoreLists with new values
               setDoc(docRefLegisWeblinksOld, { Weblinks: [weblinkDetails] }, { merge: true })
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Email modal
   const [openModalEmail, setOpenModalEmail] = useState(false)

   const handleOpenModalEmail = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalEmail(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalEmail = () => setOpenModalEmail(false)

   const handleAddEmail = async (emailDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            emailDetails.CrBy = auth.currentUser.uid
            emailDetails.CrByName = nameOfUser
            emailDetails.CrByImage = userProfile.Image.Image
            emailDetails.CrDt = new Date()
            emailDetails.CrIP = ip.data.ip
            //
            const docRefLegis = doc(db, 'Legislatures', legis.id)

            await updateDoc(docRefLegis, {
               Emails: arrayUnion(emailDetails.Email),
               EmailsOld: arrayUnion(emailDetails),
            })

            // update the website display
            legis.Emails.push(emailDetails.Email)

            // close modal
            // setOpenModalEmail(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Intro modal
   const [openModalIntro, setOpenModalIntro] = useState(false)

   const handleOpenModalIntro = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            setOpenModalIntro(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalIntro = () => setOpenModalIntro(false)

   const handleAddIntro = async (introDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) {
            try {
               introDetails.Text = introDetails.Text.trim()
               introDetails.CrBy = auth.currentUser.uid
               introDetails.CrByName = nameOfUser
               introDetails.CrByImage = userProfile.Image.Image
               introDetails.CrDt = new Date()
               introDetails.CrIP = ip.data.ip
               //
               const docRefLegis = doc(db, 'Legislatures', legis.id)
               const docRefLegisSubCollectionIntro = doc(db, 'Legislatures', legis.id, 'Intros', '000001')
               const docSnapLegisSubCollectionIntro = await getDoc(docRefLegisSubCollectionIntro)

               await updateDoc(docRefLegis, {
                  Intro: arrayUnion(introDetails),
               })

               if (docSnapLegisSubCollectionIntro.exists()) {
                  await updateDoc(docRefLegisSubCollectionIntro, {
                     Intro: arrayUnion(introDetails),
                  })
               } else {
                  setDoc(docRefLegisSubCollectionIntro, { Intro: [introDetails] }, { merge: true })
               }

               // update the intro display
               legis.Intro.push(introDetails)

               // close modal
               // setOpenModalIntro(false)
            } catch (error) {
               alert('Error adding intro: Please try again')
               console.log('Error adding intro: ', error)

               setOpenModalIntro()
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleRemoveIntro = async (introDetails) => {
      if (confirm('Are you sure you want to remove this intro?')) {
         try {
            const docRefLegis = doc(db, 'Legislatures', legis.id)
            await updateDoc(docRefLegis, {
               Intro: arrayRemove(introDetails),
            })
            // update the intro display
            // legis.Intro = ''
         } catch (error) {
            alert('Error removing intro: Please try again')
            console.log('Error removing intro: ', error)
         }
      }
   }

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   // const handleOpenDialogNotLoggedIn = async () => {
   //    setOpenDialogNotLoggedIn(true)
   // }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // invite helpers
   const [disableInviteButton, setDisableInviteButton] = useState(false)

   const [openDialogInviteHelpers, setOpenDialogInviteHelpers] = useState(false)

   const handleCloseDialogInviteHelpers = () => setOpenDialogInviteHelpers(false)

   const handleInviteHelpers = async () => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         //
         if (isDataEditor || isSuper || legis.Helpers.includes(auth.currentUser.uid)) {
            // 1. open
            setDisableInviteButton(false)
            setOpenDialogInviteHelpers(true)
         } else {
            // how did he reach here?
            navigate('/', { replace: true })
         }
      } else {
         // how did he reach here?
         navigate('/', { replace: true })
      }
   }

   const handleInviteHelpersSubmit = async (e) => {
      e.preventDefault()
      setDisableInviteButton(true)
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         try {
            if (isDataEditor || isSuper || legis.Helpers.includes(auth.currentUser.uid)) {
               const docRef = doc(db, 'Legislatures', legis.id)
               await updateDoc(docRef, {
                  AllowHelpers: !legis.AllowHelpers,
               })
               legis.AllowHelpers = !legis.AllowHelpers
               setOpenDialogInviteHelpers(false)
            } else {
               // how did he reach here?
               navigate('/', { replace: true })
            }
         } catch (error) {
            console.log('error submitting allowing helpers: ', error)
         }
      } else {
         // how did he reach here?
         navigate('/', { replace: true })
      }
   }

   // apply as helper

   const [openDialogAddMeAsHelper, setOpenDialogAddMeAsHelper] = useState(false)

   const handleCloseDialogAddMeAsHelper = () => setOpenDialogAddMeAsHelper(false)

   const [openDialogAlreadyHelper, setOpenDialogAlreadyHelper] = useState(false)

   const handleCloseDialogAlreadyHelper = () => setOpenDialogAlreadyHelper(false)

   const [openDialogAlreadyHelperSubmitted, setOpenDialogAlreadyHelperSubmitted] = useState(false)

   const handleCloseDialogAlreadyHelperSubmitted = () => setOpenDialogAlreadyHelperSubmitted(false)

   const [openDialogAddMeAsHelperSubmitted, setOpenDialogAddMeAsHelperSubmitted] = useState(false)

   const handleCloseDialogAddMeAsHelperSubmitted = () => setOpenDialogAddMeAsHelperSubmitted(false)

   const handleAddMeAsHelper = async () => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         setOpenDialogAddMeAsHelper(true)
      } else {
         setOpenDialogNotLoggedIn(true)
      }
   }

   const handleAddMeAsHelperSubmit = async () => {
      try {
         // 1. Find if the User_id exists in helper que or helper
         const docRef = doc(db, 'Legislatures', legis.id)
         const docSnap = await getDoc(docRef)
         const helper = [...docSnap.data().Helpers].find((element) => element === thisUser)

         // 2. if helper exists:
         if (helper) {
            // 2A. tell him he is already a helper.
            setOpenDialogAlreadyHelper(true)
         } else {
            // 2B. if helper does not exist, find if the this user is in the que
            const helperInQue = [...docSnap.data().Helpers_Que].find((element) => element === thisUser)
            if (helperInQue) {
               // 2B.1 :  tell him he has already submitted his request.
               setOpenDialogAlreadyHelperSubmitted(true)
            } else {
               // 2B.2 : if not in que and not a helper, accept the claim
               await updateDoc(docRef, {
                  Helpers_Que: arrayUnion(auth.currentUser.uid),
                  HelperObjects_Que: arrayUnion({
                     User_id: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     ModDt: '',
                     UserProfile_id: userProfile.id,
                     Name: nameOfUser,
                     Image: userProfile.Image.Image,
                  }),
                  HelperRequestsCount: increment(1),
               })
               const docRefSub = doc(db, 'Legislatures', legis.id, 'HelperRequests', '000001')
               const docSnapSub = await getDoc(docRefSub)

               if (docSnapSub.exists()) {
                  await updateDoc(docRefSub, {
                     Requests: arrayUnion({
                        User_id: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        ModDt: '',
                        UserProfile_id: userProfile.id,
                        Name: nameOfUser,
                        Image: userProfile.Image.Image,
                     }),
                  })
               } else {
                  setDoc(
                     docRefSub,
                     {
                        Requests: [
                           {
                              User_id: auth.currentUser.uid,
                              CrDt: new Date(),
                              CrIP: ip.data.ip,
                              ModDt: '',
                              UserProfile_id: userProfile.id,
                              Name: nameOfUser,
                              Image: userProfile.Image.Image,
                           },
                        ],
                     },
                     { merge: true },
                  )
               }
               setOpenDialogAddMeAsHelper(false)
               setOpenDialogAddMeAsHelperSubmitted(true)
            }
         }
      } catch (error) {
         console.log('error submitting candidate profile claim: ', error)
      }
   }

   // approve helpers

   const [openDialogApproveHelpers, setOpenDialogApproveHelpers] = useState(false)

   const handleCloseDialogApproveHelpers = () => setOpenDialogApproveHelpers(false)

   const handleApproveHelpers = async () => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || legis.Helpers.includes(auth.currentUser.uid)) {
            setOpenDialogApproveHelpers(true)
         } else {
            // how did he reach here?
            navigate('/', { replace: true })
         }
      } else {
         // how did he reach here?
         navigate('/', { replace: true })
      }
   }

   const handleApproveHelpersSubmit = async (e, item, what) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || legis.Helpers.includes(auth.currentUser.uid)) {
            try {
               // 1. Find if the Helper exists
               const docRef = doc(db, 'Legislatures', legis.id)
               const docSnap = await getDoc(docRef)
               const helperFetched = [...docSnap.data().Helpers].find((element) => element === thisUser)
               // 2. if Helper exists: S
               if (helperFetched) {
                  // 2A. How did he reach here
                  navigate('/', { replace: true })
               } else {
                  const obj = {
                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     ModDt: '',

                     User_id: item.User_id,
                     UserProfile_id: item.UserProfile_id,
                     Name: item.Name,
                     Image: item.Image,
                  }
                  // 2B. Approve the claim if 'true'
                  if (what) {
                     // 2B true. Approve the claim
                     await updateDoc(docRef, {
                        Helpers_Que: arrayRemove(item.User_id),
                        HelperObjects_Que: arrayRemove(item),

                        Helpers: arrayUnion(item.User_id),
                        HelperObjects: arrayUnion(obj),
                     })
                  } else {
                     // 2B false. Decline the claim
                     await updateDoc(docRef, {
                        Helpers_Que: arrayRemove(item.User_id),
                        HelperObjects_Que: arrayRemove(item),
                        HelpersRejectedCount: increment(1),
                     })
                     const docRefSub = doc(db, 'Legislatures', legis.id, 'HelperRequests', '000001')
                     const docSnapSub = await getDoc(docRefSub)

                     if (docSnapSub.exists()) {
                        await updateDoc(docRefSub, {
                           Rejected: arrayUnion(obj),
                        })
                     } else {
                        setDoc(
                           docRefSub,
                           {
                              Rejected: [obj],
                           },
                           { merge: true },
                        )
                     }
                  }

                  legis.HelperObjects_Que = legis.HelperObjects_Que.filter((thisItem) => thisItem !== item)
                  legis.Helpers_Que = legis.Helpers_Que.filter((thisItem) => thisItem !== item.User_id)

                  setHelperObjectsQue(helperObjectsQue.filter((thisItem) => thisItem !== item))
                  setOpenDialogApproveHelpers(false)
               }
            } catch (error) {
               console.log('error approving profie claim: ', error)
            }
         } else {
            // how did he reach here?
            navigate('/', { replace: true })
         }
      } else {
         // how did he reach here?
         navigate('/', { replace: true })
      }
   }

   // remove helpers

   const [openDialogRemoveHelpers, setOpenDialogRemoveHelpers] = useState(false)

   const handleCloseDialogRemoveHelpers = () => setOpenDialogRemoveHelpers(false)

   const handleRemoveHelpers = async () => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || legis.Helpers.includes(auth.currentUser.uid)) {
            setOpenDialogRemoveHelpers(true)
         } else {
            // how did he reach here?
            navigate('/', { replace: true })
         }
      } else {
         // how did he reach here?
         navigate('/', { replace: true })
      }
   }

   const handleRemoveHelpersSubmit = async (e, item, what) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper || legis.Helpers.includes(auth.currentUser.uid)) {
            try {
               // 1. Remove the helper
               const docRef = doc(db, 'Legislatures', legis.id)
               await updateDoc(docRef, {
                  Helpers: arrayRemove(item.User_id),
                  HelperObjects: arrayRemove(item),
                  HelpersRemovedCount: increment(1),
               })

               const docRefSub = doc(db, 'Legislatures', legis.id, 'HelperRequests', '000001')
               const docSnapSub = await getDoc(docRefSub)

               if (docSnapSub.exists()) {
                  await updateDoc(docRefSub, {
                     Removed: arrayUnion(item),
                  })
               } else {
                  setDoc(
                     docRefSub,
                     {
                        Removed: [item],
                     },
                     { merge: true },
                  )
               }

               legis.HelperObjects = legis.HelperObjects.filter((thisItem) => thisItem !== item)
               legis.Helpers = legis.HelperObjects.filter((thisItem) => thisItem !== item.User_id)

               setHelperObjects(helperObjects.filter((thisItem) => thisItem !== item))
               setOpenDialogRemoveHelpers(false)
            } catch (error) {
               console.log('error approving profie claim: ', error)
            }
         } else {
            // how did he reach here?
            navigate('/', { replace: true })
         }
      } else {
         // how did he reach here?
         navigate('/', { replace: true })
      }
   }

   // add minister

   const [openModalAddMinisters, setOpenModalAddMinisters] = useState(false)

   const handleCloseModalAddMinisters = () => setOpenModalAddMinisters(false)

   const handleModaAddMinister = async (e) => {
      e.preventDefault()
      //
      if (allMinisters === '') {
         const docRefLegis = doc(db, 'Legislatures', legis.Ministry_Under_Legis_id)
         const docSnapLegis = await getDoc(docRefLegis)
         const biggestNumber = Math.max(...docSnapLegis.data().HouseNumbers)

         const docRefLegisSubCollMinisters = doc(
            db,
            'Legislatures',
            legis.Ministry_Under_Legis_id,
            'Ministers',
            biggestNumber.toString(),
         )
         const docSnapLegisSubCollMinisters = await getDoc(docRefLegisSubCollMinisters)
         setAllMinisters(docSnapLegisSubCollMinisters.data().Ministers)
      } else {
         setOpenModalAddMinisters(true)
      }
   }

   useEffect(() => {
      if (allMinisters !== '') {
         setOpenModalAddMinisters(true)
      }
   }, [allMinisters])

   const handleAddMinister = async (ministerDetails, dateFromFinal, dateToFinal) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper && ministerDetails !== '') {
            try {
               const docRefMinister = doc(db, 'Ministers', ministerDetails.Minister_id)
               const docRefLegisMem = doc(db, 'LegisMembers', ministerDetails.LegisMem_id)
               const docRefMinistry = doc(db, 'Legislatures', legis.id)
               const docRefMinistrySubCollMinistersOld = doc(db, 'Legislatures', legis.id, 'MinistersOld', '001')
               const docSnapMinistrySubCollMinistersOld = await getDoc(docRefMinistrySubCollMinistersOld)

               const ministryPortfolio = {
                  Ministry_id: legis.id,
                  Name: legis.Name,
                  NameHindi: legis.NameHindi,
                  NameLocal: legis.NameLocal,
                  Image: legis.Image,
                  DateFrom: dateFromFinal,
                  DateTo: dateToFinal,
                  CrBy: auth.currentUser.uid,
                  CrByName: nameOfUser,
                  CrByImage: userProfile.Image.Image,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
                  ModBy: '',
                  ModByName: '',
                  ModByImage: '',
                  ModDt: '',
                  ModIP: '',
                  ModReason: '',
               }
               console.log('ministryPortfolio: ', ministryPortfolio)

               const minister = {
                  Minister_id: ministerDetails.Minister_id,
                  LegisMem_id: ministerDetails.LegisMem_id,
                  Name: ministerDetails.Name,
                  NameHindi: ministerDetails.NameHindi,
                  NameLocal: ministerDetails.NameLocal,
                  Image: ministerDetails.Image,
                  DateFrom: dateFromFinal,
                  DateTo: dateToFinal,
                  LevelPriority: ministerDetails.LevelPriority,
                  CrBy: auth.currentUser.uid,
                  CrByName: nameOfUser,
                  CrByImage: userProfile.Image.Image,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
                  ModBy: '',
                  ModByName: '',
                  ModByImage: '',
                  ModDt: '',
                  ModIP: '',
                  ModReason: '',
               }
               console.log('minister: ', minister)

               // 1. Update the main Minister doc for portfolios
               await updateDoc(docRefMinister, {
                  MinistryPortfolio: arrayUnion(ministryPortfolio),
               })
               // 2. Update the main LegisMember doc for portfolios
               await updateDoc(docRefLegisMem, {
                  MinistryPortfolio: arrayUnion(ministryPortfolio),
               })

               // 3. Update the ministry
               await updateDoc(docRefMinistry, {
                  Ministers: arrayUnion(minister),
               })

               if (docSnapMinistrySubCollMinistersOld.exists()) {
                  await updateDoc(docRefMinistrySubCollMinistersOld, {
                     Ministers: arrayUnion(minister),
                  })
               } else {
                  setDoc(
                     docRefMinistrySubCollMinistersOld,
                     {
                        Ministers: [minister],
                     },
                     { merge: true },
                  )
               }

               setOpenModalAddMinisters(false)
               // 5. Update Local state
               if (thisMinisters.length > 0) {
                  const list = [...thisMinisters, minister]
                  // const sortedList = list.sort((a, b) => a.LevelPriority - b.LevelPriority)
                  const listSorted = list.sort(function (x, y) {
                     return x.LevelPriority - y.LevelPriority || x.Name - y.Name
                  })
                  setThisMinisters(listSorted)
               } else {
                  setThisMinisters([minister])
               }
            } catch (error) {
               alert('Error adding minister')
               console.log(error)
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box>
         <Box>
            <Helmet>
               <title>{`Ministry`}</title>
            </Helmet>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
            {legis.Intro &&
               legis.Intro.length > 0 &&
               legis.Intro.map((item, index) => (
                  <Typography
                     Key={index}
                     variant="body2"
                     sx={{
                        backgroundColor: '#F1F1F1',
                        p: 1,
                        borderRadius: 2,
                        textAlign: 'justify',
                     }}
                  >
                     {item.Text}
                     {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                        <>
                           <IconButton
                              edge="end"
                              aria-label="editIntro"
                              onClick={(e) => {
                                 handleOpenModalIntro(e)
                              }}
                              sx={{ ml: 2, height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                           <IconButton
                              edge="end"
                              aria-label="deleteIntro"
                              onClick={(e) => {
                                 handleRemoveIntro(e)
                              }}
                              sx={{ ml: 2, height: '25px', width: '25px' }}
                           >
                              <DeleteIcon fontSize="small" />
                           </IconButton>
                        </>
                     )}
                  </Typography>
               ))}

            {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) &&
               (!legis.Intro || legis.Intro.length < 1) && (
                  <Button
                     variant="outlined"
                     endIcon={<EditIcon />}
                     sx={{ ml: 1 }}
                     onClick={(e) => {
                        handleOpenModalIntro(e)
                     }}
                  >
                     Intro
                  </Button>
               )}
         </Box>
         <Box>
            {legis.AllowHelpers && !legis.Helpers_Que.includes(thisUser) && !legis.Helpers.includes(thisUser) && (
               <Box sx={{ p: 0.5 }}>
                  <Button
                     variant="contained"
                     sx={{
                        textTransform: 'none',
                        py: 0.5,
                        minWidth: 0,
                        px: 2,
                        float: 'right',
                        backgroundColor: '#03a9f4',
                        whiteSpace: 'pre-line',
                        lineHeight: 1.2,
                        color: '#FFFFFF',
                        '&:hover': { backgroundColor: '#01579b' },
                     }}
                     onClick={(e) => {
                        handleAddMeAsHelper(e)
                     }}
                  >
                     Add Me As Helper
                  </Button>
               </Box>
            )}
         </Box>
         <Table>
            <tbody>
               <TableRow id="rowMinister">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Ministers {'\n'} मंत्रीगण:
                  </TableCell>
                  <TableCell className={myStyles.cell_style_right}>
                     <Box>
                        <List dense>
                           {thisMinisters &&
                              thisMinisters.length > 0 &&
                              thisMinisters.map((item, index) => (
                                 <div key={item.Minister_id}>
                                    <ListItem sx={{ pl: 0, ml: 0 }}>
                                       <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                          {item.Name &&
                                             (countWordsUsingReplace(item.Name) > 1 ? (
                                                <Avatar
                                                   {...stringAvatar80(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ) : (
                                                <Avatar
                                                   {...stringAvatar80Single(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ))}
                                       </ListItemAvatar>

                                       <ListItemText
                                          sx={{ ml: 1 }}
                                          secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                          primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                          primary={
                                             <>
                                                <>
                                                   <strong>{item.Name.toUpperCase()}</strong>
                                                   <br />
                                                   {item.NameLocal ? item.NameLocal : item.NameHindi}
                                                </>
                                             </>
                                          }
                                       />
                                    </ListItem>
                                 </div>
                              ))}
                        </List>
                        {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                           <IconButton
                              edge="end"
                              aria-label="addMinister"
                              onClick={(e) => {
                                 handleModaAddMinister(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )}
                     </Box>
                  </TableCell>
               </TableRow>
               {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                  <TableRow id="rowName">
                     <TableCell className={myStyles.cell_style_left} variant="head">
                        Name:
                     </TableCell>
                     <TableCell className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>English:</Box>
                           <Box sx={{ ml: 1 }}>{legis.Name}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editName"
                                 onClick={(e) => {
                                    handleOpenModalName(e, 'Name')
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                        <Divider></Divider>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>Hindi:</Box>
                           <Box sx={{ ml: 1 }}>{legis.NameHindi}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editNameHindi"
                                 onClick={(e) => {
                                    handleOpenModalName(e, 'NameHindi')
                                 }}
                                 sx={{
                                    ml: 'auto',
                                    height: '25px',
                                    width: '25px',
                                 }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                        <Divider></Divider>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>Local:</Box>
                           <Box sx={{ ml: 1 }}>{legis.NameLocal}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editNameLocal"
                                 onClick={(e) => {
                                    handleOpenModalName(e, 'NameLocal')
                                 }}
                                 sx={{
                                    ml: 'auto',
                                    height: '25px',
                                    width: '25px',
                                 }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                     </TableCell>
                  </TableRow>
               )}
               {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                  <TableRow id="rowNameHindi" className={myStyles.row_style}>
                     <TableCell className={myStyles.cell_style_left} variant="head">
                        Government:
                     </TableCell>
                     <TableCell className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>English:</Box>
                           <Box sx={{ ml: 1 }}>{legis.GovernmentKnownAs}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editName"
                                 onClick={(e) => {
                                    handleOpenModalName(e, 'GovernmentKnownAs')
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                        <Divider></Divider>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>Hindi:</Box>
                           <Box sx={{ ml: 1 }}>{legis.GovernmentKnownAsHindi}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editNameHindi"
                                 onClick={(e) => {
                                    handleOpenModalName(e, 'GovernmentKnownAsHindi')
                                 }}
                                 sx={{
                                    ml: 'auto',
                                    height: '25px',
                                    width: '25px',
                                 }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                        <Divider></Divider>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                           }}
                        >
                           <Box>Local:</Box>
                           <Box sx={{ ml: 1 }}>{legis.GovernmentKnownAsLocal}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editNameLocal"
                                 onClick={(e) => {
                                    handleOpenModalName(e, 'GovernmentKnownAsLocal')
                                 }}
                                 sx={{
                                    ml: 'auto',
                                    height: '25px',
                                    width: '25px',
                                 }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                     </TableCell>
                  </TableRow>
               )}
               {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                  <TableRow id="rowImage">
                     <TableCell className={myStyles.cell_style_left} variant="head">
                        Image:
                     </TableCell>
                     <TableCell className={myStyles.cell_style_right}>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                           }}
                        >
                           <Box>{sourceImage !== '' ? <img src={sourceImage} className={myStyles.imgX} /> : null}</Box>

                           {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                              <IconButton
                                 edge="end"
                                 aria-label="editImage"
                                 onClick={(e) => {
                                    handleOpenModalImage(e)
                                 }}
                                 sx={{ ml: 'auto', height: '25px', width: '25px' }}
                              >
                                 <EditIcon fontSize="small" />
                              </IconButton>
                           )}
                        </Box>
                     </TableCell>
                  </TableRow>
               )}
               <TableRow id="rowLogo">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Logo {'\n'} चिन्ह:
                  </TableCell>
                  <TableCell className={myStyles.cell_style_right}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'row',
                           alignItems: 'center',
                           pt: 0.5,
                        }}
                     >
                        {sourceLogo && <img src={sourceLogo} className={myStyles.imgX} />}
                        {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                           <IconButton
                              edge="end"
                              aria-label="editLogo"
                              onClick={(e) => {
                                 handleOpenModalLogo(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )}
                     </Box>
                  </TableCell>
               </TableRow>
               <TableRow id="rowAddressMain">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Address {'\n'} पता:
                  </TableCell>
                  <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'row',
                        }}
                     >
                        {legis.AddressMain && (
                           <Box>
                              <Box>{legis.AddressMain.Address}</Box>
                              <span>
                                 {legis.AddressMain.DistrictName} - {legis.AddressMain.StateName} -{' '}
                                 {legis.AddressMain.PinCode}
                              </span>
                           </Box>
                        )}

                        <Box>
                           {legis.AddressesSub &&
                              legis.AddressesSub.length > 0 &&
                              legis.AddressesSub.map((item, index) => (
                                 <Box key={index}>
                                    <Box>{item.Address}</Box>
                                    <span>
                                       {item.DistrictName} - {item.StateName} - {item.PinCode}
                                    </span>
                                 </Box>
                              ))}
                        </Box>

                        {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                           <IconButton
                              edge="end"
                              aria-label="editAddress"
                              onClick={(e) => {
                                 handleOpenModalAddress(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )}
                     </Box>
                  </TableCell>
               </TableRow>
               <TableRow id="rowPhones">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Phones {'\n'} दूरभाष:
                  </TableCell>
                  <TableCell className={myStyles.cell_style_right}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'row',
                        }}
                     >
                        <Box>
                           {legis.Phones &&
                              legis.Phones.length > 0 &&
                              legis.Phones.map((item, index) => <Box key={index}>{item}</Box>)}
                        </Box>

                        {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                           <IconButton
                              edge="end"
                              aria-label="editPhone"
                              onClick={(e) => {
                                 handleOpenModalPhone(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )}
                     </Box>
                  </TableCell>
               </TableRow>
               <TableRow id="rowWeblinks">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Web Links {'\n'} वेब लिंक:
                  </TableCell>
                  <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                     <Box>
                        <Box>
                           {legis.Weblinks &&
                              legis.Weblinks.length > 0 &&
                              legis.Weblinks.map((item, index) => (
                                 <Box key={index}>
                                    <Link underline="hover" href={item} target="_blank" rel="noopener noreferrer">
                                       {item}
                                    </Link>
                                 </Box>
                              ))}
                        </Box>

                        {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                           <IconButton
                              edge="end"
                              aria-label="editWebsite"
                              onClick={(e) => {
                                 handleOpenModalWebsite(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )}
                     </Box>
                  </TableCell>
               </TableRow>
               <TableRow id="rowWeblinksSocial">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Social Web Links {'\n'} वेब लिंक:
                  </TableCell>
                  <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                     <Box sx={{ display: 'flex', flexdirection: 'row' }}>
                        {legis.WeblinksSocial &&
                           legis.WeblinksSocial.length > 0 &&
                           legis.WeblinksSocial.map((item, index) => (
                              <Box key={index}>
                                 {item && (item.indexOf('http://') === 0 || item.indexOf('https://') === 0) && (
                                    <Link underline="hover" href={item} target="_blank" rel="noopener noreferrer">
                                       {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                          <FacebookIcon sx={{ fontSize: 30, mr: 2 }} />
                                       )}
                                       {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                          <InstagramIcon sx={{ color: '#d62976', fontSize: 30, mr: 2 }} />
                                       )}
                                       {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                          <LinkedInIcon sx={{ fontSize: 35, mr: 2 }} />
                                       )}
                                       {item.substring(0, 14) === 'https://x.com/' && (
                                          <XIcon sx={{ color: '#000000', mr: 2 }} />
                                       )}
                                       {item.substring(0, 24) === 'https://www.youtube.com/' && (
                                          <YouTubeIcon sx={{ color: 'Red', fontSize: 35, mr: 2 }} />
                                       )}
                                    </Link>
                                 )}
                                 {item && item.indexOf('http://') !== 0 && item.indexOf('https://') !== 0 && (
                                    <Link
                                       underline="hover"
                                       href={`//${item}`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                       {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                          <FacebookIcon sx={{ fontSize: 30, mr: 2 }} />
                                       ) : item.substring(0, 26) === 'https://www.instagram.com/' ? (
                                          <InstagramIcon sx={{ color: '#d62976', fontSize: 30, mr: 2 }} />
                                       ) : item.substring(0, 25) === 'https://www.linkedin.com/' ? (
                                          <LinkedInIcon sx={{ fontSize: 35, mr: 2 }} />
                                       ) : item.substring(0, 14) === 'https://x.com/' ? (
                                          <XIcon sx={{ color: '#000000', mr: 2 }} />
                                       ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                          <YouTubeIcon sx={{ color: 'Red', fontSize: 35, mr: 2 }} />
                                       ) : (
                                          item
                                       )}
                                    </Link>
                                 )}
                              </Box>
                           ))}
                     </Box>

                     {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                        <IconButton
                           edge="end"
                           aria-label="editWebsite"
                           onClick={(e) => {
                              handleOpenModalWebsiteSocial(e)
                           }}
                           sx={{ ml: 'auto', height: '25px', width: '25px' }}
                        >
                           <EditIcon fontSize="small" />
                        </IconButton>
                     )}
                  </TableCell>
               </TableRow>
               <TableRow id="rowEmails">
                  <TableCell
                     sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                     className={myStyles.cell_style_left}
                     variant="head"
                  >
                     Emails {'\n'} ईमेल:
                  </TableCell>
                  <TableCell sx={{ width: '70%', wordBreak: 'break-all' }} className={myStyles.cell_style_right}>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'row',
                        }}
                     >
                        <Box>
                           {legis.Emails &&
                              legis.Emails.length > 0 &&
                              legis.Emails.map((item, index) => <Box key={index}>{item}</Box>)}
                        </Box>

                        {(isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                           <IconButton
                              edge="end"
                              aria-label="editEmail"
                              onClick={(e) => {
                                 handleOpenModalEmail(e)
                              }}
                              sx={{ ml: 'auto', height: '25px', width: '25px' }}
                           >
                              <EditIcon fontSize="small" />
                           </IconButton>
                        )}
                     </Box>
                  </TableCell>
               </TableRow>
            </tbody>
         </Table>
         <Box>
            {auth.currentUser &&
               thisUser === auth.currentUser.uid &&
               (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) && (
                  <Box sx={{ p: 0.5 }}>
                     <Button
                        variant="contained"
                        sx={{
                           textTransform: 'none',
                           py: 0.5,
                           minWidth: 0,
                           px: 2,
                           backgroundColor: '#03a9f4',
                           whiteSpace: 'pre-line',
                           lineHeight: 1.2,
                           color: '#FFFFFF',
                           '&:hover': { backgroundColor: '#01579b' },
                        }}
                        onClick={(e) => {
                           handleInviteHelpers(e)
                        }}
                     >
                        {legis.AllowHelpers ? 'Stop Helpers' : 'Invite Helpers'}
                     </Button>
                  </Box>
               )}

            {auth.currentUser &&
               thisUser === auth.currentUser.uid &&
               (isDataEditor || isSuper || (legis.Helpers && legis.Helpers.includes(thisUser))) &&
               legis.Helpers_Que &&
               legis.Helpers_Que.length > 0 && (
                  <Box sx={{ p: 0.5 }}>
                     <Button
                        variant="contained"
                        sx={{
                           textTransform: 'none',
                           py: 0.5,
                           minWidth: 0,
                           px: 2,
                           backgroundColor: '#03a9f4',
                           whiteSpace: 'pre-line',
                           lineHeight: 1.2,
                           color: '#FFFFFF',
                           '&:hover': { backgroundColor: '#01579b' },
                        }}
                        onClick={(e) => {
                           handleApproveHelpers(e)
                        }}
                     >
                        Approve Helpers
                     </Button>
                  </Box>
               )}

            {auth.currentUser &&
               thisUser === auth.currentUser.uid &&
               (isDataEditor ||
                  isSuper ||
                  (legis.Helpers && legis.Helpers.length > 0 && legis.Helpers.includes(thisUser))) && (
                  <Box sx={{ p: 0.5 }}>
                     <Button
                        variant="contained"
                        sx={{
                           textTransform: 'none',
                           py: 0.5,
                           minWidth: 0,
                           px: 2,
                           backgroundColor: '#03a9f4',
                           whiteSpace: 'pre-line',
                           lineHeight: 1.2,
                           color: '#FFFFFF',
                           '&:hover': { backgroundColor: '#01579b' },
                        }}
                        onClick={(e) => {
                           handleRemoveHelpers(e)
                        }}
                     >
                        Remove Helpers{' '}
                     </Button>
                  </Box>
               )}
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalIntro}
               onClose={handleCloseModalIntro}
               aria-labelledby="modal-modal-title-intro"
               aria-describedby="modal-modal-description-intro"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-intro"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add or Change Intro
                  </Typography>
                  <AddTextMultiline
                     handleAddTextMultiline={handleAddIntro}
                     labelName="Intro"
                     textLength="750"
                     prevText={legis.Intro ? legis.Intro : ''}
                  ></AddTextMultiline>
               </Box>
            </Modal>
            <Modal
               open={openModalName}
               onClose={handleCloseModalName}
               aria-labelledby="modal-modal-title-name"
               aria-describedby="modal-modal-description-name"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-name"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Change name
                  </Typography>
                  <AddText handleAddText={handleAddName} labelName="Name"></AddText>
               </Box>
            </Modal>
            {/* <Modal
               open={openModalNameHindi}
               onClose={handleCloseModalNameHindi}
               aria-labelledby="modal-modal-title-Hindiname"
               aria-describedby="modal-modal-description-Hindiname"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-hindiname"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add name in hindi
                  </Typography>
                  <AddText handleAddText={handleAddNameHindi} labelName="Name in hindi"></AddText>
               </Box>
            </Modal> */}
            {/* <Modal
               open={openModalNameLocal}
               onClose={handleCloseModalNameLocal}
               aria-labelledby="modal-modal-title-localname"
               aria-describedby="modal-modal-description-localname"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-localname"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add name in local language
                  </Typography>
                  <AddText handleAddText={handleAddNameLocal} labelName="Name (in local language)"></AddText>
               </Box>
            </Modal> */}
            <Modal
               open={openModalImage}
               onClose={handleCloseModalImage}
               aria-labelledby="modal-modal-title-logo"
               aria-describedby="modal-modal-description-logo"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-logo"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add image of Candidate
                  </Typography>
                  <AddImageOnly handleAddImage={handleAddImage}></AddImageOnly>
               </Box>
            </Modal>
            <Modal
               open={openModalLogo}
               onClose={handleCloseModalLogo}
               aria-labelledby="modal-modal-title-flag"
               aria-describedby="modal-modal-description-flag"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-flag"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add image of symbol
                  </Typography>
                  <AddImageOnly handleAddImage={handleAddImageLogo}></AddImageOnly>
               </Box>
            </Modal>
            <Modal
               open={openModalAddress}
               onClose={handleCloseModalAddress}
               aria-labelledby="modal-modal-title-headquarter"
               aria-describedby="modal-modal-description-headquarter"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-headquarter"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add Address
                  </Typography>
                  <AddAddressOrganisations
                     countryId={legis.Country_id}
                     handleAddAddress={handleAddAddress}
                  ></AddAddressOrganisations>
               </Box>
            </Modal>
            <Modal
               open={openModalPhone}
               onClose={handleCloseModalPhone}
               aria-labelledby="modal-modal-title-phones"
               aria-describedby="modal-modal-description-phones"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-phones"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add Phones:
                  </Typography>
                  <AddText handleAddText={handleAddPhone} labelName="Phone"></AddText>
               </Box>
            </Modal>
            <Modal
               open={openModalWebsite}
               onClose={handleCloseModalWebsite}
               aria-labelledby="modal-modal-title-website"
               aria-describedby="modal-modal-description-website"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-website"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a web link
                  </Typography>
                  <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
               </Box>
            </Modal>
            <Modal
               open={openModalEmail}
               onClose={handleCloseModalEmail}
               aria-labelledby="modal-modal-title-email"
               aria-describedby="modal-modal-description-email"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-email"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add an email
                  </Typography>
                  <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
               </Box>
            </Modal>
            <Modal
               open={openModalAddMinisters}
               onClose={handleCloseModalAddMinisters}
               aria-labelledby="modal-modal-title-addMinisters"
               aria-describedby="modal-modal-description-addMinisters"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addMinisters"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add an minister
                  </Typography>
                  <AddMinistersToMinistry
                     handleAddMinister={handleAddMinister}
                     allMinisters={allMinisters}
                  ></AddMinistersToMinistry>
               </Box>
            </Modal>

            <Dialog
               open={openDialogNotLoggedIn}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotLoggedIn}
               aria-describedby="alert-dialog-slide-notLoggedIn"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-notLoggedIn"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     You are not logged-in.
                     {'\n'}
                     आपने लॉग - इन नहीं किया है।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
               </DialogActions>
            </Dialog>

            <Dialog
               open={openDialogInviteHelpers}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogInviteHelpers}
               aria-describedby="alert-dialog-slide-inviteHelpers"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Invite Helpers for ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए सहायकों को आमंत्रित करें`}</DialogTitle>

               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-inviteHelpers"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     {legis.AllowHelpers
                        ? ` Are you sure you want to stop inviting helpers?`
                        : ` Are you sure you want to invite helpers?`}
                     <br />
                     {legis.AllowHelpers
                        ? ` क्या आप वाकई सहायकों को आमंत्रित करना बंद करना चाहते हैं?`
                        : ` क्या आप वाकई सहायकों को आमंत्रित करना चाहते हैं?`}
                  </DialogContentText>
               </DialogContent>
               <DialogActions sx={{ justifyContent: 'space-between' }}>
                  {legis.AllowHelpers && (
                     <Button
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                           handleInviteHelpersSubmit(e)
                        }}
                        sx={{ whiteSpace: 'pre-line' }}
                        disabled={disableInviteButton}
                     >
                        Stop. {'\n'} रोक दें
                     </Button>
                  )}
                  {!legis.AllowHelpers && (
                     <Button
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                           handleInviteHelpersSubmit(e)
                        }}
                        sx={{ whiteSpace: 'pre-line' }}
                        disabled={disableInviteButton}
                     >
                        Invite. {'\n'} आमंत्रित करें|
                     </Button>
                  )}
                  <Button
                     variant="contained"
                     color="error"
                     sx={{ whiteSpace: 'pre-line' }}
                     onClick={handleCloseDialogInviteHelpers}
                  >
                     Cancel {'\n'} निरस्त करें
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAddMeAsHelper}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAddMeAsHelper}
               aria-describedby="alert-dialog-slide-addMeAsHelper"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`For ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-addMeAsHelper"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     Are you sure you want to help manage this candidate profile?
                     <br />
                     <br />
                     क्या आप वाकई इस प्रत्यशी प्रोफ़ाइल को प्रबंधित करने में मदद करना चाहते हैं?
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button
                     variant="contained"
                     color="success"
                     onClick={(e) => {
                        handleAddMeAsHelperSubmit(e)
                     }}
                  >
                     Yes
                  </Button>
                  <Button
                     variant="contained"
                     color="error"
                     sx={{ whiteSpace: 'pre-line' }}
                     onClick={handleCloseDialogAddMeAsHelper}
                  >
                     No
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAlreadyHelper}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAlreadyHelper}
               aria-describedby="alert-dialog-slide-alreadyHelper"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`For ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-alreadyHelper"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     <Typography sx={{ color: '#4caf50' }}>
                        You are already listed as a helper of this candidate profile.
                     </Typography>
                     <Typography sx={{ color: '#4caf50' }}>
                        आप पहले से ही इस प्रत्यशी प्रोफ़ाइल के सहायक के रूप में सूचीबद्ध हैं।
                     </Typography>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button variant="contained" color="error" onClick={handleCloseDialogAlreadyHelper}>
                     Close
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAlreadyHelperSubmitted}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAlreadyHelperSubmitted}
               aria-describedby="alert-dialog-slide-alreadyHelperSubmitted"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`For ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-alreadyHelperSubmitted"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     <Typography sx={{ color: '#4caf50' }}>
                        You have already requested to be a helper of this candidate profile. Please wait for the
                        response.
                     </Typography>
                     <Typography sx={{ color: '#4caf50' }}>
                        आपने पहले ही इस प्रत्यशी प्रोफ़ाइल का सहायक बनने का अनुरोध किया है। कृपया प्रतिक्रिया की
                        प्रतीक्षा करें।
                     </Typography>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button variant="contained" color="error" onClick={handleCloseDialogAlreadyHelperSubmitted}>
                     Close
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAddMeAsHelperSubmitted}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAddMeAsHelperSubmitted}
               aria-describedby="alert-dialog-slide-addMeAsHelperSubmitted"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`For ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-addMeAsHelperSubmitted"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     <Typography sx={{ color: '#4caf50' }}>
                        Thanks. Your request has been received. It will be reviewed soon and approved accordingly.
                     </Typography>
                     <Typography sx={{ color: '#4caf50' }}>
                        धन्यवाद। आपका अनुरोध प्राप्त हो गया है। इसकी जल्द ही समीक्षा की जाएगी और तदनुसार स्वीकृति दी
                        जाएगी।
                     </Typography>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button variant="contained" color="error" onClick={handleCloseDialogAddMeAsHelperSubmitted}>
                     Close
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogApproveHelpers}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogApproveHelpers}
               aria-describedby="alert-dialog-slide-approveHelpers"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Approve Helpers for ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए सहायकों को स्वीकृति दें`}</DialogTitle>

               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-approveHelpers"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     <List dense>
                        {helperObjectsQue &&
                           helperObjectsQue.length > 0 &&
                           helperObjectsQue.map((item, index) => (
                              <Box key={item.User_id}>
                                 <ListItem sx={{ pl: 0, ml: 0 }} key={item.User_id}>
                                    <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                       <Avatar alt="Travis Howard" src={item.Image} />
                                    </ListItemAvatar>

                                    <ListItemText
                                       sx={{ ml: 1 }}
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={item.Name}
                                       secondary={
                                          <>
                                             {isSuper && (
                                                <>
                                                   <Button
                                                      variant="contained"
                                                      color="success"
                                                      endIcon={<CheckCircleOutlineIcon />}
                                                      onClick={(e) => {
                                                         handleApproveHelpersSubmit(e, item, true)
                                                      }}
                                                      sx={{
                                                         textTransform: 'none',
                                                         padding: 0,
                                                         minWidth: 0,
                                                         mr: 1,
                                                         px: 1,
                                                      }}
                                                   >
                                                      Approve
                                                   </Button>

                                                   <Button
                                                      variant="contained"
                                                      color="error"
                                                      endIcon={<CancelIcon />}
                                                      onClick={(e) => {
                                                         handleApproveHelpersSubmit(e, item, false)
                                                      }}
                                                      sx={{
                                                         textTransform: 'none',
                                                         padding: 0,
                                                         minWidth: 0,
                                                         mr: 1,
                                                         px: 1,
                                                      }}
                                                   >
                                                      Decline
                                                   </Button>
                                                </>
                                             )}
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </Box>
                           ))}
                     </List>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button
                     variant="contained"
                     color="error"
                     sx={{ whiteSpace: 'pre-line' }}
                     onClick={handleCloseDialogApproveHelpers}
                  >
                     Close
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogRemoveHelpers}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogRemoveHelpers}
               aria-describedby="alert-dialog-slide-removeHelpers"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Remove Helpers for ${legis.Name} ${'\n'} ${legis.NameHindi} के लिए सहायकों को स्वीकृति दें`}</DialogTitle>

               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-removeHelpers"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     <List dense>
                        {helperObjects &&
                           helperObjects.length > 0 &&
                           helperObjects.map((item, index) => (
                              <Box key={item.User_id}>
                                 <ListItem sx={{ pl: 0, ml: 0 }} key={item.User_id}>
                                    <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                       <Avatar alt="Travis Howard" src={item.Image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                       sx={{ ml: 1 }}
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={item.Name}
                                       secondary={
                                          <>
                                             {isSuper && (
                                                <>
                                                   <Button
                                                      variant="contained"
                                                      color="error"
                                                      endIcon={<DeleteIcon />}
                                                      onClick={(e) => {
                                                         handleRemoveHelpersSubmit(e, item)
                                                      }}
                                                      sx={{
                                                         textTransform: 'none',
                                                         padding: 0,
                                                         minWidth: 0,
                                                         mr: 1,
                                                         px: 1,
                                                      }}
                                                   >
                                                      Remove
                                                   </Button>
                                                </>
                                             )}
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </Box>
                           ))}
                     </List>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button
                     variant="contained"
                     color="error"
                     sx={{ whiteSpace: 'pre-line' }}
                     onClick={handleCloseDialogRemoveHelpers}
                  >
                     Close
                  </Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

MinistryBasicInfo.propTypes = {
   legis: PropTypes.object.isRequired,
   record: PropTypes.object.isRequired,
   handleUpdateRecordLocal: PropTypes.func.isRequired,
   handleSourceImageMain: PropTypes.func.isRequired,
}

export default MinistryBasicInfo
