import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

function HomeStateAdministration() {
   return (
      <div>
         <Alert severity="success" sx={{ margin: 1, mt: 2, justifyContent: 'center', alignItems: 'center' }}>
            <AlertTitle>Administration</AlertTitle>
            This facility will be available in future.
            <br />
            <br />
            यह सुविधा भविष्य में उपलब्ध होगी।
         </Alert>
      </div>
   )
}

export default HomeStateAdministration
