import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Microlink from '@microlink/react'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   IconButton,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Typography,
} from '@mui/material'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { arrayUnion, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
// import moment from 'moment'
import AddNewsElection from '../../../Components/Edits/AddNewsElection'
import moment from 'moment'

function PetitionNews({ petition, weblinksListRoot, weblinksList, handleWeblinksUpdate }) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip
   const navigate = useNavigate()

   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   useEffect(() => {
      if (user.currentUser !== null) {
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setFirstLoadStatus('success')
      }
   }, [])

   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handeAddNewsPetition = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            weblinkDetails.Petition_id = petition.id

            if (weblinksListRoot && weblinksListRoot.length > 1) {
               const duplicateItem = weblinksListRoot.find((item) => item.Weblink === weblinkDetails.Weblink)
               if (duplicateItem) {
                  alert('This link is already present.')
                  return
               }
            }

            let coreListId = ''
            let weblinksCount = 0

            // we are fetching docPetitionFresh so that we get the real count of weblinks just before the entry of new weblink
            const docRefPetitionFresh = doc(db, 'Petitions', petition.id)
            const docSnapPetitionFresh = await getDoc(docRefPetitionFresh)
            weblinksCount = docSnapPetitionFresh.data().WeblinksCount

            const slab = 2000
            const quotient = Math.floor(weblinksCount / slab)
            // const remainder = candidatesCount % slab
            const num = quotient + 1
            const numStr = String(num).padStart(6, '0')

            // coreListId = 'CandiCoreList-' + numStr
            coreListId = numStr

            weblinkDetails.CoreListId = coreListId

            //
            const docRefPetitionSubCollWeblinks = doc(db, 'Petitions', petition.id, 'WeblinksLists', coreListId)
            const docSnapSubColPetition = await getDoc(docRefPetitionSubCollWeblinks)
            if (docSnapSubColPetition.exists()) {
               await updateDoc(docRefPetitionSubCollWeblinks, {
                  Weblinks: arrayUnion(weblinkDetails),
               })
            } else {
               setDoc(docRefPetitionSubCollWeblinks, { Weblinks: [weblinkDetails] }, { merge: true })
            }

            await updateDoc(docRefPetitionFresh, {
               WeblinksCount: increment(1),
            })

            // update the website display
            // canceled the following as date from here was causing display problem.
            handleWeblinksUpdate(weblinkDetails)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ px: 1 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - News links for ${petition.Title}`}</title>
            </Helmet>
         </div>
         {(isDataEditor || isSuper) && (
            <Box sx={{ mb: 0.5 }}>
               <Button
                  onClick={(e) => {
                     handleOpenModalWebsite(e)
                  }}
                  size="small"
                  variant="outlined"
                  sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line' }}
               >
                  List a news / समाचार सूचीबद्ध करें
               </Button>
            </Box>
         )}

         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               News / Statements {'\n'} समाचार / वक्तव्य:
            </Typography>
         </Box>

         <Box>
            <List dense>
               {weblinksList &&
                  weblinksList.length > 0 &&
                  weblinksList.map((item, index) => (
                     <div key={index}>
                        <ListItem
                           sx={{ pl: 0, ml: 0 }}
                           key={index}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       // onClick={(e) => {
                                       //    handleDelete(e, item)
                                       // }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <ListItemAvatar>
                              <Typography
                                 component="span"
                                 variant="body2"
                                 sx={{
                                    color: 'text.primary',
                                    display: 'inline',
                                    mb: 1,
                                    fontWeight: 600,
                                 }}
                              >
                                 {moment(item.Date.toDate()).format('Do MMMM YYYY')} <ArrowDropDownIcon />
                              </Typography>
                              <Microlink url={item.Weblink} fetchData lazy={{ threshold: 0.5 }} />
                           </ListItemAvatar>
                           <ListItemText
                              sx={{ ml: 1 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              // primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
                              // primary={
                              //    <>
                              //       {item &&
                              //          (item.Weblink.indexOf('http://') === 0 ||
                              //             item.Weblink.indexOf('https://') === 0) && (
                              //             <Link
                              //                underline="hover"
                              //                href={item.Weblink}
                              //                target="_blank"
                              //                rel="noopener noreferrer"
                              //             >
                              //                {item.Title}
                              //             </Link>
                              //          )}
                              //       {item &&
                              //          item.Weblink.indexOf('http://') !== 0 &&
                              //          item.Weblink.indexOf('https://') !== 0 && (
                              //             <Link
                              //                underline="hover"
                              //                href={`//${item.Weblink}`}
                              //                target="_blank"
                              //                rel="noopener noreferrer"
                              //             >
                              //                {item.Title}
                              //             </Link>
                              //          )}
                              //    </>
                              // }
                              // component={'span'}
                              // secondary={<>{moment(item.Date.toDate()).format('Do MMMM YYYY')}</>}
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {weblinksList && weblinksList.length < 1 && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        List of links for{' '}
                        <strong>
                           <u>{petition.Name}</u>
                        </strong>{' '}
                        to be available soon. <br />{' '}
                        <strong>
                           <u>{petition.NameHindi}</u>
                        </strong>{' '}
                        के लिए links की सूची शीघ्र ही उपलब्ध होगी |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalWebsite}
               onClose={handleCloseModalWebsite}
               aria-labelledby="modal-modal-title-website"
               aria-describedby="modal-modal-description-website"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-website"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a web link
                  </Typography>
                  <AddNewsElection handeAddNewsElection={handeAddNewsPetition}></AddNewsElection>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

PetitionNews.propTypes = {
   petition: PropTypes.object.isRequired,
   weblinksListRoot: PropTypes.array.isRequired,
   weblinksList: PropTypes.array.isRequired,
   handleWeblinksUpdate: PropTypes.func.isRequired,
}

export default PetitionNews
