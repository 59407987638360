import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import moment from 'moment'

function PetitionAffectedPeople({ petition, allSigns }) {
   //
   console.log('allSigns: ', allSigns)
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Petition - ${petition.Title}`}</title>
            </Helmet>
         </div>
         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               Signs and Comments: {'\n'} समर्थन और टिप्पणियाँ:
            </Typography>
         </Box>
         <Box sx={{ mb: 0.5 }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
               {allSigns &&
                  allSigns.length > 0 &&
                  allSigns.map((item, index) => (
                     <div key={index}>
                        <ListItem alignItems="flex-start">
                           <ListItemAvatar>
                              <Avatar alt="Remy Sharp" src={item.CrByImg} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ textAlign: 'left' }}
                              primary={item.CrByName}
                              secondary={
                                 <>
                                    <>
                                       {item.CrDt && moment(item.CrDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                       <span style={{ color: 'Green', fontWeight: 600 }}>
                                          You signed this petition.
                                       </span>
                                       <br />
                                    </>
                                    {item.WDt && (
                                       <>
                                          {item.WDt && moment(item.WDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                          <span style={{ color: 'Blue', fontWeight: 600 }}>
                                             You withdrew your sign from this petition.
                                          </span>
                                          <br />
                                       </>
                                    )}
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                     </div>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

PetitionAffectedPeople.propTypes = {
   petition: PropTypes.object.isRequired,
   allSigns: PropTypes.array.isRequired,
}

export default PetitionAffectedPeople
