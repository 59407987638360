import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddExitPollReports({
   handleAddExitPollReport,
   exitPollsList,
   partiesList,
   alliancesList,
   lastExitPollReport,
   uploadedExitPollReport,
   uploadingExitPollReport,
   uploadingFailedExitPollReport,
}) {
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   //
   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         setFirstLoadStatus('success')
      }
   }, [])
   //
   const [reportDetails, setReportDetails] = useState({
      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyAbbreviation: '',
      PartyFlag: '',

      Alliance_id: '',
      AllianceName: '',
      AllianceNameHindi: '',
      AllianceNameLocal: '',
      AllianceAbbreviation: '',
      AllianceLogo: '',

      VotePercent_Avg: '',
      VotePercent_Max: '',
      VotePercent_Min: '',

      Seats_Avg: '',
      Seats_Max: '',
      Seats_Min: '',

      IsRemoved: false,
   })

   const [reportDetailsForElec, setReportDetailsForElec] = useState({
      ExitPoll_id: '',
      ExitPollName: '',
      ExitPollNameHindi: '',
      ExitPollNameLocal: '',

      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyAbbreviation: '',
      PartyFlag: '',

      Alliance_id: '',
      AllianceName: '',
      AllianceNameHindi: '',
      AllianceNameLocal: '',
      AllianceAbbreviation: '',
      AllianceLogo: '',

      VotePercent_Avg: '',
      VotePercent_Max: '',
      VotePercent_Min: '',

      Seats_Avg: '',
      Seats_Max: '',
      Seats_Min: '',

      IsRemoved: false,
   })

   useEffect(() => {
      console.log('reportDetails on change: ', reportDetails)
   }, [reportDetails])

   useEffect(() => {
      //
      console.log('reportDetailsForElec on change: ', reportDetailsForElec)
   }, [reportDetailsForElec])
   //
   function handleNumberDetails(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setReportDetails({ ...reportDetails, [e.target.name]: e.target.value })
            : setReportDetails({ ...reportDetails, [e.target.name]: 0 })

         newValue && !isNaN(newValue)
            ? setReportDetailsForElec({ ...reportDetailsForElec, [e.target.name]: e.target.value })
            : setReportDetailsForElec({ ...reportDetailsForElec, [e.target.name]: 0 })
      } else {
         setReportDetails({ ...reportDetails, [e.target.name]: '' })
         setReportDetailsForElec({ ...reportDetailsForElec, [e.target.name]: '' })
      }
   }

   const handleChangeParty = (e) => {
      e.preventDefault()

      if (e.target.value !== '') {
         const party = partiesList.find((item) => item.Party_id === e.target.value)
         console.log('party flag', party)
         // console.log('party name', party.Name)
         setReportDetails({
            ...reportDetails,
            Party_id: party.Party_id,
            PartyName: party.PartyName,
            PartyNameHindi: party.PartyNameHindi,
            PartyNameLocal: party.PartyNameLocal,
            PartyAbbreviation: party.PartyAbbreviation,
            PartyFlag: party.PartyFlag,

            Alliance_id: '',
            AllianceName: '',
            AllianceNameHindi: '',
            AllianceNameLocal: '',
            AllianceAbbreviation: '',
            AllianceLogo: '',
         })
         setReportDetailsForElec({
            ...reportDetailsForElec,
            Party_id: party.Party_id,
            PartyName: party.PartyName,
            PartyNameHindi: party.PartyNameHindi,
            PartyNameLocal: party.PartyNameLocal,
            PartyAbbreviation: party.PartyAbbreviation,
            PartyFlag: party.PartyFlag,

            Alliance_id: '',
            AllianceName: '',
            AllianceNameHindi: '',
            AllianceNameLocal: '',
            AllianceAbbreviation: '',
            AllianceLogo: '',
         })
      } else {
         setReportDetails({
            ...reportDetails,
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
         })
         setReportDetailsForElec({
            ...reportDetailsForElec,
            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
         })
      }
   }

   const handleChangeAlliance = (e) => {
      e.preventDefault()

      if (e.target.value !== '') {
         const alliance = alliancesList.find((item) => item.Alliance_id === e.target.value)
         // console.log('party flag', party.Flag)
         // console.log('party name', party.Name)
         setReportDetails({
            ...reportDetails,
            Alliance_id: alliance.Alliance_id,
            AllianceName: alliance.Name,
            AllianceAbbreviation: alliance.Abbreviation,

            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
         })
         setReportDetailsForElec({
            ...reportDetailsForElec,
            Alliance_id: alliance.Alliance_id,
            AllianceName: alliance.Name,
            AllianceAbbreviation: alliance.Abbreviation,

            Party_id: '',
            PartyName: '',
            PartyNameHindi: '',
            PartyNameLocal: '',
            PartyAbbreviation: '',
            PartyFlag: '',
         })
      } else {
         setReportDetails({
            ...reportDetails,
            Alliance_id: '',
            AllianceName: '',
            AllianceNameHindi: '',
            AllianceNameLocal: '',
            AllianceAbbreviation: '',
            AllianceLogo: '',
         })
         setReportDetailsForElec({
            ...reportDetailsForElec,
            Alliance_id: '',
            AllianceName: '',
            AllianceNameHindi: '',
            AllianceNameLocal: '',
            AllianceAbbreviation: '',
            AllianceLogo: '',
         })
      }
   }

   const handleChangeExitPoll = (e) => {
      e.preventDefault()

      if (e.target.value !== '') {
         const exitPoll = exitPollsList.find((item) => item.ExitPoll_id === e.target.value)
         setReportDetailsForElec({
            ...reportDetailsForElec,
            ExitPoll_id: e.target.value,
            ExitPollName: exitPoll.AgencyName,
            ExitPollNameHindi: exitPoll.AgencyNameHindi,
            ExitPollNameLocal: exitPoll.AgencyNameLocal,
         })
      } else {
         setReportDetailsForElec({
            ...reportDetailsForElec,
            ExitPoll_id: '',
            ExitPollName: '',
            ExitPollNameHindi: '',
            ExitPollNameLocal: '',
         })
      }
   }

   const handleSubmit = (e) => {
      e.preventDefault()

      if (
         (reportDetailsForElec.ExitPoll_id !== '' &&
            (reportDetailsForElec.Party_id !== '' || reportDetailsForElec.Alliance_id !== '')) ||
         (reportDetailsForElec.VotePercent_Avg !== '' && reportDetailsForElec.Seats_Avg !== '') ||
         (reportDetailsForElec.VotePercent_Max !== '' && reportDetailsForElec.Seats_Max !== '') ||
         (reportDetailsForElec.VotePercent_Min !== '' && reportDetailsForElec.Seats_Min !== '')
      ) {
         handleAddExitPollReport(reportDetails, reportDetailsForElec)
      }

      reset()
   }

   const reset = () => {
      setReportDetailsForElec({
         ...reportDetailsForElec,

         Party_id: '',
         PartyName: '',
         PartyNameHindi: '',
         PartyNameLocal: '',
         PartyAbbreviation: '',
         PartyFlag: '',

         Alliance_id: '',
         AllianceName: '',
         AllianceNameHindi: '',
         AllianceNameLocal: '',
         AllianceAbbreviation: '',
         AllianceLogo: '',

         VotePercent_Avg: '',
         VotePercent_Max: '',
         VotePercent_Min: '',

         Seats_Avg: '',
         Seats_Max: '',
         Seats_Min: '',

         IsRemoved: false,
      })
      setReportDetails({
         Party_id: '',
         PartyName: '',
         PartyNameHindi: '',
         PartyNameLocal: '',
         PartyAbbreviation: '',
         PartyFlag: '',

         Alliance_id: '',
         AllianceName: '',
         AllianceNameHindi: '',
         AllianceNameLocal: '',
         AllianceAbbreviation: '',
         AllianceLogo: '',

         VotePercent_Avg: '',
         VotePercent_Max: '',
         VotePercent_Min: '',

         Seats_Avg: '',
         Seats_Max: '',
         Seats_Min: '',

         IsRemoved: false,
      })
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 2,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <FormControl sx={{ m: 1 }} size="small">
            <InputLabel>Exit Poll</InputLabel>
            <Select
               value={reportDetailsForElec.ExitPoll_id}
               label="Exit Poll"
               onChange={(e) => {
                  handleChangeExitPoll(e)
               }}
               sx={{ backgroundColor: '#ffffff' }}
            >
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {exitPollsList &&
                  exitPollsList.map((item) => (
                     <MenuItem key={item.ExitPoll_id} value={item.ExitPoll_id} sx={{ fontSize: 13 }}>
                        {item.AgencyName}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
         <Box sx={{ border: 1, borderRadius: 1, mb: 2 }}>
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Party</InputLabel>
               <Select
                  value={reportDetails.Party_id}
                  label="Party"
                  onChange={(e) => {
                     handleChangeParty(e)
                  }}
                  sx={{ backgroundColor: '#ffffff' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {partiesList &&
                     partiesList.map((item) => (
                        <MenuItem key={item.Party_id} value={item.Party_id} sx={{ fontSize: 13 }}>
                           {item.PartyName} - <strong> {item.PartyAbbreviation}</strong>
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>OR</Box>
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Alliance</InputLabel>
               <Select
                  value={reportDetails.Alliance_id}
                  label="Alliance"
                  onChange={(e) => {
                     handleChangeAlliance(e)
                  }}
                  sx={{ backgroundColor: '#ffffff' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {alliancesList &&
                     alliancesList.map((item) => (
                        <MenuItem key={item.Alliance_id} value={item.Alliance_id} sx={{ fontSize: 13 }}>
                           {item.Name} - <strong> {item.Abbreviation}</strong>
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         </Box>

         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={reportDetails.VotePercent_Min}
               name="VotePercent_Min"
               id="votePercent_Min"
               label="VotePercent_Min"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={reportDetails.VotePercent_Max}
               name="VotePercent_Max"
               id="votePercent_Max"
               label="VotePercent_Max"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>

         <Box sx={{ my: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={reportDetails.VotePercent_Avg}
               name="VotePercent_Avg"
               id="votePercent_Avg"
               label="VotePercent_Avg"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>

         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={reportDetails.Seats_Min}
               name="Seats_Min"
               id="seats_Min"
               label="Seats_Min"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={reportDetails.Seats_Max}
               name="Seats_Max"
               id="seats_Max"
               label="Seats_Max"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>

         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleNumberDetails(e)
               }}
               value={reportDetails.Seats_Avg}
               name="Seats_Avg"
               id="seats_Avg"
               label="Seats_Avg"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mb: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmit(e)
               }}
               disabled={
                  reportDetails.ExitPoll_id === '' ||
                  (reportDetails.Party_id === '' && reportDetails.Alliance_id === '') ||
                  (reportDetails.VotePercent_Avg === '' && reportDetails.Seats_Avg === '') ||
                  (reportDetails.VotePercent_Max === '' && reportDetails.Seats_Max === '') ||
                  (reportDetails.VotePercent_Min === '' && reportDetails.Seats_Min === '')
               }
            >
               Submit
            </Button>
         </Box>
         <Box sx={{ mb: 2 }}>
            {uploadingExitPollReport && (
               <MyLoaderCircularGradient
                  title={`Creating exit poll by ${lastExitPollReport} ...`}
               ></MyLoaderCircularGradient>
            )}
            {uploadedExitPollReport && (
               <Alert variant="outlined" severity="success">
                  <strong>{lastExitPollReport}</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedExitPollReport && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>{lastExitPollReport}</strong> could not be listed.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddExitPollReports.propTypes = {
   handleAddExitPollReport: PropTypes.func.isRequired,
   exitPollsList: PropTypes.array.isRequired,
   partiesList: PropTypes.array.isRequired,
   alliancesList: PropTypes.array.isRequired,
   lastExitPollReport: PropTypes.string.isRequired,
   uploadingExitPollReport: PropTypes.bool.isRequired,
   uploadedExitPollReport: PropTypes.bool.isRequired,
   uploadingFailedExitPollReport: PropTypes.bool.isRequired,
}

export default AddExitPollReports
