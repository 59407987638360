import { Button } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function CustomButtons() {
   return <div></div>
}

function CustomButtonGreen(props) {
   return (
      <Button
         variant="contained"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            backgroundColor: '#2e7d32',
            whiteSpace: 'pre-line',
            lineHeight: 1.2,
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#4caf50' },
            // display: 'none',
         }}
         onClick={props.handleClick}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonGreen.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
}

function CustomButtonGreenO(props) {
   return (
      <Button
         variant="outlined"
         color="success"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            whiteSpace: 'pre-line',
            lineHeight: 1.2,
            '&:hover': { backgroundColor: '#4caf50', color: '#FFFFFF' },
            // display: 'none',
         }}
         onClick={props.handleClick}
         disabled={props.disabled}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonGreenO.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
}

function CustomButtonBlue(props) {
   return (
      <Button
         variant="contained"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            backgroundColor: '#01579b',
            whiteSpace: 'pre-line',
            lineHeight: 1.2,
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#03a9f4' },
         }}
         onClick={props.handleClick}
         disabled={props.disabled}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonBlue.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
}

function CustomButtonBlueO(props) {
   return (
      <Button
         variant="outlined"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            whiteSpace: 'pre-line',
            lineHeight: 1.2,

            '&:hover': { backgroundColor: '#03a9f4', color: '#FFFFFF' },
         }}
         onClick={props.handleClick}
         disabled={props.disabled}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonBlueO.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
}

function CustomButtonRed(props) {
   return (
      <Button
         variant="contained"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            backgroundColor: '#d32f2f',
            whiteSpace: 'pre-line',
            lineHeight: 1.2,
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#ef5350' },
         }}
         onClick={props.handleClick}
         disabled={props.disabled}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonRed.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
}

function CustomButtonRedO(props) {
   return (
      <Button
         variant="outlined"
         color="error"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            whiteSpace: 'pre-line',
            lineHeight: 1.2,

            '&:hover': { backgroundColor: '#ef5350', color: '#FFFFFF' },
         }}
         onClick={props.handleClick}
         disabled={props.disabled}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonRedO.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
}

function CustomButtonOrange(props) {
   return (
      <Button
         variant="contained"
         sx={{
            textTransform: 'none',
            py: 0.5,
            minWidth: 0,
            px: 2,
            ml: 3,
            backgroundColor: '#ed6c02',
            whiteSpace: 'pre-line',
            lineHeight: 1.2,
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#ff9800' },
         }}
         onClick={props.handleClick}
         disabled={props.disabled}
      >
         {props.labelName}
      </Button>
   )
}

CustomButtonOrange.propTypes = {
   labelName: PropTypes.string.isRequired,
   handleClick: PropTypes.func.isRequired,
   disabled: PropTypes.bool.isRequired,
}

export {
   CustomButtonGreen,
   CustomButtonGreenO,
   CustomButtonBlue,
   CustomButtonBlueO,
   CustomButtonRed,
   CustomButtonRedO,
   CustomButtonOrange,
}

export default CustomButtons
