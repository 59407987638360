import React, { useEffect, useRef } from 'react'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Divider,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

function HomeLocalLegis({ legisList, textLevel, textName, selectedStateId, selectedDistrictId }) {
   const focusRef = useRef(null)

   useEffect(() => {
      if (legisList) {
         //
         focusRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
   }, [legisList])

   return (
      <Box sx={{ p: 0.5 }}>
         {(!selectedStateId || !selectedDistrictId) && (
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               Please select a state / UT and district. <br /> कृपया राज्य/ केंद्र शासित प्रदेश और जिला चुनें|
            </Alert>
         )}
         {legisList && (
            <Typography component="div" sx={{ px: 1, mt: 0.5, fontSize: 13 }}>
               <Box
                  display="inline"
                  sx={{
                     backgroundColor: '#a5c9ff',
                     fontSize: 12,
                     color: '#000000',
                     px: 1,
                     fontWeight: 500,
                  }}
               >
                  {textLevel}
               </Box>{' '}
               :{' '}
               <Box
                  fontWeight="fontWeightMedium"
                  display="inline"
                  sx={{
                     backgroundColor: '#ffb2b2',
                     fontSize: 12,
                     color: '#000000',
                     px: 1,
                     fontWeight: 500,
                  }}
               >
                  {textName}
               </Box>
            </Typography>
         )}

         {legisList && legisList.length < 1 && (
            <div>
               <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle sx={{ fontSize: 12 }}>
                     To be available soon. <br /> शीघ्र उपलब्ध होगा |
                  </AlertTitle>
               </Alert>
            </div>
         )}

         <List dense ref={focusRef}>
            {legisList &&
               legisList.length > 0 &&
               legisList.map((item, index) => (
                  <Link
                     key={item.Legislature_id}
                     href={`/legislature/${item.Name}/${item.Legislature_id}`}
                     sx={{ textDecoration: 'none !important', px: 0 }}
                     rel="noreferrer"
                     target="_blank"
                  >
                     <ListItem sx={{ padding: 0 }}>
                        <ListItemAvatar>
                           <Avatar alt="Travis Howard" src={item.Image} />
                        </ListItemAvatar>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                           primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                           primary={item.Name}
                           secondary={item.NameHindi}
                        />
                     </ListItem>
                     <Divider />
                  </Link>
               ))}
         </List>
      </Box>
   )
}

HomeLocalLegis.propTypes = {
   legisList: PropTypes.array.isRequired,
   textLevel: PropTypes.string.isRequired,
   textName: PropTypes.string.isRequired,
   selectedStateId: PropTypes.string.isRequired,
   selectedDistrictId: PropTypes.string.isRequired,
}

export default HomeLocalLegis
