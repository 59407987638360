import { Box, FormControl, InputLabel, MenuItem, Select, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { a11yProps, StyledTab1, TabPanel1 } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import { TabContext } from '@mui/lab'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import { sxTypoGraphy2 } from '../../Utils/MUITheme/MUITheme'
import HomeStateLegis from './HomeStateLegis'
import HomeStateExecutive from './HomeStateExecutive'
import HomeStateJudiciary from './HomeStateJudiciary'
import HomeStateAdministration from './HomeStateAdministration'

function HomeTabPanelForState() {
   const theme = useTheme()
   const [value, setValue] = useState(0)

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [legisList, setLegisList] = useState('')
   const [statesList, setStatesList] = useState('')
   const [selectedStateId, setSelectedStateId] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchCountry()

         setFirstLoadStatus('success')
      }
   }, [])

   const [individualCountry, setIndividualCountry] = useState('')

   const fetchCountry = async () => {
      const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualCountry({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualCountry) {
         const sortedListStates = [...individualCountry.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setStatesList(sortedListStates)
      }
   }, [individualCountry])

   const handleChangeState = (e) => {
      e.preventDefault()
      setSelectedStateId(e.target.value)
      setValue(0)
   }

   const [individualState, setIndividualState] = useState('')
   const [fullStateObjectsList, setFullStateObjectsList] = useState([])

   useEffect(() => {
      if (selectedStateId) {
         if (fullStateObjectsList.length > 0) {
            const thisState = fullStateObjectsList.find((item) => item.id === selectedStateId)
            if (thisState) {
               console.log('second')
               //    const list = thisState.Legislatures
               //    const listLegislative = list.filter((item) => item.Type === 'Legislative')

               //    const sortedListLegis = [...listLegislative].sort((a, b) => {
               //       return parseInt(a.Priority) - parseInt(b.Priority)
               //    })
               setLegisList(thisState.Legislatures)
            } else {
               getIndividualState()
            }
         } else {
            getIndividualState()
         }
      } else {
         setIndividualState('')
      }
   }, [selectedStateId])

   const getIndividualState = async () => {
      const docRef = doc(db, 'States', selectedStateId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualState({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }

   useEffect(() => {
      if (individualState) {
         console.log('first')
         setFullStateObjectsList([...fullStateObjectsList, individualState])
         const list = individualState.Legislatures
         const listLegislative = list.filter((item) => item.Type === 'Legislative')
         console.log('individualState', individualState)
         console.log('list:', list)
         console.log('listLegislative:', listLegislative)

         // const sortedListLegis = [...listLegislative].sort((a, b) => {
         //    return parseInt(a.Priority) - parseInt(b.Priority)
         // })
         setLegisList(list)
      } else {
         setLegisList('')
      }
   }, [individualState])

   useEffect(() => {
      console.log('legisList', legisList)
   }, [legisList])

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeIndex = (index) => {
      setValue(index)
   }

   return (
      <Box sx={{ pb: 0.5 }}>
         <Box
            sx={{
               // Love Couple from uiGradient
               background: 'linear-gradient(to right, #3a6186, #89253e)',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: 50,
               mb: 1,
            }}
         >
            <Typography sx={sxTypoGraphy2}>
               State{' - '}
               <span style={{ fontSize: 15 }}>
                  <strong>राज्य</strong>
               </span>
            </Typography>
         </Box>
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormControl sx={{ m: 1, width: '80%', backgroundColor: '#ffffff' }} size="small">
               <InputLabel>Choose State / UT</InputLabel>
               <Select
                  id="xxx"
                  value={selectedStateId}
                  label="Choose State / UT"
                  onChange={(e) => {
                     handleChangeState(e)
                  }}
                  sx={{ padding: 0 }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {statesList &&
                     statesList.length > 0 &&
                     statesList.map((item) => (
                        <MenuItem key={item.State_id} value={item.State_id}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         </Box>
         <TabContext value={value}>
            <Box sx={{ width: '100%', mb: 1 }}>
               <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  //   centered
                  variant="scrollable"
                  scrollButtons="false"
                  sx={{
                     minHeight: 25,
                     width: '100%',
                     mr: 0,
                  }}
               >
                  <StyledTab1 label={`Legislative \n विधायिका`} {...a11yProps(0)} />
                  <StyledTab1 label={`Executive \n (कार्यपालिका)`} {...a11yProps(1)} />
                  <StyledTab1 label={`Administration \n (प्रशासन)`} {...a11yProps(2)} />
                  <StyledTab1 label={`Judiciary \n (न्यायपालिका)`} {...a11yProps(3)} />
               </Tabs>
            </Box>

            <SwipeableViews
               axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
               index={value}
               onChangeIndex={handleChangeIndex}
            >
               <TabPanel1 value={value} index={0} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && (
                     <HomeStateLegis legisList={legisList} selectedStateId={selectedStateId} />
                  )}
               </TabPanel1>
               <TabPanel1 value={value} index={1} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && (
                     <HomeStateExecutive legisList={legisList} selectedStateId={selectedStateId} />
                  )}
               </TabPanel1>
               <TabPanel1 value={value} index={2} dir={theme.direction}>
                  <HomeStateAdministration></HomeStateAdministration>
               </TabPanel1>
               <TabPanel1 value={value} index={3} dir={theme.direction}>
                  <HomeStateJudiciary></HomeStateJudiciary>
               </TabPanel1>
            </SwipeableViews>
         </TabContext>
      </Box>
   )
}

export default HomeTabPanelForState
