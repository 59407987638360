import { Alert, AlertTitle, Box, Link } from '@mui/material'
import React from 'react'
import logoMaxLife from '../../Images/Adverts/MaxLife-01.jpeg'
import styles from './Home.module.css'

function HomeCentralJudiciary() {
   return (
      <Box>
         <Alert severity="success" sx={{ margin: 1, mt: 5, justifyContent: 'center', alignItems: 'center' }}>
            <AlertTitle>Judiciary</AlertTitle>
            This facility will be available in future.
            <br />
            <br />
            यह सुविधा भविष्य में उपलब्ध होगी।
         </Alert>
         <Box
            sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               p: 1,
            }}
         >
            <Link
               target="_blank"
               rel="noopener"
               href="https://www.maxlifeinsurance.com/investment-plans/investment-calculator?utmCode=14230408&utm_source=Impact&utm_medium=Bing_Native_Savings&utm_campaign=May24_MLI_BingNative_Savings_English_Domestic_ROI_Misc_PR&utm_term=1Cr_FatherSon1English_ULIP_15032024&utm_content={{site_source_name}}&utm_theme=ULIP"
            >
               <img src={logoMaxLife} className={styles.imgAd} />
            </Link>
         </Box>
      </Box>
   )
}

export default HomeCentralJudiciary
