import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Divider,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'

function HomeStateExecutive({ legisList, selectedStateId }) {
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [fetchingExec, setFetchingExec] = useState(true)
   const [fetchingMin, setFetchingMin] = useState(true)
   const [fetchingDep, setFetchingDep] = useState(true)
   const [fetchingApex, setFetchingApex] = useState(true)

   const [legisListExec, setLegisListExec] = useState('')
   const [legisListMin, setLegisListMin] = useState('')
   const [legisListDep, setLegisListDep] = useState('')
   const [legisListApex, setLegisListApex] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle' && legisList) {
         const listExec = legisList.filter((item) => item.Type === 'Executive')
         const listMin = legisList.filter((item) => item.Type === 'Ministry')
         const listDep = legisList.filter((item) => item.Type === 'Independent Department')
         const listApex = legisList.filter((item) => item.Type === 'Apex / Independent Body')

         const sortedListExec = [...listExec].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         const sortedListMin = [...listMin].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         const sortedListDep = [...listDep].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         const sortedListApex = [...listApex].sort((a, b) => {
            if (b.Name > a.Name) {
               return -1
            } else if (b.Name < a.Name) {
               return 1
            } else {
               return 0
            }
         })
         setLegisListExec(sortedListExec)
         setLegisListMin(sortedListMin)
         setLegisListDep(sortedListDep)
         setLegisListApex(sortedListApex)

         setFirstLoadStatus('success')
      }
   }, [])

   useEffect(() => {
      if (legisListExec) {
         setFetchingExec(false)
      }
   }, [legisListExec])
   useEffect(() => {
      if (legisListMin) {
         setFetchingMin(false)
      }
   }, [legisListMin])
   useEffect(() => {
      if (legisListDep) {
         setFetchingDep(false)
      }
   }, [legisListDep])
   useEffect(() => {
      if (legisListApex) {
         setFetchingApex(false)
      }
   }, [legisListApex])

   return (
      <Box sx={{ p: 0.5 }}>
         {!selectedStateId && (
            <Alert
               variant="outlined"
               severity="success"
               sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
            >
               Please select a state / UT. <br /> कृपया राज्य / केंद्र शासित प्रदेश चुनें|
            </Alert>
         )}
         {legisList && (
            <Box>
               <Box>
                  <Typography variant="button" display="block" gutterBottom>
                     Head Executive:
                  </Typography>
                  <List dense>
                     {legisListExec && legisListExec.length > 0 ? (
                        legisListExec.map((item, index) => (
                           <Link
                              key={item.Legislature_id}
                              href={`/executive-central/${item.Name}/${item.Legislature_id}`}
                              sx={{ textDecoration: 'none !important', px: 0 }}
                              rel="noreferrer"
                              target="_blank"
                           >
                              <ListItem sx={{ padding: 0 }}>
                                 <ListItemAvatar>
                                    <Avatar alt="Travis Howard" src={item.Image} />
                                 </ListItemAvatar>
                                 <ListItemText
                                    secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                                    primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                                    primary={item.Name}
                                    secondary={item.NameHindi}
                                 />
                              </ListItem>
                              <Divider />
                           </Link>
                        ))
                     ) : fetchingExec ? (
                        <MyLoaderCircularGradient title={'Fetching data ...'}></MyLoaderCircularGradient>
                     ) : (
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                           </AlertTitle>
                        </Alert>
                     )}
                  </List>
               </Box>
               <Box>
                  <Typography variant="button" display="block" gutterBottom>
                     Ministries:
                  </Typography>
                  <List dense>
                     {legisListMin && legisListMin.length > 0 ? (
                        legisListMin.map((item, index) => (
                           <div key={item.Legislature_id}>
                              <ListItem sx={{ padding: 0 }}>
                                 <ListItemAvatar>
                                    <Avatar
                                       src={item.Image}
                                       sx={{ backgroundImage: 'radial-gradient( #3f87a6, #ebf8e1, #f69d3c)' }}
                                    />
                                 </ListItemAvatar>
                                 <ListItemText
                                    secondaryTypographyProps={{ align: 'left' }}
                                    // primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                                    primary={
                                       <>
                                          <Link
                                             href={`/ministry/${item.Name}/${item.Legislature_id}`}
                                             sx={{ textDecoration: 'none !important', px: 0, fontWeight: 600 }}
                                             rel="noreferrer"
                                             target="_blank"
                                          >
                                             {item.Name}
                                          </Link>
                                          <br />
                                          {item.GovernmentKnownAs}
                                       </>
                                    }
                                    secondary={
                                       <>
                                          <Link
                                             href={`/ministry/${item.Name}/${item.Legislature_id}`}
                                             sx={{ textDecoration: 'none !important', px: 0, fontWeight: 600 }}
                                             rel="noreferrer"
                                             target="_blank"
                                          >
                                             {item.NameHindi}
                                          </Link>
                                          <br />
                                          {item.GovernmentKnownAsHindi}
                                       </>
                                    }
                                 />
                              </ListItem>
                              <Divider />
                           </div>
                        ))
                     ) : fetchingMin ? (
                        <MyLoaderCircularGradient title={'Fetching data ...'}></MyLoaderCircularGradient>
                     ) : (
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                           </AlertTitle>
                        </Alert>
                     )}
                  </List>
               </Box>
               {legisListDep && legisListDep.length > 0 && (
                  <Box>
                     <Typography variant="button" display="block" gutterBottom>
                        Independent Departments:
                     </Typography>
                     <List dense>
                        {legisListDep && legisListDep.length > 0 ? (
                           legisListDep.map((item, index) => (
                              <Link
                                 key={item.Legislature_id}
                                 href={`/ministry/${item.Name}/${item.Legislature_id}`}
                                 sx={{ textDecoration: 'none !important', px: 0 }}
                                 rel="noreferrer"
                                 target="_blank"
                              >
                                 <ListItem sx={{ padding: 0 }}>
                                    <ListItemAvatar>
                                       <Avatar alt="Travis Howard" src={item.Image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                       secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                                       primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                                       primary={item.Name}
                                       secondary={item.NameHindi}
                                    />
                                 </ListItem>
                                 <Divider />
                              </Link>
                           ))
                        ) : fetchingDep ? (
                           <MyLoaderCircularGradient title={'Fetching data ...'}></MyLoaderCircularGradient>
                        ) : (
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle sx={{ fontSize: 12 }}>
                                 To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                              </AlertTitle>
                           </Alert>
                        )}
                     </List>
                  </Box>
               )}
               {legisListApex && legisListApex.length > 0 && (
                  <Box>
                     <Typography variant="button" display="block" gutterBottom>
                        Apex / Independent Bodies:
                     </Typography>
                     <List dense>
                        {legisListApex && legisListApex.length > 0 ? (
                           legisListApex.map((item, index) => (
                              <Link
                                 key={item.Legislature_id}
                                 href={`/legislature/${item.Name}/${item.Legislature_id}`}
                                 sx={{ textDecoration: 'none !important', px: 0 }}
                                 rel="noreferrer"
                                 target="_blank"
                              >
                                 <ListItem sx={{ padding: 0 }}>
                                    <ListItemAvatar>
                                       <Avatar alt="Travis Howard" src={item.Image} />
                                    </ListItemAvatar>
                                    <ListItemText
                                       secondaryTypographyProps={{ align: 'left', fontWeight: 600 }}
                                       primaryTypographyProps={{ fontWeight: 600, fontSize: 16 }}
                                       primary={item.Name}
                                       secondary={item.NameHindi}
                                    />
                                 </ListItem>
                                 <Divider />
                              </Link>
                           ))
                        ) : fetchingApex ? (
                           <MyLoaderCircularGradient title={'Fetching data ...'}></MyLoaderCircularGradient>
                        ) : (
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle sx={{ fontSize: 12 }}>
                                 To be available soon. <br /> यथाशीघ्र उपलब्ध होगा |
                              </AlertTitle>
                           </Alert>
                        )}
                     </List>
                  </Box>
               )}
            </Box>
         )}
      </Box>
   )
}

HomeStateExecutive.propTypes = {
   legisList: PropTypes.array.isRequired,
   selectedStateId: PropTypes.string.isRequired,
}

export default HomeStateExecutive
