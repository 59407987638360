import {
   Alert,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   IconButton,
   InputLabel,
   List,
   ListItem,
   ListItemText,
   MenuItem,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { auth, db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'
import { Transition } from '../../../Utils/MUITheme/MUITheme'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'

function Contact() {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()

   const [thisUser, setThisUser] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')
   const [nameOfUserHindi, setNameOfUserHindi] = useState('')
   const [nameOfUserLocal, setNameOfUserLocal] = useState('')
   const [messagesToSiteList, setMessagesToSiteList] = useState('')
   const [thisMonth, setThisMonth] = useState('')

   const userProfile = useSelector(selectUserProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.NameFirst + ' ' + userProfile.NameLast
         const nameHindi = userProfile.NameHindiFirst + ' ' + ' ' + userProfile.NameHindiLast
         const nameLocal = userProfile.NameLocalFirst + ' ' + userProfile.NameLocalLast
         setNameOfUser(name)
         setNameOfUserHindi(nameHindi)
         setNameOfUserLocal(nameLocal)

         fetchUserMessagesToSite()
      }
   }, [userProfile])

   useEffect(() => {
      console.log('ministry - first use effect entered')

      if (user.currentUser !== null) {
         console.log('ministry - found user state not-null')
         setThisUser(user.currentUser.id)
      } else {
         console.log('ministry - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const today = new Date()
   const quarter = Math.floor((today.getMonth() + 3) / 3)
   const month = String(today.getMonth() + 1).padStart(2, '0')
   const year = today.getFullYear()
   const docIdMessages = year + '-' + quarter + '-' + month

   const fetchUserMessagesToSite = async () => {
      console.log('fetching user messages at first fetch: ')
      try {
         console.log('fetching user messages at first fetch 2: ')
         const querry = query(
            collection(db, 'UserProfiles', userProfile.id, 'MessagesToSite'),
            where('Type', '==', 'MessagesToSiteCoreList'),
         )
         const querySnapshot = await getDocs(querry)

         const listFetched = []

         if (!querySnapshot.empty) {
            querySnapshot.forEach((docSnap) => {
               listFetched.push(...docSnap.data().Messages)
            })
         }
         console.log('Messages To Site at first fetch: ', listFetched)
         if (listFetched.length > 1) {
            const sortedList = listFetched.sort((a, b) => {
               // bigger date first
               if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                  return -1
               } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                  return 1
               } else {
                  return 0
               }
            })
            setMessagesToSiteList(sortedList)
         } else {
            setMessagesToSiteList(listFetched)
         }
      } catch (err) {
         console.log('error fetching messages', err)
      }
   }

   useEffect(() => {
      if (messagesToSiteList) {
         if (messagesToSiteList.length > 0) {
            const top = messagesToSiteList[0]

            if (top.CrDt === '' || moment(top.CrDt.toDate()).isSame(new Date(), 'month')) {
               setThisMonth({ Status: true, Date: top.CrDt })
            } else {
               setThisMonth({ Status: false, Date: '' })
            }
         } else {
            setThisMonth({ Status: false, Date: '' })
         }
      }
   }, [messagesToSiteList])

   const addresses = [
      `1-i-10, Mahaveer Nagar Extension, Kota, Rajasthan, India. Pin - 324005`,
      `2/6A, Natural Green, BD-37, Rabindrapally, Kestopur, Kolkata, West Bengal, India. Pin - 700101`,
   ]

   const emails = [`admin@trackdemocracy.com`]

   const categories = [`General`, `Advertisement`, `Career`, `Business`]

   // message

   const [selectedCategory, setSelectedCategory] = useState('')
   const [msgText, setMsgText] = useState('')
   const [tLength, setTLength] = useState(0)

   const [uploadingMsg, setUploadingMsg] = useState(false)
   const [uploadedMsg, setUploadedMsg] = useState(false)
   const [uploadingFailedMsg, setUploadingFailedMsg] = useState(false)

   function handleChangeCategory(e) {
      e.preventDefault()

      setSelectedCategory(e.target.value)
   }

   function handleMsgText(event) {
      console.log('msgText: ', event.target.value)
      if (typeof event.target.value !== 'undefined') {
         const data = event.target.value
         if (data.length <= 500) {
            setMsgText(data)
            setTLength(data.length)
         } else if (data.length > 500) {
            const newData = data.slice(0, 500)
            setMsgText(newData)
            setTLength(newData.length)
         }
      } else {
         setMsgText('')
         return false
      }
   }

   function handleSubmit(e) {
      e.preventDefault()
      console.log('stage 1 submit')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (thisMonth.Status) {
            handleOpenDialogThisMonth()
         } else {
            setUploadingMsg(true)
            setUploadedMsg(false)
            setUploadingFailedMsg(false)
            handleSendMessage()
         }
      } else {
         handleOpenDialogNotLoggedIn()
      }
      console.log('stage 2 submit')
      reset()
      console.log('stage 3 submit')
   }

   function reset() {
      setMsgText('')
      setSelectedCategory('')
   }

   const handleSendMessage = async () => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (selectedCategory !== '' && msgText.length > 50) {
            try {
               //

               const message = {
                  CrBy: auth.currentUser.uid,
                  CrByName: nameOfUser,
                  CrByNameHindi: nameOfUserHindi,
                  CrByNameLocal: nameOfUserLocal,
                  CrByImage: userProfile.Image.Image,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,

                  Text: msgText,
                  Category: selectedCategory,
                  Replies: [],
                  AwaitingUserReply: false,
                  AwaitingSiteReply: true,
                  Closed: false,
               }

               const record = {
                  Message_id: '',
                  Text: msgText,
                  Category: selectedCategory,
                  AwaitingUserReply: false,
                  AwaitingSiteReply: true,
                  Closed: false,

                  CrBy: auth.currentUser.uid,
                  CrByName: nameOfUser,
                  CrByNameHindi: nameOfUserHindi,
                  CrByNameLocal: nameOfUserLocal,
                  CrByImage: userProfile.Image.Image,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }
               console.log('record: ', record)

               // i. coreListId for user profile
               const docRefUserP = doc(db, 'UserProfiles', userProfile.id)
               const docSnapUserP = await getDoc(docRefUserP)
               const messagesCountUserP = docSnapUserP.data().MessagesToSiteCount
                  ? docSnapUserP.data().MessagesToSiteCount
                  : 0
               const slabUserP = 2000
               const quotientUserP = Math.floor(messagesCountUserP / slabUserP)
               // const remainder = candidatesCount % slab
               const numUserP = quotientUserP + 1
               const numStrUserP = String(numUserP).padStart(9, '0')
               message.CoreListId_AtUserP = { SubColl_id: 'MessagesToSite', CoreList_id: numStrUserP }
               record.CoreListId_AtUserP = { SubColl_id: 'MessagesToSite', CoreList_id: numStrUserP }
               console.log('coreListId UserP: ', numStrUserP)

               // ii. coreListId for base
               const docRefMessagesToSiteSubCollCount = doc(db, 'BaseLists', 'MessagesToSite', docIdMessages, 'Count')
               const docSnapMessagesToSiteSubCollCount = await getDoc(docRefMessagesToSiteSubCollCount)
               const countMessagesToSite = docSnapMessagesToSiteSubCollCount.data().Count
                  ? docSnapMessagesToSiteSubCollCount.data().Count
                  : 0
               const slabMessagesToSite = 2000
               const quotientMessagesToSite = Math.floor(countMessagesToSite / slabMessagesToSite)
               const numMessagesToSite = quotientMessagesToSite + 1
               const numStrMessagesToSite = String(numMessagesToSite).padStart(9, '0')

               message.CoreListId_AtBase = { SubColl_id: docIdMessages, CoreList_id: numStrMessagesToSite }
               record.CoreListId_AtBase = { SubColl_id: docIdMessages, CoreList_id: numStrMessagesToSite }
               console.log('coreListId MessagesToSite: ', numStrMessagesToSite)

               if (docSnapMessagesToSiteSubCollCount.data().Count) {
                  await updateDoc(docRefMessagesToSiteSubCollCount, {
                     Count: increment(1),
                  })
               } else {
                  setDoc(docRefMessagesToSiteSubCollCount, { Count: 1, Type: 'Count' }, { merge: true })
               }

               // 1. Create the main message doc
               const docRef = await addDoc(collection(db, 'MessagesToSite'), message)
               record.Message_id = docRef.id

               // 2. Update base list for MessagesToSite

               const docRefMessagesToSiteSubCollMessages = doc(
                  db,
                  'BaseLists',
                  'MessagesToSite',
                  docIdMessages,
                  numStrMessagesToSite,
               )
               const docSnapMessagesToSiteSubCollMessages = await getDoc(docRefMessagesToSiteSubCollMessages)
               console.log('base list MessagesToSite update start: ')
               if (docSnapMessagesToSiteSubCollMessages.exists()) {
                  await updateDoc(docRefMessagesToSiteSubCollMessages, {
                     Messages: arrayUnion(record),
                  })
               } else {
                  setDoc(
                     docRefMessagesToSiteSubCollMessages,
                     { Messages: [record], Type: 'MessagesToSiteCoreList' },
                     { merge: true },
                  )
               }

               console.log('base list MessagesToSite update end')

               // 3. Update the User

               if (docSnapUserP.data().MessagesToSiteCount) {
                  await updateDoc(docRefUserP, {
                     MessagesToSiteCount: increment(1),
                  })
               } else {
                  await updateDoc(docRefUserP, {
                     MessagesToSiteCount: 1,
                  })
               }

               const docRefUserSubCollMessagesToSite = doc(
                  db,
                  'UserProfiles',
                  userProfile.id,
                  'MessagesToSite',
                  numStrUserP,
               )
               const docSnapUserSubCollMessagesToSite = await getDoc(docRefUserSubCollMessagesToSite)
               console.log('user update start: ')
               if (docSnapUserSubCollMessagesToSite.exists()) {
                  await updateDoc(docRefUserSubCollMessagesToSite, {
                     Messages: arrayUnion(record),
                  })
               } else {
                  setDoc(docRefUserSubCollMessagesToSite, { Messages: [record] }, { merge: true })
               }

               // 7. update local state

               const docRefMessage = doc(db, 'MessagesToSite', docRef.id)
               const docSnapMessage = await getDoc(docRefMessage)
               const local = {
                  Message_id: docRef.id,
                  Text: docSnapMessage.data().Text,
                  Category: docSnapMessage.data().Category,
                  AwaitingUserReply: docSnapMessage.data().AwaitingUserReply,
                  AwaitingSiteReply: docSnapMessage.data().AwaitingSiteReply,
                  Closed: false,

                  CrDt: docSnapMessage.data().CrDt,
               }

               const list = [...messagesToSiteList, local]
               if (list.length > 1) {
                  const sortedList = list.sort((a, b) => {
                     // bigger date first
                     if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                        return -1
                     } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                        return 1
                     } else {
                        return 0
                     }
                  })

                  setMessagesToSiteList(sortedList)
               } else {
                  setMessagesToSiteList(list)
               }

               console.log('user update end')

               setUploadingMsg(false)
               setUploadedMsg(true)
               setUploadingFailedMsg(false)
            } catch (error) {
               alert('Error adding message')
               console.log('Error adding message', error)

               setUploadingMsg(false)
               setUploadedMsg(false)
               setUploadingFailedMsg(true)

               handleOpenDialogTryAgain()
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // try again

   const [openDialogTryAgain, setOpenDialogTryAgain] = useState(false)

   const handleOpenDialogTryAgain = async () => {
      setOpenDialogTryAgain(true)
   }

   const handleCloseDialogTryAgain = () => setOpenDialogTryAgain(false)

   // this month

   const [openDialogThisMonth, setOpenDialogThisMonth] = useState(false)

   const handleOpenDialogThisMonth = async () => {
      setOpenDialogThisMonth(true)
   }

   const handleCloseDialogThisMonth = () => setOpenDialogThisMonth(false)

   // message details

   const [openDialogMessage, setOpenDialogMessage] = useState(false)
   const [selectedMessage, setSelectedMessage] = useState('')
   const [selectedMessageReplies, setSelectedMessageReplies] = useState('')

   const handleOpenDialogMessage = async (e, item) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         setSelectedMessage(item)
         const docRefMessagesToSiteSubCollReplies = doc(db, 'MessagesToSite', item.Message_id, 'Replies', 'Replies-001')
         const docSnapMessagesToSiteSubCollReplies = await getDoc(docRefMessagesToSiteSubCollReplies)
         if (docSnapMessagesToSiteSubCollReplies.exists()) {
            if (docSnapMessagesToSiteSubCollReplies.data().Replies.length > 1) {
               const sortedList = [...docSnapMessagesToSiteSubCollReplies.data().Replies].sort((a, b) => {
                  // bigger date first
                  if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                     return -1
                  } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                     return 1
                  } else {
                     return 0
                  }
               })
               setSelectedMessageReplies(sortedList)
            } else {
               setSelectedMessageReplies(docSnapMessagesToSiteSubCollReplies.data().Replies)
            }
         }
         setOpenDialogMessage(true)
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseDialogMessage = () => setOpenDialogMessage(false)

   // reply

   const [replyText, setReplyText] = useState('')
   const [tLengthReply, setTLengthReply] = useState(0)

   const [uploadingReply, setUploadingReply] = useState(false)
   const [uploadedReply, setUploadedReply] = useState(false)
   const [uploadingFailedReply, setUploadingFailedReply] = useState(false)

   function handleReplyText(event) {
      console.log('ReplyText: ', event.target.value)
      if (typeof event.target.value !== 'undefined') {
         const data = event.target.value
         if (data.length <= 500) {
            setReplyText(data)
            setTLengthReply(data.length)
         } else if (data.length > 500) {
            const newData = data.slice(0, 500)
            setReplyText(newData)
            setTLengthReply(newData.length)
         }
      } else {
         setReplyText('')
         return false
      }
   }

   function handleSubmitReply(e) {
      e.preventDefault()
      console.log('stage 1 submit Reply')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (selectedMessage.AwaitingUserReply && !selectedMessage.Closed) {
            setUploadingReply(true)
            setUploadedReply(false)
            setUploadingFailedReply(false)
            handleSendReply()
         } else {
            handleCloseDialogMessage()
         }
      } else {
         handleCloseDialogMessage()
         handleOpenDialogNotLoggedIn()
      }
      console.log('stage 2 submit Reply')
      resetReply()
      console.log('stage 3 submit Reply')
   }

   function resetReply() {
      setReplyText('')
   }

   const handleSendReply = async () => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (selectedCategory !== '' && msgText.length > 50) {
            try {
               //
               const objReply = {
                  CrBy: auth.currentUser.uid,
                  CrByName: nameOfUser,
                  CrByNameHindi: nameOfUserHindi,
                  CrByNameLocal: nameOfUserLocal,
                  CrByImage: userProfile.Image.Image,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,

                  Text: msgText,
                  ReplyBy: 'User',
               }

               const record = selectedMessage
               const recordNew = {
                  ...selectedMessage,
                  AwaitingUserReply: false,
                  AwaitingSiteReply: true,
               }

               // 1. Add the reply
               const docRefMessageReplies = doc(
                  db,
                  'MessagesToSite',
                  selectedMessage.Message_id,
                  'Replies',
                  'Replies-001',
               )
               const docSnapMessageReplies = await getDoc(docRefMessageReplies)

               if (docSnapMessageReplies.exists()) {
                  await updateDoc(docRefMessageReplies, {
                     Replies: arrayUnion(objReply),
                  })
               } else {
                  setDoc(docRefMessageReplies, { Replies: [objReply] }, { merge: true })
               }

               // 2. Update the User
               console.log('user update start')
               const docRefUserSubCollMessagesToSite = doc(
                  db,
                  'UserProfiles',
                  userProfile.id,
                  'MessagesToSite',
                  selectedMessage.CoreListId_AtUserP.CoreList_id,
               )

               await updateDoc(docRefUserSubCollMessagesToSite, {
                  Messages: arrayRemove(record),
               })
               await updateDoc(docRefUserSubCollMessagesToSite, {
                  Messages: arrayUnion(recordNew),
               })

               console.log('user update end')

               // 3. Update baselist
               console.log('base update start')

               const docRefMessagesToSiteSubCollMessages = doc(
                  db,
                  'BaseLists',
                  'MessagesToSite',
                  selectedMessage.CoreListId_AtBase.SubColl_id,
                  selectedMessage.CoreListId_AtBase.CoreList_id,
               )

               await updateDoc(docRefMessagesToSiteSubCollMessages, {
                  Messages: arrayRemove(record),
               })
               await updateDoc(docRefMessagesToSiteSubCollMessages, {
                  Messages: arrayUnion(recordNew),
               })

               console.log('base update end')

               // 4. update local state
               // 4.i. update the message list
               const thisMessage = messagesToSiteList.find((thisItem) => thisItem === selectedMessage)
               const thisMessageNew = { ...thisMessage, AwaitingSiteReply: true, AwaitingUserReply: false }
               const list = messagesToSiteList.filter((thisItem) => thisItem !== selectedMessage)
               const newList = [...list, thisMessageNew]

               if (newList.length > 1) {
                  const sortedList = list.sort((a, b) => {
                     // bigger date first
                     if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                        return -1
                     } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                        return 1
                     } else {
                        return 0
                     }
                  })

                  setMessagesToSiteList(sortedList)
               } else {
                  setMessagesToSiteList(newList)
               }

               // 4.ii. update the reply list
               const docSnapMessageRepliesNew = await getDoc(docRefMessageReplies)

               if (docSnapMessageRepliesNew.data().Replies.length > 1) {
                  const sortedList = [...docSnapMessageRepliesNew.data().Replies].sort((a, b) => {
                     // bigger date first
                     if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                        return -1
                     } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  setSelectedMessageReplies(sortedList)
               } else {
                  setSelectedMessageReplies(docSnapMessageRepliesNew.data().Replies)
               }

               // 4.iii. update the selected message
               selectedMessage.AwaitingSiteReply = true
               selectedMessage.AwaitingUserReply = false

               setUploadingMsg(false)
               setUploadedMsg(true)
               setUploadingFailedMsg(false)
            } catch (error) {
               alert('Error adding message')
               console.log('Error adding message', error)

               setUploadingMsg(false)
               setUploadedMsg(false)
               setUploadingFailedMsg(true)

               handleOpenDialogTryAgain()
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ minHeight: 800, m: 1, pb: 20, backgroundColor: '#FFFFF0' }}>
         <Box
            sx={{
               p: 2,
               fontWeight: 800,
               fontSize: 18,
               textAlign: 'center',
            }}
         >
            CONTACT
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Addresses:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {addresses.map((value, index) => (
                  <ListItem key={value} sx={{ p: 1, ml: 4 }}>
                     <ListItemText
                        sx={{ my: 0 }}
                        primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                        secondaryTypographyProps={{
                           whiteSpace: 'pre-line',
                           color: 'text.primary',
                           display: 'inline',
                           textAlign: 'left',
                        }}
                        primary={
                           <>
                              <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                 {index + 1}.
                              </Typography>
                              <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                 {value}
                              </Typography>
                           </>
                        }
                     />
                  </ListItem>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Emails:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {emails.map((value, index) => (
                  <ListItem key={value} sx={{ p: 1, ml: 4 }}>
                     <ListItemText
                        sx={{ my: 0 }}
                        primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                        secondaryTypographyProps={{
                           whiteSpace: 'pre-line',
                           color: 'text.primary',
                           display: 'inline',
                           textAlign: 'left',
                        }}
                        primary={
                           <>
                              <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                 {index + 1}.
                              </Typography>
                              <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                 {value}
                              </Typography>
                           </>
                        }
                     />
                  </ListItem>
               ))}
            </List>
         </Box>
         <Box sx={{ p: 2 }}>
            <strong>Message:</strong>
            <Box>
               <FormControl size="small" fullWidth sx={{ m: 2, width: '40ch' }} disabled={uploadingMsg}>
                  <InputLabel>Category</InputLabel>
                  <Select
                     value={selectedCategory}
                     label="Category"
                     onChange={(e) => {
                        handleChangeCategory(e)
                     }}
                     sx={{ backgroundColor: '#ffffff' }}
                  >
                     <MenuItem value="">
                        <em>None</em>
                     </MenuItem>
                     {categories.map((item, index) => (
                        <MenuItem key={index} value={item} sx={{ fontSize: 13 }}>
                           {item}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
            <Box>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleMsgText}
                  slotProps={{ htmlInput: { maxLength: 500 } }}
                  value={msgText}
                  name="Message"
                  id="message"
                  label="Message"
                  multiline
                  rows={4}
                  // defaultValue="the address"
                  size="small"
                  margin="dense"
                  autoComplete="off"
                  autoFocus={true}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', m: 2, mb: 0, width: '50ch' }}
                  disabled={uploadingMsg}
               />
            </Box>
            <Box sx={{ ml: 2 }}>Length of message: {tLength} / 500</Box>
            <Box>
               {uploadingMsg && <MyLoaderCircularGradient title={'Uploading message ...'}></MyLoaderCircularGradient>}
               {uploadedMsg && (
                  <Alert variant="outlined" severity="success" sx={{ width: '50ch' }}>
                     <strong>Message</strong> uploaded successfully.
                  </Alert>
               )}
               {uploadingFailedMsg && (
                  <Alert variant="outlined" severity="error" sx={{ width: '50ch' }}>
                     Error occured! <strong>Message</strong> could not be uploaded.
                  </Alert>
               )}
            </Box>
            <Box sx={{ m: 2 }}>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmit(e)
                  }}
                  disabled={selectedCategory === '' || msgText < 10 || uploadingMsg}
               >
                  Submit
               </Button>
            </Box>
         </Box>

         <Box sx={{ p: 2 }}>
            <strong>Your messages:</strong>
            <br />
            <List sx={{ ml: 4 }}>
               {userProfile &&
                  messagesToSiteList &&
                  messagesToSiteList.length > 0 &&
                  messagesToSiteList.map((item, index) => (
                     <ListItem
                        key={item.Message_id}
                        sx={{ p: 0, ml: 4 }}
                        secondaryAction={
                           <IconButton
                              edge="end"
                              aria-label="edit"
                              onClick={(e) => {
                                 handleOpenDialogMessage(e, item)
                              }}
                              sx={{ ml: 'auto', height: '30px', width: '30px' }}
                           >
                              <EditIcon />
                           </IconButton>
                        }
                     >
                        <ListItemText
                           sx={{ my: 0 }}
                           primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                           secondaryTypographyProps={{
                              whiteSpace: 'pre-line',
                              color: 'text.primary',
                              display: 'inline',
                              textAlign: 'left',
                           }}
                           primary={
                              <>
                                 <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                    {index + 1}.
                                 </Typography>
                                 <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                    {item.Text}
                                 </Typography>
                              </>
                           }
                        />
                     </ListItem>
                  ))}
            </List>
            {((userProfile && !messagesToSiteList) || (userProfile && messagesToSiteList.length < 1)) && (
               <Alert variant="outlined" severity="warning" sx={{ width: '50ch', m: 1 }}>
                  You have not sent any message to the website till now.
                  <br />
                  आपने अब तक वेबसाइट पर कोई संदेश नहीं भेजा है।
               </Alert>
            )}
            {!userProfile && (
               <Alert variant="outlined" severity="error" sx={{ width: '50ch', m: 1 }}>
                  You are not logged in.
                  <br />
                  आपने लॉग - इन नहीं किया है।
               </Alert>
            )}
         </Box>

         <Box id="boxModals">
            <Dialog
               open={openDialogNotLoggedIn}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotLoggedIn}
               aria-describedby="alert-dialog-slide-notLoggedIn"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-notLoggedIn"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     You are not logged-in.
                     <br /> <br />
                     आपने लॉग - इन नहीं किया है।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogTryAgain}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogTryAgain}
               aria-describedby="alert-dialog-slide-tryAgain"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Oops. There was some problem. ${'\n'} ओह, कुछ समस्या थी।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-tryAgain" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                     There was some problem! Please try again!
                     <br /> <br />
                     कुछ समस्या थी! कृपया पुन: प्रयास करें!
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogTryAgain}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogThisMonth}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogThisMonth}
               aria-describedby="alert-dialog-slide-thisMonth"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Limit ${'\n'} सीमा`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-tryAgain" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                     You can send one message in a month. {'\n'}{' '}
                     <span style={{ color: '#0288d1' }}>Please try next month!</span>
                     <br /> <br />
                     आप एक महीने में एक संदेश भेज सकते हैं। {'\n'}{' '}
                     <span style={{ color: '#0288d1' }}>कृपया अगले महीने प्रयास करें!</span>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogThisMonth}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogMessage}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogMessage}
               aria-describedby="alert-dialog-slide-message"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Message ${'\n'} संदेश`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-message">{selectedMessage.Text}</DialogContentText>
               </DialogContent>
               <Box>
                  <List sx={{ ml: 4 }}>
                     {selectedMessageReplies &&
                        selectedMessageReplies.length > 0 &&
                        selectedMessageReplies.map((item, index) => (
                           <ListItem key={index} sx={{ p: 0, ml: 4 }}>
                              <ListItemText
                                 sx={{ my: 0 }}
                                 primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                 secondaryTypographyProps={{
                                    whiteSpace: 'pre-line',
                                    color: 'text.primary',
                                    display: 'inline',
                                    textAlign: 'left',
                                 }}
                                 primary={
                                    <>
                                       <Typography component="span" sx={{ fontWeight: 600, mr: 2 }}>
                                          {moment(item.CrDt.toDate()).format('Do MMMM YYYY')}
                                       </Typography>
                                       <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>
                                          {item.Text}
                                       </Typography>
                                    </>
                                 }
                              />
                           </ListItem>
                        ))}
                  </List>
                  {(!selectedMessageReplies || selectedMessageReplies.length < 1) && (
                     <Alert variant="outlined" severity="warning" sx={{ width: '50ch', m: 1 }}>
                        No replies available.
                        <br />
                        कोई उत्तर उपलब्ध नहीं है।
                     </Alert>
                  )}
               </Box>
               {selectedMessage.AwaitingUserReply && !selectedMessage.Closed && (
                  <Box>
                     <Box>
                        <TextField
                           onBlur={(event) => event}
                           onChange={handleReplyText}
                           slotProps={{ htmlInput: { maxLength: 500 } }}
                           value={replyText}
                           name="Reply"
                           id="reply"
                           label="Reply"
                           multiline
                           rows={4}
                           // defaultValue="the address"
                           size="small"
                           margin="dense"
                           autoComplete="off"
                           autoFocus={true}
                           sx={{ paddingBottom: 0, backgroundColor: '#ffffff', m: 2, mb: 0, width: '50ch' }}
                           disabled={uploadingReply}
                        />
                     </Box>
                     <Box sx={{ ml: 2 }}>Length of reply: {tLengthReply} / 500</Box>
                     <Box>
                        {uploadingReply && (
                           <MyLoaderCircularGradient title={'Uploading reply ...'}></MyLoaderCircularGradient>
                        )}
                        {uploadedReply && (
                           <Alert variant="outlined" severity="success" sx={{ width: '50ch' }}>
                              <strong>Reply</strong> uploaded successfully.
                           </Alert>
                        )}
                        {uploadingFailedReply && (
                           <Alert variant="outlined" severity="error" sx={{ width: '50ch' }}>
                              Error occured! <strong>Reply</strong> could not be uploaded.
                           </Alert>
                        )}
                     </Box>
                     <Box sx={{ m: 2 }}>
                        <Button
                           type="submit"
                           align="center"
                           variant="contained"
                           color="primary"
                           size="small"
                           onClick={(e) => {
                              handleSubmitReply(e)
                           }}
                           disabled={replyText < 10 || uploadingReply}
                        >
                           Submit
                        </Button>
                     </Box>
                  </Box>
               )}
               {selectedMessage.Closed && (
                  <Alert variant="outlined" severity="warning" sx={{ width: '50ch', m: 1 }}>
                     The issue has been closed.
                     <br />
                     यह मुद्दा बंद कर दिया गया है।
                  </Alert>
               )}
               {selectedMessage.AwaitingSiteReply && !selectedMessage.Closed && (
                  <Alert variant="outlined" severity="warning" sx={{ width: '50ch', m: 1 }}>
                     The reply from admin is awaited.
                     <br />
                     एडमिन से जवाब की प्रतीक्षा है।
                  </Alert>
               )}
               <DialogActions>
                  <Button onClick={handleCloseDialogMessage}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

export default Contact
