import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle, Box } from '@mui/material'
import { Helmet } from 'react-helmet'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function ElecStarCampaigners({ props }) {
   const elec = props
   // console.log('value from props inside star campaigner page: ', elec)
   return (
      <Box sx={{ px: 1 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Star campaigners of ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               color: '#ffffff',
            }}
         >
            <Box>Star campaigners:</Box>
            <Box>स्टार प्रचारक:</Box>
         </Box>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box>
               <Box>
                  <Box>
                     <Alert variant="outlined" severity="success" sx={{ mx: 10, mt: 5 }}>
                        List will be available soon.
                        <br />
                        <br />
                        सूची जल्द ही उपलब्ध होगी|
                     </Alert>
                  </Box>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecStarCampaigners.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecStarCampaigners
