import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Button,
   Collapse,
   Divider,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import StarBorder from '@mui/icons-material/StarBorder'
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

function CustomizedListItemForManifesto({
   item,
   socialInfraList,
   socialBenefitsList,
   industryInfraList,
   industryBenefitsList,
   transportationInfraList,
   transportationBenefitsList,
   educationInfraList,
   educationBenefitsList,
   healthInfraList,
   healthBenefitsList,
   environmentInfraList,
   environmentBenefitsList,
   corruptionAllegationsList,
   corruptionTacklingList,
   governanceEthicsList,
   judiciaryList,
   civicFaciitiesList,
   lawAndOrderList,
   sportsInfraList,
   sportsBenefitsList,
   businessList,
   agricultureInfraList,
   agricultureBenefitsList,
   housingList,
   employmentList,
   defenceList,
   ruralDevelopmentList,
   cultureAndHeritageList,
   tourismList,
}) {
   const [open, setOpen] = useState(false)

   const handleClick = () => {
      setOpen((prevState) => !prevState)
   }
   return (
      <div>
         <ListItemButton key={item.Id} onClick={() => handleClick()}>
            <ListItemIcon>
               <ViewHeadlineIcon />
            </ListItemIcon>
            <ListItemText
               primaryTypographyProps={{ fontWeight: 600, whiteSpace: 'pre-line' }}
               primary={
                  <>
                     {item.Name} {'\n'} <span style={{ color: '#666666' }}>{item.NameHindi}</span>
                     {((item.Id === 'Social-Welfare-Infrastructure' && socialInfraList && socialInfraList.length > 0) ||
                        (item.Id === 'Social-Welfare-Benefits' &&
                           socialBenefitsList &&
                           socialBenefitsList.length > 0) ||
                        (item.Id === 'Industry-Infrastructure' && industryInfraList && industryInfraList.length > 0) ||
                        (item.Id === 'Industry-Benefits' && industryBenefitsList && industryBenefitsList.length > 0) ||
                        (item.Id === 'Transportation-Infrastructure' &&
                           transportationInfraList &&
                           transportationInfraList.length > 0) ||
                        (item.Id === 'Transportation-Benefits' &&
                           transportationBenefitsList &&
                           transportationBenefitsList.length > 0) ||
                        (item.Id === 'Education-Infrastructure' &&
                           educationInfraList &&
                           educationInfraList.length > 0) ||
                        (item.Id === 'Education-Benefits' &&
                           educationBenefitsList &&
                           educationBenefitsList.length > 0) ||
                        (item.Id === 'Health-Infrastructure' && healthInfraList && healthInfraList.length > 0) ||
                        (item.Id === 'Health-Benefits' && healthBenefitsList && healthBenefitsList.length > 0) ||
                        (item.Id === 'Environment-Infrastructure' &&
                           environmentInfraList &&
                           environmentInfraList.length > 0) ||
                        (item.Id === 'Environment-Benefits' &&
                           environmentBenefitsList &&
                           environmentBenefitsList.length > 0) ||
                        (item.Id === 'Corruption-Allegations' &&
                           corruptionAllegationsList &&
                           corruptionAllegationsList.length > 0) ||
                        (item.Id === 'Corruption-Tackling' &&
                           corruptionTacklingList &&
                           corruptionTacklingList.length > 0) ||
                        (item.Id === 'Governance-Ethics' && governanceEthicsList && governanceEthicsList.length > 0) ||
                        (item.Id === 'Judiciary' && judiciaryList && judiciaryList.length > 0) ||
                        (item.Id === 'Civic-Facilities' && civicFaciitiesList && civicFaciitiesList.length > 0) ||
                        (item.Id === 'Law-Order' && lawAndOrderList && lawAndOrderList.length > 0) ||
                        (item.Id === 'Sports-Infrastructure' && sportsInfraList && sportsInfraList.length > 0) ||
                        (item.Id === 'Sports-Benefits' && sportsBenefitsList && sportsBenefitsList.length > 0) ||
                        (item.Id === 'Business' && businessList && businessList.length > 0) ||
                        (item.Id === 'Agriculture-Infrastructure' &&
                           agricultureInfraList &&
                           agricultureInfraList.length > 0) ||
                        (item.Id === 'Agriculture-Benefits' &&
                           agricultureBenefitsList &&
                           agricultureBenefitsList.length > 0) ||
                        (item.Id === 'Culture-And-Heritage' &&
                           agricultureBenefitsList &&
                           agricultureBenefitsList.length > 0) ||
                        (item.Id === 'Travel-And-Tourism' && tourismList && tourismList.length > 0) ||
                        (item.Id === 'Employment' && employmentList && employmentList.length > 0) ||
                        (item.Id === 'Housing' && housingList && housingList.length > 0) ||
                        (item.Id === 'Rural-Development' &&
                           ruralDevelopmentList &&
                           ruralDevelopmentList.length > 0)) && <CheckCircleIcon sx={{ ml: 5, color: '#4caf50' }} />}
                  </>
               }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
         <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
               {item.Id === 'Social-Welfare-Infrastructure' &&
                  socialInfraList &&
                  socialInfraList.length > 0 &&
                  socialInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}
               {item.Id === 'Social-Welfare-Infrastructure' && (!socialInfraList || socialInfraList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Social-Welfare-Benefits' &&
                  socialBenefitsList &&
                  socialBenefitsList.length > 0 &&
                  socialBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Social-Welfare-Benefits' && (!socialBenefitsList || socialBenefitsList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Industry-Infrastructure' &&
                  industryInfraList &&
                  industryInfraList.length > 0 &&
                  industryInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Industry-Infrastructure' && (!industryInfraList || industryInfraList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Industry-Benefits' &&
                  industryBenefitsList &&
                  industryBenefitsList.length > 0 &&
                  industryBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Industry-Benefits' && (!industryBenefitsList || industryBenefitsList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Transportation-Infrastructure' &&
                  transportationInfraList &&
                  transportationInfraList.length > 0 &&
                  transportationInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Transportation-Infrastructure' &&
                  (!transportationInfraList || transportationInfraList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}
               {item.Id === 'Transportation-Benefits' &&
                  transportationBenefitsList &&
                  transportationBenefitsList.length > 0 &&
                  transportationBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Transportation-Benefits' &&
                  (!transportationBenefitsList || transportationBenefitsList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}
               {item.Id === 'Education-Infrastructure' &&
                  educationInfraList &&
                  educationInfraList.length > 0 &&
                  educationInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Education-Infrastructure' && (!educationInfraList || educationInfraList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Education-Benefits' &&
                  educationBenefitsList &&
                  educationBenefitsList.length > 0 &&
                  educationBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Education-Benefits' && (!educationBenefitsList || educationBenefitsList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Health-Infrastructure' &&
                  healthInfraList &&
                  healthInfraList.length > 0 &&
                  healthInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Health-Infrastructure' && (!healthInfraList || healthInfraList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Health-Benefits' &&
                  healthBenefitsList &&
                  healthBenefitsList.length > 0 &&
                  healthBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Health-Benefits' && (!healthBenefitsList || healthBenefitsList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Environment-Infrastructure' &&
                  environmentInfraList &&
                  environmentInfraList.length > 0 &&
                  environmentInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Environment-Infrastructure' &&
                  (!environmentInfraList || environmentInfraList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}

               {item.Id === 'Environment-Benefits' &&
                  environmentBenefitsList &&
                  environmentBenefitsList.length > 0 &&
                  environmentBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Environment-Benefits' &&
                  (!environmentBenefitsList || environmentBenefitsList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}

               {item.Id === 'Corruption-Allegations' &&
                  corruptionAllegationsList &&
                  corruptionAllegationsList.length > 0 &&
                  corruptionAllegationsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Corruption-Allegations' &&
                  (!corruptionAllegationsList || corruptionAllegationsList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}

               {item.Id === 'Corruption-Tackling' &&
                  corruptionTacklingList &&
                  corruptionTacklingList.length > 0 &&
                  corruptionTacklingList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Corruption-Tackling' && (!corruptionTacklingList || corruptionTacklingList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
               {item.Id === 'Governance-Ethics' &&
                  governanceEthicsList &&
                  governanceEthicsList.length > 0 &&
                  governanceEthicsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Governance-Ethics' && (!governanceEthicsList || governanceEthicsList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Judiciary' &&
                  judiciaryList &&
                  judiciaryList.length > 0 &&
                  judiciaryList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Judiciary' && (!judiciaryList || judiciaryList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Civic-Facilities' &&
                  civicFaciitiesList &&
                  civicFaciitiesList.length > 0 &&
                  civicFaciitiesList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Civic-Facilities' && (!civicFaciitiesList || civicFaciitiesList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Law-Order' &&
                  lawAndOrderList &&
                  lawAndOrderList.length > 0 &&
                  lawAndOrderList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Law-Order' && (!lawAndOrderList || lawAndOrderList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Sports-Infrastructure' &&
                  sportsInfraList &&
                  sportsInfraList.length > 0 &&
                  sportsInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Sports-Infrastructure' && (!sportsInfraList || sportsInfraList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Sports-Benefits' &&
                  sportsBenefitsList &&
                  sportsBenefitsList.length > 0 &&
                  sportsBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Sports-Benefits' && (!sportsBenefitsList || sportsBenefitsList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Business' &&
                  businessList &&
                  businessList.length > 0 &&
                  businessList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Business' && (!businessList || businessList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Agriculture-Infrastructure' &&
                  agricultureInfraList &&
                  agricultureInfraList.length > 0 &&
                  agricultureInfraList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}
               {item.Id === 'Agriculture-Infrastructure' &&
                  (!agricultureInfraList || agricultureInfraList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}

               {item.Id === 'Agriculture-Benefits' &&
                  agricultureBenefitsList &&
                  agricultureBenefitsList.length > 0 &&
                  agricultureBenefitsList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}
               {item.Id === 'Agriculture-Benefits' &&
                  (!agricultureBenefitsList || agricultureBenefitsList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}

               {item.Id === 'Housing' &&
                  housingList &&
                  housingList.length > 0 &&
                  housingList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Housing' && (!housingList || housingList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Employment' &&
                  employmentList &&
                  employmentList.length > 0 &&
                  employmentList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Employment' && (!employmentList || employmentList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'National-Security-And-Defence' &&
                  defenceList &&
                  defenceList.length > 0 &&
                  defenceList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'National-Security-And-Defence' && (!defenceList || defenceList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Rural-Development' &&
                  ruralDevelopmentList &&
                  ruralDevelopmentList.length > 0 &&
                  ruralDevelopmentList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Rural-Development' && (!ruralDevelopmentList || ruralDevelopmentList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}

               {item.Id === 'Culture-And-Heritage' &&
                  cultureAndHeritageList &&
                  cultureAndHeritageList.length > 0 &&
                  cultureAndHeritageList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Culture-And-Heritage' &&
                  (!cultureAndHeritageList || cultureAndHeritageList.length < 1) && (
                     <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                     >
                        <AlertTitle>
                           No article to show! {'\n'}
                           दिखाने के लिए कोई लेख नहीं!
                        </AlertTitle>
                     </Alert>
                  )}

               {item.Id === 'Travel-And-Tourism' &&
                  tourismList &&
                  tourismList.length > 0 &&
                  tourismList.map((subItem, index) => (
                     <ListItem sx={{ pl: 4 }} key={index}>
                        <ListItemIcon>
                           <StarBorder />
                        </ListItemIcon>
                        <ListItemText
                           secondaryTypographyProps={{ align: 'left' }}
                           primary={subItem.Article}
                           secondary={
                              <>
                                 {subItem.ArticleHindi} <br />{' '}
                                 <Button
                                    component="span"
                                    sx={{
                                       padding: 0,
                                       minWidth: 0,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       borderRadius: 1,
                                       px: 2,
                                       cursor: 'none',
                                       '&:hover': {
                                          backgroundColor: '#01579b',
                                       },
                                    }}
                                 >
                                    For:
                                 </Button>
                                 {subItem.PublicCats &&
                                    subItem.PublicCats.length > 0 &&
                                    subItem.PublicCats.map((forItem, index) => (
                                       <span key={index} style={{ color: '#01579b', fontSize: '16px' }}>
                                          {' '}
                                          <span> {forItem.Name} </span>
                                          <span> {forItem.NameHindi} </span>
                                          <span style={{ color: '#e65100', fontSize: '16px' }}> || </span>{' '}
                                       </span>
                                    ))}
                              </>
                           }
                        />
                     </ListItem>
                  ))}

               {item.Id === 'Travel-And-Tourism' && (!tourismList || tourismList.length < 1) && (
                  <Alert
                     variant="outlined"
                     severity="error"
                     sx={{ mx: 5, my: 1, justifyContent: 'center', alignItems: 'center', whiteSpace: 'pre-line' }}
                  >
                     <AlertTitle>
                        No article to show! {'\n'}
                        दिखाने के लिए कोई लेख नहीं!
                     </AlertTitle>
                  </Alert>
               )}
            </List>
         </Collapse>
         <Divider />
      </div>
   )
}

CustomizedListItemForManifesto.propTypes = {
   item: PropTypes.object.isRequired,
   socialInfraList: PropTypes.array.isRequired,
   socialBenefitsList: PropTypes.array.isRequired,
   industryInfraList: PropTypes.array.isRequired,
   industryBenefitsList: PropTypes.array.isRequired,
   transportationInfraList: PropTypes.array.isRequired,
   transportationBenefitsList: PropTypes.array.isRequired,
   educationInfraList: PropTypes.array.isRequired,
   educationBenefitsList: PropTypes.array.isRequired,
   healthInfraList: PropTypes.array.isRequired,
   healthBenefitsList: PropTypes.array.isRequired,
   environmentInfraList: PropTypes.array.isRequired,
   environmentBenefitsList: PropTypes.array.isRequired,
   corruptionAllegationsList: PropTypes.array.isRequired,
   corruptionTacklingList: PropTypes.array.isRequired,
   governanceEthicsList: PropTypes.array.isRequired,
   judiciaryList: PropTypes.array.isRequired,
   civicFaciitiesList: PropTypes.array.isRequired,
   sportsInfraList: PropTypes.array.isRequired,
   sportsBenefitsList: PropTypes.array.isRequired,
   lawAndOrderList: PropTypes.array.isRequired,
   businessList: PropTypes.array.isRequired,
   agricultureInfraList: PropTypes.array.isRequired,
   agricultureBenefitsList: PropTypes.array.isRequired,
   housingList: PropTypes.array.isRequired,
   employmentList: PropTypes.array.isRequired,
   defenceList: PropTypes.array.isRequired,
   ruralDevelopmentList: PropTypes.array.isRequired,

   cultureAndHeritageList: PropTypes.array.isRequired,
   tourismList: PropTypes.array.isRequired,
}

export default CustomizedListItemForManifesto
