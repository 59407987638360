import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import moment from 'moment'

function PetitionSupports({ petition, allSupports }) {
   //
   console.log('allSupports: ', allSupports)
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Petition - ${petition.Title}`}</title>
            </Helmet>
         </div>
         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               Supports and Comments: {'\n'} समर्थन और टिप्पणियाँ:
            </Typography>
         </Box>
         <Box sx={{ mb: 0.5 }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
               {allSupports &&
                  allSupports.length > 0 &&
                  allSupports.map((item, index) => (
                     <div key={index}>
                        <ListItem alignItems="flex-start">
                           <ListItemAvatar>
                              <Avatar alt="Remy Sharp" src={item.CrByImg} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ textAlign: 'left' }}
                              primary={item.CrByName}
                              secondary={
                                 <>
                                    <>
                                       {item.CrDt && moment(item.CrDt.toDate()).format('Do MMM YYYY HH:mm:ss')} {': '}{' '}
                                       {item.CrType === 'Support' && (
                                          <span style={{ color: 'Green', fontWeight: 600 }}>supported</span>
                                       )}
                                       {item.CrType === 'Oppose' && (
                                          <span style={{ color: 'Red', fontWeight: 600 }}>opposed</span>
                                       )}
                                       <br />
                                    </>
                                    {item.ModDt && (
                                       <>
                                          {item.ModDt && moment(item.ModDt.toDate()).format('Do MMM YYYY HH:mm:ss')}{' '}
                                          {': '}{' '}
                                          {item.ModType === 'Support' && (
                                             <span style={{ color: 'Green', fontWeight: 600 }}>supported</span>
                                          )}
                                          {item.ModType === 'Oppose' && (
                                             <span style={{ color: 'Red', fontWeight: 600 }}>opposed</span>
                                          )}
                                          <br />
                                       </>
                                    )}
                                    {item.WDt && (
                                       <>
                                          {item.WDt && moment(item.WDt.toDate()).format('Do MMM YYYY HH:mm:ss')} {': '}{' '}
                                          <span style={{ color: 'Blue', fontWeight: 600 }}>withdrew</span>
                                          <br />
                                       </>
                                    )}
                                    {item.Comment && item.Comment.length > 0 && (
                                       <List dense>
                                          {item.Comment.map((itemX) => (
                                             <div key={itemX}>
                                                <ListItem sx={{ my: 0, py: 0 }}>
                                                   <ListItemText
                                                      sx={{ my: 0 }}
                                                      primary={
                                                         <>
                                                            {itemX.Dt &&
                                                               moment(itemX.Dt.toDate()).format(
                                                                  'Do MMM YYYY HH:mm:ss',
                                                               )}{' '}
                                                            {': '}{' '}
                                                            <span style={{ color: '#000000' }}>{itemX.Text}</span>
                                                            <Divider />
                                                         </>
                                                      }
                                                   />
                                                </ListItem>
                                             </div>
                                          ))}
                                       </List>
                                    )}
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                     </div>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

PetitionSupports.propTypes = {
   petition: PropTypes.object.isRequired,
   allSupports: PropTypes.array.isRequired,
}

export default PetitionSupports
