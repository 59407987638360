import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { v4 } from 'uuid'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   FormControl,
   FormControlLabel,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Radio,
   RadioGroup,
   TextField,
   Typography,
} from '@mui/material'
import moment from 'moment'

import { styleModalBox, styleModalTypography, Transition } from '../../../Utils/MUITheme/MUITheme'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { addDoc, arrayUnion, collection, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore'
import RaisePetition from '../../../Components/Petitions/RaisePetition'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import AddIcon from '@mui/icons-material/Add'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function LegisMemInteract({
   legislature,
   member,
   attachedToConsti,
   petitionCategoriesList,
   petitionsList,
   handlePetitionsListUpdate,
}) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [thisUser, setThisUser] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')
   const [nameOfUserHindi, setNameOfUserHindi] = useState('')
   const [nameOfUserLocal, setNameOfUserLocal] = useState('')

   const userProfile = useSelector(selectUserProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.NameFirst + ' ' + userProfile.NameLast
         const nameHindi = userProfile.NameHindiFirst + ' ' + ' ' + userProfile.NameHindiLast
         const nameLocal = userProfile.NameLocalFirst + ' ' + userProfile.NameLocalLast
         setNameOfUser(name)
         setNameOfUserHindi(nameHindi)
         setNameOfUserLocal(nameLocal)
      }
   }, [userProfile])

   useEffect(() => {
      console.log('ministry - first use effect entered')

      if (user.currentUser !== null) {
         console.log('ministry - found user state not-null')
         setThisUser(user.currentUser.id)
      } else {
         console.log('ministry - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const today = new Date()
   // const quarter = Math.floor((today.getMonth() + 3) / 3)
   const month = String(today.getMonth() + 1).padStart(2, '0')
   const year = today.getFullYear()
   const docIdPetitions = 'Petitions' + year + '-' + month

   const [sortField, setSortField] = useState('Support')
   function handleSortField(e) {
      e.preventDefault()
      setSortField(e.target.value)
   }

   useEffect(() => {
      if (sortField) {
         //
      }
   }, [sortField])

   const [uploadingPetition, setUploadingPetition] = useState(false)
   const [uploadedPetition, setUploadedPetition] = useState(false)
   const [uploadingFailedPetition, setUploadingFailedPetition] = useState(false)

   const [openModalRaisePetition, setOpenModalRaisePetition] = useState(false)

   const handleCloseModalRaisePetition = () => setOpenModalRaisePetition(false)

   const handleOpenModalRaisePetition = async (e) => {
      e.preventDefault()

      if (!member.ToDate) {
         console.log('1. ')
         if (auth.currentUser && thisUser === auth.currentUser.uid) {
            if (attachedToConsti) {
               const thisuserPetitions = petitionsList.filter((item) => item.CrBy === auth.currentUser.uid)
               console.log('2. ', thisuserPetitions)
               //
               setUploadedPetition(false)
               setUploadingFailedPetition(false)

               if (thisuserPetitions && thisuserPetitions.length > 1) {
                  // get the top one
                  const sortedList = thisuserPetitions.sort((a, b) => {
                     // bigger date first
                     if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                        return -1
                     } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('3. ', sortedList)
                  const top = sortedList[0]

                  if (top.CrDt === '' || moment(top.CrDt.toDate()).isSame(new Date(), 'month')) {
                     handleOpenDialogThisMonth()
                  } else {
                     setOpenModalRaisePetition(true)
                  }
               } else if (thisuserPetitions && thisuserPetitions.length === 1) {
                  const top = thisuserPetitions[0]
                  if (top.CrDt === '' || moment(top.CrDt.toDate()).isSame(new Date(), 'month')) {
                     handleOpenDialogThisMonth()
                  } else {
                     setOpenModalRaisePetition(true)
                  }
               } else if (thisuserPetitions && thisuserPetitions.length === 0) {
                  //
                  setOpenModalRaisePetition(true)
               }
            } else {
               handleOpenDialogNotAttached()
            }
         } else {
            handleOpenDialogNotLoggedIn()
         }
      } else {
         handleOpenDialogNotAvtive()
      }
   }

   const handleRaisePetition = async (petitionDetails, selectedImage1, selectedImage2) => {
      if (attachedToConsti) {
         if (auth.currentUser && thisUser === auth.currentUser.uid) {
            if (attachedToConsti && petitionDetails.Paragraphs.length > 0 && petitionDetails.Title !== '') {
               try {
                  //
                  setUploadingPetition(true)
                  setUploadedPetition(false)
                  setUploadingFailedPetition(false)

                  petitionDetails.CrBy = auth.currentUser.uid
                  petitionDetails.CrByName = nameOfUser
                  petitionDetails.CrByNameHindi = nameOfUserHindi
                  petitionDetails.CrByNameLocal = nameOfUserLocal
                  petitionDetails.CrByImage = userProfile.Image.Image
                  petitionDetails.CrDt = new Date()
                  petitionDetails.CrIP = ip.data.ip

                  petitionDetails.AddressedTo = 'LegisMember'

                  petitionDetails.Strata = [legislature.Strata]
                  petitionDetails.Country_id = userProfile.Location.Country_id ? userProfile.Location.Country_id : null
                  petitionDetails.CountryName = userProfile.Location.CountryName
                     ? userProfile.Location.CountryName
                     : null
                  petitionDetails.State_id = userProfile.Location.State_id ? userProfile.Location.State_id : null
                  petitionDetails.StateName = userProfile.Location.StateName ? userProfile.Location.StateName : null
                  petitionDetails.Division_id = userProfile.Location.Division_id
                     ? userProfile.Location.Division_id
                     : null
                  petitionDetails.DivisionName = userProfile.Location.DivisionName
                     ? userProfile.Location.DivisionName
                     : null
                  petitionDetails.District_id = userProfile.Location.District_id
                     ? userProfile.Location.District_id
                     : null
                  petitionDetails.DistrictName = userProfile.Location.DistrictName
                     ? userProfile.Location.DistrictName
                     : null
                  petitionDetails.SubDistrict_id = userProfile.Location.SubDistrict_id
                     ? userProfile.Location.SubDistrict_id
                     : null
                  petitionDetails.SubDistrictName = userProfile.Location.SubDistrictName
                     ? userProfile.Location.SubDistrictName
                     : null
                  petitionDetails.Block_id = userProfile.Location.Block_id ? userProfile.Location.Block_id : null
                  petitionDetails.BlockName = userProfile.Location.BlockName ? userProfile.Location.BlockName : null
                  petitionDetails.Village_id = userProfile.Location.Village_id ? userProfile.Location.Village_id : null
                  petitionDetails.VillageName = userProfile.Location.VillageName
                     ? userProfile.Location.VillageName
                     : null

                  petitionDetails.LegisMem_id = member.id
                  petitionDetails.LegisMemName = member.Name
                  petitionDetails.LegisMemNameHindi = member.NameHindi
                  petitionDetails.LegisMemNameLocal = member.NameLocal
                  petitionDetails.LegisMemImage = member.Image

                  petitionDetails.Party_id = member.Party.Party_id
                  petitionDetails.PartyName = member.Party.PartyName
                  petitionDetails.PartyNameHindi = member.Party.PartyNameHindi
                  petitionDetails.PartyNameLocal = member.Party.PartyNameLocal
                  petitionDetails.PartyFlag = member.Party.PartyFlag
                  petitionDetails.PartySymbol = member.Party.PartySymbol

                  const record = {
                     Petition_id: '',
                     Category: petitionDetails.Category,
                     Title: petitionDetails.Title,
                     TitleHindi: petitionDetails.TitleHindi,
                     TitleLocal: petitionDetails.TitleLocal,
                     Image1: '',

                     AddressedTo: 'LegisMember',

                     SupportCount_Total: 0,
                     SignCount_Total: 0,
                     SupportCountDt: '',

                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                  }

                  console.log('record: ', record)

                  // i. coreListId for user profile
                  const docRefUserP = doc(db, 'UserProfiles', userProfile.id)
                  const docSnapUserP = await getDoc(docRefUserP)
                  const petitionsCountUserP = docSnapUserP.data().PetitionsCount
                     ? docSnapUserP.data().PetitionsCount
                     : 0
                  const slabUserP = 2000
                  const quotientUserP = Math.floor(petitionsCountUserP / slabUserP)
                  // const remainder = candidatesCount % slab
                  const numUserP = quotientUserP + 1
                  const numStrUserP = String(numUserP).padStart(9, '0')
                  petitionDetails.CoreListId_AtUserP = { SubColl_id: 'Petitions', CoreList_id: numStrUserP }
                  console.log('coreListId UserP: ', numStrUserP)

                  // ii. coreListId for constituency
                  const docRefConstiPCount = doc(
                     db,
                     'LegisConstituencies',
                     member.Constituency_id,
                     docIdPetitions,
                     'Count',
                  )
                  const docSnapConstiPCount = await getDoc(docRefConstiPCount)
                  const petitionsCountConsti = docSnapConstiPCount.data().Count ? docSnapConstiPCount.data().Count : 0
                  const slabConsti = 2000
                  const quotientConsti = Math.floor(petitionsCountConsti / slabConsti)
                  const numConsti = quotientConsti + 1
                  const numStrConsti = String(numConsti).padStart(9, '0')
                  petitionDetails.CoreListId_AtConsti = petitionDetails.CoreListId_AtConsti
                     ? [
                          ...petitionDetails.CoreListId_AtConsti,
                          { SubColl_id: docIdPetitions, CoreList_id: numStrConsti },
                       ]
                     : [{ SubColl_id: docIdPetitions, CoreList_id: numStrConsti }]
                  console.log('coreListId Consti: ', numStrConsti)

                  // iii. coreListId for legislature
                  const docRefLegisPCount = doc(db, 'Legislatures', member.Legisature_id, docIdPetitions, 'Count')
                  const docSnapLegisPCount = await getDoc(docRefLegisPCount)
                  const petitionsCountLegis = docSnapLegisPCount.data().Count ? docSnapLegisPCount.data().Count : 0
                  const slabLegis = 2000
                  const quotientLegis = Math.floor(petitionsCountLegis / slabLegis)
                  const numLegis = quotientLegis + 1
                  const numStrLegis = String(numLegis).padStart(9, '0')

                  petitionDetails.CoreListId_AtLegis = petitionDetails.CoreListId_AtLegis
                     ? [...petitionDetails.CoreListId_AtLegis, { SubColl_id: docIdPetitions, CoreList_id: numStrLegis }]
                     : [{ SubColl_id: docIdPetitions, CoreList_id: numStrLegis }]
                  console.log('coreListId Legis: ', numStrLegis)

                  //

                  let newRecord = {}

                  // 1. Create the main petition doc
                  const docRef = await addDoc(collection(db, 'Petitions'), petitionDetails)
                  record.Petition_id = docRef.id
                  console.log('record after main doc creation: ', record)

                  const docRefNew = doc(db, 'Petitions', docRef.id)
                  await updateDoc(docRefNew, {
                     OriginalPetition_id: docRef.id,
                  })

                  // 2. Insert images

                  if (selectedImage1 !== '' || selectedImage2 !== '') {
                     if (selectedImage1) {
                        const imageRef = ref(dbStorage, `Petitions/${docRef.id}/${selectedImage1.name + v4()}`)
                        await uploadBytes(imageRef, selectedImage1).then((snapshot) => {
                           getDownloadURL(snapshot.ref).then(async (url) => {
                              // 2A. update the main doc of Petition
                              await updateDoc(docRefNew, {
                                 Image1: url,
                              })

                              newRecord = {
                                 ...record,
                                 Image1: url,
                              }
                           })
                        })
                     }

                     if (selectedImage2) {
                        const imageRef = ref(dbStorage, `Petitions/${docRef.id}/${selectedImage2.name + v4()}`)
                        await uploadBytes(imageRef, selectedImage2).then((snapshot) => {
                           getDownloadURL(snapshot.ref).then(async (url) => {
                              // 2A. update the main doc of Petition

                              if (!selectedImage1) {
                                 await updateDoc(docRefNew, {
                                    Image1: url,
                                 })
                                 newRecord = {
                                    ...record,
                                    Image1: url,
                                 }
                              } else {
                                 await updateDoc(docRefNew, {
                                    Image2: url,
                                 })
                              }
                           })
                        })
                     }
                  } else {
                     newRecord = {
                        ...record,
                     }
                     console.log('newRecord on create', newRecord)
                  }

                  // 3. Update LegisMem Profile

                  console.log('newRecord', record)

                  const docRefLegisMemSubCollCount = doc(db, 'LegisMembers', member.id, docIdPetitions, 'Count')
                  const docSnapLegisMemSubCollCount = await getDoc(docRefLegisMemSubCollCount)
                  const petitionsCountLegisMem = docSnapLegisMemSubCollCount.data().Count
                     ? docSnapLegisMemSubCollCount.data().Count
                     : 0
                  const slabLegisMem = 2000
                  const quotientLegisMem = Math.floor(petitionsCountLegisMem / slabLegisMem)
                  const numLegisMem = quotientLegisMem + 1
                  const numStrLegisMem = String(numLegisMem).padStart(9, '0')

                  petitionDetails.CoreListId_AtLegisMem = { SubColl_id: docIdPetitions, CoreList_id: numStrLegisMem }

                  console.log('coreListId Legis Mem: ', numStrLegisMem)

                  if (docSnapLegisMemSubCollCount.data().Count) {
                     await updateDoc(docRefLegisMemSubCollCount, {
                        Count: increment(1),
                     })
                  } else {
                     setDoc(docRefLegisMemSubCollCount, { Count: 1, Type: 'Count' }, { merge: true })
                  }

                  const docRefLegisMemSubCollPetitions = doc(
                     db,
                     'LegisMembers',
                     member.id,
                     docIdPetitions,
                     numStrLegisMem,
                  )
                  const docSnapLegisMemSubCollPetitions = await getDoc(docRefLegisMemSubCollPetitions)
                  console.log('Legis Mem update start: ')
                  if (docSnapLegisMemSubCollPetitions.exists()) {
                     await updateDoc(docRefLegisMemSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(
                        docRefLegisMemSubCollPetitions,
                        { Petitions: [record], Type: 'PetitionsCoreList' },
                        { merge: true },
                     )
                  }

                  console.log('Legis Mem update end')

                  // 4. Update the User
                  console.log('newRecordForUser', newRecord)
                  const docRefUser = doc(db, 'UserProfiles', userProfile.id)
                  const docSnapUser = await getDoc(docRefUser)

                  if (docSnapUser.data().PetitionsCount) {
                     await updateDoc(docRefUser, {
                        PetitionsCount: increment(1),
                     })
                  } else {
                     await updateDoc(docRefUser, {
                        PetitionsCount: 1,
                     })
                  }

                  const docRefUserSubCollPetitions = doc(db, 'UserProfiles', userProfile.id, 'Petitions', numStrUserP)
                  const docSnapUserSubCollPetitions = await getDoc(docRefUserSubCollPetitions)
                  console.log('user update start: ')
                  if (docSnapUserSubCollPetitions.exists()) {
                     await updateDoc(docRefUserSubCollPetitions, {
                        Petitions: arrayUnion(newRecord),
                     })
                  } else {
                     setDoc(docRefUserSubCollPetitions, { Petitions: [newRecord] }, { merge: true })
                  }

                  // 5. Update the Constituency

                  console.log('newRecord', newRecord)

                  if (docSnapConstiPCount.data().Count) {
                     await updateDoc(docRefConstiPCount, {
                        Count: increment(1),
                     })
                  } else {
                     setDoc(docRefConstiPCount, { Count: 1, Type: 'Count' }, { merge: true })
                  }

                  const docRefConstiSubCollPetitions = doc(
                     db,
                     'LegisConstituencies',
                     member.Constituency_id,
                     docIdPetitions,
                     numStrConsti,
                  )
                  const docSnapConstiSubCollPetitions = await getDoc(docRefConstiSubCollPetitions)
                  console.log('Consti update start: ')
                  if (docSnapConstiSubCollPetitions.exists()) {
                     await updateDoc(docRefConstiSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(
                        docRefConstiSubCollPetitions,
                        { Petitions: [record], Type: 'PetitionsCoreList' },
                        { merge: true },
                     )
                  }

                  console.log('Consti update end')

                  // 6. Update the Legislature
                  console.log('newRecord', newRecord)

                  if (docSnapLegisPCount.data().Count) {
                     await updateDoc(docRefLegisPCount, {
                        Count: increment(1),
                     })
                  } else {
                     setDoc(docRefLegisPCount, { Count: 1, Type: 'Count' }, { merge: true })
                  }

                  const docRefLegisSubCollPetitions = doc(
                     db,
                     'Legislatures',
                     member.Legislature_id,
                     docIdPetitions,
                     numStrLegis,
                  )
                  const docSnapLegisSubCollPetitions = await getDoc(docRefLegisSubCollPetitions)
                  console.log('Legis update start: ')
                  if (docSnapLegisSubCollPetitions.exists()) {
                     await updateDoc(docRefLegisSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(
                        docRefLegisSubCollPetitions,
                        { Petitions: [record], Type: 'PetitionsCoreList' },
                        { merge: true },
                     )
                  }

                  console.log('Legis update end')

                  // 7. update local state
                  const docRefPetition = doc(db, 'Petitions', docRef.id)
                  const docSnapPetition = await getDoc(docRefPetition)
                  const local = {
                     Petition_id: docRef.id,
                     OriginalPetition_id: docSnapPetition.data().OriginalPetition_id,
                     Category: docSnapPetition.data().Category,
                     Title: docSnapPetition.data().Title,
                     TitleHindi: docSnapPetition.data().TitleHindi,
                     TitleLocal: docSnapPetition.data().TitleLocal,
                     Image1: docSnapPetition.data().Image1,
                     Image2: docSnapPetition.data().Image2,

                     AddressedTo: 'LegisMember',

                     SupportCount_Total: 0,
                     SignCount_Total: 0,
                     SupportCountDt: '',

                     CrBy: docSnapPetition.data().CrBy,
                     CrByName: docSnapPetition.data().CrByName,
                     CrByImage: docSnapPetition.data().CrByImage,
                     CrDt: docSnapPetition.data().CrDt,
                  }
                  handlePetitionsListUpdate(local)

                  console.log('user update end')

                  setUploadingPetition(false)
                  setUploadedPetition(true)
                  setUploadingFailedPetition(false)

                  setOpenModalRaisePetition(false)
               } catch (error) {
                  alert('Error adding petition')
                  console.log('Error adding petition', error)

                  setUploadingPetition(false)
                  setUploadedPetition(false)
                  setUploadingFailedPetition(true)

                  handleOpenDialogTryAgain()
               }
            } else {
               navigate('/', { replace: true })
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // attach existing petition

   const handleOpenModalAttachPetition = async (e) => {
      e.preventDefault()
      //

      if (member.IsActive && !member.IsBlocked && !member.IsSuspended && !member.IsSubscriptionExpired) {
         console.log('1. ')
         if (auth.currentUser && thisUser === auth.currentUser.uid) {
            if (attachedToConsti) {
               const thisuserPetitions = petitionsList.filter((item) => item.CrBy === auth.currentUser.uid)
               console.log('2. ', thisuserPetitions)
               //
               setUploadedPetition(false)
               setUploadingFailedPetition(false)
               setPetitionIdForAttachment('')
               setPetitionForAttachment('')

               if (thisuserPetitions && thisuserPetitions.length > 1) {
                  // get the top one
                  console.log('3A. ', thisuserPetitions)
                  const sortedList = thisuserPetitions.sort((a, b) => {
                     // bigger date first
                     if (moment(a.CrDt.toDate()).isAfter(moment(b.CrDt.toDate()))) {
                        return -1
                     } else if (moment(a.CrDt.toDate()).isBefore(moment(b.CrDt.toDate()))) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('3B. ', sortedList)
                  const top = sortedList[0]

                  if (top.CrDt === '' || moment(top.CrDt.toDate()).isSame(new Date(), 'month')) {
                     handleOpenDialogThisMonth()
                  } else {
                     handleOpenDialogAttachPetition(true)
                  }
               } else if (thisuserPetitions && thisuserPetitions.length === 1) {
                  console.log('4. ', thisuserPetitions)
                  const top = thisuserPetitions[0]
                  if (top.CrDt === '' || moment(top.CrDt.toDate()).isSame(new Date(), 'month')) {
                     handleOpenDialogThisMonth()
                  } else {
                     handleOpenDialogAttachPetition(true)
                  }
               } else if (thisuserPetitions && thisuserPetitions.length === 0) {
                  console.log('5. ', thisuserPetitions)
                  //
                  handleOpenDialogAttachPetition(true)
               }
            } else {
               handleOpenDialogNotAttached()
            }
         } else {
            handleOpenDialogNotLoggedIn()
         }
      } else {
         handleOpenDialogNotAvtive()
      }
   }

   const [openDialogAttachPetition, setOpenDialogAttachPetition] = useState(false)
   const [petitionIdForAttachment, setPetitionIdForAttachment] = useState('')
   const [petitionForAttachment, setPetitionForAttachment] = useState('')

   const handleOpenDialogAttachPetition = async () => {
      setOpenDialogAttachPetition(true)
   }

   const handleCloseDialogAttachPetition = () => setOpenDialogAttachPetition(false)

   const handlePetitionIdForAttachment = async (e) => {
      e.preventDefault()
      setPetitionIdForAttachment(e.target.value)
   }

   const handleVerifyPetitionIdForAttachment = async (e) => {
      e.preventDefault()
      setUploadedPetition(false)
      setUploadingFailedPetition(false)
      const thisPetition = petitionsList.find(
         (item) => item.OriginalPetition_id === petitionForAttachment.OriginalPetition_id,
      )
      if (thisPetition) {
         setOpenDialogAttachPetition(false)
         setOpenDialogAlreadyExists(true)
         console.log('already exists', thisPetition)
      } else {
         fetchPetitionForAttachment(petitionIdForAttachment)
      }
   }

   const fetchPetitionForAttachment = async (petitionId) => {
      try {
         const docRef = doc(db, 'Petitions', petitionId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setPetitionForAttachment({ ...docSnap.data(), id: docSnap.id })
            console.log('PetitionForAttachment: ', docSnap.data())
         } else {
            navigate('/', { replace: true })
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   const handleAttachPetition = async (e) => {
      e.preventDefault()

      if (attachedToConsti) {
         if (auth.currentUser && thisUser === auth.currentUser.uid) {
            // 1. find if a petition under this candi exists with the same OriginalPetition_id
            const thisPetition = petitionsList.find(
               (item) => item.OriginalPetition_id === petitionForAttachment.OriginalPetition_id,
            )
            if (thisPetition) {
               // 2. if exists, open dialog already present
               setOpenDialogAttachPetition(false)
               setOpenDialogAlreadyExists(true)
               console.log('already exists', thisPetition)
            } else {
               // 3. if does not exist:
               try {
                  setUploadingPetition(true)
                  setUploadedPetition(false)
                  setUploadingFailedPetition(false)
                  // 4. Create an object from petitionForAttachment
                  const objPetition = {
                     Category: petitionForAttachment.Category,
                     Title: petitionForAttachment.Title,
                     TitleHindi: petitionForAttachment.TitleHindi,
                     TitleLocal: petitionForAttachment.TitleLocal,
                     TitlesOld: petitionForAttachment.TitlesOld,

                     Paragraphs: petitionForAttachment.Paragraphs,
                     ParagraphsOld: petitionForAttachment.ParagraphsOld,
                     Weblinks: petitionForAttachment.Weblinks,
                     Image1: petitionForAttachment.Image1,

                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByNameHindi: nameOfUserHindi,
                     CrByNameLocal: nameOfUserLocal,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,

                     ModBy: '',
                     ModByName: '',
                     ModByImage: '',
                     ModDt: '',
                     ModIP: '',
                     ModReason: '',

                     AddressedTo: 'LegisMember',

                     Strata: [legislature.Strata],
                     Country_id: userProfile.Location.Country_id ? userProfile.Location.Country_id : null,
                     CountryName: userProfile.Location.CountryName ? userProfile.Location.CountryName : null,
                     State_id: userProfile.Location.State_id ? userProfile.Location.State_id : null,
                     StateName: userProfile.Location.StateName ? userProfile.Location.StateName : null,
                     Division_id: userProfile.Location.Division_id ? userProfile.Location.Division_id : null,
                     DivisionName: userProfile.Location.DivisionName ? userProfile.Location.DivisionName : null,
                     District_id: userProfile.Location.District_id ? userProfile.Location.District_id : null,
                     DistrictName: userProfile.Location.DistrictName ? userProfile.Location.DistrictName : null,
                     SubDistrict_id: userProfile.Location.SubDistrict_id ? userProfile.Location.SubDistrict_id : null,
                     SubDistrictName: userProfile.Location.SubDistrictName
                        ? userProfile.Location.SubDistrictName
                        : null,
                     Block_id: userProfile.Location.Block_id ? userProfile.Location.Block_id : null,
                     BlockName: userProfile.Location.BlockName ? userProfile.Location.BlockName : null,
                     Village_id: userProfile.Location.Village_id ? userProfile.Location.Village_id : null,
                     VillageName: userProfile.Location.VillageName ? userProfile.Location.VillageName : null,

                     Legislature_id: '',
                     LegislatureName: '',
                     LegislatureNameHindi: '',
                     LegislatureNameLocal: '',

                     Election_id: '',
                     ElectionName: '',
                     ElectionNameHindi: '',
                     ElectionNameLocal: '',

                     Consti_id: '',
                     ConstiName: '',
                     ConstiNameHindi: '',
                     ConstiNameLocal: '',

                     Candi_id: '',
                     CandiName: '',
                     CandiNameHindi: '',
                     CandiNameLocal: '',
                     CandiImage: '',

                     OriginalPetition_id: petitionForAttachment.OriginalPetition_id,
                     LinkedPetitions: petitionForAttachment.LinkedPetitions,

                     AllowSignatories: true,
                     SignatoriesCount: 0,

                     Legislatures: [],
                     Constituencies: [],

                     Neta_id: member.id,
                     NetaName: member.Name,
                     NetaNameHindi: member.NameHindi,
                     NetaNameLocal: member.NameLocal,
                     NetaImage: member.Image.Image,

                     LegisMem_id: '',
                     LegisMemName: '',
                     LegisMemNameHindi: '',
                     LegisMemNameLocal: '',
                     LegisMemImage: '',

                     Ministry_id: '',
                     MinistryName: '',
                     MinistryNameHindi: '',
                     MinistryNameLocal: '',

                     Department_id: '',
                     DepartmentName: '',
                     DepartmentNameHindi: '',
                     DepartmentNameLocal: '',

                     Office_id: '',
                     OfficeName: '',
                     OfficeNameHindi: '',
                     OfficeNameLocal: '',

                     Copy_To_Ministry_id: '',
                     Copy_To_Department_id: '',
                     Copy_To_Office_id: '',

                     Party_id: member.Party_id,
                     PartyName: member.PartyName,
                     PartyNameHindi: member.PartyNameHindi,
                     PartyNameLocal: member.PartyNameLocal,
                     PartyFlag: member.PartyFlag,
                     PartySymbol: member.PartySymbol,

                     Party_OB_id: '',
                     Party_OB_Name: '',
                     Party_OB_NameHindi: '',
                     Party_OB_NameLocal: '',
                     Copy_To_Party_OB_id: '',

                     SupportCount_Positive_Insider: 0,
                     SupportCount_Positive_Outsider: 0,
                     SupportCount_Negative_Insider: 0,
                     SupportCount_Negative_Outsider: 0,
                     SupportCount_Withdrawn: 0,
                     SupportCount_Total: 0,

                     SignCount_Insider: 0,
                     SignCount_Outsider: 0,
                     SignCount_Withdrawn: 0,
                     SignCount_Total: 0,
                  }

                  const record = {
                     Petition_id: '',
                     OriginalPetition_id: petitionForAttachment.OriginalPetition_id,
                     Category: petitionForAttachment.Category,
                     Title: petitionForAttachment.Title,
                     TitleHindi: petitionForAttachment.TitleHindi,
                     TitleLocal: petitionForAttachment.TitleLocal,
                     Image1: petitionForAttachment.Image1,

                     AddressedTo: 'LegisMember',

                     SupportCount_Total: 0,
                     SignCount_Total: 0,
                     SupportCountDt: '',

                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByImage: userProfile.Image.Image,
                     CrDt: new Date(),
                  }

                  console.log('objPetition: ', objPetition)
                  console.log('record: ', record)

                  // 5. Create coreListId
                  // i. for user profile
                  const docRefUserP = doc(db, 'UserProfiles', userProfile.id)
                  const docSnapUserP = await getDoc(docRefUserP)
                  const petitionsCountUserP = docSnapUserP.data().PetitionsCount
                     ? docSnapUserP.data().PetitionsCount
                     : 0
                  const slabUserP = 2000
                  const quotientUserP = Math.floor(petitionsCountUserP / slabUserP)
                  // const remainder = candidatesCount % slab
                  const numUserP = quotientUserP + 1
                  const numStrUserP = String(numUserP).padStart(9, '0')
                  objPetition.CoreListId_AtUserP = { SubColl_id: 'Petitions', CoreList_id: numStrUserP }
                  console.log('coreListId UserP: ', numStrUserP)
                  // ii. for constituency
                  const docRefConstiPCount = doc(
                     db,
                     'LegisConstituencies',
                     member.Constituency_id,
                     docIdPetitions,
                     'Count',
                  )
                  const docSnapConstiPCount = await getDoc(docRefConstiPCount)
                  const petitionsCountConsti = docSnapConstiPCount.data().Count ? docSnapConstiPCount.data().Count : 0
                  const slabConsti = 2000
                  const quotientConsti = Math.floor(petitionsCountConsti / slabConsti)
                  const numConsti = quotientConsti + 1
                  const numStrConsti = String(numConsti).padStart(9, '0')
                  objPetition.CoreListId_AtConsti = objPetition.CoreListId_AtConsti
                     ? [...objPetition.CoreListId_AtConsti, { SubColl_id: docIdPetitions, CoreList_id: numStrConsti }]
                     : [{ SubColl_id: docIdPetitions, CoreList_id: numStrConsti }]
                  console.log('coreListId Consti: ', numStrConsti)

                  // iii. for legislature
                  const docRefLegisPCount = doc(db, 'Legislatures', member.Legisature_id, docIdPetitions, 'Count')
                  const docSnapLegisPCount = await getDoc(docRefLegisPCount)
                  const petitionsCountLegis = docSnapLegisPCount.data().Count ? docSnapLegisPCount.data().Count : 0
                  const slabLegis = 2000
                  const quotientLegis = Math.floor(petitionsCountLegis / slabLegis)
                  const numLegis = quotientLegis + 1
                  const numStrLegis = String(numLegis).padStart(9, '0')

                  objPetition.CoreListId_AtLegis = objPetition.CoreListId_AtLegis
                     ? [...objPetition.CoreListId_AtLegis, { SubColl_id: docIdPetitions, CoreList_id: numStrLegis }]
                     : [{ SubColl_id: docIdPetitions, CoreList_id: numStrLegis }]
                  console.log('coreListId Legis: ', numStrLegis)

                  //

                  // 6. Create a new main doc in petitions

                  const docRef = await addDoc(collection(db, 'Petitions'), objPetition)
                  record.Petition_id = docRef.id
                  console.log('record after main doc creation: ', record)

                  // 7. Update Legis Mem Profile

                  console.log('newRecord', record)

                  const docRefLegisMemSubCollCount = doc(db, 'LegisMembers', member.id, docIdPetitions, 'Count')
                  const docSnapLegisMemSubCollCount = await getDoc(docRefLegisMemSubCollCount)
                  const petitionsCountLegisMem = docSnapLegisMemSubCollCount.data().Count
                     ? docSnapLegisMemSubCollCount.data().Count
                     : 0
                  const slabLegisMem = 2000
                  const quotientLegisMem = Math.floor(petitionsCountLegisMem / slabLegisMem)
                  const numLegisMem = quotientLegisMem + 1
                  const numStrLegisMem = String(numLegisMem).padStart(9, '0')

                  objPetition.CoreListId_AtLegisMem = { SubColl_id: docIdPetitions, CoreList_id: numStrLegisMem }
                  console.log('coreListId Neta: ', numStrLegisMem)

                  if (docSnapLegisMemSubCollCount.data().Count) {
                     await updateDoc(docRefLegisMemSubCollCount, {
                        Count: increment(1),
                     })
                  } else {
                     setDoc(docRefLegisMemSubCollCount, { Count: 1, Type: 'Count' }, { merge: true })
                  }

                  const docRefLegisMemSubCollPetitions = doc(
                     db,
                     'LegisMembers',
                     member.id,
                     docIdPetitions,
                     numStrLegisMem,
                  )
                  const docSnapLegisMemSubCollPetitions = await getDoc(docRefLegisMemSubCollPetitions)
                  console.log('Neta update start: ')
                  if (docSnapLegisMemSubCollPetitions.exists()) {
                     await updateDoc(docRefLegisMemSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(
                        docRefLegisMemSubCollPetitions,
                        { Petitions: [record], Type: 'PetitionsCoreList' },
                        { merge: true },
                     )
                  }

                  console.log('Neta update end')

                  // 8. Update User
                  console.log('newRecordForUser', record)
                  const docRefUser = doc(db, 'UserProfiles', userProfile.id)
                  const docSnapUser = await getDoc(docRefUser)

                  if (docSnapUser.data().PetitionsCount) {
                     await updateDoc(docRefUser, {
                        PetitionsCount: increment(1),
                     })
                  } else {
                     await updateDoc(docRefUser, {
                        PetitionsCount: 1,
                     })
                  }

                  const docRefUserSubCollPetitions = doc(db, 'UserProfiles', userProfile.id, 'Petitions', numStrUserP)
                  const docSnapUserSubCollPetitions = await getDoc(docRefUserSubCollPetitions)
                  console.log('user update start: ')
                  if (docSnapUserSubCollPetitions.exists()) {
                     await updateDoc(docRefUserSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(docRefUserSubCollPetitions, { Petitions: [record] }, { merge: true })
                  }
                  console.log('user update end')

                  // 9. Update the Constituency

                  if (docSnapConstiPCount.data().Count) {
                     await updateDoc(docRefConstiPCount, {
                        Count: increment(1),
                     })
                  } else {
                     setDoc(docRefConstiPCount, { Count: 1, Type: 'Count' }, { merge: true })
                  }

                  const docRefConstiSubCollPetitions = doc(
                     db,
                     'LegisConstituencies',
                     member.Constituency_id,
                     docIdPetitions,
                     numStrConsti,
                  )
                  const docSnapConstiSubCollPetitions = await getDoc(docRefConstiSubCollPetitions)
                  console.log('Consti update start: ')
                  if (docSnapConstiSubCollPetitions.exists()) {
                     await updateDoc(docRefConstiSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(
                        docRefConstiSubCollPetitions,
                        { Petitions: [record], Type: 'PetitionsCoreList' },
                        { merge: true },
                     )
                  }

                  console.log('Consti update end')

                  // 10. Update the Legislature

                  if (docSnapLegisPCount.data().Count) {
                     await updateDoc(docRefLegisPCount, {
                        Count: increment(1),
                     })
                  } else {
                     setDoc(docRefLegisPCount, { Count: 1, Type: 'Count' }, { merge: true })
                  }

                  const docRefLegisSubCollPetitions = doc(
                     db,
                     'Legislatures',
                     member.Legislature_id,
                     docIdPetitions,
                     numStrLegis,
                  )
                  const docSnapLegisSubCollPetitions = await getDoc(docRefLegisSubCollPetitions)
                  console.log('Legis update start: ')
                  if (docSnapLegisSubCollPetitions.exists()) {
                     await updateDoc(docRefLegisSubCollPetitions, {
                        Petitions: arrayUnion(record),
                     })
                  } else {
                     setDoc(
                        docRefLegisSubCollPetitions,
                        { Petitions: [record], Type: 'PetitionsCoreList' },
                        { merge: true },
                     )
                  }

                  console.log('Legis update end')

                  // 11. Update Local State
                  console.log('local update start')
                  const docRefPetition = doc(db, 'Petitions', docRef.id)
                  const docSnapPetition = await getDoc(docRefPetition)
                  const local = {
                     Petition_id: docRef.id,
                     OriginalPetition_id: docSnapPetition.data().OriginalPetition_id,
                     Category: docSnapPetition.data().Category,
                     Title: docSnapPetition.data().Title,
                     TitleHindi: docSnapPetition.data().TitleHindi,
                     TitleLocal: docSnapPetition.data().TitleLocal,
                     Image1: docSnapPetition.data().Image1,
                     Image2: docSnapPetition.data().Image2,

                     AddressedTo: 'LegisMember',

                     SupportCount_Total: 0,
                     SignCount_Total: 0,
                     SupportCountDt: '',

                     CrBy: docSnapPetition.data().CrBy,
                     CrByName: docSnapPetition.data().CrByName,
                     CrByImage: docSnapPetition.data().CrByImage,
                     CrDt: docSnapPetition.data().CrDt,
                  }
                  handlePetitionsListUpdate(local)

                  console.log('local update end')
                  setPetitionIdForAttachment('')
                  setPetitionForAttachment('')

                  setUploadingPetition(false)
                  setUploadedPetition(true)
                  setUploadingFailedPetition(false)

                  setOpenModalRaisePetition(false)
               } catch (error) {
                  alert('Error adding petition')
                  console.log('Error adding petition', error)

                  setUploadingPetition(false)
                  setUploadedPetition(false)
                  setUploadingFailedPetition(true)

                  handleOpenDialogTryAgain()
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // already exists

   const [openDialogAlreadyExists, setOpenDialogAlreadyExists] = useState(false)

   const handleCloseDialogAlreadyExists = () => setOpenDialogAlreadyExists(false)

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // not attachedToConsti

   const [openDialogNotAttached, setOpenDialogNotAttached] = useState(false)

   const handleOpenDialogNotAttached = async () => {
      setOpenDialogNotAttached(true)
   }

   const handleCloseDialogNotAttached = () => setOpenDialogNotAttached(false)

   // try again

   const [openDialogTryAgain, setOpenDialogTryAgain] = useState(false)

   const handleOpenDialogTryAgain = async () => {
      setOpenDialogTryAgain(true)
   }

   const handleCloseDialogTryAgain = () => setOpenDialogTryAgain(false)

   // this month

   const [openDialogThisMonth, setOpenDialogThisMonth] = useState(false)

   const handleOpenDialogThisMonth = async () => {
      setOpenDialogThisMonth(true)
   }

   const handleCloseDialogThisMonth = () => setOpenDialogThisMonth(false)

   // not active

   const [openDialogNotAvtive, setOpenDialogNotAvtive] = useState(false)

   const handleOpenDialogNotAvtive = async () => {
      setOpenDialogNotAvtive(true)
   }

   const handleCloseDialogNotAvtive = () => setOpenDialogNotAvtive(false)

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Send personal requests to - ${member.Name} - member of ${member.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Box sx={{ display: 'flex', mb: 0.5, justifyContent: 'space-between' }}>
            <Button
               onClick={(e) => {
                  handleOpenModalRaisePetition(e)
               }}
               size="small"
               variant="outlined"
               sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line' }}
            >
               Create petition / याचिका बनाएँ
            </Button>
            <Button
               onClick={(e) => {
                  handleOpenModalAttachPetition(e)
               }}
               size="small"
               variant="outlined"
               sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line', ml: 2 }}
            >
               Attach existing petition / मौजूदा याचिका संलग्न करें
            </Button>
         </Box>

         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               Messages from public: {'\n'} जनता से संदेश:
            </Typography>
         </Box>

         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               px: 1,
               mt: 0.5,
               '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '25ch' },
            }}
         >
            <Box></Box>
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  px: 0.5,
                  ml: 'auto',
                  border: 1,
                  borderRadius: 1,
                  borderColor: '#888889',
               }}
            >
               <Box sx={{ color: '#795548', fontSize: 15, textDecoration: 'underline' }}>Sort by / क्रमबद्धता:</Box>
               <Box>
                  <FormControl
                     sx={{
                        p: 0,
                     }}
                  >
                     {/* <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel> */}
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="SortField"
                        value={sortField}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleSortField(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="Date"
                           control={<Radio size="small" />}
                           label="Date / तिथि"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="Supports"
                           control={<Radio size="small" />}
                           label="Supports / समर्थन"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
               </Box>
            </Box>
         </Box>

         <Box>
            <List dense>
               {petitionsList &&
                  petitionsList.length > 0 &&
                  petitionsList.map((item, index) => (
                     <div key={index}>
                        <ListItem sx={{ backgroundColor: '#FFFFFF' }} secondaryAction={null}>
                           <ListItemText
                              primaryTypographyProps={{ fontWeight: 600 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line', fontSize: 16 }}
                              primary=<Link
                                 href={`/petition/${item.Title}/${item.Petition_id}`}
                                 rel="noreferrer"
                                 target="_blank"
                              >
                                 {item.Title}
                              </Link>
                              secondary={item.TitleLocal}
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {!petitionsList ||
               (petitionsList.length < 1 && (
                  <Typography variant="body2" sx={{ textAlign: 'left', color: '#635344' }}>
                     No requests yet ...
                  </Typography>
               ))}
         </Box>

         <Box id="boxModals">
            <Modal
               open={openModalRaisePetition}
               onClose={handleCloseModalRaisePetition}
               aria-labelledby="modal-modal-title-addPetition"
               aria-describedby="modal-modal-description-addPetition"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addPetition"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Raise a Petition {'\n'} याचिका तैयार करें
                  </Typography>
                  <RaisePetition
                     petitionCategoriesList={petitionCategoriesList}
                     handleRaisePetition={handleRaisePetition}
                     uploadingPetition={uploadingPetition}
                     uploadedPetition={uploadedPetition}
                     uploadingFailedPetition={uploadingFailedPetition}
                  ></RaisePetition>
               </Box>
            </Modal>
            <Dialog
               open={openDialogNotLoggedIn}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotLoggedIn}
               aria-describedby="alert-dialog-slide-notLoggedIn"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-notLoggedIn"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     You are not logged-in.
                     <br /> <br />
                     आपने लॉग - इन नहीं किया है।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
               </DialogActions>
            </Dialog>

            <Dialog
               open={openDialogNotAttached}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotAttached}
               aria-describedby="alert-dialog-slide-notAttached"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`You are not attached to this consti. ${'\n'} आप जुड़े हुए नहीं हैं।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-notAttached"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     Please attach to your constituency first.
                     <br /> <br />
                     कृपया पहले अपने निर्वाचन क्षेत्र से जुड़ें।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotAttached}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogTryAgain}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogTryAgain}
               aria-describedby="alert-dialog-slide-tryAgain"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Oops. There was some problem. ${'\n'} ओह, कुछ समस्या थी।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-tryAgain" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                     There was some problem! Please try again!
                     <br /> <br />
                     कुछ समस्या थी! कृपया पुन: प्रयास करें!
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogTryAgain}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogThisMonth}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogThisMonth}
               aria-describedby="alert-dialog-slide-thisMonth"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Limit ${'\n'} सीमा`}</DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-slide-tryAgain" sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}>
                     You can raise one petition to a candidate in a month. {'\n'}{' '}
                     <span style={{ color: '#0288d1' }}>
                        Please raise a petition to another candidate {'\n'} or {'\n'}
                        Try next month!
                     </span>
                     <br /> <br />
                     आप एक महीने में एक उम्मीदवार के लिए एक याचिका प्रस्तुत कर सकते हैं। {'\n'}{' '}
                     <span style={{ color: '#0288d1' }}>
                        कृपया किसी अन्य उम्मीदवार के लिए याचिका प्रस्तुत करें {'\n'} या {'\n'} अगले महीने प्रयास करें!
                     </span>
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogThisMonth}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAlreadyExists}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAlreadyExists}
               aria-describedby="alert-dialog-slide-alreadyExists"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Already Exists. ${'\n'} पहले से ही मौजूद है।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-alreadyExists"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     This petion has already been attached here.
                     <br /> <br />
                     यह याचिका पहले ही यहां संलग्न की जा चुकी है।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogAlreadyExists}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogAttachPetition}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAttachPetition}
               aria-describedby="alert-dialog-slide-AttachPetition"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Attach an existing petition ${'\n'} मौजूदा याचिका संलग्न करें`}</DialogTitle>
               <DialogContent>
                  <Box
                     sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                     disabled={petitionForAttachment}
                     fullWidth
                  >
                     <TextField
                        onBlur={(event) => event}
                        slotProps={{ htmlInput: { maxLength: 100 } }}
                        onChange={(event) => {
                           handlePetitionIdForAttachment(event)
                        }}
                        value={petitionIdForAttachment}
                        name="PetitionIdForAttachment"
                        id="petitionIdForAttachment"
                        label="Petition Id:"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        autoComplete="off"
                        sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                        disabled={petitionForAttachment}
                     ></TextField>
                     <IconButton
                        sx={{
                           ml: 0.5,
                           height: '25px',
                           width: '25px',
                           backgroundColor: '#cc7900',
                           color: '#FFFFFF',
                           '&:hover': { backgroundColor: '#ff9800' },
                        }}
                        disabled={petitionIdForAttachment.length < 20 || petitionForAttachment}
                     >
                        <AddIcon
                           onClick={(e) => {
                              handleVerifyPetitionIdForAttachment(e)
                           }}
                        />
                     </IconButton>
                  </Box>
                  {petitionForAttachment && (
                     <Box>
                        <Box sx={{ color: '#0288d1', my: 1 }}>You have chosen to attach the following petition:</Box>
                        <Box sx={{ my: 2 }}>
                           {petitionForAttachment.AddressedTo === 'Candidate' && (
                              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                 <ListItemAvatar>
                                    <Avatar alt="Travis Howard" src={petitionForAttachment.CandiImage} />
                                 </ListItemAvatar>

                                 {petitionForAttachment.CandiNameLocal
                                    ? `${petitionForAttachment.CandiName} \n ${petitionForAttachment.CandiNameLocal}`
                                    : `${petitionForAttachment.CandiName} \n ${petitionForAttachment.CandiNameHindi}`}
                              </Box>
                           )}
                        </Box>
                        <Box
                           sx={{
                              display: 'inline',
                              mx: 0.5,
                              fontSize: 16,
                              fontWeight: 600,
                           }}
                        >
                           {petitionForAttachment.Title}
                        </Box>
                        <Typography
                           component="div"
                           variant="h5"
                           sx={{ fontWeight: 600, fontSize: 14, lineHeight: 1.4, fontFamily: 'Montserrat', mb: 1 }}
                        >
                           {petitionForAttachment.TitleLocal}
                        </Typography>

                        <Box sx={{ mt: 2 }}>
                           <Button variant="outlined" onClick={handleAttachPetition}>
                              Submit
                           </Button>
                        </Box>
                     </Box>
                  )}
                  <Box sx={{ mt: 2 }}>
                     {uploadingPetition && (
                        <MyLoaderCircularGradient title={'Attaching the petition ...'}></MyLoaderCircularGradient>
                     )}
                     {uploadedPetition && (
                        <Alert variant="outlined" severity="success">
                           Petition attached successfully.
                        </Alert>
                     )}
                     {uploadingFailedPetition && (
                        <Alert variant="outlined" severity="error">
                           Error occured! Petition could not be attached.
                        </Alert>
                     )}
                  </Box>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogAttachPetition}>Close</Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogNotAvtive}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotAvtive}
               aria-describedby="alert-dialog-slide-datePassed"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-datePassed"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     This leader profile is not active.
                     <br /> <br />
                     यह नेता प्रोफ़ाइल सक्रिय नहीं है.
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotAvtive}>Close</Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

LegisMemInteract.propTypes = {
   legislature: PropTypes.object.isRequired,
   member: PropTypes.object.isRequired,
   attachedToConsti: PropTypes.bool.isRequired,
   petitionCategoriesList: PropTypes.array.isRequired,
   petitionsList: PropTypes.array.isRequired,
   handlePetitionsListUpdate: PropTypes.func.isRequired,
}

export default LegisMemInteract
