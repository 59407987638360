import {
   Alert,
   Box,
   Button,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   List,
   ListItem,
   ListItemText,
   MenuItem,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../redux/reducers/users-slice'
import { auth } from '../../FirebaseConfig'
import MyLoaderCircularGradient from '../../Utils/Loaders/MyLoaderCircularGradient'
import { useNavigate } from 'react-router-dom'
import myStyles from './RaisePetition.module.css'
import validator from 'validator'
import Tooltip from '@mui/material/Tooltip'

function RaisePetition({
   petitionCategoriesList,
   handleRaisePetition,
   uploadingPetition,
   uploadedPetition,
   uploadingFailedPetition,
}) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip
   const userProfile = useSelector(selectUserProfile)
   const [nameOfUser, setNameOfUser] = useState('')
   // const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const navigate = useNavigate()
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
      }
   }, [userProfile])

   useEffect(() => {
      if (user.currentUser !== null) {
         setThisUser(user.currentUser.id)
      } else {
         setThisUser('')
      }
   }, [user.currentUser])

   const paraHeaderRef = useRef(null)
   const weblinkRef = useRef(null)

   // useEffect(() => {
   //    if (firstLoadStatus === 'idle') {
   //       inputRef.current.focus()
   //       setFirstLoadStatus('success')
   //    }
   // }, [])

   const [petitionDetails, setPetitionDetails] = useState({
      Category: '',
      Title: '',
      TitleHindi: '',
      TitleLocal: '',
      TitlesOld: [],

      Paragraphs: [],
      ParagraphsOld: [],
      Weblinks: [],
      Image1: '',
      Image2: '',

      AddressedTo: '',

      Strata: '',
      Country_id: '',
      CountryName: '',
      State_id: '',
      StateName: '',
      Division_id: '',
      DivisionName: '',
      District_id: '',
      DistrictName: '',
      SubDistrict_id: '',
      SubDistrictName: '',
      Block_id: '',
      BlockName: '',
      Village_id: '',
      VillageName: '',

      Legislatures: [],
      Constituencies: [],

      Legislature_id: '',
      LegislatureName: '',
      LegislatureNameHindi: '',
      LegislatureNameLocal: '',

      Election_id: '',
      ElectionName: '',
      ElectionNameHindi: '',
      ElectionNameLocal: '',

      Consti_id: '',
      ConstiName: '',
      ConstiNameHindi: '',
      ConstiNameLocal: '',

      Candi_id: '',
      CandiName: '',
      CandiNameHindi: '',
      CandiNameLocal: '',
      CandiImage: '',

      Neta_id: '',
      NetaName: '',
      NetaNameHindi: '',
      NetaNameLocal: '',
      NetaImage: '',

      LegisMem_id: '',
      LegisMemName: '',
      LegisMemNameHindi: '',
      LegisMemNameLocal: '',
      LegisMemImage: '',

      Ministry_id: '',
      MinistryName: '',
      MinistryNameHindi: '',
      MinistryNameLocal: '',

      Department_id: '',
      DepartmentName: '',
      DepartmentNameHindi: '',
      DepartmentNameLocal: '',

      Office_id: '',
      OfficeName: '',
      OfficeNameHindi: '',
      OfficeNameLocal: '',

      Copy_To_Ministry_id: '',
      Copy_To_Department_id: '',
      Copy_To_Office_id: '',

      Party_id: '',
      PartyName: '',
      PartyNameHindi: '',
      PartyNameLocal: '',
      PartyFlag: '',
      PartySymbol: '',

      Party_OB_id: '',
      Party_OB_Name: '',
      Party_OB_NameHindi: '',
      Party_OB_NameLocal: '',
      Copy_To_Party_OB_id: '',

      SupportCount_Positive_Insider: 0,
      SupportCount_Positive_Outsider: 0,
      SupportCount_Negative_Insider: 0,
      SupportCount_Negative_Outsider: 0,
      SupportCount_Withdrawn: 0,
      SupportCount_Total: 0,

      SignCount_Insider: 0,
      SignCount_Outsider: 0,
      SignCount_Withdrawn: 0,
      SignCount_Total: 0,

      OriginalPetition_id: '',
      LinkedPetitions: [],

      AllowSignatories: false,
      SignatoriesCount: 0,

      ModBy: '',
      ModByName: '',
      ModByImage: '',
      ModDt: '',
      ModIP: '',
      ModReason: '',
   })

   const [paragraphsList, setParagraphsList] = useState([])
   const [paragraph, setParagraph] = useState('')
   const [pLength, setPLength] = useState(0)

   const [paraHeader, setParaHeader] = useState('')
   const [pHLength, setPHLength] = useState(0)
   // const [title, setTitle] = useState('')
   const [tLength, setTLength] = useState(0)
   const [tLengthHindi, setTLengthHindi] = useState(0)
   const [tLengthLocal, setTLengthLocal] = useState(0)

   const [weblink, setWeblink] = useState([])
   const [weblinksList, setWeblinksList] = useState([])

   useEffect(() => {
      console.log('paragraphsList: ', paragraphsList)
   }, [paragraphsList])

   // const handlePetitionDetails = (e) => {
   //    e.preventDefault()
   //    setPetitionDetails({ ...petitionDetails, [e.target.name]: e.target.value })
   // }

   // regarding categories

   function handleChangeCategory(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         // setSelectedCategory(e.target.value)
         setPetitionDetails({
            ...petitionDetails,
            Category: e.target.value,
         })
      } else {
         setPetitionDetails({
            ...petitionDetails,
            Category: '',
         })
      }
   }

   // regarding titles
   const handleWriteTitle = (event) => {
      try {
         if (typeof event.target.value !== 'undefined') {
            const data = event.target.value
            if (data.length <= 100) {
               // setTitle(data)
               setPetitionDetails({ ...petitionDetails, Title: data })
               setTLength(data.length)
            } else if (data.length > 100) {
               const newData = data.slice(0, 100)
               // setTitle(newData)
               setPetitionDetails({ ...petitionDetails, Title: newData })
               setTLength(newData.length)
            }
         } else {
            setPetitionDetails({ ...petitionDetails, Title: '' })
            return false
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleWriteTitleLocal = (event) => {
      try {
         if (typeof event.target.value !== 'undefined') {
            const data = event.target.value
            if (data.length <= 100) {
               // setTitle(data)
               setPetitionDetails({ ...petitionDetails, TitleLocal: data })
               setTLengthLocal(data.length)
            } else if (data.length > 100) {
               const newData = data.slice(0, 100)
               // setTitle(newData)
               setPetitionDetails({ ...petitionDetails, TitleLocal: newData })
               setTLengthLocal(newData.length)
            }
         } else {
            setPetitionDetails({ ...petitionDetails, TitleLocal: '' })
            return false
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleWriteTitleHindi = (event) => {
      try {
         if (typeof event.target.value !== 'undefined') {
            const data = event.target.value
            if (data.length <= 100) {
               // setTitle(data)
               setPetitionDetails({ ...petitionDetails, TitleHindi: data })
               setTLengthHindi(data.length)
            } else if (data.length > 100) {
               const newData = data.slice(0, 100)
               // setTitle(newData)
               setPetitionDetails({ ...petitionDetails, TitleHindi: newData })
               setTLengthHindi(newData.length)
            }
         } else {
            setPetitionDetails({ ...petitionDetails, TitleHindi: '' })
            return false
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleWriteParaHeader = (event) => {
      try {
         if (paragraphsList.length < 5) {
            if (typeof event.target.value !== 'undefined') {
               const data = event.target.value
               if (data.length <= 25) {
                  setParaHeader(data)
                  setPHLength(data.length)
               } else if (data.length > 25) {
                  const newData = data.slice(0, 250)
                  setParaHeader(newData)
                  setPHLength(newData.length)
               }
            } else {
               setParaHeader('')
               setPHLength(0)
               return false
            }
         } else {
            setParaHeader('')
            setPHLength(0)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleWriteParagraph = (event) => {
      try {
         if (paragraphsList.length < 5) {
            if (typeof event.target.value !== 'undefined') {
               const data = event.target.value
               if (data.length <= 250) {
                  setParagraph(data)
                  setPLength(data.length)
               } else if (data.length > 250) {
                  const newData = data.slice(0, 250)
                  setParagraph(newData)
                  setPLength(newData.length)
               }
            } else {
               setParagraph('')
               setPLength(0)
               return false
            }
         } else {
            setParagraph('')
            setPLength(0)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleKeyDown = (e) => {
      if (pLength > 99 && e.key === 'Enter') {
         handleAddParagraph(e)
      }
   }

   const handleAddParagraph = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (paragraphsList.length < 10 && pLength > 99) {
            const paragraphNew = {
               Header: paraHeader,
               Text: paragraph,
               Priority: paragraphsList && paragraphsList.length > 0 ? paragraphsList.length + 1 : 1,
               CrBy: auth.currentUser.uid,
               CrByName: nameOfUser,
               CrByImage: userProfile.Image.Image,
               CrDt: new Date(),
               CrIP: ip.data.ip,
               ModBy: '',
               ModByName: '',
               ModByImage: '',
               ModDt: '',
               ModIP: '',
               ModReason: '',
            }

            const list = [...paragraphsList, paragraphNew]
            setParagraphsList(list)
            console.log('paragraphNew: ', paragraphNew)
            setParagraph('')
            setPLength(0)
            setParaHeader('')
            setPHLength(0)
            paraHeaderRef.current.focus()
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleMoveUp = (e, item) => {
      e.preventDefault()

      const itemToBePushedDown = paragraphsList.find((thisItem) => thisItem.Priority === item.Priority - 1)
      console.log('itemToBePushedDown: ', itemToBePushedDown)
      const itemNewAtUp = { ...item, Priority: item.Priority - 1 }
      console.log('itemNewAtUp: ', itemNewAtUp)
      const itemNewAtDown = { ...itemToBePushedDown, Priority: item.Priority }
      console.log('itemNewAtDown: ', itemNewAtDown)

      const list = paragraphsList.filter((thisItem) => thisItem !== item && thisItem !== itemToBePushedDown)
      console.log('list: ', list)
      const listNew = [...list, itemNewAtUp, itemNewAtDown]
      console.log('listNew: ', listNew)
      const listSorted = [...listNew].sort((a, b) => a.Priority - b.Priority)
      console.log('listSorted: ', listSorted)

      setParagraphsList(listSorted)
   }

   const handleMoveDown = (e, item) => {
      e.preventDefault()

      const itemToBePulledUp = paragraphsList.find((thisItem) => thisItem.Priority === item.Priority + 1)
      console.log('itemToBePulledUp: ', itemToBePulledUp)
      const itemNewAtDown = { ...item, Priority: item.Priority + 1 }
      console.log('itemNewAtDown: ', itemNewAtDown)
      const itemNewAtUp = { ...itemToBePulledUp, Priority: item.Priority }
      console.log('itemNewAtUp: ', itemNewAtUp)

      const list = paragraphsList.filter((thisItem) => thisItem !== item && thisItem !== itemToBePulledUp)
      console.log('list: ', list)
      const listNew = [...list, itemNewAtUp, itemNewAtDown]
      console.log('listNew: ', listNew)
      const listSorted = [...listNew].sort((a, b) => a.Priority - b.Priority)
      console.log('listSorted: ', listSorted)

      setParagraphsList(listSorted)
   }

   const handleDeleteParagraph = (e, item) => {
      e.preventDefault()
      if (confirm('Are you sure you want to delete this paragraph?')) {
         const i = item.Priority
         const list = paragraphsList.filter((thisItem) => thisItem !== item)
         const listNew = []
         list.forEach((listItem) => {
            if (listItem.Priority < i) {
               listNew.push(listItem)
            } else {
               const newItem = { ...listItem, Priority: listItem.Priority - 1 }
               listNew.push(newItem)
            }
         })
         console.log('listNew: ', listNew)
         const listSorted = [...listNew].sort((a, b) => a.Priority - b.Priority)
         console.log('listSorted: ', listSorted)

         setParagraphsList(listSorted)
      }
   }

   const handleEditParagraph = (e, item) => {
      e.preventDefault()

      // remove the paragraph from the list and resort thye remaining paragraphs
      const i = item.Priority
      const list = paragraphsList.filter((thisItem) => thisItem !== item)
      const listNew = []
      list.forEach((listItem) => {
         if (listItem.Priority < i) {
            listNew.push(listItem)
         } else {
            const newItem = { ...listItem, Priority: listItem.Priority - 1 }
            listNew.push(newItem)
         }
      })
      const listSorted = [...listNew].sort((a, b) => a.Priority - b.Priority)
      setParagraphsList(listSorted)

      // add the item to text box
      setParagraph(item.Text)
      setPLength(item.Text.length)
      paraHeaderRef.current.focus()
   }

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleWeblink(event) {
      setWeblink(event.target.value)
      validate(event.target.value)
   }

   const handleKeyDownWeblink = (e) => {
      if (errorMessage === 'Is Valid URL' && e.key === 'Enter') {
         handleAddWeblink(e)
      }
   }

   const handleAddWeblink = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (weblinksList.length < 2) {
            const weblinkNew = {
               Weblink: weblink,
               CrBy: auth.currentUser.uid,
               CrByName: nameOfUser,
               CrByImage: userProfile.Image.Image,
               CrDt: new Date(),
               CrIP: ip.data.ip,
               ModBy: '',
               ModByName: '',
               ModByImage: '',
               ModDt: '',
               ModIP: '',
               ModReason: '',
            }

            const list = [...weblinksList, weblinkNew]
            setWeblinksList(list)
            console.log('weblinkNew: ', weblinkNew)
            setWeblink('')
            setErrorMessage('')
            if (weblinksList.length < 2) {
               weblinkRef.current.focus()
            }
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDeleteWeblink = (e, item) => {
      e.preventDefault()
      if (confirm('Are you sure you want to delete this weblink?')) {
         const list = weblinksList.filter((thisItem) => thisItem !== item)

         setWeblinksList(list)
      }
   }

   // for image 1

   const [selectedImage1, setSelectedImage1] = useState('')
   const [imageUrl1, setImageUrl1] = useState(null)
   const refImageInput1 = useRef()

   function handleChangeImageInput1(e) {
      if (e.target.files[0]) {
         setSelectedImage1(e.target.files[0])
      } else {
         setSelectedImage1(null)
         setImageUrl1(null)
      }
   }

   useEffect(() => {
      if (selectedImage1) {
         setImageUrl1(URL.createObjectURL(selectedImage1))
      }
   }, [selectedImage1])

   function RemoveImage1() {
      setSelectedImage1(null)
      setImageUrl1(null)
      refImageInput1.current.value = ''
   }

   // for image 2

   const [selectedImage2, setSelectedImage2] = useState('')
   const [imageUrl2, setImageUrl2] = useState(null)
   const refImageInput2 = useRef()

   function handleChangeImageInput2(e) {
      if (e.target.files[0]) {
         setSelectedImage2(e.target.files[0])
      } else {
         setSelectedImage2(null)
         setImageUrl2(null)
      }
   }

   useEffect(() => {
      if (selectedImage2) {
         setImageUrl2(URL.createObjectURL(selectedImage2))
      }
   }, [selectedImage2])

   function RemoveImage2() {
      setSelectedImage2(null)
      setImageUrl2(null)
      refImageInput2.current.value = ''
   }

   const handleSubmit = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            (petitionDetails.Title.length > 14 ||
               petitionDetails.TitleLocal.length > 14 ||
               petitionDetails.TitleHindi.length > 14) &&
            paragraphsList.length > 0 &&
            petitionDetails.Category !== ''
         ) {
            const newPetitionDetails = { ...petitionDetails, Paragraphs: paragraphsList, Weblinks: weblinksList }
            console.log('petitionDetails on submit: ', newPetitionDetails)
            handleRaisePetition(newPetitionDetails, selectedImage1, selectedImage2)

            reset()
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   function reset() {
      setPetitionDetails({
         Category: '',
         Title: '',
         TitleHindi: '',
         TitleLocal: '',
         TitlesOld: [],

         Paragraphs: [],
         ParagraphsOld: [],
         Weblinks: [],
         Image1: '',
         Image2: '',

         AddressedTo: '',

         Strata: '',
         Country_id: '',
         CountryName: '',
         State_id: '',
         StateName: '',
         Division_id: '',
         DivisionName: '',
         District_id: '',
         DistrictName: '',
         SubDistrict_id: '',
         SubDistrictName: '',
         Block_id: '',
         BlockName: '',
         Village_id: '',
         VillageName: '',

         Legislature_id: '',
         LegislatureName: '',
         LegislatureNameHindi: '',
         LegislatureNameLocal: '',

         Election_id: '',
         ElectionName: '',
         ElectionNameHindi: '',
         ElectionNameLocal: '',

         Consti_id: '',
         ConstiName: '',
         ConstiNameHindi: '',
         ConstiNameLocal: '',

         Candi_id: '',
         CandiName: '',
         CandiNameHindi: '',
         CandiNameLocal: '',
         CandiImage: '',

         Neta_id: '',
         NetaName: '',
         NetaNameHindi: '',
         NetaNameLocal: '',
         NetaImage: '',

         LegisMem_id: '',
         LegisMemName: '',
         LegisMemNameHindi: '',
         LegisMemNameLocal: '',
         LegisMemImage: '',

         Ministry_id: '',
         MinistryName: '',
         MinistryNameHindi: '',
         MinistryNameLocal: '',

         Department_id: '',
         DepartmentName: '',
         DepartmentNameHindi: '',
         DepartmentNameLocal: '',

         Office_id: '',
         OfficeName: '',
         OfficeNameHindi: '',
         OfficeNameLocal: '',

         Copy_To_Ministry_id: '',
         Copy_To_Department_id: '',
         Copy_To_Office_id: '',

         Party_id: '',
         PartyName: '',
         PartyNameHindi: '',
         PartyNameLocal: '',
         PartyFlag: '',
         PartySymbol: '',

         Party_OB_id: '',
         Party_OB_Name: '',
         Party_OB_NameHindi: '',
         Party_OB_NameLocal: '',
         Copy_To_Party_OB_id: '',

         SupportCount_Positive_Insider: 0,
         SupportCount_Positive_Outsider: 0,
         SupportCount_Negative_Insider: 0,
         SupportCount_Negative_Outsider: 0,
         SupportCount_Withdrawn: 0,
         SupportCount_Total: 0,

         SignCount_Insider: 0,
         SignCount_Outsider: 0,
         SignCount_Withdrawn: 0,
         SignCount_Total: 0,

         OriginalPetition_id: '',
         LinkedPetitions: [],

         AllowSignatories: false,
         SignatoriesCount: 0,

         ModBy: '',
         ModByName: '',
         ModByImage: '',
         ModDt: '',
         ModIP: '',
         ModReason: '',
      })
      setParagraph('')
      setParagraphsList([])
      setWeblink('')
      setWeblinksList([])
      setPLength(0)
      setTLength(0)
      setSelectedImage1(null)
      setImageUrl1(null)
      setSelectedImage2(null)
      setImageUrl2(null)
   }

   return (
      <Box sx={{ mx: 2 }}>
         <Box sx={{ p: 1, backgroundColor: '#D1D1D1', borderRadius: 2 }}>
            <FormControl size="small" fullWidth>
               <InputLabel>Category</InputLabel>
               <Select
                  value={petitionDetails.Category}
                  label="Category"
                  onChange={(e) => {
                     handleChangeCategory(e)
                  }}
                  sx={{ backgroundColor: '#ffffff' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {petitionCategoriesList &&
                     petitionCategoriesList.map((item) => (
                        <MenuItem key={item.Name} value={item.Name} sx={{ fontSize: 13 }}>
                           <Tooltip
                              title={<div style={{ whiteSpace: 'pre-line' }}>{`${item.Tip}\n\n${item.TipHindi}`}</div>}
                              placement="right"
                              arrow
                           >
                              <Box sx={{ border: 1, borderRadius: 1, whiteSpace: 'pre-line', px: 1 }}>
                                 {item.Name} {'\n'} {item.NameHindi}
                              </Box>
                           </Tooltip>
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         </Box>
         <Box sx={{ p: 1, mt: 1, backgroundColor: '#D1D1D1', borderRadius: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'left' }}>
               Title:
            </Typography>
            <TextField
               onBlur={(event) => event}
               slotProps={{ htmlInput: { maxLength: 100 } }}
               onChange={(event) => {
                  handleWriteTitle(event)
               }}
               value={petitionDetails.Title}
               name="Title"
               id="title"
               label="Title in Engish"
               variant="outlined"
               margin="dense"
               fullWidth
               autoComplete="off"
               autoFocus={true}
               sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>

            <TextField
               onBlur={(event) => event}
               slotProps={{ htmlInput: { maxLength: 100 } }}
               onChange={(event) => {
                  handleWriteTitleLocal(event)
               }}
               value={petitionDetails.TitleLocal}
               name="TitleLocal"
               id="titleLocal"
               label="Title in Other Language"
               variant="outlined"
               margin="dense"
               fullWidth
               autoComplete="off"
               sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>

            <TextField
               onBlur={(event) => event}
               slotProps={{ htmlInput: { maxLength: 100 } }}
               onChange={(event) => {
                  handleWriteTitleHindi(event)
               }}
               value={petitionDetails.TitleHindi}
               name="TitleHindi"
               id="titleHindi"
               label="Title in Hindi"
               variant="outlined"
               margin="dense"
               fullWidth
               autoComplete="off"
               sx={{ backgroundColor: '#ffffff', borderRadius: 1, display: 'none' }}
            ></TextField>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
               <Box>
                  <Typography
                     variant="body2"
                     sx={{
                        color:
                           petitionDetails.Title.length < 15 &&
                           petitionDetails.TitleLocal.length < 15 &&
                           petitionDetails.TitleHindi.length < 15
                              ? 'Red'
                              : 'Green',
                        mr: 3,
                     }}
                  >
                     Min length of at least one title: 15
                  </Typography>
               </Box>
               <Box>
                  <Typography
                     variant="body2"
                     sx={{
                        color: tLength === 0 && tLengthLocal === 0 && tLengthHindi === 0 ? '#808080' : '#635344',
                     }}
                  >
                     Length of largest title: {Math.max(tLength, tLengthLocal, tLengthHindi)} / {100}
                  </Typography>
               </Box>
            </Box>
         </Box>

         <Box sx={{ p: 1, mt: 1, backgroundColor: '#D1D1D1', borderRadius: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'left' }}>
               List of paragraphs:
            </Typography>
            <List dense>
               {paragraphsList &&
                  paragraphsList.length > 0 &&
                  paragraphsList.map((item, index) => (
                     <div key={index}>
                        <ListItem
                           sx={{ backgroundColor: '#FFFFFF' }}
                           secondaryAction={
                              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{
                                       height: '25px',
                                       width: '25px',
                                       mr: 0.5,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       '&:hover': { backgroundColor: '#03a9f4' },
                                    }}
                                    onClick={(e) => {
                                       handleMoveUp(e, item)
                                    }}
                                    disabled={index === 0}
                                 >
                                    <ArrowUpwardIcon sx={{ height: '20px', width: '20px' }} />
                                 </IconButton>

                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{
                                       height: '25px',
                                       width: '25px',
                                       mr: 0.5,
                                       backgroundColor: '#01579b',
                                       color: '#FFFFFF',
                                       '&:hover': { backgroundColor: '#03a9f4' },
                                    }}
                                    onClick={(e) => {
                                       handleMoveDown(e, item)
                                    }}
                                    disabled={index + 1 === paragraphsList.length}
                                 >
                                    <ArrowDownwardIcon sx={{ height: '20px', width: '20px' }} />
                                 </IconButton>

                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{
                                       height: '25px',
                                       width: '25px',
                                       mr: 0.5,
                                       backgroundColor: '#cc7900',
                                       color: '#FFFFFF',
                                       '&:hover': { backgroundColor: '#ff9800' },
                                    }}
                                    onClick={(e) => {
                                       handleEditParagraph(e, item)
                                    }}
                                 >
                                    <EditIcon sx={{ height: '20px', width: '20px' }} />
                                 </IconButton>

                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{
                                       height: '25px',
                                       width: '25px',
                                       backgroundColor: '#c62828',
                                       color: '#FFFFFF',
                                       '&:hover': { backgroundColor: '#ef5350' },
                                    }}
                                    onClick={(e) => {
                                       handleDeleteParagraph(e, item)
                                    }}
                                 >
                                    <DeleteIcon sx={{ height: '15px', width: '15px' }} />
                                 </IconButton>
                              </Box>
                           }
                        >
                           <ListItemText
                              primaryTypographyProps={{ fontWeight: 600 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line', fontSize: 16 }}
                              primary={`${item.Header}`}
                              secondary={item.Text}
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {!paragraphsList ||
               (paragraphsList.length < 1 && (
                  <Typography variant="body2" sx={{ textAlign: 'left', color: '#635344' }}>
                     Add a paragraph to get started ...
                  </Typography>
               ))}
            <Box>
               <TextField
                  onBlur={(event) => event}
                  slotProps={{ htmlInput: { maxLength: 25 } }}
                  onChange={(event) => {
                     handleWriteParaHeader(event)
                  }}
                  value={paraHeader}
                  name="Header"
                  id="header"
                  label="Paragraph header"
                  variant="outlined"
                  margin="dense"
                  autoComplete="off"
                  autoFocus={true}
                  sx={{ backgroundColor: '#ffffff', borderRadius: 1, width: '50%' }}
                  inputRef={paraHeaderRef}
               ></TextField>
            </Box>
            <Box>
               <Typography variant="body2" sx={{ color: '#808080', textAlign: 'left' }}>
                  Length of paragraph header: {pHLength} / {25}
               </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
               <TextField
                  onBlur={(event) => event}
                  // slotProps={{ htmlInput: { maxLength: 250 } }}
                  onChange={handleWriteParagraph}
                  value={paragraph}
                  name="Text"
                  id="text"
                  label="Add a Paragraph"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  size="small"
                  autoComplete="off"
                  // autoFocus={true}
                  onKeyDown={handleKeyDown}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
                  disabled={paragraphsList.length > 4}
               ></TextField>
               <IconButton
                  sx={{
                     ml: 0.5,
                     height: '25px',
                     width: '25px',
                     backgroundColor: '#cc7900',
                     color: '#FFFFFF',
                     '&:hover': { backgroundColor: '#ff9800' },
                  }}
                  disabled={paragraphsList.length > 4 || pLength < 100}
               >
                  <AddIcon
                     onClick={(e) => {
                        handleAddParagraph(e)
                     }}
                  />
               </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
               <Box>
                  <Typography
                     variant="body2"
                     sx={{
                        color: paragraph.length === 0 ? '#808080' : paragraph.length < 100 ? 'Red' : 'Green',
                        mr: 3,
                     }}
                  >
                     Min length: 100
                  </Typography>
               </Box>
               <Box>
                  <Typography
                     variant="body2"
                     sx={{
                        color: paragraph.length === 0 ? '#808080' : '#635344',
                     }}
                  >
                     Length of paragraph: {pLength} / {250}
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Box sx={{ p: 1, mt: 1, backgroundColor: '#D1D1D1', borderRadius: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'left' }}>
               Image 1:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Box>
                  <input
                     type="file"
                     id="select-image1"
                     onChange={handleChangeImageInput1}
                     accept="image/*"
                     ref={refImageInput1}
                  />
                  {imageUrl1 && selectedImage1 && (
                     <Button
                        variant="outlined"
                        sx={{ textTransform: 'none', py: 0, minWidth: 0 }}
                        onClick={RemoveImage1}
                     >
                        {' '}
                        Clear image
                     </Button>
                  )}
               </Box>
               <Box>{imageUrl1 && selectedImage1 && <img src={imageUrl1} className={myStyles.imgX} />}</Box>
            </Box>
         </Box>

         <Box sx={{ p: 1, mt: 1, backgroundColor: '#D1D1D1', borderRadius: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'left' }}>
               Image 2:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Box>
                  <input
                     type="file"
                     id="select-image2"
                     onChange={handleChangeImageInput2}
                     accept="image/*"
                     ref={refImageInput2}
                  />
                  {imageUrl2 && selectedImage2 && (
                     <Button
                        variant="outlined"
                        sx={{ textTransform: 'none', py: 0, minWidth: 0 }}
                        onClick={RemoveImage2}
                     >
                        {' '}
                        Clear image
                     </Button>
                  )}
               </Box>
               <Box>{imageUrl2 && selectedImage2 && <img src={imageUrl2} className={myStyles.imgX} />}</Box>
            </Box>
         </Box>

         <Box sx={{ p: 1, mt: 1, backgroundColor: '#D1D1D1', borderRadius: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'left' }}>
               Weblinks:
            </Typography>
            <List dense>
               {weblinksList &&
                  weblinksList.length > 0 &&
                  weblinksList.map((item, index) => (
                     <div key={index}>
                        <ListItem
                           sx={{ backgroundColor: '#FFFFFF' }}
                           secondaryAction={
                              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                 <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    sx={{
                                       height: '25px',
                                       width: '25px',
                                       backgroundColor: '#c62828',
                                       color: '#FFFFFF',
                                       '&:hover': { backgroundColor: '#ef5350' },
                                    }}
                                    onClick={(e) => {
                                       handleDeleteWeblink(e, item)
                                    }}
                                 >
                                    <DeleteIcon sx={{ height: '15px', width: '15px' }} />
                                 </IconButton>
                              </Box>
                           }
                        >
                           <ListItemText
                              primaryTypographyProps={{ fontWeight: 600 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line', fontSize: 16 }}
                              primary={`(${index + 1}).`}
                              secondary={item.Weblink}
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {!weblinksList ||
               (weblinksList.length < 1 && (
                  <Typography variant="body2" sx={{ textAlign: 'left', color: '#635344' }}>
                     Add a web link, if avaiable ...
                  </Typography>
               ))}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
               <TextField
                  onBlur={(event) => event}
                  onChange={handleWeblink}
                  value={weblink}
                  name="Weblink"
                  id="weblink"
                  label="Weblink / URL"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  autoComplete="off"
                  inputRef={weblinkRef}
                  onKeyDown={handleKeyDownWeblink}
                  disabled={weblinksList.length > 1}
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
               <IconButton
                  sx={{
                     ml: 0.5,
                     height: '25px',
                     width: '25px',
                     backgroundColor: '#cc7900',
                     color: '#FFFFFF',
                     '&:hover': { backgroundColor: '#ff9800' },
                  }}
                  disabled={weblinksList.length > 10 || errorMessage !== 'Is Valid URL'}
               >
                  <AddIcon
                     onClick={(e) => {
                        handleAddWeblink(e)
                     }}
                  />
               </IconButton>
            </Box>
            <Box
               sx={{ fontWeight: 'bold', fontSize: 14, color: errorMessage === 'Is Not Valid URL' ? 'Red' : 'Green' }}
            >
               {errorMessage || null}
            </Box>
         </Box>

         <Box sx={{ mt: 2 }}>
            {uploadingPetition && (
               <MyLoaderCircularGradient title={'Creating the petition ...'}></MyLoaderCircularGradient>
            )}
            {uploadedPetition && (
               <Alert variant="outlined" severity="success">
                  Petition created successfully.
               </Alert>
            )}
            {uploadingFailedPetition && (
               <Alert variant="outlined" severity="error">
                  Error occured! Petition could not be created.
               </Alert>
            )}
         </Box>
         <Box sx={{ mt: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               onClick={(e) => {
                  handleSubmit(e)
               }}
               disabled={
                  (petitionDetails.Title.length < 15 &&
                     petitionDetails.TitleLocal.length < 15 &&
                     petitionDetails.TitleHindi.length < 15) ||
                  paragraphsList.length < 1 ||
                  petitionDetails.Category === ''
               }
            >
               Submit The Petition
            </Button>
         </Box>
      </Box>
   )
}

RaisePetition.propTypes = {
   petitionCategoriesList: PropTypes.array.isRequired,
   handleRaisePetition: PropTypes.func.isRequired,
   uploadingPetition: PropTypes.bool.isRequired,
   uploadedPetition: PropTypes.bool.isRequired,
   uploadingFailedPetition: PropTypes.bool.isRequired,
}

export default RaisePetition
