import { Box, Button, List, ListSubheader, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AddArticle from './AddArticle'
import { styleModalBox, styleModalTypography } from '../../Utils/MUITheme/MUITheme'
import CustomizedListItemForManifesto from '../../Components/CustomizedListItems/CustomizedListItemForManifesto'
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function ElecManifestoText({ manifesto }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const userProfile = useSelector(selectUserProfile)
   const [nameOfUser, setNameOfUser] = useState('')
   useEffect(() => {
      if (userProfile && userProfile !== '') {
         // console.log('userProfile use state: ', userProfile, 'strata', consti.Strata)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
      }
   }, [userProfile])

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         fetchCats()
         fetchArticles()

         setFirstLoadStatus('success')

         console.log('ElecTheManifesto - first use effect blank')
      }
   }, [])

   const [mainCats, setMainCats] = useState('')
   const [publicCats, setPublicCats] = useState('')
   const [articles, setArticles] = useState('')
   console.log('articles: ', articles)

   const fetchCats = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'ManifestoCats')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists) {
            const activeMainCats = [...docSnap.data().MainCats].filter((item) => item.Active === true)
            const sortedMainCats = activeMainCats.sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setMainCats(sortedMainCats)
            const activePublicCats = [...docSnap.data().PublicCats].filter((item) => item.Active === true)
            const sortedPublicCats = activePublicCats.sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setPublicCats(sortedPublicCats)
         }
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   const [socialInfraList, setSocialInfraList] = useState('')
   const [socialBenefitsList, setSocialBenefitsList] = useState('')
   const [industryInfraList, setIndustryInfraList] = useState('')
   const [industryBenefitsList, setIndustryBenefitsList] = useState('')
   const [transportationInfraList, setTransportationInfraList] = useState('')
   const [transportationBenefitsList, setTransportationBenefitsList] = useState('')
   const [educationInfraList, setEducationInfraList] = useState('')
   const [educationBenefitsList, setEducationBenefitsList] = useState('')
   const [healthInfraList, setHealthInfraList] = useState('')
   const [healthBenefitsList, setHealthBenefitsList] = useState('')
   const [environmentInfraList, setEnvironmentInfraList] = useState('')
   const [environmentBenefitsList, setEnvironmentBenefitsList] = useState('')
   const [corruptionAllegationsList, setCorruptionAllegationsList] = useState('')
   const [corruptionTacklingList, setCorruptionTacklingList] = useState('')
   const [governanceEthicsList, setGovernanceEthicsList] = useState('')
   const [judiciaryList, setJudiciaryList] = useState('')
   const [civicFaciitiesList, setCivicFacilitiesList] = useState('')
   const [sportsInfraList, setSportsInfraList] = useState('')
   const [sportsBenefitsList, setSportsBenefitsList] = useState('')
   const [lawAndOrderList, setLawAndOrderList] = useState('')
   const [businessList, setBusinessList] = useState('')
   const [agricultureInfraList, setAgricultureInfraList] = useState('')
   const [agricultureBenefitsList, setAgricultureBenefitsList] = useState('')
   const [housingList, setHousingList] = useState('')
   const [employmentList, setEmploymentList] = useState('')
   const [defenceList, setDefenceList] = useState('')
   const [ruralDevelopmentList, setRuralDevelopmentList] = useState('')
   const [cultureAndHeritageList, setCultureAndHeritageList] = useState('')
   const [tourismList, setTourismList] = useState('')

   const fetchArticles = async () => {
      try {
         const docRef = doc(db, 'ElectionManifestoes', manifesto.id, 'Articles', '001')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists) {
            setArticles(docSnap.data().Articles)
         }
         console.log('fetched articles')
      } catch (err) {
         console.log('error fetching weblinks', err)
      }
   }

   useEffect(() => {
      if (articles) {
         console.log('articles changed:', articles)

         sortArticles()
      }
   }, [articles])

   const sortArticles = () => {
      const listSorted = [...articles].sort((a, b) => b.Priority - a.Priority)

      const socialInfra = listSorted.filter((item) => item.MainCat_id === 'Social-Welfare-Infrastructure')
      const socialBenefits = listSorted.filter((item) => item.MainCat_id === 'Social-Welfare-Benefits')
      setSocialInfraList(socialInfra)
      setSocialBenefitsList(socialBenefits)

      console.log('Social benefits; ', socialBenefits)
      const industryInfra = listSorted.filter((item) => item.MainCat_id === 'Industry-Infrastructure')
      const industryBenefits = listSorted.filter((item) => item.MainCat_id === 'Industry-Benefits')
      setIndustryInfraList(industryInfra)
      setIndustryBenefitsList(industryBenefits)

      const transportationInfra = listSorted.filter((item) => item.MainCat_id === 'Transportation-Infrastructure')
      const transportationBenefits = listSorted.filter((item) => item.MainCat_id === 'Transportation-Benefits')
      setTransportationInfraList(transportationInfra)
      setTransportationBenefitsList(transportationBenefits)

      const educationInfra = listSorted.filter((item) => item.MainCat_id === 'Education-Infrastructure')
      const educationBenefits = listSorted.filter((item) => item.MainCat_id === 'Education-Benefits')
      setEducationInfraList(educationInfra)
      setEducationBenefitsList(educationBenefits)

      const healthInfra = listSorted.filter((item) => item.MainCat_id === 'Health-Infrastructure')
      const healthBenefits = listSorted.filter((item) => item.MainCat_id === 'Health-Benefits')
      setHealthInfraList(healthInfra)
      setHealthBenefitsList(healthBenefits)

      const environmentInfra = listSorted.filter((item) => item.MainCat_id === 'Environment-Infrastructure')
      const environmentBenefits = listSorted.filter((item) => item.MainCat_id === 'Environment-Benefits')
      setEnvironmentInfraList(environmentInfra)
      setEnvironmentBenefitsList(environmentBenefits)

      const corruptionAllegations = listSorted.filter((item) => item.MainCat_id === 'Corruption-Allegations')
      const corruptionTackling = listSorted.filter((item) => item.MainCat_id === 'Corruption-Tackling')
      setCorruptionAllegationsList(corruptionAllegations)
      setCorruptionTacklingList(corruptionTackling)

      const governanceEthics = listSorted.filter((item) => item.MainCat_id === 'Governance-Ethics')
      setGovernanceEthicsList(governanceEthics)

      const judiciary = listSorted.filter((item) => item.MainCat_id === 'Judiciary')
      setJudiciaryList(judiciary)

      const civicFacilities = listSorted.filter((item) => item.MainCat_id === 'Civic-Facilities')
      setCivicFacilitiesList(civicFacilities)

      const sportsInfra = listSorted.filter((item) => item.MainCat_id === 'Sports-Infrastructure')
      const sportsBenefits = listSorted.filter((item) => item.MainCat_id === 'Sports-Benefits')
      setSportsInfraList(sportsInfra)
      setSportsBenefitsList(sportsBenefits)

      const lawAndOrder = listSorted.filter((item) => item.MainCat_id === 'Law-Order')
      setLawAndOrderList(lawAndOrder)

      const business = listSorted.filter((item) => item.MainCat_id === 'Business')
      setBusinessList(business)

      const agricultureInfra = listSorted.filter((item) => item.MainCat_id === 'Agriculture-Infrastructure')
      const agricultureBenefits = listSorted.filter((item) => item.MainCat_id === 'Agriculture-Benefits')
      setAgricultureInfraList(agricultureInfra)
      setAgricultureBenefitsList(agricultureBenefits)

      const housing = listSorted.filter((item) => item.MainCat_id === 'Housing')
      setHousingList(housing)

      const employment = listSorted.filter((item) => item.MainCat_id === 'Employment')
      setEmploymentList(employment)

      const defence = listSorted.filter((item) => item.MainCat_id === 'National-Security-And-Defence')
      setDefenceList(defence)

      const ruralDevelopment = listSorted.filter((item) => item.MainCat_id === 'Rural-Development')
      setRuralDevelopmentList(ruralDevelopment)

      const cutureAndHeritage = listSorted.filter((item) => item.MainCat_id === 'Culture-And-Heritage')
      setCultureAndHeritageList(cutureAndHeritage)

      const tourism = listSorted.filter((item) => item.MainCat_id === 'Travel-And-Tourism')
      setTourismList(tourism)

      console.log('sorted articles')
   }

   // related to Article modal
   const [openModalArticle, setOpenModalArticle] = useState(false)

   const handleOpenModalArticle = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalArticle(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalArticle = () => setOpenModalArticle(false)

   const handleAddArticle = async (articleDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            articleDetails.CrBy = auth.currentUser.uid
            articleDetails.CrByName = nameOfUser
            articleDetails.CrDt = new Date()
            articleDetails.CrIP = ip.data.ip
            //
            const docRef = doc(db, 'ElectionManifestoes', manifesto.id, 'Articles', '001')
            const docSnap = await getDoc(docRef)

            if (docSnap.exists()) {
               await updateDoc(docRef, {
                  Articles: arrayUnion(articleDetails),
               })
            } else {
               setDoc(docRef, { Articles: [articleDetails] }, { merge: true })
            }

            setArticles([...articles, articleDetails])
            console.log('added article', articleDetails)
            // close modal
            // setOpenModalArticle(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Manifesto titled "${manifesto.Name} issued by ${manifesto.IssuedByPartyName} for ${manifesto.ElectionName}`}</title>
            </Helmet>
         </div>
         <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            {(isDataEditor || isSuper) && (
               <Button
                  onClick={(e) => {
                     handleOpenModalArticle(e)
                  }}
                  size="small"
                  variant="outlined"
                  sx={{ py: 0, px: 1, minWidth: 0 }}
               >
                  Add an article
               </Button>
            )}
         </Box>

         <Box>
            <List
               sx={{ width: '100%', bgcolor: 'background.paper' }}
               component="nav"
               aria-labelledby="nested-list-subheader"
               subheader={
                  <ListSubheader
                     component="div"
                     id="nested-list-subheader"
                     sx={{ fontSize: 16, fontWeight: 600, color: '#000000' }}
                  >
                     {manifesto.Name}
                  </ListSubheader>
               }
            >
               {mainCats &&
                  mainCats.map((item, index) => (
                     <div key={index}>
                        <CustomizedListItemForManifesto
                           key={item.Id}
                           item={item}
                           socialInfraList={socialInfraList}
                           socialBenefitsList={socialBenefitsList}
                           industryInfraList={industryInfraList}
                           industryBenefitsList={industryBenefitsList}
                           transportationInfraList={transportationInfraList}
                           transportationBenefitsList={transportationBenefitsList}
                           educationInfraList={educationInfraList}
                           educationBenefitsList={educationBenefitsList}
                           healthInfraList={healthInfraList}
                           healthBenefitsList={healthBenefitsList}
                           environmentInfraList={environmentInfraList}
                           environmentBenefitsList={environmentBenefitsList}
                           corruptionAllegationsList={corruptionAllegationsList}
                           corruptionTacklingList={corruptionTacklingList}
                           governanceEthicsList={governanceEthicsList}
                           judiciaryList={judiciaryList}
                           civicFaciitiesList={civicFaciitiesList}
                           lawAndOrderList={lawAndOrderList}
                           sportsInfraList={sportsInfraList}
                           sportsBenefitsList={sportsBenefitsList}
                           businessList={businessList}
                           agricultureInfraList={agricultureInfraList}
                           agricultureBenefitsList={agricultureBenefitsList}
                           housingList={housingList}
                           employmentList={employmentList}
                           defenceList={defenceList}
                           ruralDevelopmentList={ruralDevelopmentList}
                           cultureAndHeritageList={cultureAndHeritageList}
                           tourismList={tourismList}
                        />
                     </div>
                  ))}
            </List>
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalArticle}
               onClose={handleCloseModalArticle}
               aria-labelledby="modal-modal-title-Article"
               aria-describedby="modal-modal-description-Article"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-Article"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add an Article
                  </Typography>
                  <AddArticle
                     handleAddArticle={handleAddArticle}
                     mainCats={mainCats}
                     publicCats={publicCats}
                  ></AddArticle>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

ElecManifestoText.propTypes = {
   manifesto: PropTypes.object.isRequired,
}

export default ElecManifestoText
