import { Alert, Box, Button, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import validator from 'validator'
import myStyles from './ElecExitPolls.module.css'

function AddAgency({ handleAddAgency, lastAgency, uploadingAgency, uploadedAgency, uploadingFailedAgency }) {
   //
   const [agencyDetails, setAgencyDetails] = useState({
      Name: '',
      NameHindi: '',
      NameLocal: '',
      Abbreviation: '',
      Logo: '',
      Categories: ['Exit Polls'],
      CategoriesObj: [],
      WeblinkMain: '',
      Weblinks: [],
   })

   function handleAgencyDetails(e) {
      setAgencyDetails({ ...agencyDetails, [e.target.name]: e.target.value })
   }

   useEffect(() => {
      console.log('Agency details on change of its value: ', agencyDetails)
   }, [agencyDetails])

   const [selectedImage, setSelectedImage] = useState(null)
   const [imageUrl, setImageUrl] = useState(null)
   const refImageInput = useRef()

   function handleChangeImageInput(e) {
      if (e.target.files[0]) {
         setSelectedImage(e.target.files[0])
         // setImageUrl(URL.createObjectURL(e.target.files[0]))
      } else {
         setSelectedImage(null)
         setImageUrl(null)
      }
   }

   useEffect(() => {
      if (selectedImage) {
         setImageUrl(URL.createObjectURL(selectedImage))
         setAgencyDetails({
            ...agencyDetails,
            Logo: selectedImage,
         })
      }
   }, [selectedImage])

   function removeImage() {
      setSelectedImage(null)
      setImageUrl(null)
      refImageInput.current.value = ''
      setAgencyDetails({
         ...agencyDetails,
         Logo: '',
      })
   }

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
         setAgencyDetails({ ...agencyDetails, WeblinkMain: value })
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleWeblinkDetails(event) {
      validate(event.target.value)
   }

   const handleSubmit = (e) => {
      e.preventDefault()

      if (agencyDetails.Name.length > 1) {
         handleAddAgency(agencyDetails)
      }

      setAgencyDetails({
         Name: '',
         NameHindi: '',
         NameLocal: '',
         Abbreviation: '',
         Logo: '',
         Categories: ['Exit Polls'],
         CategoriesObj: [],
         WeblinkMain: '',
         Weblinks: [],
      })
      setErrorMessage('')
      setSelectedImage(null)
      setImageUrl(null)
      refImageInput.current.value = ''
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 2,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAgencyDetails(e)
               }}
               value={agencyDetails.Name}
               name="Name"
               id="name"
               label="Name"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAgencyDetails(e)
               }}
               value={agencyDetails.NameHindi}
               name="NameHindi"
               id="nameHindi"
               label="Name in Hindi"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAgencyDetails(e)
               }}
               value={agencyDetails.NameLocal}
               name="NameLocal"
               id="nameLocal"
               label="Name in Local Language"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box sx={{ mb: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handleAgencyDetails(e)
               }}
               value={agencyDetails.Abbreviation}
               name="Abbreviation"
               id="abbreviation"
               label="Abbreviation"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <Box className={myStyles.flexRow}>
            <input
               type="file"
               id="select-image"
               onChange={handleChangeImageInput}
               accept="image/*"
               ref={refImageInput}
            />
            {imageUrl && selectedImage && (
               <Button variant="outlined" sx={{ textTransform: 'none', py: 0, minWidth: 0 }} onClick={removeImage}>
                  {' '}
                  Clear image
               </Button>
            )}
         </Box>
         {imageUrl && selectedImage && <img src={imageUrl} className={myStyles.imgX} />}

         <Box sx={{ my: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={handleWeblinkDetails}
               value={agencyDetails.WeblinkMain}
               name="Weblink"
               id="weblink"
               label="Website"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <br />
         <span
            style={{
               fontWeight: 'bold',
               color: errorMessage === 'Is Not Valid URL' ? 'Red' : 'Green',
            }}
         >
            {errorMessage}
         </span>

         <Box sx={{ my: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmit(e)
               }}
               disabled={agencyDetails.Name.length < 2}
            >
               Submit
            </Button>
         </Box>
         <Box sx={{ mb: 2 }}>
            {uploadingAgency && (
               <MyLoaderCircularGradient title={`Creating exit poll by ${lastAgency} ...`}></MyLoaderCircularGradient>
            )}
            {uploadedAgency && (
               <Alert variant="outlined" severity="success">
                  <strong>{lastAgency}</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedAgency && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>{lastAgency}</strong> could not be listed.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

AddAgency.propTypes = {
   handleAddAgency: PropTypes.func.isRequired,
   lastAgency: PropTypes.string.isRequired,
   uploadingAgency: PropTypes.bool.isRequired,
   uploadedAgency: PropTypes.bool.isRequired,
   uploadingFailedAgency: PropTypes.bool.isRequired,
}

export default AddAgency
