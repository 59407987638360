import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import validator from 'validator'

function AddWeblinkWithPriority({ handleAddWeblinkWithPriority }) {
   //
   const [weblinkDetails, setWeblinkDetails] = useState({
      Weblink: '',
      Priority: 0,
      Description: '',

      Removed: false,
      RemovedBy: '',
      RemovedByName: '',
      RemovedByImage: '',
      RemovedDt: '',
      RemovedIP: '',
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', weblinkDetails)
   }, [weblinkDetails])

   const [errorMessage, setErrorMessage] = useState('')

   const validate = (value) => {
      if (validator.isURL(value)) {
         setErrorMessage('Is Valid URL')
      } else {
         setErrorMessage('Is Not Valid URL')
      }
   }

   function handleDescriptionDetails(event) {
      setWeblinkDetails({ ...weblinkDetails, [event.target.name]: event.target.value })
   }

   function handlePriorityDetails(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setWeblinkDetails({ ...weblinkDetails, Priority: newValue })
            : setWeblinkDetails({ ...weblinkDetails, Priority: 0 })
      } else {
         setWeblinkDetails({ ...weblinkDetails, Priority: 0 })
      }
   }

   function handleWeblinkDetails(event) {
      setWeblinkDetails({ ...weblinkDetails, [event.target.name]: event.target.value })
      validate(event.target.value)
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         handleSubmitWeblink(event)
      }
   }

   function handleSubmitWeblink(e) {
      e.preventDefault()

      if (weblinkDetails.Text === '') {
         alert('Please add some text.')
         return
      }

      if (errorMessage !== '' || errorMessage === 'Is Valid URL') {
         handleAddWeblinkWithPriority(weblinkDetails)
         setErrorMessage('Submitted ...')
      }

      reset()
   }

   function reset() {
      setWeblinkDetails({
         Weblink: '',
         Priority: 0,
         Description: '',

         Removed: false,
         RemovedBy: '',
         RemovedByName: '',
         RemovedByImage: '',
         RemovedDt: '',
         RemovedIP: '',
      })
   }

   return (
      <Box sx={{ m: 2 }}>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleWeblinkDetails}
               value={weblinkDetails.Weblink}
               name="Weblink"
               id="weblink"
               label="Web link"
               variant="outlined"
               margin="dense"
               required
               fullWidth
               size="small"
               autoComplete="off"
               autoFocus={true}
               onKeyDown={handleKeyDown}
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <br />
         <span
            style={{
               fontWeight: 'bold',
               color: errorMessage === 'Is Not Valid URL' ? 'Red' : 'Green',
            }}
         >
            {errorMessage}
         </span>
         <Box sx={{ mt: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handlePriorityDetails(e)
               }}
               value={weblinkDetails.Priority}
               name="Priority"
               id="priority"
               label="Priority"
               variant="outlined"
               margin="dense"
               // required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleDescriptionDetails}
               value={weblinkDetails.Description}
               name="Description"
               id="description"
               label="Description"
               variant="outlined"
               margin="dense"
               // required
               fullWidth
               multiline
               rows={4}
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmitWeblink(e)
               }}
               disabled={errorMessage === 'Is Not Valid URL'}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

AddWeblinkWithPriority.propTypes = {
   handleAddWeblinkWithPriority: PropTypes.func.isRequired,
}

export default AddWeblinkWithPriority
