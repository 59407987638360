import React, { useEffect, useState } from 'react'

import { Alert, Box, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PropTypes from 'prop-types'

import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'

function AddNewScam({ uploadingScam, uploadedScam, uploadingScamFailed, handleAddScam, lastScam }) {
   /// /
   /// /
   const [scamDetails, setScamDetails] = useState({
      Title: '',
      TitleOld: [],
      TitleHindi: '',
      TitleHindiOld: [],
      TitleLocal: '',
      TitleLocalOld: [],
      TitleAlt: [],
      TitleAltOld: [],

      ImageMain: '',

      DateStart: '',
      DateStartOld: '',

      DatePublic: '',
      DatePublicSource: '',

      BaseParagraphs: [],

      WeblinksCount: 0,
      IsActive: true,
   })

   useEffect(() => {
      console.log('scam details on change: ', scamDetails)
   }, [scamDetails])

   function handleScamDetails(e) {
      setScamDetails({ ...scamDetails, [e.target.name]: e.target.value })
   }

   const [datePublic, setDatePublic] = useState('')
   useEffect(() => {
      if (datePublic) {
         setScamDetails({ ...scamDetails, DatePublic: datePublic.$d })
      }
   }, [datePublic])

   const handleSubmitScam = async (e) => {
      e.preventDefault()
      if (scamDetails.Title.length >= 5 && scamDetails.DatePublic !== '') {
         console.log('scamDetails before submit: ', scamDetails)
         handleAddScam(scamDetails)
         setScamDetails({
            Title: '',
            TitleOld: [],
            TitleHindi: '',
            TitleHindiOld: [],
            TitleLocal: '',
            TitleLocalOld: [],
            TitleAlt: [],
            TitleAltOld: [],

            ImageMain: '',
            ImageDisplayGroup: [],
            VideoMain: '',
            VideoDisplayGroup: [],

            DateStart: '',
            DateStartOd: '',

            DatePublic: '',
            DatePublicOld: [],
            DatePublicSource: '',

            BaseParagraphs: [],

            WeblinksCount: 0,
            IsActive: true,
         })
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 1 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleScamDetails(e)
                  }}
                  value={scamDetails.Title}
                  name="Title"
                  id="title"
                  label="Title"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleScamDetails(e)
                  }}
                  value={scamDetails.TitleHindi}
                  name="TitleHindi"
                  id="titleHindi"
                  label="Title in Hindi"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <TextField
                  onBlur={(event) => event}
                  onChange={(e) => {
                     handleScamDetails(e)
                  }}
                  value={scamDetails.TitleLocal}
                  name="TitleLocal"
                  id="titleLocal"
                  label="Title in Local Language"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               ></TextField>
            </div>
            <div>
               <DatePicker
                  label="Start Date"
                  name="DatePublic"
                  value={scamDetails.DatePublic != null ? dayjs(scamDetails.DatePublic) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDatePublic(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
               />
            </div>
            <Box sx={{ my: 2 }}>
               {uploadingScam && (
                  <MyLoaderCircularGradient title={'Listing the scamtion ...'}></MyLoaderCircularGradient>
               )}
               {uploadedScam && (
                  <Alert variant="outlined" severity="success">
                     <strong>{lastScam}</strong> listed successfully.
                  </Alert>
               )}
               {uploadingScamFailed && (
                  <Alert variant="outlined" severity="error">
                     Error occured! <strong>{lastScam}</strong> could not be listed.
                  </Alert>
               )}
            </Box>
            <Box>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitScam(e)
                  }}
                  disabled={scamDetails.Title.length < 5 || scamDetails.DatePublic === ''}
               >
                  Submit
               </Button>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddNewScam.propTypes = {
   handleAddScam: PropTypes.func.isRequired,
   uploadingScam: PropTypes.bool.isRequired,
   uploadedScam: PropTypes.bool.isRequired,
   uploadingScamFailed: PropTypes.bool.isRequired,
   lastScam: PropTypes.string.isRequired,
}

export default AddNewScam
