import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Paper,
   Radio,
   RadioGroup,
   styled,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Microlink from '@microlink/react'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import AddNewsElection from '../../../Components/Edits/AddNewsElection'
import { useNavigate } from 'react-router-dom'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import {
   addDoc,
   arrayUnion,
   collection,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
} from 'firebase/firestore'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'

import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import moment from 'moment'
import AddExitPoll from './AddExitPoll'
import AddExitPollReports from './AddExitPollReports'
import AddAgency from './AddAgency'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { v4 } from 'uuid'
import myStyles from './ElecExitPolls.module.css'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'
import LanguageIcon from '@mui/icons-material/Language'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
   },
}))
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#c62828',
      color: theme.palette.common.white,
      fontFamily: 'Poppins',
      fontWeight: 800,
      fontSize: 20,
      p: 0,
      width: '30%',
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
   },
}))

const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      color: theme.palette.common.black,
      // backgroundColor: '#F0B99A',
      // backgroundImage: 'linear-gradient( 92.7deg,  rgba(245,212,212,1) 8.5%, rgba(252,251,224,1) 90.2% )',
      backgroundImage:
         'radial-gradient( circle 321px at 8.3% 75.7%,  rgba(209,247,241,1) 0%, rgba(249,213,213,1) 81% )',
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
   },
}))

const StyledTableCell4 = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      fontFamily: 'Poppins',
      fontWeight: 800,
      fontSize: 20,
      padding: 0,
      width: '30%',
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
   },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
   },
   // hide last border
   '&:last-child td, &:last-child th': {
      border: 0,
   },
}))

function ElecExitPolls({
   elec,
   candidatesListRoot,
   exitPollLinksListRoot,
   exitPollLinksList,
   handleExitPollLinksUpdate,
}) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')
   console.log(thisUser)
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [agencies, setAgencies] = useState([])

   const [exitPollsList, setExitPollsList] = useState('')
   const [exitPollsReportsList, setExitPollsReportsList] = useState('')
   const [partiesList, setPartiesList] = useState('')
   const [alliancesList, setAlliancesList] = useState('')
   const [viewField, setViewField] = useState('Reports')

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchAgencies()

         const listConsolidated = candidatesListRoot.filter(
            (item) => item && Object.keys(item).length > 0 && item.Name && item.Name.toLowerCase() !== 'zznota',
         )

         // get the list of all parties that are associated with elected members
         const parties = []
         listConsolidated.forEach((candi) => {
            const partyObj = {
               Party_id: candi.Party_id,
               PartyName: candi.PartyName,
               PartyNameHindi: candi.PartyNameHindi,
               PartyNameLocal: candi.PartyNameLocal,
               PartyAbbreviation: candi.PartyAbbreviation,
               PartyFlag: candi.PartyFlag,
            }
            parties.push(partyObj)
         })
         // now create a list of unique items in parties list
         // in the following code, we can have multiple conditions
         const partiesUnique = parties.filter(
            (ele, ind) => ind === parties.findIndex((elem) => elem.Party_id === ele.Party_id),
         )
         const sortedParties = partiesUnique.sort((a, b) => {
            if (b.PartyName.trim() > a.PartyName.trim()) {
               return -1
            } else if (b.PartyName.trim() < a.PartyName.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setPartiesList(sortedParties)

         // get the list of all alliances that are associated with elected members
         const alliances = []
         listConsolidated.forEach((candi) => {
            alliances.push(...candi.Alliances)
         })

         // now create a list of unique items in alliances list
         // in the following code, we can have multiple conditions
         // console.log('alliances: ', alliances)
         const alliancesUnique = alliances.filter(
            (ele, ind) => ind === alliances.findIndex((elem) => elem.Alliance_id === ele.Alliance_id),
         )
         console.log('alliancesUnique: ', alliancesUnique)
         const sortedAlliances = alliancesUnique.sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         console.log('sortedAlliances: ', sortedAlliances)
         setAlliancesList(sortedAlliances)

         // get the list of all exit polls and their reports
         fetchExitPolls()

         setFirstLoadStatus('success')
      }
   }, [])

   const fetchAgencies = async (e) => {
      const querry = query(collection(db, 'Agencies'))
      const querySnapshot = await getDocs(querry)

      const listFetched = []

      // if (querySnapshot.exists()) {
      querySnapshot.forEach((docSnap) => {
         listFetched.push({ ...docSnap.data(), id: docSnap.id })
      })

      const sortedList = listFetched.sort((a, b) => {
         if (b.Name.trim() > a.Name.trim()) {
            return -1
         } else if (b.Name.trim() < a.Name.trim()) {
            return 1
         } else {
            return 0
         }
      })

      setAgencies(sortedList)
      console.log('agencies fetched')
   }

   const fetchExitPolls = async (e) => {
      const docRef = doc(db, 'Elections', elec.id, 'ExitPolls', '001')
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
         console.log('inside fetchExitPolls: ', docSnap.data())
         if (docSnap.data().ExitPolls && docSnap.data().ExitPolls.length > 0) {
            const list = []
            const withWebsite = docSnap.data().ExitPolls.filter((item) => item.WeblinkMain !== '')
            const withWebsiteSorted = withWebsite.sort((a, b) => {
               if (b.AgencyName.trim() > a.AgencyName.trim()) {
                  return -1
               } else if (b.AgencyName.trim() < a.AgencyName.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            list.push(...withWebsiteSorted)
            const noWebsite = docSnap.data().ExitPolls.filter((item) => item.WeblinkMain === '')
            const noWebsiteSorted = noWebsite.sort((a, b) => {
               if (b.AgencyName.trim() > a.AgencyName.trim()) {
                  return -1
               } else if (b.AgencyName.trim() < a.AgencyName.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            list.push(...noWebsiteSorted)
            setExitPollsList(list)
            console.log(' polls list: ', list)
         } else {
            setExitPollsList([])
         }
         if (docSnap.data().ExitPollsReports && docSnap.data().ExitPollsReports.length > 0) {
            setExitPollsReportsList(docSnap.data().ExitPollsReports)
            console.log('ExitPollsReports', docSnap.data().ExitPollsReports)
         } else {
            setExitPollsReportsList([])
         }
      }
   }

   const handleViewField = async (e) => {
      e.preventDefault()
      setViewField(e.target.value)
   }

   // const chaffPartiesList = async (e) => {
   //    const parties = []
   //    console.log('candi list root inside chaff parties', candidatesListRoot)
   //    candidatesListLocal.forEach((candi) => {
   //       const partyObj = {
   //          Party_id: candi.Party_id,
   //          PartyName: candi.PartyName,
   //          PartyNameHindi: candi.PartyNameHindi,
   //          PartyNameLocal: candi.PartyNameLocal,
   //          PartyAbbreviation: candi.PartyAbbreviation,
   //          PartyFlag: candi.PartyFlag,
   //       }
   //       parties.push(partyObj)
   //    })
   //    console.log('parties list root inside chaff parties', parties)
   //    // now create a list of unique items in parties list
   //    // in the following code, we can have multiple conditions
   //    const partiesUnique = parties.filter(
   //       (ele, ind) => ind === parties.findIndex((elem) => elem.Party_id === ele.Party_id),
   //    )
   //    const sortedParties = partiesUnique.sort((a, b) => {
   //       if (b.PartyName.trim() > a.PartyName.trim()) {
   //          return -1
   //       } else if (b.PartyName.trim() < a.PartyName.trim()) {
   //          return 1
   //       } else {
   //          return 0
   //       }
   //    })

   //    setPartiesList(sortedParties)
   // }

   // const chaffAlliancesList = async (e) => {
   //    const alliances = []
   //    console.log('candi list root inside chaff alliance', candidatesListRoot)
   //    candidatesListLocal.forEach((candi) => {
   //       alliances.push(...candi.Alliances)
   //    })
   //    console.log('alliances list root inside chaff parties', alliances)
   //    // now create a list of unique items in alliances list
   //    // in the following code, we can have multiple conditions
   //    // console.log('alliances: ', alliances)
   //    const alliancesUnique = alliances.filter(
   //       (ele, ind) => ind === alliances.findIndex((elem) => elem.Alliance_id === ele.Alliance_id),
   //    )
   //    const sortedAlliances = alliancesUnique.sort((a, b) => {
   //       if (b.Name.trim() > a.Name.trim()) {
   //          return -1
   //       } else if (b.Name.trim() < a.Name.trim()) {
   //          return 1
   //       } else {
   //          return 0
   //       }
   //    })

   //    setAlliancesList(sortedAlliances)
   // }

   // for news weblink
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handeAddNewsElection = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            weblinkDetails.Candidate_id = ''
            weblinkDetails.Constituency_id = ''
            weblinkDetails.Parties = []
            weblinkDetails.Alliances = []
            weblinkDetails.Election = elec.id

            const duplicateItem = exitPollLinksListRoot.find((item) => item.Weblink === weblinkDetails.Weblink)

            if (duplicateItem) {
               alert('This link is already present.')
            } else {
               try {
                  let coreListId = ''
                  let exitPollLinksCount = 0

                  // we are fetching docElecFresh so that we get the real count of weblinks just before the entry of new weblink
                  const docRefElecFresh = doc(db, 'Elections', elec.id)
                  const docSnapElecFresh = await getDoc(docRefElecFresh)
                  exitPollLinksCount = docSnapElecFresh.data().ExitPollLinksCount

                  const slab = 1000
                  const quotient = Math.floor(exitPollLinksCount / slab)
                  // const remainder = candidatesCount % slab
                  const num = quotient + 1
                  const numStr = String(num).padStart(6, '0')

                  // coreListId = 'CandiCoreList-' + numStr
                  coreListId = numStr

                  weblinkDetails.CoreListId = coreListId

                  //
                  const docRefElecSubCollWeblinks = doc(db, 'Elections', elec.id, 'ExitPollLinksLists', coreListId)
                  const docSnapSubColElec = await getDoc(docRefElecSubCollWeblinks)
                  if (docSnapSubColElec.exists()) {
                     await updateDoc(docRefElecSubCollWeblinks, {
                        ExitPollLinks: arrayUnion(weblinkDetails),
                     })
                  } else {
                     setDoc(docRefElecSubCollWeblinks, { ExitPollLinks: [weblinkDetails] }, { merge: true })
                  }

                  await updateDoc(docRefElecFresh, {
                     ExitPollLinksCount: increment(1),
                  })

                  // update the website display
                  // canceled the following as date from here was causing display problem.
                  handleExitPollLinksUpdate(weblinkDetails)
               } catch (error) {
                  //
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to exit poll
   const [openModalExitPoll, setOpenModalExitPoll] = useState(false)

   const [lastExitPoll, setLastExitPoll] = useState('')
   const [uploadingExitPoll, setUploadingExitPoll] = useState(false)
   const [uploadedExitPoll, setUploadedExitPoll] = useState(false)
   const [uploadingFailedExitPoll, setUploadingFailedExitPoll] = useState(false)

   const handleOpenModalExitPoll = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            console.log('agencies: ', agencies)
            setOpenModalExitPoll(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalExitPoll = () => setOpenModalExitPoll(false)

   const handleAddExitPoll = async (agencyDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            agencyDetails.CrBy = auth.currentUser.uid
            agencyDetails.CrDt = new Date()
            agencyDetails.CrIP = ip.data.ip

            const detailsForMainDoc = {
               ...agencyDetails,
               Election_id: elec.id,
               ElectionName: elec.Name,
               ElectionNameHindi: elec.NameHindi,
               ElectionNameLocal: elec.NameLocal,
               Legislature_id: elec.Legislature_id,
               LegislatureName: elec.LegislatureName,
               LegislatureNameHindi: elec.LegislatureNameHindi,
               LegislatureNameLocal: elec.LegislatureNameLocal,
            }

            const duplicateItem = exitPollsList.find((item) => item.AgencyName === agencyDetails.AgencyName)

            if (duplicateItem) {
               alert(`This agency's poll is already present.`)
            } else {
               if (agencyDetails.AgencyName.length > 1) {
                  setUploadingExitPoll(true)
                  setLastExitPoll(agencyDetails.AgencyName)
                  try {
                     // 1. create the main doc of Exit Poll
                     const docRefExitPoll = await addDoc(collection(db, 'ExitPolls'), detailsForMainDoc)
                     const newRecord = { ...agencyDetails, ExitPoll_id: docRefExitPoll.id }

                     // 2. Add the Exit Poll record to the coreList of Election
                     const docRefElecSubCollExitPolls = doc(db, 'Elections', elec.id, 'ExitPolls', '001')
                     const docSnapElecSubCollExitPolls = await getDoc(docRefElecSubCollExitPolls)

                     if (docSnapElecSubCollExitPolls.exists()) {
                        // 2.1 means at least one record has been inserted erliear for this subcollection
                        // update subcollection 'Exit Polls' of election with new values
                        await updateDoc(docRefElecSubCollExitPolls, {
                           ExitPolls: arrayUnion(newRecord),
                        })
                     } else {
                        // 2.2 means no record has been inserted for this subcollection
                        // create the subcollection Exit Polls with new values
                        setDoc(docRefElecSubCollExitPolls, { ExitPolls: [newRecord] }, { merge: true })
                     }

                     // 3. Add the Exit Poll  record to the core list of Agency
                     const docRefAgencySubCollExitPolls = doc(
                        db,
                        'Agencies',
                        agencyDetails.Agency_id,
                        'ExitPolls',
                        '000001',
                     )
                     const docSnapAgencySubCollExitPolls = await getDoc(docRefAgencySubCollExitPolls)
                     if (docSnapAgencySubCollExitPolls.exists()) {
                        // 3.1 means at least one record has been inserted erliear for this subcollection
                        // update subcollection 'Exit Polls' of election with new values
                        await updateDoc(docRefAgencySubCollExitPolls, {
                           ExitPolls: arrayUnion(newRecord),
                        })
                     } else {
                        // 3.2 means no record has been inserted for this subcollection
                        // create the subcollection Exit Polls with new values
                        setDoc(docRefAgencySubCollExitPolls, { ExitPolls: [newRecord] }, { merge: true })
                     }

                     // 4. update the local state of exit polls list
                     setExitPollsList([...exitPollsList, newRecord])

                     setUploadingExitPoll(false)
                     setUploadedExitPoll(true)
                  } catch (error) {
                     console.log('error creating poll: ', error)
                     setUploadingExitPoll(false)
                     setUploadedExitPoll(false)
                     setUploadingFailedExitPoll(true)
                  }
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to exit poll Report
   const [openModalExitPollReport, setOpenModalExitPollReport] = useState(false)

   const [lastExitPollReport, setLastExitPollReport] = useState('')
   const [uploadingExitPollReport, setUploadingExitPollReport] = useState(false)
   const [uploadedExitPollReport, setUploadedExitPollReport] = useState(false)
   const [uploadingFailedExitPollReport, setUploadingFailedExitPollReport] = useState(false)

   const handleOpenModalExitPollReport = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalExitPollReport(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalExitPollReport = () => setOpenModalExitPollReport(false)

   const handleAddExitPollReport = async (reportDetails, reportDetailsForElec) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            reportDetails.CrBy = auth.currentUser.uid
            reportDetails.CrDt = new Date()
            reportDetails.CrIP = ip.data.ip

            reportDetailsForElec.CrBy = auth.currentUser.uid
            reportDetailsForElec.CrDt = new Date()
            reportDetailsForElec.CrIP = ip.data.ip

            const duplicateItem = exitPollsReportsList.find(
               (item) =>
                  item.ExitPoll_id === reportDetailsForElec.ExitPoll_id &&
                  item.Party_id === reportDetailsForElec.Party_id &&
                  item.Alliance_id === reportDetailsForElec.Alliance_id &&
                  item.IsRemoved === false,
            )

            if (duplicateItem) {
               alert(`This report is already present.`)
            } else {
               if (
                  (reportDetailsForElec.ExitPoll_id !== '' &&
                     (reportDetailsForElec.Party_id !== '' || reportDetailsForElec.Alliance_id !== '')) ||
                  (reportDetailsForElec.VotePercent_Avg !== '' && reportDetailsForElec.Seats_Avg !== '') ||
                  (reportDetailsForElec.VotePercent_Max !== '' && reportDetailsForElec.Seats_Max !== '') ||
                  (reportDetailsForElec.VotePercent_Min !== '' && reportDetailsForElec.Seats_Min !== '')
               ) {
                  setUploadingExitPollReport(true)
                  setLastExitPollReport(reportDetailsForElec.ExitPollName)
                  try {
                     // 1. Update the main doc of Exit Poll after removing ExitPoll_id and ExitPollName
                     const docRefExitPoll = doc(collection(db, 'ExitPolls'), reportDetailsForElec.ExitPoll_id)
                     await updateDoc(docRefExitPoll, {
                        Reports: arrayUnion(reportDetails),
                     })

                     // 2. Add the Exit Poll Report to the coreList of Election
                     const docRefElecSubCollExitPolls = doc(db, 'Elections', elec.id, 'ExitPolls', '001')

                     await updateDoc(docRefElecSubCollExitPolls, {
                        ExitPollsReports: arrayUnion(reportDetailsForElec),
                     })

                     // 3. update the local state of exit polls reports list
                     setExitPollsReportsList([...exitPollsReportsList, reportDetailsForElec])

                     setUploadingExitPollReport(false)
                     setUploadedExitPollReport(true)
                  } catch (error) {
                     console.log('error creating poll: ', error)
                     setUploadingExitPollReport(false)
                     setUploadedExitPollReport(false)
                     setUploadingFailedExitPollReport(true)
                  }
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to exit poll
   const [openModalAgency, setOpenModalAgency] = useState(false)

   const [lastAgency, setLastAgency] = useState('')
   const [uploadingAgency, setUploadingAgency] = useState(false)
   const [uploadedAgency, setUploadedAgency] = useState(false)
   const [uploadingFailedAgency, setUploadingFailedAgency] = useState(false)

   const handleOpenModalAgency = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            console.log('agencies: ', agencies)
            setOpenModalAgency(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAgency = () => setOpenModalAgency(false)

   const handleAddAgency = async (agencyDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            agencyDetails.CrBy = auth.currentUser.uid
            agencyDetails.CrDt = new Date()
            agencyDetails.CrIP = ip.data.ip

            const duplicateAgency = agencies.find((item) => item.Name === agencyDetails.Name)

            if (duplicateAgency) {
               alert(`This agency is already present.`)
            } else {
               if (agencyDetails.Name.length > 1) {
                  setUploadingAgency(true)
                  setLastAgency(agencyDetails.Name)
                  console.log('stage 1')
                  try {
                     console.log('stage 2')
                     if (agencyDetails.Logo !== '') {
                        console.log('stage 3')
                        const imageRef = ref(dbStorage, `Agencies/${agencyDetails.Logo.name + v4()}`)
                        await uploadBytes(imageRef, agencyDetails.Logo).then((snapshot) => {
                           getDownloadURL(snapshot.ref).then(async (url) => {
                              agencyDetails = {
                                 ...agencyDetails,
                                 Logo: url,
                                 LogosOld: [
                                    {
                                       Logo: url,
                                       CrBy: auth.currentUser.uid,
                                       CrDt: new Date(),
                                       CrIP: ip.data.ip,
                                    },
                                 ],
                              }

                              // 1. create the main doc of Candidate
                              const docRefAgency = await addDoc(collection(db, 'Agencies'), agencyDetails)

                              // 3. update local state
                              const newRecord = { ...agencyDetails, id: docRefAgency.id }
                              setAgencies([...agencies, newRecord])
                           })
                        })
                     } else {
                        console.log('stage 4')
                        // 1. create the main doc
                        const docRefAgency = await addDoc(collection(db, 'Agencies'), agencyDetails)

                        // 3. update local state
                        const newRecord = { ...agencyDetails, id: docRefAgency.id }
                        setAgencies([...agencies, newRecord])
                        console.log('stage 5')
                     }
                     console.log('stage 6')
                     setUploadingAgency(false)
                     setUploadedAgency(true)
                  } catch (error) {
                     console.log('error creating poll: ', error)
                     setUploadingAgency(false)
                     setUploadedAgency(false)
                     setUploadingFailedAgency(true)
                  }
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ px: 1 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Manifestoes of ${elec.Name}`}</title>
            </Helmet>
         </div>
         <Box
            sx={{
               p: 1,
               mt: 0.5,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               color: '#ffffff',
            }}
         >
            <Box>Exit Polls:</Box>
            <Box>निर्गम मतदान:</Box>
         </Box>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && (
            <Box>
               <Box>
                  <FormControl
                     sx={{
                        backgroundColor: '#F1F1F1',
                        py: 1,
                        px: 1.5,
                        my: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        border: 2,
                        borderColor: '#ffffff',
                        float: 'right',
                     }}
                  >
                     <FormLabel id="demo-controlled-radio-buttons-group">View:</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="ViewField"
                        value={viewField}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleViewField(e)
                        }}
                        sx={{
                           backgroundColor: '#ffffff',
                           borderRadius: 1,
                           border: 0.5,
                           py: 0,
                           ml: 1,
                           px: 0.5,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 13,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        <FormControlLabel
                           value="Reports"
                           control={<Radio size="small" />}
                           label="Survey Reports"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                        <FormControlLabel
                           value="News"
                           control={<Radio size="small" />}
                           label="Survey News"
                           sx={{ py: 0, fontSize: 15 }}
                        />
                     </RadioGroup>
                  </FormControl>
               </Box>
               <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', p: 1 }}>
                  {(isDataEditor || isSuper) && (
                     <>
                        <Button
                           onClick={(e) => {
                              handleOpenModalAgency(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0, mr: 2 }}
                        >
                           Add Agency
                        </Button>
                        <Button
                           onClick={(e) => {
                              handleOpenModalExitPoll(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0, mr: 2 }}
                        >
                           Add an exit poll
                        </Button>
                        <Button
                           onClick={(e) => {
                              handleOpenModalExitPollReport(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0, mr: 2 }}
                        >
                           Add an exit poll report
                        </Button>
                        <Button
                           onClick={(e) => {
                              handleOpenModalWebsite(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line' }}
                        >
                           List a news / समाचार सूचीबद्ध करें
                        </Button>
                     </>
                  )}
               </Box>

               {viewField === 'Reports' && (
                  <Box>
                     {exitPollsList &&
                        exitPollsList.length > 0 &&
                        exitPollsList.map((item, index) => (
                           <TableContainer component={Paper} key={item.AgencyName} sx={{ mb: 5 }}>
                              <Table aria-label="customized table">
                                 <TableHead>
                                    <TableRow>
                                       <strong>{index + 1}.</strong>
                                    </TableRow>
                                    <TableRow>
                                       <StyledTableCell2 align="center" colSpan={1}>
                                          {item.AgencyName}
                                       </StyledTableCell2>
                                       <StyledTableCell3 align="center" colSpan={6}>
                                          <Box
                                             sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                             }}
                                          >
                                             <Box sx={{ mr: 2 }}>Digital Footprint:</Box>
                                             {item.WeblinkMain ? (
                                                <Link
                                                   underline="hover"
                                                   href={item.WeblinkMain}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   sx={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                   }}
                                                >
                                                   WEBSITE <LanguageIcon />
                                                </Link>
                                             ) : (
                                                'NO WEBSITE AVAILABLE'
                                             )}
                                             {item.Weblinks &&
                                                item.Weblinks.length > 0 &&
                                                item.Weblinks.map((itemSocial, indexSocial) => (
                                                   <Box key={indexSocial}>
                                                      {itemSocial &&
                                                         (itemSocial.indexOf('http://') === 0 ||
                                                            itemSocial.indexOf('https://') === 0) && (
                                                            <Link
                                                               key={indexSocial}
                                                               underline="hover"
                                                               href={itemSocial}
                                                               target="_blank"
                                                               rel="noopener noreferrer"
                                                               sx={{ mx: 1 }}
                                                            >
                                                               {itemSocial.substring(0, 25) ===
                                                               'https://www.facebook.com/' ? (
                                                                  <FacebookIcon sx={{ fontSize: 30 }} />
                                                               ) : itemSocial.substring(0, 26) ===
                                                                 'https://www.instagram.com/' ? (
                                                                  <InstagramIcon
                                                                     sx={{ color: '#d62976', fontSize: 30 }}
                                                                  />
                                                               ) : itemSocial.substring(0, 25) ===
                                                                 'https://www.linkedin.com/' ? (
                                                                  <LinkedInIcon sx={{ fontSize: 35 }} />
                                                               ) : itemSocial.substring(0, 24) ===
                                                                 'https://in.linkedin.com/' ? (
                                                                  <LinkedInIcon sx={{ fontSize: 35 }} />
                                                               ) : itemSocial.substring(0, 14) === 'https://x.com/' ? (
                                                                  <XIcon sx={{ color: '#000000' }} />
                                                               ) : itemSocial.substring(0, 24) ===
                                                                 'https://www.youtube.com/' ? (
                                                                  <YouTubeIcon sx={{ color: 'Red', fontSize: 35 }} />
                                                               ) : null}
                                                            </Link>
                                                         )}
                                                      {itemSocial &&
                                                         itemSocial.indexOf('http://') !== 0 &&
                                                         itemSocial.indexOf('https://') !== 0 && (
                                                            <Link
                                                               key={indexSocial}
                                                               underline="hover"
                                                               href={`//${itemSocial}`}
                                                               target="_blank"
                                                               rel="noopener noreferrer"
                                                               sx={{ mx: 1 }}
                                                            >
                                                               {itemSocial.substring(0, 25) ===
                                                               'https://www.facebook.com/' ? (
                                                                  <FacebookIcon sx={{ fontSize: 30 }} />
                                                               ) : itemSocial.substring(0, 26) ===
                                                                 'https://www.instagram.com/' ? (
                                                                  <InstagramIcon
                                                                     sx={{ color: '#d62976', fontSize: 30 }}
                                                                  />
                                                               ) : itemSocial.substring(0, 25) ===
                                                                 'https://www.linkedin.com/' ? (
                                                                  <LinkedInIcon sx={{ fontSize: 35 }} />
                                                               ) : itemSocial.substring(0, 24) ===
                                                                 'https://in.linkedin.com/' ? (
                                                                  <LinkedInIcon sx={{ fontSize: 35 }} />
                                                               ) : itemSocial.substring(0, 14) === 'https://x.com/' ? (
                                                                  <XIcon sx={{ color: '#000000' }} />
                                                               ) : itemSocial.substring(0, 24) ===
                                                                 'https://www.youtube.com/' ? (
                                                                  <YouTubeIcon sx={{ color: 'Red', fontSize: 35 }} />
                                                               ) : null}
                                                            </Link>
                                                         )}
                                                   </Box>
                                                ))}
                                          </Box>
                                       </StyledTableCell3>
                                    </TableRow>
                                    <TableRow>
                                       <StyledTableCell4 align="center" colSpan={1}>
                                          {item.AgencyLogo ? (
                                             <Link
                                                underline="hover"
                                                href={item.WeblinkMain}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                             >
                                                {item.AgencyLogo !== '' ? (
                                                   <img src={item.AgencyLogo} className={myStyles.imgX} />
                                                ) : null}
                                             </Link>
                                          ) : null}
                                       </StyledTableCell4>
                                       <StyledTableCell align="center" colSpan={3}>
                                          Vote Share
                                       </StyledTableCell>
                                       <StyledTableCell align="center" colSpan={3}>
                                          Seats
                                       </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                       <StyledTableCell>Party / Alliance</StyledTableCell>
                                       <StyledTableCell align="right">Min</StyledTableCell>
                                       <StyledTableCell align="right">Max</StyledTableCell>
                                       <StyledTableCell align="right">Avg</StyledTableCell>
                                       <StyledTableCell align="right">Min</StyledTableCell>
                                       <StyledTableCell align="right">Max</StyledTableCell>
                                       <StyledTableCell align="right">Avg</StyledTableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    {exitPollsReportsList &&
                                       exitPollsReportsList.length > 0 &&
                                       exitPollsReportsList.map((row, index) =>
                                          row.ExitPollName === item.AgencyName ? (
                                             <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">
                                                   {row.PartyName ? row.PartyName : row.AllianceName}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.VotePercent_Min} %</StyledTableCell>
                                                <StyledTableCell align="right">{row.VotePercent_Max} %</StyledTableCell>
                                                <StyledTableCell align="right">{row.VotePercent_Avg} %</StyledTableCell>
                                                <StyledTableCell align="right">{row.Seats_Min}</StyledTableCell>
                                                <StyledTableCell align="right">{row.Seats_Max}</StyledTableCell>
                                                <StyledTableCell align="right">{row.Seats_Avg}</StyledTableCell>
                                             </StyledTableRow>
                                          ) : null,
                                       )}
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        ))}
                     {exitPollsList && exitPollsList.length < 1 && (
                        <Box>
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle sx={{ fontSize: 12 }}>
                                 List of exit poll survey reports for{' '}
                                 <strong>
                                    <u>{elec.Name}</u>
                                 </strong>{' '}
                                 is awaited. <br />{' '}
                                 <strong>
                                    <u>{elec.NameHindi}</u>
                                 </strong>{' '}
                                 के लिए एग्जिट पोल सर्वेक्षण रिपोर्ट की सूची की प्रतीक्षा है |
                              </AlertTitle>
                           </Alert>
                        </Box>
                     )}
                  </Box>
               )}

               {viewField === 'News' && (
                  <Box>
                     <List dense>
                        {exitPollLinksList &&
                           exitPollLinksList.length > 0 &&
                           exitPollLinksList.map((item, index) => (
                              <div key={index}>
                                 <ListItem
                                    sx={{ pl: 0, ml: 0 }}
                                    key={index}
                                    secondaryAction={
                                       <Box sx={{ ml: 'auto' }}>
                                          {isSuper && (
                                             <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                // onClick={(e) => {
                                                //    handleDelete(e, item)
                                                // }}
                                                sx={{ mr: 1, height: '25px', width: '25px' }}
                                             >
                                                <DeleteIcon fontSize="small" />
                                             </IconButton>
                                          )}
                                       </Box>
                                    }
                                 >
                                    <ListItemAvatar>
                                       <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{
                                             color: 'text.primary',
                                             display: 'inline',
                                             mb: 1,
                                             fontWeight: 600,
                                          }}
                                       >
                                          {moment(item.Date.toDate()).format('Do MMMM YYYY')} <ArrowDropDownIcon />
                                       </Typography>
                                       <Microlink url={item.Weblink} fetchData lazy={{ threshold: 0.5 }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                       sx={{ ml: 1 }}
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       // primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
                                       // primary={
                                       //    <>
                                       //       {item &&
                                       //          (item.Weblink.indexOf('http://') === 0 ||
                                       //             item.Weblink.indexOf('https://') === 0) && (
                                       //             <Link
                                       //                underline="hover"
                                       //                href={item.Weblink}
                                       //                target="_blank"
                                       //                rel="noopener noreferrer"
                                       //             >
                                       //                {item.Title}
                                       //             </Link>
                                       //          )}
                                       //       {item &&
                                       //          item.Weblink.indexOf('http://') !== 0 &&
                                       //          item.Weblink.indexOf('https://') !== 0 && (
                                       //             <Link
                                       //                underline="hover"
                                       //                href={`//${item.Weblink}`}
                                       //                target="_blank"
                                       //                rel="noopener noreferrer"
                                       //             >
                                       //                {item.Title}
                                       //             </Link>
                                       //          )}
                                       //    </>
                                       // }
                                       // component={'span'}
                                       // secondary={<>{moment(item.Date.toDate()).format('Do MMMM YYYY')}</>}
                                    />
                                 </ListItem>
                                 {/* <Box sx={{ mb: 0.5 }}>
                                 <Link
                                    underline="hover"
                                    href={`/election/candidate/${item.CandidateName}/${item.Candidate_id}`}
                                    sx={{ textDecoration: 'none !important' }}
                                    target="_blank"
                                    rel="noopener"
                                 >
                                    <Button
                                       variant="contained"
                                       sx={{ mr: 2, textTransform: 'none', py: 0, px: 1, minWidth: 0 }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.CandidateName}
                                    </Button>
                                 </Link>

                                 <Button
                                    variant="contained"
                                    sx={{ mr: 2, textTransform: 'none', py: 0, px: 1, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleCandidateLink(e, item.Constituency_id)
                                    }}
                                    target="_blank"
                                    rel="noopener"
                                 >
                                    {item.ConstituencyName}
                                 </Button>
                              </Box> */}
                                 <Divider />
                              </div>
                           ))}
                     </List>
                     {exitPollLinksList && exitPollLinksList.length < 1 && (
                        <Box>
                           <Alert
                              variant="outlined"
                              severity="warning"
                              sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                           >
                              <AlertTitle sx={{ fontSize: 12 }}>
                                 List of links for{' '}
                                 <strong>
                                    <u>{elec.Name}</u>
                                 </strong>{' '}
                                 to be available soon. <br />{' '}
                                 <strong>
                                    <u>{elec.NameHindi}</u>
                                 </strong>{' '}
                                 के लिए links की सूची शीघ्र ही उपलब्ध होगी |
                              </AlertTitle>
                           </Alert>
                        </Box>
                     )}
                  </Box>
               )}
               <Box id="boxModals">
                  <Modal
                     open={openModalWebsite}
                     onClose={handleCloseModalWebsite}
                     aria-labelledby="modal-modal-title-website"
                     aria-describedby="modal-modal-description-website"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-website"
                           variant="h6"
                           component="h2"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Add a web link
                        </Typography>
                        <AddNewsElection handeAddNewsElection={handeAddNewsElection}></AddNewsElection>
                     </Box>
                  </Modal>
                  <Modal
                     open={openModalExitPoll}
                     onClose={handleCloseModalExitPoll}
                     aria-labelledby="modal-modal-title-exitPoll"
                     aria-describedby="modal-modal-description-exitPoll"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-exitPoll"
                           variant="h6"
                           component="h2"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Add an exit poll
                        </Typography>
                        <AddExitPoll
                           handleAddExitPoll={handleAddExitPoll}
                           agencies={agencies}
                           exitPollsList={exitPollsList}
                           lastExitPoll={lastExitPoll}
                           uploadingExitPoll={uploadingExitPoll}
                           uploadedExitPoll={uploadedExitPoll}
                           uploadingFailedExitPoll={uploadingFailedExitPoll}
                        ></AddExitPoll>
                     </Box>
                  </Modal>
                  <Modal
                     open={openModalExitPollReport}
                     onClose={handleCloseModalExitPollReport}
                     aria-labelledby="modal-modal-title-exitPollReport"
                     aria-describedby="modal-modal-description-exitPollReport"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-exitPollReport"
                           variant="h6"
                           component="h2"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Add a report for exit poll
                        </Typography>
                        <AddExitPollReports
                           handleAddExitPollReport={handleAddExitPollReport}
                           exitPollsList={exitPollsList}
                           partiesList={partiesList}
                           alliancesList={alliancesList}
                           lastExitPollReport={lastExitPollReport}
                           uploadingExitPollReport={uploadingExitPollReport}
                           uploadedExitPollReport={uploadedExitPollReport}
                           uploadingFailedExitPollReport={uploadingFailedExitPollReport}
                        ></AddExitPollReports>
                     </Box>
                  </Modal>
                  <Modal
                     open={openModalAgency}
                     onClose={handleCloseModalAgency}
                     aria-labelledby="modal-modal-title-agency"
                     aria-describedby="modal-modal-description-agency"
                     disableScrollLock
                  >
                     <Box sx={styleModalBox}>
                        <Typography
                           id="modal-modal-title-agency"
                           variant="h6"
                           component="h2"
                           align="center"
                           sx={styleModalTypography}
                        >
                           Add Agency
                        </Typography>
                        <AddAgency
                           handleAddAgency={handleAddAgency}
                           lastAgency={lastAgency}
                           uploadingAgency={uploadingAgency}
                           uploadedAgency={uploadedAgency}
                           uploadingFailedAgency={uploadingFailedAgency}
                        ></AddAgency>
                     </Box>
                  </Modal>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecExitPolls.propTypes = {
   candidatesListRoot: PropTypes.array.isRequired,
   elec: PropTypes.object.isRequired,
   exitPollLinksListRoot: PropTypes.array.isRequired,
   handleExitPollLinksUpdate: PropTypes.func.isRequired,
   exitPollLinksList: PropTypes.array.isRequired,
}

export default ElecExitPolls
