import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'

function DeclareAsMinister({
   ministerLevelsList,
   populateLevels,
   handleDeclareAsMinister,
   uploadingMinister,
   uploadingFailedMinister,
   uploadedMinister,
}) {
   //
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         if (!ministerLevelsList || !ministerLevelsList.length > 0) {
            fetchMinisterLevels()
         }
         setFirstLoadStatus('success')
      }
   }, [])

   const [selectedLevel, setSelectedLevel] = useState('')
   const [levelDetails, setLevelDetails] = useState({
      Level: '',
      Priority: 0,
   })

   const fetchMinisterLevels = async () => {
      try {
         const docRef = doc(db, 'BaseLists', 'MinisterLevels')
         const docSnap = await getDoc(docRef)
         populateLevels(docSnap.data().Levels)
      } catch (err) {
         console.log('error: ', err)
      }
   }

   function handleChangeLevel(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedLevel(e.target.value)

         const level = ministerLevelsList.find((item) => item.Name === e.target.value)
         // console.log('party flag', party.Flag)
         // console.log('party name', party.Name)
         setLevelDetails({
            ...levelDetails,
            Level: level.Name,
            LevelHindi: level.NameHindi,
            Priority: level.Priority,
         })
      } else {
         setSelectedLevel('')
         setLevelDetails({
            ...levelDetails,
            Name: '',
            Priority: '',
         })
      }
   }

   const handleSubmitLevel = async (e) => {
      e.preventDefault()
      if (levelDetails.Name !== '') {
         handleDeclareAsMinister(levelDetails)

         setLevelDetails({
            Name: '',
            Priority: 0,
         })
      }
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            mx: 1,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Box>
            {!ministerLevelsList ||
               (!ministerLevelsList.length > 0 && (
                  <MyLoaderCircularGradient title={'Fetching the list of levels...'}></MyLoaderCircularGradient>
               ))}
         </Box>
         <FormControl sx={{ m: 1 }} size="small">
            <InputLabel>Level</InputLabel>
            <Select
               value={selectedLevel}
               label="Level"
               onChange={(e) => {
                  handleChangeLevel(e)
               }}
               sx={{ backgroundColor: '#ffffff' }}
            >
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {ministerLevelsList &&
                  ministerLevelsList.map((item) => (
                     <MenuItem key={item.Name} value={item.Name} sx={{ fontSize: 15 }}>
                        {item.Name}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
         <Box>
            {uploadingMinister && (
               <MyLoaderCircularGradient title={'Uploading Minister Status...'}></MyLoaderCircularGradient>
            )}
         </Box>
         <Box>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmitLevel(e)
               }}
               disabled={levelDetails.Name === ''}
            >
               Submit
            </Button>
         </Box>
      </Box>
   )
}

DeclareAsMinister.propTypes = {
   ministerLevelsList: PropTypes.array.isRequired,
   populateLevels: PropTypes.func.isRequired,
   handleDeclareAsMinister: PropTypes.func.isRequired,

   uploadingMinister: PropTypes.bool.isRequired,
   uploadedMinister: PropTypes.bool.isRequired,
   uploadingFailedMinister: PropTypes.bool.isRequired,
}

export default DeclareAsMinister
