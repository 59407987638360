import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function AddMinistersToMinistry({ handleAddMinister, allMinisters }) {
   const [selectedMinisterId, setSelectedMinisterId] = useState('')
   const [ministerDetails, setMinisterDetails] = useState('')
   const [dateFrom, setDateFrom] = useState('')
   const [dateFromFinal, setDateFromFinal] = useState('')
   const [dateTo, setDateTo] = useState('')
   const [dateToFinal, setDateToFinal] = useState('')

   function handleChangeMinister(e) {
      e.preventDefault()

      if (e.target.value !== '') {
         setSelectedMinisterId(e.target.value)

         const minister = allMinisters.find((item) => item.Minister_id === e.target.value)
         // console.log('party flag', party.Flag)
         // console.log('party name', party.Name)
         setMinisterDetails(minister)
      } else {
         setSelectedMinisterId('')
         setMinisterDetails('')
      }
   }

   useEffect(() => {
      if (dateFrom !== '') {
         setDateFromFinal(dateFrom.$d)
      } else {
         setDateFromFinal('')
      }
   }, [dateFrom])

   useEffect(() => {
      if (dateTo !== '') {
         setDateToFinal(dateTo.$d)
      } else {
         setDateToFinal('')
      }
   }, [dateTo])

   const handleSubmitMinister = async (e) => {
      e.preventDefault()
      if (ministerDetails !== '') {
         handleAddMinister(ministerDetails, dateFromFinal, dateToFinal)

         setMinisterDetails('')
      }
   }
   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Box
            sx={{
               //    mb: 1,
               mx: 1,
               pb: 1,
               borderRadius: 2,
               display: 'flex',
               flexDirection: 'column',
               '& .MuiButton-root': { width: '15ch', mt: 1 },
               '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
               // '& .MuiInputBase-root': { mb: 2 },
               '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <FormControl sx={{ m: 1 }} size="small">
               <InputLabel>Minister</InputLabel>
               <Select
                  value={selectedMinisterId}
                  label="Minister"
                  onChange={(e) => {
                     handleChangeMinister(e)
                  }}
                  sx={{ backgroundColor: '#ffffff' }}
               >
                  <MenuItem value="">
                     <em>None</em>
                  </MenuItem>
                  {allMinisters &&
                     allMinisters.map((item) => (
                        <MenuItem key={item.Minister_id} value={item.Minister_id} sx={{ fontSize: 15 }}>
                           {item.Name}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
            <Box>
               <DatePicker
                  label="From Date"
                  name="DateFrom"
                  value={dateFrom != null ? dayjs(dateFrom) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateFrom(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1, mt: 1 }}
               />
            </Box>
            <Box>
               <DatePicker
                  label="To Date"
                  name="DateTo"
                  value={dateTo != null ? dayjs(dateTo) : null}
                  format="DD-MMM-YYYY"
                  onChange={(newValue) => setDateTo(newValue)}
                  views={['year', 'month', 'day']}
                  sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1, mt: 1 }}
               />
            </Box>
            <Box>
               <Button
                  type="submit"
                  align="center"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={(e) => {
                     handleSubmitMinister(e)
                  }}
                  disabled={ministerDetails === ''}
               >
                  Submit
               </Button>
            </Box>
         </Box>
      </LocalizationProvider>
   )
}

AddMinistersToMinistry.propTypes = {
   allMinisters: PropTypes.array.isRequired,
   handleAddMinister: PropTypes.func.isRequired,
}

export default AddMinistersToMinistry
