import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Microlink from '@microlink/react'
import {
   Alert,
   AlertTitle,
   Box,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   FormLabel,
   IconButton,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Modal,
   Radio,
   RadioGroup,
   Typography,
} from '@mui/material'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { arrayUnion, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
// import moment from 'moment'
import moment from 'moment'
import AddNewsLinksCandidate from '../../../Components/Edits/AddNewsLinksCandidate'

function ElecCandiNews({
   candi,
   elec,
   weblinksListRoot,
   weblinksList,
   handleWeblinksUpdate,
   handleConstituencyCandidatesLink,
}) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip
   const navigate = useNavigate()

   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [weblinksListDisplay, setWeblinksListDisplay] = useState([])
   const [weblinksListByCandi, setWeblinksListByCandi] = useState([])
   const [weblinksListByConsti, setWeblinksListByConsti] = useState([])

   useEffect(() => {
      if (user.currentUser !== null) {
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         // console.log('weblinksList', weblinksList)
         const filteredListByCandi = weblinksList.filter((item) => item.Candidate_id === candi.id)
         setWeblinksListByCandi(filteredListByCandi)
         // console.log('filteredListByCandi', filteredListByCandi)
         const filteredListByConsti = weblinksList.filter((item) => item.Constituency_id === candi.Constituency_id)
         setWeblinksListByConsti(filteredListByConsti)
         // console.log('filteredListByCandi', filteredListByConsti)
         // if (filteredListByCandi.length > 0) {
         //    setFilterField('Candidate')
         //    setWeblinksListDisplay(filteredListByCandi)
         // } else if (filteredListByConsti.length > 0) {
         //    setFilterField('All')
         //    setWeblinksListDisplay(filteredListByConsti)
         // } else {
         //    setFilterField('Candidate')
         //    setWeblinksListDisplay(filteredListByCandi)
         // }
         setFilterField('All')
         setWeblinksListDisplay(filteredListByConsti)

         setFirstLoadStatus('success')
      }
   }, [])

   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))
         ) {
            setOpenModalWebsite(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleNewsLinks = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (weblinkDetails.Weblink !== '' && weblinkDetails.ThisCandidate !== '') {
            if (
               isDataEditor ||
               isSuper ||
               (candi.User_id && candi.User_id === thisUser) ||
               (candi.Helpers && candi.Helpers.includes(thisUser))
            ) {
               weblinkDetails.CrBy = auth.currentUser.uid
               weblinkDetails.CrDt = new Date()
               weblinkDetails.CrIP = ip.data.ip

               weblinkDetails.Candidate_id = weblinkDetails.ThisCandidate === 'Yes' ? candi.id : ''
               weblinkDetails.CandidateName = weblinkDetails.ThisCandidate === 'Yes' ? candi.Name : ''
               weblinkDetails.CandidateNameHindi = weblinkDetails.ThisCandidate === 'Yes' ? candi.NameHindi : ''
               weblinkDetails.CandidateNameLocal = weblinkDetails.ThisCandidate === 'Yes' ? candi.NameLocal : ''
               weblinkDetails.Constituency_id = candi.Constituency_id
               weblinkDetails.ConstituencyName = candi.ConstituencyName
               weblinkDetails.ConstituencyNameHindi = candi.ConstituencyNameHindi
               weblinkDetails.ConstituencyNameLocal = candi.ConstituencyNameLocal
               weblinkDetails.Parties = []
               weblinkDetails.Alliances = []
               weblinkDetails.Election = candi.Election_id

               const duplicateItem = weblinksListRoot.find((item) => item.Weblink === weblinkDetails.Weblink)

               if (duplicateItem) {
                  alert('This link is already present.')
               } else {
                  let coreListId = ''
                  let weblinksCount = 0

                  // we are fetching docElecFresh so that we get the real count of weblinks just before the entry of new weblink
                  const docRefElecFresh = doc(db, 'Elections', elec.id)
                  const docSnapElecFresh = await getDoc(docRefElecFresh)
                  weblinksCount = docSnapElecFresh.data().WeblinksCount

                  const slab = 2000
                  const quotient = Math.floor(weblinksCount / slab)
                  // const remainder = candidatesCount % slab
                  const num = quotient + 1
                  const numStr = String(num).padStart(6, '0')

                  // coreListId = 'CandiCoreList-' + numStr
                  coreListId = numStr

                  weblinkDetails.CoreListId = coreListId

                  //
                  const docRefElecSubCollWeblinks = doc(db, 'Elections', elec.id, 'WeblinksLists', coreListId)
                  const docSnapSubColElec = await getDoc(docRefElecSubCollWeblinks)
                  if (docSnapSubColElec.exists()) {
                     await updateDoc(docRefElecSubCollWeblinks, {
                        Weblinks: arrayUnion(weblinkDetails),
                     })
                  } else {
                     setDoc(docRefElecSubCollWeblinks, { Weblinks: [weblinkDetails] }, { merge: true })
                  }

                  await updateDoc(docRefElecFresh, {
                     WeblinksCount: increment(1),
                  })

                  // update the website display
                  // canceled the following as date from here was causing display problem.
                  handleWeblinksUpdate(weblinkDetails)
               }
            } else {
               navigate('/', { replace: true })
            }
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // filter list of elections

   const [filterField, setFilterField] = useState('Candidate')
   function handleSortField(e) {
      e.preventDefault()
      setFilterField(e.target.value)
      // console.log('XXX')
      if (e.target.value === 'Candidate') {
         setWeblinksListDisplay(weblinksListByCandi)
         // console.log('YYY')
      } else {
         setWeblinksListDisplay(weblinksListByConsti)
         // console.log('ZZZ')
      }
   }

   // const handleCandidateLink = async (e, constituencyId) => {
   //    e.preventDefault()

   //    handleConstituencyCandidatesLink(constituencyId)
   // }

   return (
      <div>
         <div>
            <Helmet>
               <title>{`Track Democracy - News / Statements of candidate - ${candi.Name} for ${elec.Name}`}</title>
            </Helmet>
         </div>
         {(isDataEditor ||
            isSuper ||
            (candi.User_id && candi.User_id === thisUser) ||
            (candi.Helpers && candi.Helpers.includes(thisUser))) && (
            <Box sx={{ mb: 0.5 }}>
               <Button
                  onClick={(e) => {
                     handleOpenModalWebsite(e)
                  }}
                  size="small"
                  variant="outlined"
                  sx={{ py: 0, px: 1, minWidth: 0, whiteSpace: 'pre-line' }}
               >
                  List a statement / कथन सूचीबद्ध करें
               </Button>
            </Box>
         )}

         <Box sx={{ mb: 0.5 }}>
            <Typography
               variant="subtitle1"
               color="#FFFFFF"
               component="div"
               sx={{
                  fontWeight: 500,
                  backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                  px: 1,
                  py: 0.5,
                  whiteSpace: 'pre-line',
                  lineHeight: 1.2,
               }}
            >
               News / Statements {'\n'} समाचार / वक्तव्य:
            </Typography>
         </Box>
         <Box sx={{ display: 'flex' }}>
            <FormControl
               sx={{
                  ml: 'auto',
               }}
            >
               <FormLabel id="demo-controlled-radio-buttons-group">Filter by:</FormLabel>
               <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="SortField"
                  value={filterField}
                  // style={{ display: 'flex', gap: '2rem' }}
                  onChange={(e) => {
                     handleSortField(e)
                  }}
                  sx={{
                     backgroundColor: '#ffffff',
                     borderRadius: 1,
                     border: 0.5,
                     py: 0,
                     px: 0.5,
                     '& .MuiSvgIcon-root': {
                        fontSize: 15,
                     },
                     '& .MuiButtonBase-root': {
                        py: 0,
                     },
                     '& MuiFormControlLabel-label': {
                        fontSize: 13,
                        lineHeight: 16,
                        py: 0,
                     },
                  }}
               >
                  <FormControlLabel
                     value="Candidate"
                     control={<Radio size="small" />}
                     label="Candidate"
                     sx={{ py: 0, fontSize: 15 }}
                  />
                  <FormControlLabel
                     value="All"
                     control={<Radio size="small" />}
                     label="All"
                     sx={{ py: 0, fontSize: 15 }}
                  />
               </RadioGroup>
            </FormControl>
         </Box>
         <Box>
            <List dense>
               {weblinksListDisplay &&
                  weblinksListDisplay.length > 0 &&
                  weblinksListDisplay.map((item, index) => (
                     <div key={index}>
                        <ListItem
                           sx={{ pl: 0, ml: 0 }}
                           key={index}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       // onClick={(e) => {
                                       //    handleDelete(e, item)
                                       // }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <ListItemAvatar>
                              <Typography
                                 component="span"
                                 variant="body2"
                                 sx={{
                                    color: 'text.primary',
                                    display: 'inline',
                                    mb: 1,
                                 }}
                              >
                                 {moment(item.Date.toDate()).format('Do MMMM YYYY')}
                              </Typography>
                              <Microlink url={item.Weblink} fetchData lazy={{ threshold: 0.5 }} />
                           </ListItemAvatar>
                           <ListItemText
                              sx={{ ml: 1 }}
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              // primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
                              // primary={
                              //    <>
                              //       {item &&
                              //          (item.Weblink.indexOf('http://') === 0 ||
                              //             item.Weblink.indexOf('https://') === 0) && (
                              //             <Link
                              //                underline="hover"
                              //                href={item.Weblink}
                              //                target="_blank"
                              //                rel="noopener noreferrer"
                              //             >
                              //                {item.Title}
                              //             </Link>
                              //          )}
                              //       {item &&
                              //          item.Weblink.indexOf('http://') !== 0 &&
                              //          item.Weblink.indexOf('https://') !== 0 && (
                              //             <Link
                              //                underline="hover"
                              //                href={`//${item.Weblink}`}
                              //                target="_blank"
                              //                rel="noopener noreferrer"
                              //             >
                              //                {item.Title}
                              //             </Link>
                              //          )}
                              //    </>
                              // }
                              // component={'span'}
                              // secondary={<>{moment(item.Date.toDate()).format('Do MMMM YYYY')}</>}
                           />
                        </ListItem>
                        <Box sx={{ mb: 0.5 }}>
                           {item.Candidate_id !== candi.id && (
                              <Link
                                 underline="hover"
                                 href={`/election/candidate/${item.CandidateName}/${item.Candidate_id}`}
                                 sx={{ textDecoration: 'none !important' }}
                                 target="_blank"
                                 rel="noopener"
                              >
                                 <Button
                                    variant="contained"
                                    sx={{ mr: 2, textTransform: 'none', py: 0, px: 1, minWidth: 0 }}
                                    target="_blank"
                                    rel="noopener"
                                 >
                                    {item.CandidateName}
                                 </Button>
                              </Link>
                           )}

                           {/* <Button
                              variant="contained"
                              sx={{ mr: 2, textTransform: 'none', py: 0, px: 1, minWidth: 0 }}
                              onClick={(e) => {
                                 handleCandidateLink(e, item.Constituency_id)
                              }}
                              target="_blank"
                              rel="noopener"
                           >
                              {item.ConstituencyName}
                           </Button> */}
                        </Box>
                        <Divider />
                     </div>
                  ))}
            </List>
            {weblinksList && weblinksList.length < 1 && (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        List of links for{' '}
                        <strong>
                           <u>{candi.Name}</u>
                        </strong>{' '}
                        to be available soon. <br />{' '}
                        <strong>
                           <u>{candi.NameHindi}</u>
                        </strong>{' '}
                        के लिए links की सूची शीघ्र ही उपलब्ध होगी |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}
         </Box>
         <Box sx={{ mt: 2, mx: 10 }}></Box>
         <Box id="boxModals">
            <Modal
               open={openModalWebsite}
               onClose={handleCloseModalWebsite}
               aria-labelledby="modal-modal-title-website"
               aria-describedby="modal-modal-description-website"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-website"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a news link
                  </Typography>
                  <AddNewsLinksCandidate handleNewsLinks={handleNewsLinks}></AddNewsLinksCandidate>
               </Box>
            </Modal>
         </Box>
      </div>
   )
}

ElecCandiNews.propTypes = {
   candi: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
   weblinksListRoot: PropTypes.array.isRequired,
   weblinksList: PropTypes.array.isRequired,
   handleWeblinksUpdate: PropTypes.func.isRequired,
   handleConstituencyCandidatesLink: PropTypes.func.isRequired,
}

export default ElecCandiNews
