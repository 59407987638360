import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { Avatar, Box, Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

import { stringAvatar80, stringAvatar80Single } from '../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace } from '../../Utils/Conversions/CaseConversion'
import styles from './ElecCandiComp.module.css'
import winner from '../../Images/winner.png'

function ElecCandiCompSearch({ item, index }) {
   return (
      <ListItem sx={{ pl: 0, ml: 0 }} key={item.Candidate_id}>
         <Link
            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
            sx={{ textDecoration: 'none !important' }}
            target="_blank"
            rel="noopener"
         >
            <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
               <Typography
                  component="span"
                  variant="body2"
                  sx={{
                     color: 'text.primary',
                     display: 'inline',
                     fontWeight: 600,
                     mb: 1,
                  }}
               >
                  {index + 1}.
               </Typography>
               {item.Name &&
                  (countWordsUsingReplace(item.Name) > 1 ? (
                     <Avatar {...stringAvatar80(item.Name)} alt="Travis Howard" src={item.Image} />
                  ) : (
                     <Avatar {...stringAvatar80Single(item.Name)} alt="Travis Howard" src={item.Image} />
                  ))}
            </ListItemAvatar>
         </Link>

         <ListItemText
            sx={{ ml: 1 }}
            secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
            primaryTypographyProps={{ whiteSpace: 'pre-line' }}
            primary={
               <>
                  <Link
                     // color="inherit"
                     underline="hover"
                     href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                     sx={{ textDecoration: 'none !important' }}
                     target="_blank"
                     rel="noopener"
                  >
                     <strong>{item.Name.toUpperCase()}</strong>
                  </Link>{' '}
                  -{' '}
                  {item.NameLocal && (
                     <Link
                        href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                        className={styles.overrideUnderline}
                        sx={{ textDecoration: 'none !important' }}
                        target="_blank"
                        rel="noopener"
                     >
                        {item.NameLocal}
                     </Link>
                  )}
                  {'  '}-{' '}
                  <Link
                     underline="none"
                     href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                     color="warning"
                     sx={{ textDecoration: 'none !important' }}
                     target="_blank"
                     rel="noopener"
                  >
                     {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                     {item.NameHindi}
                     {'  '}
                  </Link>
                  ({item.Age}y)
               </>
            }
            component={'span'}
            secondary={
               <>
                  <>{<strong> Constituency: </strong>}</>
                  {item.ConstituencyName} {`- ${item.ConstituencyNameLocal} - ${item.ConstituencyNameHindi}`}
                  <br />
                  <>{<strong> Constituency No.: </strong>}</>
                  {item.ConstituencyNumber}
                  <br />
                  <>
                     {item.PartyFlag && (
                        <Link
                           href={`/political-party/${item.PartyName}/${item.Party_id}`}
                           target="_blank"
                           rel="noopener"
                        >
                           <img src={item.PartyFlag} className={styles.imgX2} />
                        </Link>
                     )}

                     {item.PartySymbol && <img src={item.PartySymbol} className={styles.imgX3} />}
                     {item.AllottedSymbol && <img src={item.AllottedSymbol} className={styles.imgX3} />}
                  </>
                  <>
                     <>
                        {<strong> Party: </strong>}
                        {item.PartyName !== 'Independent' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                              <Link
                                 href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                 underline="none"
                                 sx={{ textDecoration: 'none !important' }}
                                 target="_blank"
                                 rel="noopener"
                              >
                                 {item.PartyName}
                              </Link>
                           </Typography>
                        )}
                        {item.PartyName === 'Independent' && (
                           <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                              {item.PartyName}
                           </Typography>
                        )}
                        <Box sx={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
                           {<strong> Alliances: </strong>}
                           {item.Alliances.map((itemAl, index) => (
                              <Fragment key={itemAl.Alliance_id}>
                                 {index + 1}.
                                 <Link
                                    key={itemAl.Alliance_id}
                                    href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                    sx={{
                                       mr: 1,
                                       ml: 0.5,
                                       textDecoration: 'none !important',
                                    }}
                                    rel="noreferrer"
                                    target="_blank"
                                 >
                                    {itemAl.Abbreviation}
                                 </Link>
                              </Fragment>
                           ))}
                        </Box>
                     </>

                     <>
                        {<strong> Votes: </strong>}
                        <Typography
                           sx={
                              ({
                                 display: 'inline',
                              },
                              item.Winner ? { color: '#4CAF50' } : { color: '#DC143C' })
                           }
                           component="span"
                           variant="body2"
                        >
                           <strong>{item.Votes}</strong>
                        </Typography>
                        {'\n'}
                     </>
                     <>
                        {item.Winner === 'true' && <img src={winner} className={styles.imgXX} />} {'\n'}
                     </>
                  </>
                  <>
                     {<strong> Status: </strong>}
                     {item.Announced && item.Announced !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#03a9f4">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.AnnouncedDate && item.AnnouncedDate !== ''
                              ? `Announced by party on ${moment(item.AnnouncedDate.toDate()).format('Do MMMM YYYY')} `
                              : `Announced by party. `}
                        </Typography>
                     )}
                     {item.Filed !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#ba68c8">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.FiledDate && item.FiledDate !== ''
                              ? `Candidature filed on ${moment(item.FiledDate.toDate()).format('Do MMMM YYYY')} `
                              : `Candidature filed. `}
                        </Typography>
                     )}
                     {item.Accepted !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#4caf50">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.AcceptedDate && item.AcceptedDate !== ''
                              ? `Candidature accepted by ECI on ${moment(item.AcceptedDate.toDate()).format('Do MMMM YYYY')} `
                              : `Candidature accepted by ECI. `}
                        </Typography>
                     )}
                     {item.Rejected !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#d32f2f">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.RejectedDate && item.RejectedDate !== ''
                              ? `Candidature rejected by ECI on ${moment(item.RejectedDate.toDate()).format('Do MMMM YYYY')} `
                              : `Candidature rejected by ECI. `}
                        </Typography>
                     )}
                     {item.Withdrawn !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#ff9800">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.WithdrawnDate && item.WithdrawnDate !== ''
                              ? `Candidature Withdrawn by candidate on ${moment(item.WithdrawnDate.toDate()).format('Do MMMM YYYY')} `
                              : `Candidature Withdrawn by candidate. `}
                        </Typography>
                     )}
                     {item.AnnounceCancelled && item.AnnounceCancelled !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#d32f2f">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.AnnounceCancelledDate && item.AnnounceCancelledDate !== ''
                              ? `Announcement cancelled by party on ${moment(item.AnnounceCancelledDate.toDate()).format('Do MMMM YYYY')} `
                              : `Announcement cancelled by party. `}
                        </Typography>
                     )}
                     {item.Refused && item.Refused !== '' && (
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#d32f2f">
                           <ArrowCircleRightIcon sx={{ fontSize: 14 }} />
                           {item.RefusedDate && item.RefusedDate !== ''
                              ? `Refused by candidate on ${moment(item.RefusedDate.toDate()).format('Do MMMM YYYY')} `
                              : `Refused by candidate. `}
                        </Typography>
                     )}
                  </>
               </>
            }
         />
      </ListItem>
   )
}

ElecCandiCompSearch.propTypes = {
   index: PropTypes.number.isRequired,
   item: PropTypes.object.isRequired,
}

export default ElecCandiCompSearch
