import { Alert, AlertTitle, Box } from '@mui/material'
import React from 'react'

function HomeCentralAdministration() {
   return (
      <Box>
         <Alert severity="success" sx={{ margin: 1, mt: 5, justifyContent: 'center', alignItems: 'center' }}>
            <AlertTitle>Administration</AlertTitle>
            This facility will be available in future.
            <br />
            <br />
            यह सुविधा भविष्य में उपलब्ध होगी।
         </Alert>
      </Box>
   )
}

export default HomeCentralAdministration
