import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { auth, db, dbStorage } from '../../FirebaseConfig'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import { v4 } from 'uuid'

import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../redux/reducers/users-slice'
import { Box, Button, ImageList, ImageListItem, Modal, Typography } from '@mui/material'

// import myStyles from './ElecManifesto.module.css'
import { styleModalBox, styleModalTypography } from '../../Utils/MUITheme/MUITheme'
import AddImage from '../../Components/Edits/AddImage'
import { Helmet } from 'react-helmet'

function ElecTheManifesto({ manifesto }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [pages, setPages] = useState('')

   const userProfile = useSelector(selectUserProfile)
   const [nameOfUser, setNameOfUser] = useState('')
   useEffect(() => {
      if (userProfile && userProfile !== '') {
         // console.log('userProfile use state: ', userProfile, 'strata', consti.Strata)
         const name = userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         setNameOfUser(name)
      }
   }, [userProfile])

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         const sortedList = [...manifesto.ManifestoPages].sort((a, b) => a.Priority - b.Priority)
         setPages(sortedList)

         setFirstLoadStatus('success')

         console.log('ElecTheManifesto - first use effect blank')
      }
   }, [])

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalImage(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            //

            const imageRef = ref(
               dbStorage,
               `ElectionManifestoes/${manifesto.Election_id}/${imageDetails.image.name + v4()}`,
            )
            await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
               getDownloadURL(snapshot.ref).then(async (url) => {
                  const manifestoRef = doc(db, 'ElectionManifestoes', manifesto.id)

                  await updateDoc(manifestoRef, {
                     ManifestoPages: arrayUnion({
                        Image: url,
                        Priority: imageDetails.Priority,
                        Description: imageDetails.Description,
                        IsDeleted: false,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        ModBy: '',
                        ModDt: '',
                        ModIP: '',
                     }),
                     DocsAvailable: true,
                  })

                  setPages([
                     ...pages,
                     {
                        Image: url,
                        Priority: imageDetails.Priority,
                        Description: imageDetails.Description,
                        IsDeleted: false,
                        CrBy: auth.currentUser.uid,
                        CrByName: nameOfUser,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                        ModBy: '',
                        ModDt: '',
                        ModIP: '',
                     },
                  ])
               })
            })

            // close modal
            // setOpenModalImage(false)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Manifesto titled "${manifesto.Name} issued by ${manifesto.IssuedByPartyName} for ${manifesto.ElectionName}`}</title>
            </Helmet>
         </div>

         <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            {(isDataEditor || isSuper) && (
               <Button
                  onClick={(e) => {
                     handleOpenModalImage(e)
                  }}
                  size="small"
                  variant="outlined"
                  sx={{ py: 0, px: 1, minWidth: 0, mr: 2 }}
               >
                  Add page
               </Button>
            )}
         </Box>

         <Box>
            <Box sx={{ pl: 5 }}>
               <ImageList sx={{ width: '100%', height: '100%' }} cols={1}>
                  {pages &&
                     pages.length > 0 &&
                     pages.map((item, index) => (
                        <ImageListItem key={index}>
                           <img srcSet={item.Image} src={item.Image} loading="lazy" />
                        </ImageListItem>
                     ))}
               </ImageList>
            </Box>
         </Box>

         <Box id="boxModals">
            <Modal
               open={openModalImage}
               onClose={handleCloseModalImage}
               aria-labelledby="modal-modal-title-image"
               aria-describedby="modal-modal-description-image"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-image"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add image of Candidate
                  </Typography>
                  <AddImage handleAddImage={handleAddImage}></AddImage>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

ElecTheManifesto.propTypes = {
   manifesto: PropTypes.object.isRequired,
}

export default ElecTheManifesto
