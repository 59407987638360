import { Box, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { a11yProps, StyledTab1, TabPanel1 } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import { TabContext } from '@mui/lab'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig'
import { sxTypoGraphy2 } from '../../Utils/MUITheme/MUITheme'
import HomeCentralLegis from './HomeCentralLegis'
import HomeCentralExecutive from './HomeCentralExecutive'
import HomeCentralAdministration from './HomeCentralAdministration'
import HomeCentralJudiciary from './HomeCentralJudiciary'

function HomeTabPanelForCenter() {
   const theme = useTheme()
   const [value, setValue] = useState(0)

   const handleChange = (event, newValue) => {
      setValue(newValue)
   }

   const handleChangeIndex = (index) => {
      setValue(index)
   }

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchCountry()
         setFirstLoadStatus('success')
      }
   }, [])

   const [individualCountry, setIndividualCountry] = useState('')

   const fetchCountry = async () => {
      const docRef = doc(db, 'Countries', 'lsTDGRF8XHWnR3VjVuB4')
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
         setIndividualCountry({ ...docSnap.data(), id: docSnap.id })
      } else {
         // docSnap.data() will be undefined in this case
         console.log('No such document!')
      }
   }
   return (
      <Box sx={{ pb: 0.5 }}>
         <Box
            sx={{
               // Love Couple from uiGradient
               background: 'linear-gradient(to right, #3a6186, #89253e)',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: 50,
               mb: 1,
            }}
         >
            <Typography sx={sxTypoGraphy2}>
               Center{' - '}
               <span style={{ fontSize: 15 }}>
                  <strong>केंद्र</strong>
               </span>
            </Typography>
         </Box>
         <TabContext value={value}>
            <Box sx={{ width: '100%', mb: 1 }}>
               <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  //   centered
                  variant="scrollable"
                  scrollButtons="false"
                  sx={{
                     minHeight: 25,
                     width: '100%',
                     mr: 0,
                  }}
               >
                  <StyledTab1 label={`Legislative \n विधायिका`} {...a11yProps(0)} />
                  <StyledTab1 label={`Executive \n (कार्यपालिका)`} {...a11yProps(1)} />
                  <StyledTab1 label={`Administration \n (प्रशासन)`} {...a11yProps(2)} />
                  <StyledTab1 label={`Judiciary \n (न्यायपालिका)`} {...a11yProps(3)} />
               </Tabs>
            </Box>
            <SwipeableViews
               axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
               index={value}
               onChangeIndex={handleChangeIndex}
            >
               <TabPanel1 value={value} index={0} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && <HomeCentralLegis props={individualCountry} />}
               </TabPanel1>
               <TabPanel1 value={value} index={1} dir={theme.direction}>
                  {individualCountry && individualCountry !== '' && <HomeCentralExecutive props={individualCountry} />}
               </TabPanel1>
               <TabPanel1 value={value} index={2} dir={theme.direction}>
                  <HomeCentralAdministration></HomeCentralAdministration>
               </TabPanel1>
               <TabPanel1 value={value} index={3} dir={theme.direction}>
                  <HomeCentralJudiciary></HomeCentralJudiciary>
               </TabPanel1>
            </SwipeableViews>
         </TabContext>
      </Box>
   )
}

export default HomeTabPanelForCenter
