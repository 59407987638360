import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Alert,
   AlertTitle,
   Box,
   Divider,
   Link,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   Typography,
} from '@mui/material'
import moment from 'moment'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../FirebaseConfig'

function PPartyManifestoes({ pParty, handlePPartyDetails }) {
   console.log('value from props from inside PParty Manifestoes : ', pParty)

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [manifestoesList, setManifestoesList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         fetchManifestoes()
         setFirstLoadStatus('success')
      }
   }, [])

   const fetchManifestoes = async () => {
      try {
         const docRef = doc(db, 'PoliticalParties', pParty.id, 'CoreLists', 'Manifestoes-0001')
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            const listConsolidated = [...docSnap.data().Manifestoes].filter(
               (item) => item && Object.keys(item).length > 0,
            )
            const sortedManifestoes = listConsolidated.sort((a, b) => {
               if (b.DatePublished > a.DatePublished) {
                  return -1
               } else if (b.DatePublished < a.DatePublished) {
                  return 1
               } else {
                  return 0
               }
            })
            setManifestoesList(sortedManifestoes)
         } else {
            setManifestoesList([])
         }
      } catch (error) {
         console.log(error.message)
      }
   }

   return (
      <div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.4,
            }}
         >
            Manifestoes: {'\n'} घोषणापत्र:
         </Typography>
         <Box sx={{ m: 1 }}>
            {manifestoesList && manifestoesList.length > 0 ? (
               <List dense sx={{ py: 0.5 }}>
                  {manifestoesList.map((item, index) => (
                     <Box key={item.Manifesto_id}>
                        <ListItem sx={{ p: 0, ml: 0 }} key={item.Manifesto_id}>
                           <ListItemIcon sx={{ minWidth: '50px !important' }}>
                              {item.Image && (
                                 <Box
                                    key={item.Manifesto_id}
                                    component="img"
                                    src={item.Image}
                                    alt=""
                                    sx={{ height: '50px' }}
                                 />
                              )}
                           </ListItemIcon>
                           <ListItemText
                              sx={{ ml: 1, fontSize: 13, p: 0 }}
                              secondaryTypographyProps={{
                                 fontSize: 12,
                                 color: 'Crimson',
                                 pr: 1,
                                 align: 'left',
                              }}
                              primaryTypographyProps={{ fontSize: 12 }}
                              primary={
                                 <>
                                    <Link
                                       href={`/election/${item.ElectionName}/manifesto/${item.Manifesto_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <strong>{item.Name.toUpperCase()}</strong>
                                    </Link>

                                    {item.NameLocal && (
                                       <>
                                          <br />
                                          <Link
                                             href={`/election/${item.ElectionName}/manifesto/${item.Manifesto_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <strong>{item.NameLocal}</strong>
                                          </Link>
                                       </>
                                    )}
                                    {!item.NameLocal && (
                                       <>
                                          <br />
                                          <Link
                                             href={`/election/${item.ElectionName}/manifesto/${item.Manifesto_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <strong>{item.NameHindi}</strong>
                                          </Link>
                                       </>
                                    )}
                                 </>
                              }
                              secondary={
                                 <>
                                    {item.DateReleased && (
                                       <>{moment(item.DateReleased.toDate()).format('Do MMM YYYY')}</>
                                    )}
                                    <>
                                       {' '}
                                       By:{' '}
                                       <strong>
                                          {item.IssuedByParty_id && <>{item.IssuedByPartyName}</>}
                                          {item.IssuedByAlliance_id && <>{item.IssuedByAllianceName}</>}
                                       </strong>
                                    </>
                                    <>
                                       {item.IssuedByParty_id !== pParty.id && (
                                          <Typography
                                             sx={{
                                                color: '#3a6186',
                                                textAlign: 'left',
                                                fontSize: 14,
                                                whiteSpace: 'pre-line',
                                             }}
                                          >
                                             Indirect association through alliance: {'\n'} गठबंधन के माध्यम से
                                             अप्रत्यक्ष सम्बद्धता:
                                          </Typography>
                                       )}
                                    </>
                                 </>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </Box>
                  ))}
               </List>
            ) : (
               <Box>
                  <Alert
                     variant="outlined"
                     severity="warning"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        Manifestoes to be available soon. <br /> घोषणा पत्र शीघ्र उपलब्ध होंगे |
                     </AlertTitle>
                  </Alert>
               </Box>
            )}
         </Box>
      </div>
   )
}

PPartyManifestoes.propTypes = {
   pParty: PropTypes.object.isRequired,
   handlePPartyDetails: PropTypes.func.isRequired,
}

export default PPartyManifestoes
