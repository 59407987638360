import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import {
   Alert,
   Avatar,
   Box,
   Button,
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControl,
   FormControlLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Radio,
   RadioGroup,
   Slide,
   TextField,
   Typography,
} from '@mui/material'
import { auth, db } from '../../../FirebaseConfig'
import { useSelector } from 'react-redux'
import { selectUserProfile, selectUsers } from '../../../redux/reducers/users-slice'
import { CustomButtonBlue, CustomButtonOrange } from '../../../Utils/CustomButtons/CustomButtons'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import { useNavigate } from 'react-router-dom'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { arrayRemove, arrayUnion, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import CopyToClipboard from 'react-copy-to-clipboard'

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function PetitionBasicInfo({
   petition,
   handleSupportsByUser,
   supportsByUser,
   handleSignsByUser,
   signsByUser,
   supportsByUserPopulated,
   attachedToConsti,
   location,
}) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [thisUser, setThisUser] = useState('')
   const [nameOfUser, setNameOfUser] = useState('')
   const [nameOfUserHindi, setNameOfUserHindi] = useState('')
   const [nameOfUserLocal, setNameOfUserLocal] = useState('')

   const userProfile = useSelector(selectUserProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         const name = userProfile.NameFirst + ' ' + userProfile.NameLast
         const nameHindi = userProfile.NameHindiFirst + ' ' + ' ' + userProfile.NameHindiLast
         const nameLocal = userProfile.NameLocalFirst + ' ' + userProfile.NameLocalLast
         setNameOfUser(name)
         setNameOfUserHindi(nameHindi)
         setNameOfUserLocal(nameLocal)
      }
   }, [userProfile])

   useEffect(() => {
      // console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         // console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
      } else {
         // console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [addressedToObj, setAddressedToObj] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle' && petition) {
         switch (petition.AddressedTo) {
            case 'Candidate':
               setAddressedToObj({
                  Candi_id: petition.Candi_id,
                  Name: petition.CandiName,
                  NameHindi: petition.CandiNameHindi,
                  NameLocal: petition.CandiNameLocal,
                  Image: petition.CandiImage,
                  Election_id: petition.Election_id,
                  ElectionName: petition.ElectionName,
                  ElectionNameHindi: petition.ElectionNameHindi,
                  ElectionNameLocal: petition.ElectionNameLocal,
               })

               break
            case 'ConstituencyNumber':
               // addressedToObj = {}
               break
         }

         setFirstLoadStatus('success')
      } else {
         // console.log('states - found user state null')
      }
   }, [])

   // const dispatch = useDispatch()

   // const handleUserProfile = async (userId) => {
   //    try {
   //       const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', userId))
   //       const querySnapshot = await getDocs(querryUser)
   //       let userFetched = ''
   //       querySnapshot.forEach((docSnap) => {
   //          userFetched = { id: docSnap.id, ...docSnap.data() }
   //       })
   //       console.log('user profile from app js: ', userFetched)
   //       dispatch(setUserProfile(userFetched))
   //    } catch (error) {
   //       console.error('error fetching user profile', error)
   //    }
   // }

   // Copy
   const [copyStatus, setCopyStatus] = useState(false) // To indicate if the text was copied

   const onCopyText = (index) => {
      setCopyStatus(true)
      setTimeout(() => setCopyStatus(false), 2000) // Reset status after 2 seconds
   }

   // related to support
   const [supportOption, setSupportOption] = useState('')

   const handleOpenModalSupport = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (attachedToConsti && attachedToConsti.Attached === 'Nowhere') {
            alert(
               'You are not attachedToConsti to any constituency at Central level. Please get attachedToConsti to your constituency.',
            )
            return
         }
         // get the existing supports by user for this petition
         setSelectedSupportChoice('')
         setSupportComment('')
         setTLength(0)
         setUploadingSupport(false)
         setUploadedSupport(false)
         setUploadingFailedSupport(false)
         if (supportsByUserPopulated) {
            console.log('inside stage 1', supportsByUserPopulated, ' ', supportsByUser)
            if (supportsByUser && supportsByUser.length > 1) {
               const thisSupport = supportsByUser[1]
               if (thisSupport) {
                  if (thisSupport.WDt !== '') {
                     setSupportOption('Three-W')
                     console.log('support option: - Three-W', thisSupport)
                     handleOpenDialogAlreadySupported()
                  } else if (thisSupport.ModDt !== '') {
                     // (i) means he has supported / opposed and then changed it already.
                     // (ii) show the list of supportsByUserForThisPetition
                     // (iii) show that you can withdraw.
                     setSupportOption('Three-M')
                     console.log('support option: - Three-M', thisSupport)

                     handleOpenDialogSupport()
                  } else {
                     // (i) show that you can either change or withdraw
                     // (ii) show the list of supportsByUserForThisPetition
                     if (thisSupport.CrType === 'Support') {
                        setSupportOption('Three-Oppose')
                        console.log('support option: - Three-Oppose', thisSupport)
                     } else {
                        setSupportOption('Three-Support')
                        console.log('support option: - Three-Support', thisSupport)
                     }

                     handleOpenDialogSupport()
                  }
               }
            } else if (supportsByUser && supportsByUser.length === 1) {
               const thisSupport = supportsByUser[0]
               if (thisSupport.WDt !== '') {
                  // (i) means he has supported / opposed and then changed it already.
                  // (i) Then, he has withdrawn it.
                  setSupportOption('Two-W')
                  console.log('support option: - Two-W', thisSupport)

                  // handleOpenDialogSupport()
                  handleOpenDialogAlreadySupported()
               } else if (thisSupport.ModDt !== '') {
                  // (i) means he has supported / opposed and then changed it already.
                  // (ii) show the list of supportsByUserForThisPetition
                  // (iii) show that you can withdraw.
                  if (thisSupport.ModType === 'Support') {
                     setSupportOption('Two-M-Support')
                  } else {
                     setSupportOption('Two-M-Oppose')
                  }
                  console.log('support option: - Two-M', thisSupport)

                  handleOpenDialogSupport()
               } else {
                  // (i) show that you can either change or withdraw
                  // (ii) show the list of supportsByUserForThisPetition
                  if (thisSupport.CrType === 'Support') {
                     setSupportOption('Two-Oppose')
                     console.log('support option: - Two-Oppose', thisSupport)
                  } else {
                     setSupportOption('Two-Support')
                     console.log('support option: - Two-Support', thisSupport)
                  }

                  handleOpenDialogSupport()
               }
            } else if (!supportsByUser || supportsByUser.length === 0) {
               // means he is fresh
               setSupportOption('One')
               console.log('support option: - One')

               handleOpenDialogSupport()
            }
         }
      } else {
         // console.log('reached stage prior to open modal for support - You are NOT logged in.')
         handleOpenDialogNotLoggedIn()
      }
   }

   // AlreadySupported

   const [openDialogAlreadySupported, setOpenDialogAlreadySupported] = useState(false)

   const handleOpenDialogAlreadySupported = async () => {
      setOpenDialogAlreadySupported(true)
   }

   const handleCloseDialogAlreadySupported = async () => {
      setOpenDialogAlreadySupported(false)
   }

   // change support

   const [openDialogSupport, setOpenDialogSupport] = useState(false)

   const handleOpenDialogSupport = async () => {
      setOpenDialogSupport(true)
   }

   const handleCloseDialogSupport = async () => {
      setOpenDialogSupport(false)
      setSelectedSupportChoice('')

      setUploadingSupport(false)
      setUploadedSupport(false)
      setUploadingFailedSupport(false)
   }

   const [selectedSupportChoice, setSelectedSupportChoice] = useState('')

   function handleSupportChoice(e) {
      e.preventDefault()
      setSelectedSupportChoice(e.target.value)
   }

   const [uploadingSupport, setUploadingSupport] = useState(false)
   const [uploadedSupport, setUploadedSupport] = useState(false)
   const [uploadingFailedSupport, setUploadingFailedSupport] = useState(false)

   //  support comment

   const [supportComment, setSupportComment] = useState('')
   const [tLength, setTLength] = useState(0)

   const handleWriteSupportComment = (event) => {
      try {
         if (typeof event.target.value !== 'undefined') {
            const data = event.target.value
            if (data.length <= 100) {
               // setTitle(data)
               setSupportComment(data)
               setTLength(data.length)
            } else if (data.length > 100) {
               const newData = data.slice(0, 100)
               // setTitle(newData)
               setSupportComment(newData)
               setTLength(newData.length)
            }
         } else {
            setSupportComment('')
            return false
         }
      } catch (error) {
         console.log(error)
      }
   }

   // submit support

   const handleRegisterSupport = async () => {
      console.log('statrted - 1')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('statrted - 2')
         if (attachedToConsti && attachedToConsti.Attached === 'Nowhere') {
            alert(
               'You are not attachedToConsti to any constituency at this level. Please get attachedToConsti to your constituency.',
            )
            setOpenDialogSupport(false)

            setSupportOption('')
            setSelectedSupportChoice('')
            return
         }
         if (supportsByUserPopulated && selectedSupportChoice !== '') {
            //
            console.log('statrted- 3')
            try {
               setUploadingSupport(true)
               console.log('statrted')
               let insider = ''
               if (attachedToConsti && attachedToConsti.Attached === 'Here') {
                  insider = true
               } else {
                  insider = false
               }

               if (supportOption === 'One' || supportOption === 'Two-W') {
                  // A.
                  // for inserting fresh record
                  //
                  const objSupport = {
                     Petition_id: petition.id,
                     Category: petition.Category,
                     Title: petition.Title,
                     TitleHindi: petition.TitleHindi,
                     TitleLocal: petition.TitleLocal,
                     AddressedTo: petition.AddressedTo,
                     Comment: supportComment ? [{ Text: supportComment, ReplyBy: '', Dt: new Date() }] : [],

                     Insider: insider,

                     CrBy: auth.currentUser.uid,
                     CrByName: nameOfUser,
                     CrByNameH: nameOfUserHindi,
                     CrByNameL: nameOfUserLocal,
                     CrByImg: userProfile.Image.Image,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                     CrType: selectedSupportChoice,

                     Loc: location,

                     ModDt: '',
                     ModIP: '',
                     ModType: '',

                     WDt: '',
                     WIP: '',
                  }

                  console.log('objSupport: ', objSupport)

                  // A-1. Create CoreList for User

                  const docRefUserProfiles = doc(db, 'UserProfiles', userProfile.id)
                  let coreListIdUser = ''

                  if (userProfile.PetitionsSupportedCount) {
                     const slabUser = 2000
                     const quotientUser = Math.floor(userProfile.PetitionsSupportedCount / slabUser)
                     // const remainder = candidatesCount % slab
                     const numUser = quotientUser + 1
                     const numStrUser = String(numUser).padStart(9, '0')

                     // coreListId = 'CandiCoreList-' + numStr
                     coreListIdUser = numStrUser

                     objSupport.CoreListId_AtUser = coreListIdUser
                  } else {
                     await updateDoc(docRefUserProfiles, {
                        PetitionsSupportedCount: 0,
                     })
                     coreListIdUser = '000000001'
                     objSupport.CoreListId_AtUser = '000000001'
                  }

                  // A-2. Create CoreList for Petition - we are fetching docRefPetitionFresh so that we get the real count of supports just before the entry of new candidate

                  let coreListIdPetition = ''
                  let supportCountPositiveInsider = 0
                  let supportCountPositiveOutsider = 0
                  let supportCountNegativeInsider = 0
                  let supportCountNegativeOutsider = 0
                  let supportCountTotal = 0

                  const docRefPetitionFresh = doc(db, 'Petitions', petition.id)
                  const docSnapPetitionFresh = await getDoc(docRefPetitionFresh)
                  supportCountPositiveInsider = docSnapPetitionFresh.data().SupportCount_Positive_Insider
                  supportCountPositiveOutsider = docSnapPetitionFresh.data().SupportCount_Positive_Outsider
                  supportCountNegativeInsider = docSnapPetitionFresh.data().SupportCount_Negative_Insider
                  supportCountNegativeOutsider = docSnapPetitionFresh.data().SupportCount_Negative_Outsider
                  supportCountTotal = docSnapPetitionFresh.data().SupportCount_Total

                  const slabPetition = 2000
                  const quotientPetition = Math.floor(supportCountTotal / slabPetition)
                  // const remainder = candidatesCount % slab
                  const numPetition = quotientPetition + 1
                  const numStrPetition = String(numPetition).padStart(9, '0')

                  // coreListId = 'CandiCoreList-' + numStr
                  coreListIdPetition = numStrPetition

                  objSupport.CoreListId_AtPetition = coreListIdPetition

                  // A-3. update the petition for count
                  // at first instance, this is being done to counter the situation where the allowed number counters the space crunch

                  switch (selectedSupportChoice) {
                     case 'Support':
                        if (insider) {
                           await updateDoc(docRefPetitionFresh, {
                              SupportCount_Positive_Insider: increment(1),
                              SupportCount_Total: increment(1),
                           })
                           // update local state
                           petition.SupportCount_Positive_Insider = supportCountPositiveInsider + 1
                           petition.SupportCount_Total = supportCountTotal + 1
                        } else {
                           await updateDoc(docRefPetitionFresh, {
                              SupportCount_Positive_Outsider: increment(1),
                              SupportCount_Total: increment(1),
                           })
                           // update local state
                           petition.SupportCount_Positive_Outsider = supportCountPositiveOutsider + 1
                           petition.SupportCount_Total = supportCountTotal + 1
                        }

                        break
                     case 'Oppose':
                        if (insider) {
                           await updateDoc(docRefPetitionFresh, {
                              SupportCount_Negative_Insider: increment(1),
                              SupportCount_Total: increment(1),
                           })
                           // update local state
                           petition.SupportCount_Negative_Insider = supportCountNegativeInsider + 1
                           petition.SupportCount_Total = supportCountTotal + 1
                        } else {
                           await updateDoc(docRefPetitionFresh, {
                              SupportCount_Negative_Outsider: increment(1),
                              SupportCount_Total: increment(1),
                           })
                           // update local state
                           petition.SupportCount_Negative_Outsider = supportCountNegativeOutsider + 1
                           petition.SupportCount_Total = supportCountTotal + 1
                        }

                        break
                  }

                  // A-4. update the Petition Sub Coll Supports
                  const docRefPetitionSubColSupports = doc(db, 'Petitions', petition.id, 'Supports', coreListIdPetition)
                  const docSnapPetitionSubColSupports = await getDoc(docRefPetitionSubColSupports)

                  if (docSnapPetitionSubColSupports.exists()) {
                     await updateDoc(docRefPetitionSubColSupports, {
                        Supports: arrayUnion(objSupport),
                     })
                  } else {
                     setDoc(docRefPetitionSubColSupports, { Supports: [objSupport] }, { merge: true })
                  }

                  // A-5. update the User sub coll PetitionsSupported

                  const docRefUserSubColPetitionsSupported = doc(
                     db,
                     'UserProfiles',
                     userProfile.id,
                     'PetitionsSupported',
                     coreListIdUser,
                  )
                  const docSnapUserSubColPetitionsSupported = await getDoc(docRefUserSubColPetitionsSupported)

                  if (docSnapUserSubColPetitionsSupported.exists()) {
                     await updateDoc(docRefUserSubColPetitionsSupported, {
                        Supports: arrayUnion(objSupport),
                     })
                  } else {
                     setDoc(docRefUserSubColPetitionsSupported, { Supports: [objSupport] }, { merge: true })
                  }

                  // A-6. update the user for count
                  await updateDoc(docRefUserProfiles, {
                     PetitionsSupportedCount: increment(1),
                  })
               } else {
                  // B.
                  // for updating the existing record
                  //
                  let objSupport = {}
                  if (supportsByUser && supportsByUser.length > 1) {
                     objSupport = supportsByUser[1]
                  } else if (supportsByUser && supportsByUser.length === 1) {
                     objSupport = supportsByUser[0]
                  }

                  let objSupportNew = {}

                  if (selectedSupportChoice === 'Support') {
                     objSupportNew = {
                        ...objSupport,
                        ModDt: new Date(),
                        ModIP: ip.data.ip,
                        ModType: 'Support',
                        Comment: supportComment
                           ? [...objSupport.Comment, { Text: supportComment, ReplyBy: '', Dt: new Date() }]
                           : objSupport.Comment,
                     }
                  } else if (selectedSupportChoice === 'Oppose') {
                     objSupportNew = {
                        ...objSupport,
                        ModDt: new Date(),
                        ModIP: ip.data.ip,
                        ModType: 'Oppose',
                        Comment: supportComment
                           ? [...objSupport.Comment, { Text: supportComment, ReplyBy: '', Dt: new Date() }]
                           : objSupport.Comment,
                     }
                  } else if (selectedSupportChoice === 'Withdraw') {
                     objSupportNew = {
                        ...objSupport,
                        WDt: new Date(),
                        WIP: ip.data.ip,
                        Comment: supportComment
                           ? [...objSupport.Comment, { Text: supportComment, ReplyBy: '', Dt: new Date() }]
                           : objSupport.Comment,
                     }
                  }

                  console.log('objSupport: ', objSupport)
                  console.log('objSupport New: ', objSupportNew)

                  // B-1. update the Petition Sub Coll Supports
                  const docRefPetitionSubColSupports = doc(
                     db,
                     'Petitions',
                     petition.id,
                     'Supports',
                     objSupport.CoreListId_AtPetition,
                  )

                  await updateDoc(docRefPetitionSubColSupports, {
                     Supports: arrayRemove(objSupport),
                  })
                  await updateDoc(docRefPetitionSubColSupports, {
                     Supports: arrayUnion(objSupportNew),
                  })

                  // B-2. Update Petition for counts

                  const docRefPetition = doc(db, 'Petitions', petition.id)
                  switch (selectedSupportChoice) {
                     case 'Support':
                        if (insider) {
                           await updateDoc(docRefPetition, {
                              SupportCount_Positive_Insider: increment(1),
                              SupportCount_Negative_Insider: increment(-1),
                           })
                           // update local state
                           petition.SupportCount_Positive_Insider = petition.SupportCount_Positive_Insider + 1
                           petition.SupportCount_Negative_Insider = petition.SupportCount_Negative_Insider - 1
                        } else {
                           await updateDoc(docRefPetition, {
                              SupportCount_Positive_Outsider: increment(1),
                              SupportCount_Negative_Outsider: increment(-1),
                           })
                           // update local state
                           petition.SupportCount_Positive_Outsider = petition.SupportCount_Positive_Outsider + 1
                           petition.SupportCount_Negative_Outsider = petition.SupportCount_Negative_Outsider - 1
                        }

                        break
                     case 'Oppose':
                        if (insider) {
                           await updateDoc(docRefPetition, {
                              SupportCount_Positive_Insider: increment(-1),
                              SupportCount_Negative_Insider: increment(1),
                           })
                           // update local state
                           petition.SupportCount_Positive_Insider = petition.SupportCount_Positive_Insider - 1
                           petition.SupportCount_Negative_Insider = petition.SupportCount_Negative_Insider + 1
                        } else {
                           await updateDoc(docRefPetition, {
                              SupportCount_Positive_Outsider: increment(-1),
                              SupportCount_Negative_Outsider: increment(1),
                           })
                           // update local state
                           petition.SupportCount_Positive_Outsider = petition.SupportCount_Positive_Outsider - 1
                           petition.SupportCount_Negative_Outsider = petition.SupportCount_Negative_Outsider + 1
                        }

                        break
                     case 'Withdraw':
                        if (insider) {
                           if (supportOption === 'Two-Support' || supportOption === 'Two-M-Oppose') {
                              await updateDoc(docRefPetition, {
                                 SupportCount_Withdrawn: increment(1),
                                 SupportCount_Negative_Insider: increment(-1),
                              })
                              // update local state
                              petition.SupportCount_Withdrawn = petition.SupportCount_Withdrawn + 1
                              petition.SupportCount_Negative_Insider = petition.SupportCount_Negative_Insider - 1
                           } else if (supportOption === 'Two-Oppose' || supportOption === 'Two-M-Support') {
                              await updateDoc(docRefPetition, {
                                 SupportCount_Withdrawn: increment(1),
                                 SupportCount_Positive_Insider: increment(-1),
                              })
                              // update local state
                              petition.SupportCount_Withdrawn = petition.SupportCount_Withdrawn + 1
                              petition.SupportCount_Positive_Insider = petition.SupportCount_Positive_Insider - 1
                           }
                        } else {
                           if (supportOption === 'Two-Support' || supportOption === 'Two-M-Oppose') {
                              await updateDoc(docRefPetition, {
                                 SupportCount_Withdrawn: increment(1),
                                 SupportCount_Negative_Outsider: increment(-1),
                              })
                              // update local state
                              petition.SupportCount_Withdrawn = petition.SupportCount_Withdrawn + 1
                              petition.SupportCount_Negative_Outsider = petition.SupportCount_Negative_Outsider - 1
                           } else if (supportOption === 'Two-Oppose' || supportOption === 'Two-M-Support') {
                              await updateDoc(docRefPetition, {
                                 SupportCount_Withdrawn: increment(1),
                                 SupportCount_Positive_Outsider: increment(-1),
                              })
                              // update local state
                              petition.SupportCount_Withdrawn = petition.SupportCount_Withdrawn + 1
                              petition.SupportCount_Positive_Outsider = petition.SupportCount_Positive_Outsider - 1
                           }
                        }

                        break
                  }

                  // B-3. update the User sub coll SupportsForPetition

                  const docRefUserSubColSupports = doc(
                     db,
                     'UserProfiles',
                     userProfile.id,
                     'PetitionsSupported',
                     objSupport.CoreListId_AtUser,
                  )

                  await updateDoc(docRefUserSubColSupports, {
                     Supports: arrayRemove(objSupport),
                  })
                  await updateDoc(docRefUserSubColSupports, {
                     Supports: arrayUnion(objSupportNew),
                  })
               }

               handleSupportsByUser()
               setOpenDialogSupport(false)

               setSupportOption('')
               setSelectedSupportChoice('')

               setUploadingSupport(false)
               setUploadedSupport(true)
               setUploadingFailedSupport(false)
            } catch (error) {
               console.log('error updating support: ', error)
               setUploadingSupport(false)
               setUploadedSupport(false)
               setUploadingFailedSupport(true)
            }
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to signatory

   const [uploadingSign, setUploadingSign] = useState(false)
   const [uploadedSign, setUploadedSign] = useState(false)
   const [uploadingFailedSign, setUploadingFailedSign] = useState(false)

   const handleOpenModalSign = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         handleOpenDialogSign()
      } else {
         handleOpenDialogSign()
      }
   }

   const [openDialogSign, setOpenDialogSign] = useState(false)

   const handleOpenDialogSign = async () => {
      setOpenDialogSign(true)
   }

   const handleCloseDialogSign = async () => {
      setOpenDialogSign(false)
   }

   const [checkedPledge, setCheckedPledge] = useState(false)

   const handlePledge = (event) => {
      event.preventDefault()
      setCheckedPledge(event.target.checked)
   }

   const handleSign = async (e) => {
      e.preventDefault()

      if (checkedPledge) {
         if (auth.currentUser && thisUser === auth.currentUser.uid) {
            console.log('statrted - 2')
            if (attachedToConsti && attachedToConsti.Attached === 'Nowhere') {
               alert(
                  'You are not attachedToConsti to any constituency at this level. Please get attachedToConsti to your constituency.',
               )
               setOpenDialogSign(false)
               return
            }

            console.log('statrted- 3')
            try {
               setUploadingSign(true)
               console.log('statrted')
               let insider = ''
               if (attachedToConsti && attachedToConsti.Attached === 'Here') {
                  insider = true
               } else {
                  insider = false
               }

               // A.
               // for inserting fresh record
               //
               const objSign = {
                  Petition_id: petition.id,
                  Category: petition.Category,
                  Title: petition.Title,
                  TitleHindi: petition.TitleHindi,
                  TitleLocal: petition.TitleLocal,
                  AddressedTo: petition.AddressedTo,
                  Comment: [],

                  Insider: insider,

                  CrBy: auth.currentUser.uid,
                  CrByName: nameOfUser,
                  CrByNameH: nameOfUserHindi,
                  CrByNameL: nameOfUserLocal,
                  CrByImg: userProfile.Image.Image,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,

                  Loc: location,

                  WDt: '',
                  WIP: '',
               }

               console.log('objSign: ', objSign)

               // A-1. Create CoreList for User

               const docRefUserProfiles = doc(db, 'UserProfiles', userProfile.id)
               let coreListIdUser = ''

               if (userProfile.PetitionsSignedCount) {
                  const slabUser = 2000
                  const quotientUser = Math.floor(userProfile.PetitionsSignedCount / slabUser)
                  // const remainder = candidatesCount % slab
                  const numUser = quotientUser + 1
                  const numStrUser = String(numUser).padStart(9, '0')

                  // coreListId = 'CandiCoreList-' + numStr
                  coreListIdUser = numStrUser

                  objSign.CoreListId_AtUser = coreListIdUser
               } else {
                  await updateDoc(docRefUserProfiles, {
                     PetitionsSignedCount: 0,
                  })
                  coreListIdUser = '000000001'
                  objSign.CoreListId_AtUser = '000000001'
               }

               // A-2. Create CoreList for Petition - we are fetching docRefPetitionFresh so that we get the real count of supports just before the entry of new candidate

               let coreListIdPetition = ''

               let SignCountInsider = 0
               let SignCountOutsider = 0
               let SignCountTotal = 0

               const docRefPetitionFresh = doc(db, 'Petitions', petition.id)
               const docSnapPetitionFresh = await getDoc(docRefPetitionFresh)
               SignCountInsider = docSnapPetitionFresh.data().SignCount_Insider
               SignCountOutsider = docSnapPetitionFresh.data().SignCount_Outsider
               SignCountTotal = docSnapPetitionFresh.data().SupportCount_Total

               const slabPetition = 2000
               const quotientPetition = Math.floor(SignCountTotal / slabPetition)
               // const remainder = candidatesCount % slab
               const numPetition = quotientPetition + 1
               const numStrPetition = String(numPetition).padStart(9, '0')

               // coreListId = 'CandiCoreList-' + numStr
               coreListIdPetition = numStrPetition

               objSign.CoreListId_AtPetition = coreListIdPetition

               // A-3. update the petition for count
               // at first instance, this is being done to counter the situation where the allowed number counters the space crunch

               if (insider) {
                  await updateDoc(docRefPetitionFresh, {
                     SignCount_Insider: increment(1),
                     SignCount_Total: increment(1),
                  })
                  // update local state
                  petition.SignCount_Insider = SignCountInsider + 1
                  petition.SignCount_Total = SignCountTotal + 1
               } else {
                  await updateDoc(docRefPetitionFresh, {
                     SignCount_Outsider: increment(1),
                     SignCount_Total: increment(1),
                  })
                  // update local state
                  petition.SignCount_Outsider = SignCountOutsider + 1
                  petition.SignCount_Total = SignCountTotal + 1
               }

               // A-4. update the Petition Sub Coll Signs
               const docRefPetitionSubColSigns = doc(db, 'Petitions', petition.id, 'Signatories', coreListIdPetition)
               const docSnapPetitionSubColSigns = await getDoc(docRefPetitionSubColSigns)

               if (docSnapPetitionSubColSigns.exists()) {
                  await updateDoc(docRefPetitionSubColSigns, {
                     Signatories: arrayUnion(objSign),
                  })
               } else {
                  setDoc(docRefPetitionSubColSigns, { Signatories: [objSign] }, { merge: true })
               }

               // A-5. update the User sub coll PetitionsSigned

               const docRefUserSubColPetitionsSigned = doc(
                  db,
                  'UserProfiles',
                  userProfile.id,
                  'PetitionsSigned',
                  coreListIdUser,
               )
               const docSnapUserSubColPetitionsSigned = await getDoc(docRefUserSubColPetitionsSigned)

               if (docSnapUserSubColPetitionsSigned.exists()) {
                  await updateDoc(docRefUserSubColPetitionsSigned, {
                     Signs: arrayUnion(objSign),
                  })
               } else {
                  setDoc(docRefUserSubColPetitionsSigned, { Signs: [objSign] }, { merge: true })
               }

               // A-6. update the user for count
               await updateDoc(docRefUserProfiles, {
                  PetitionsSignedCount: increment(1),
               })

               handleSignsByUser()
               setOpenDialogSign(false)

               setCheckedPledge(false)

               setUploadingSign(false)
               setUploadedSign(true)
               setUploadingFailedSign(false)
            } catch (error) {
               console.log('error updating support: ', error)
               setUploadingSign(false)
               setUploadedSign(false)
               setUploadingFailedSign(true)
            }
         } else {
            navigate('/', { replace: true })
         }
      }
   }

   const handleSignWithdraw = async (e) => {
      e.preventDefault()
      try {
         const obj = signsByUser[0]
         const objNew = { ...obj, WDt: new Date(), WIP: ip.data.ip }
         const docRefPetition = doc(db, 'Petitions', petition.id)
         const docRefPetitionSubColSigns = doc(db, 'Petitions', petition.id, 'Signatories', obj.CoreListId_AtPetition)
         const docRefUserSubColPetitionsSigned = doc(
            db,
            'UserProfiles',
            userProfile.id,
            'PetitionsSigned',
            obj.CoreListId_AtUser,
         )
         await updateDoc(docRefPetitionSubColSigns, {
            Signatories: arrayRemove(obj),
         })
         await updateDoc(docRefPetitionSubColSigns, {
            Signatories: arrayUnion(objNew),
         })
         await updateDoc(docRefUserSubColPetitionsSigned, {
            Signs: arrayRemove(obj),
         })
         await updateDoc(docRefUserSubColPetitionsSigned, {
            Signs: arrayUnion(objNew),
         })
         if (attachedToConsti && attachedToConsti.Attached === 'Here') {
            await updateDoc(docRefPetition, {
               SignCount_Insider: increment(-1),
               SignCount_Total: increment(-1),
               SignCount_Withdrawn: increment(1),
            })
            // update local state
            petition.SignCount_Total = petition.SignCount_Total - 1
            petition.SignCount_Insider = petition.SignCount_Insider - 1
            petition.SignCount_Withdrawn = petition.SignCount_Withdrawn + 1
         } else {
            await updateDoc(docRefPetition, {
               SignCount_Outsider: increment(-1),
               SignCount_Total: increment(-1),
               SignCount_Withdrawn: increment(1),
            })
            // update local state
            petition.SignCount_Total = petition.SignCount_Total - 1
            petition.SignCount_Outsider = petition.SignCount_Outsider - 1
            petition.SignCount_Withdrawn = petition.SignCount_Withdrawn + 1
         }
         handleSignsByUser()
      } catch (error) {
         console.log('withdrawa error: ', error)
      }
   }

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // style
   const innerBoxLeft = { display: 'flex', mr: 2, width: '50%', justifyContent: 'right' }
   const innerBoxRight = { fontWeight: 600, width: '50%', display: 'flex', flexDirection: 'row' }
   const outerBox = { display: 'flex', flexDirection: 'row', py: 0.5 }

   return (
      <Box sx={{ px: 1 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - ${petition.Title} - Basic Info`}</title>
            </Helmet>
         </div>
         <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CopyToClipboard text={petition.id} onCopy={() => onCopyText(petition.id)}>
               <Button
                  variant="outlined"
                  color="success"
                  sx={{
                     textTransform: 'none',
                     py: 0.5,
                     minWidth: 0,
                     px: 2,
                     ml: 'auto',
                     lineHeight: 1.2,
                  }}
               >
                  COPY this petition
               </Button>
            </CopyToClipboard>
            {copyStatus && <Box> &nbsp;... copied!</Box>}
         </Box>
         <Box sx={{ ...outerBox }}>
            <Box sx={{ ...innerBoxLeft }}>Addressed to / संबोधित:</Box>
            <Box sx={{ ...innerBoxRight }}>
               {petition.AddressedTo === 'Candidate' && (
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                     <Link
                        href={`/election/candidate/${addressedToObj.Name}/${addressedToObj.Candi_id}`}
                        sx={{ textDecoration: 'none !important' }}
                        target="_blank"
                        rel="noopener"
                     >
                        <ListItemAvatar>
                           <Avatar alt="Travis Howard" src={addressedToObj.Image} />
                        </ListItemAvatar>
                     </Link>
                     <Link
                        href={`/election/candidate/${addressedToObj.Name}/${addressedToObj.Candi_id}`}
                        sx={{
                           textDecoration: 'none !important',
                           whiteSpace: 'pre-line',
                        }}
                        target="_blank"
                        rel="noopener"
                     >
                        {addressedToObj.NameLocal
                           ? `${addressedToObj.Name} \n ${addressedToObj.NameLocal}`
                           : `${addressedToObj.Name} \n ${addressedToObj.NameHindi}`}
                     </Link>
                  </Box>
               )}
            </Box>
         </Box>
         <Box sx={{ ...outerBox }}>
            <Box sx={{ ...innerBoxLeft }}>Petitioner / याचिकाकर्ता:</Box>
            <Box sx={{ ...innerBoxRight }}>
               <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <ListItemAvatar>
                     <Avatar alt="Travis Howard" src={petition.CrByImage} />
                  </ListItemAvatar>

                  {petition.CrByNameLocal
                     ? `${petition.CrByName} \n ${petition.CrByNameLocal}`
                     : `${petition.CrByName} \n ${petition.CrByNameHindi}`}
               </Box>
            </Box>
         </Box>
         <Box sx={{ ...outerBox }}>
            <Box sx={{ ...innerBoxLeft }}>Supported by / समर्थनकर्ता :</Box>
            <Box sx={{ ...innerBoxRight }}>
               <Box sx={{ mr: 1, whiteSpace: 'pre-line' }}>From inside constituency:</Box>
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     borderRadius: 1,
                     border: 1,
                     px: 1,
                     mr: 1,
                     color: '#4caf50',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => {
                     handleOpenModalSupport(e)
                  }}
               >
                  <ThumbUpAltIcon sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} />
                  {petition.SupportCount_Positive_Insider}
               </Box>
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     borderRadius: 1,
                     border: 1,
                     px: 1,
                     mr: 1,
                     color: 'Crimson',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => {
                     handleOpenModalSupport(e)
                  }}
               >
                  <ThumbDownAltIcon sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} />
                  {petition.SupportCount_Negative_Insider}
               </Box>
               {/* <CustomButtonGreen
                  labelName="Show List / सूची दिखाएं"
                  handleClick={(e) => {
                     handleOpenModalSupport(e)
                  }}
               ></CustomButtonGreen> */}
            </Box>
         </Box>
         <Box sx={{ ...outerBox }}>
            <Box sx={{ ...innerBoxLeft }}></Box>
            <Box sx={{ ...innerBoxRight }}>
               <Box sx={{ mr: 1, whiteSpace: 'pre-line' }}>From outside constituency:</Box>
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     borderRadius: 1,
                     border: 1,
                     px: 1,
                     mr: 1,
                     color: '#4caf50',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => {
                     handleOpenModalSupport(e)
                  }}
               >
                  <ThumbUpAltIcon sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} />
                  {petition.SupportCount_Positive_Outsider}
               </Box>
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     borderRadius: 1,
                     border: 1,
                     px: 1,
                     mr: 1,
                     color: 'Crimson',
                     cursor: 'pointer',
                  }}
                  onClick={(e) => {
                     handleOpenModalSupport(e)
                  }}
               >
                  <ThumbDownAltIcon sx={{ fontSize: 20, verticalAlign: 'middle', mr: 1 }} />
                  {petition.SupportCount_Negative_Outsider}
               </Box>
            </Box>
         </Box>
         <Box
            sx={{
               ...outerBox,
            }}
         >
            <Box sx={{ ...innerBoxLeft }}>Affected people / प्रभावित लोग:</Box>
            <Box sx={{ ...innerBoxRight }}>
               <Box>
                  <Box
                     sx={{
                        backgroundColor: '#FFFFFF',
                        px: 1,
                        mr: 1,
                        fontWeight: 400,
                     }}
                     // onClick={(e) => {
                     //    handleAcceptedCount(e)
                     // }}
                  >
                     Active signs: <strong style={{ fontWeight: 500 }}>{petition.SignCount_Total}</strong>
                  </Box>
                  <Box
                     sx={{
                        backgroundColor: '#FFFFFF',
                        px: 1,
                        mr: 1,
                        fontWeight: 400,
                     }}
                     // onClick={(e) => {
                     //    handleAcceptedCount(e)
                     // }}
                  >
                     Withdrawn: <strong style={{ fontWeight: 500 }}>{petition.SignCount_Withdrawn}</strong>
                  </Box>
               </Box>
               <CustomButtonOrange
                  labelName={`Sign the petition \n याचिका पर हस्ताक्षर करें`}
                  handleClick={(e) => {
                     handleOpenModalSign(e)
                  }}
               ></CustomButtonOrange>
            </Box>
         </Box>
         <Box>
            <List dense>
               {petition.Paragraphs &&
                  petition.Paragraphs.length > 0 &&
                  petition.Paragraphs.map((item, index) => (
                     <div key={index}>
                        <ListItem sx={{ backgroundColor: '#FFFFFF' }} secondaryAction={null}>
                           <ListItemText
                              primaryTypographyProps={{ fontWeight: 600, fontSize: 24, fontFamily: 'Montserrat' }}
                              secondaryTypographyProps={{
                                 // align: 'justify',
                                 textAlign: 'initial',
                                 whiteSpace: 'pre-line',
                                 fontSize: 21,
                                 fontFamily: 'Montserrat',
                              }}
                              primary={item.Header ? `${item.Header}:` : null}
                              secondary={
                                 <>
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    {item.Text}
                                 </>
                              }
                           />
                        </ListItem>
                        {/* <Divider /> */}
                     </div>
                  ))}
            </List>
         </Box>
         <Box id="boxModals">
            <Dialog
               open={openDialogNotLoggedIn}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogNotLoggedIn}
               aria-describedby="alert-dialog-slide-notLoggedIn"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
               <DialogContent>
                  <DialogContentText
                     id="alert-dialog-slide-notLoggedIn"
                     sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                  >
                     You are not logged-in.
                     {'\n'}
                     आपने लॉग - इन नहीं किया है।
                  </DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
               </DialogActions>
            </Dialog>

            <Dialog
               open={openDialogAlreadySupported}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogAlreadySupported}
               aria-describedby="alert-dialog-slide-AlreadySupported"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                     color: 'Crimson',
                  }}
               >{`You have already supported / opposed this petition twice and withdrawn. ${'\n'} You cannot support / oppose this petition anymore. ${'\n'} आप पहले ही दो बार इस याचिका का समर्थन/विरोध कर चुके हैं और वापस ले चुके हैं। ${'\n'} अब आप इस याचिका का समर्थन/विरोध नहीं कर सकते।`}</DialogTitle>
               <DialogContent>
                  <List dense>
                     {supportsByUser &&
                        supportsByUser.length &&
                        supportsByUser.map((item, index) => (
                           <ListItem key={index}>
                              <ListItemText
                                 primaryTypographyProps={{ fontSize: 16 }}
                                 primary={
                                    <>
                                       <>
                                          {item.CrDt && moment(item.CrDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                          {item.CrType === 'Support' && (
                                             <span style={{ color: 'Green', fontWeight: 600 }}>
                                                You supported this petition
                                             </span>
                                          )}
                                          {item.CrType === 'Oppose' && (
                                             <span style={{ color: 'Red', fontWeight: 600 }}>
                                                You opposed this petition
                                             </span>
                                          )}
                                          <br />
                                       </>
                                       {item.ModDt && (
                                          <>
                                             {item.ModDt && moment(item.ModDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                             {item.ModType === 'Support' && (
                                                <span style={{ color: 'Green', fontWeight: 600 }}>
                                                   You supported this petition
                                                </span>
                                             )}
                                             {item.ModType === 'Oppose' && (
                                                <span style={{ color: 'Red', fontWeight: 600 }}>
                                                   You opposed this petition
                                                </span>
                                             )}
                                             <br />
                                          </>
                                       )}
                                       {item.WDt && (
                                          <>
                                             {item.WDt && moment(item.WDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                             <span style={{ color: 'Blue', fontWeight: 600 }}>
                                                You withdrew from this petition.
                                             </span>
                                             <br />
                                          </>
                                       )}
                                    </>
                                 }
                                 secondary={null}
                              />
                           </ListItem>
                        ))}
                  </List>
               </DialogContent>
               <DialogActions>
                  <Button variant="outlined" color="error" onClick={handleCloseDialogAlreadySupported}>
                     Close
                  </Button>
               </DialogActions>
            </Dialog>

            <Dialog
               open={openDialogSupport}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogSupport}
               aria-describedby="alert-dialog-slide-support"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Change Support / Oppose this petition. ${'\n'} इस याचिका का समर्थन / विरोध करें।`}</DialogTitle>
               <DialogContent>
                  {supportsByUser && supportsByUser.length > 0 && (
                     <List dense>
                        {supportsByUser.map((item, index) => (
                           <ListItem key={index}>
                              <ListItemText
                                 primaryTypographyProps={{ fontSize: 16 }}
                                 primary={
                                    <>
                                       <>
                                          {item.CrDt && moment(item.CrDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                          {item.CrType === 'Support' && (
                                             <span style={{ color: 'Green', fontWeight: 600 }}>
                                                You supported this petition
                                             </span>
                                          )}
                                          {item.CrType === 'Oppose' && (
                                             <span style={{ color: 'Red', fontWeight: 600 }}>
                                                You opposed this petition
                                             </span>
                                          )}
                                          <br />
                                       </>
                                       {item.ModDt && (
                                          <>
                                             {item.ModDt && moment(item.ModDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                             {item.ModType === 'Support' && (
                                                <span style={{ color: 'Green', fontWeight: 600 }}>
                                                   You supported this petition
                                                </span>
                                             )}
                                             {item.ModType === 'Oppose' && (
                                                <span style={{ color: 'Red', fontWeight: 600 }}>
                                                   You opposed this petition
                                                </span>
                                             )}
                                             <br />
                                          </>
                                       )}
                                       {item.WDt && (
                                          <>
                                             {item.WDt && moment(item.WDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                             <span style={{ color: 'Blue', fontWeight: 600 }}>
                                                You withdrew from this petition.
                                             </span>
                                             <br />
                                          </>
                                       )}
                                    </>
                                 }
                                 secondary={null}
                              />
                           </ListItem>
                        ))}
                     </List>
                  )}
                  {supportOption !== 'One' && (
                     <Typography variant="body1" sx={{ whiteSpace: 'pre-line', textAlign: 'left', fontWeight: 500 }}>
                        Do you want to change your decision? {'\n'} क्या आप अपना निर्णय बदलना चाहते हैं?
                     </Typography>
                  )}

                  <FormControl
                     sx={{
                        mt: 2,
                        py: 0,
                        px: 1,
                        border: 1,
                        borderRadius: 1,
                     }}
                  >
                     {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender / लिंग:</FormLabel> */}
                     <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="Support"
                        value={selectedSupportChoice}
                        // style={{ display: 'flex', gap: '2rem' }}
                        onChange={(e) => {
                           handleSupportChoice(e)
                        }}
                        sx={{
                           borderRadius: 1,
                           py: 0,
                           '& .MuiSvgIcon-root': {
                              fontSize: 15,
                           },
                           '& .MuiButtonBase-root': {
                              py: 0,
                           },
                           '& MuiFormControlLabel-label': {
                              fontSize: 10,
                              lineHeight: 16,
                              py: 0,
                           },
                        }}
                     >
                        {['One', 'Two-W', 'Two-Support', 'Three-Support'].indexOf(supportOption) > -1 && (
                           <FormControlLabel
                              value="Support"
                              control={<Radio />}
                              label={
                                 <Typography
                                    variant="body1"
                                    sx={{ whiteSpace: 'pre-line', textAlign: 'left', fontWeight: 500 }}
                                 >
                                    I support. {'\n'} मेरा समर्थन है।
                                 </Typography>
                              }
                              sx={{ py: 0, fontSize: 10, color: 'Green' }}
                           />
                        )}

                        {['One', 'Two-W', 'Two-Oppose', 'Three-Oppose'].indexOf(supportOption) > -1 && (
                           <FormControlLabel
                              sx={{ color: 'Red' }}
                              value="Oppose"
                              control={<Radio />}
                              label={
                                 <Typography
                                    variant="body1"
                                    sx={{ whiteSpace: 'pre-line', textAlign: 'left', fontWeight: 500 }}
                                 >
                                    I oppose. {'\n'} मेरा विरोध है।।
                                 </Typography>
                              }
                           />
                        )}

                        {[
                           'Two-M-Support',
                           'Two-M-Oppose',
                           'Two-Support',
                           'Two-Oppose',
                           'Three-M',
                           'Three-Oppose',
                           'Three-Support',
                        ].indexOf(supportOption) > -1 && (
                           <FormControlLabel
                              sx={{ color: 'Blue' }}
                              value="Withdraw"
                              control={<Radio />}
                              label={
                                 <Typography
                                    variant="body1"
                                    sx={{ whiteSpace: 'pre-line', textAlign: 'left', fontWeight: 500 }}
                                 >
                                    I withdraw. {'\n'} समर्थन / विरोध वापस लेता / लेती हूं।
                                 </Typography>
                              }
                           />
                        )}
                     </RadioGroup>
                  </FormControl>
                  <Box>
                     <TextField
                        onBlur={(event) => event}
                        slotProps={{ htmlInput: { maxLength: 100 } }}
                        onChange={(event) => {
                           handleWriteSupportComment(event)
                        }}
                        value={supportComment}
                        name="Comment"
                        id="comment"
                        label="Comment / टिप्पणी"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        autoComplete="off"
                        sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
                     ></TextField>
                     <Box>
                        <Typography
                           variant="body2"
                           sx={{
                              color: '#808080',
                           }}
                        >
                           {tLength} / {100}
                        </Typography>
                     </Box>
                  </Box>
                  <Box>
                     {uploadingSupport && (
                        <MyLoaderCircularGradient title={'Listing your change ...'}></MyLoaderCircularGradient>
                     )}
                     {uploadedSupport && (
                        <Alert variant="outlined" severity="success">
                           Your support listed successfully.
                        </Alert>
                     )}
                     {uploadingFailedSupport && (
                        <Alert variant="outlined" severity="error">
                           Your support could not be listed.
                        </Alert>
                     )}
                  </Box>
               </DialogContent>
               <DialogActions sx={{ justifyContent: 'space-between' }}>
                  <CustomButtonBlue
                     labelName="Submit / सबमिट करें"
                     handleClick={handleRegisterSupport}
                     disabled={!supportsByUserPopulated || selectedSupportChoice === ''}
                  ></CustomButtonBlue>
                  <Button variant="outlined" color="error" onClick={handleCloseDialogSupport}>
                     Cancel
                  </Button>
               </DialogActions>
            </Dialog>
            <Dialog
               open={openDialogSign}
               TransitionComponent={Transition}
               keepMounted
               onClose={handleCloseDialogSign}
               aria-describedby="alert-dialog-slide-Sign"
            >
               <DialogTitle
                  sx={{
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >{`Are you sure you want to SIGN this petition? ${'\n'} क्या आप सचमुच इस याचिका पर हस्ताक्षर करना चाहते हैं?`}</DialogTitle>
               <DialogContent>
                  {signsByUser && signsByUser.length > 0 && (
                     <List dense>
                        {signsByUser.map((item, index) => (
                           <ListItem key={index}>
                              <ListItemText
                                 primaryTypographyProps={{ fontSize: 16 }}
                                 primary={
                                    <>
                                       <>
                                          {item.CrDt && moment(item.CrDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                          <span style={{ color: 'Green', fontWeight: 600 }}>
                                             You signed this petition.
                                          </span>
                                          <br />
                                       </>
                                       {item.WDt && (
                                          <>
                                             {item.WDt && moment(item.WDt.toDate()).format('Do MMM YYYY')} {': '}{' '}
                                             <span style={{ color: 'Blue', fontWeight: 600 }}>
                                                You withdrew your sign from this petition.
                                             </span>
                                             <br />
                                          </>
                                       )}
                                    </>
                                 }
                                 secondary={null}
                              />
                           </ListItem>
                        ))}
                     </List>
                  )}
                  {signsByUser && signsByUser.length === 0 && (
                     <DialogContentText id="alert-dialog-slide-Sign" sx={{ whiteSpace: 'pre-line', color: '#660000' }}>
                        I am expressly / directly affected by this issue.{'\n'}
                        मैं इस मुद्दे से प्रत्यक्ष / स्पष्ट रूप से प्रभावित हूं।
                     </DialogContentText>
                  )}
                  {signsByUser && signsByUser.length === 0 && (
                     <Box sx={{ border: 1, borderColor: '#d1d1d1', px: 1, borderRadius: 1, mt: 2 }}>
                        <FormControlLabel
                           required
                           control={
                              <Checkbox
                                 checked={checkedPledge}
                                 onChange={handlePledge}
                                 inputProps={{ 'aria-label': 'controlled' }}
                              />
                           }
                           label="I declare with moral and legal responsibility that the above statement is true. मैं नैतिक और कानूनी जिम्मेदारी के साथ घोषणा करता हूं कि उपरोक्त कथन सत्य है।"
                        />
                     </Box>
                  )}
                  <Box>
                     {uploadingSign && <MyLoaderCircularGradient title={'Signing ...'}></MyLoaderCircularGradient>}
                     {uploadedSign && (
                        <Alert variant="outlined" severity="success">
                           You signed successfully.
                        </Alert>
                     )}
                     {uploadingFailedSign && (
                        <Alert variant="outlined" severity="error">
                           You could not be signed onto this petition. Please tray later.
                        </Alert>
                     )}
                  </Box>
               </DialogContent>
               <DialogActions sx={{ justifyContent: 'space-between' }}>
                  {signsByUser && signsByUser.length === 0 && (
                     <Button
                        variant="outlined"
                        color="success"
                        sx={{ whiteSpace: 'pre-line' }}
                        onClick={(e) => {
                           handleSign(e)
                        }}
                        disabled={!checkedPledge}
                     >
                        Sign {'\n'} हस्ताक्षर करें
                     </Button>
                  )}
                  {signsByUser && signsByUser.length > 0 && signsByUser[0].WDt === '' && (
                     <Button
                        variant="outlined"
                        color="success"
                        sx={{ whiteSpace: 'pre-line' }}
                        onClick={(e) => {
                           handleSignWithdraw(e)
                        }}
                     >
                        I withdraw. {'\n'} हस्ताक्षर वापस लेता / लेती हूं
                     </Button>
                  )}
                  <Button variant="outlined" color="error" onClick={handleCloseDialogSign}>
                     Cancel
                  </Button>
               </DialogActions>
            </Dialog>
         </Box>
      </Box>
   )
}

PetitionBasicInfo.propTypes = {
   petition: PropTypes.object.isRequired,
   handleSupportsByUser: PropTypes.func.isRequired,
   supportsByUser: PropTypes.array.isRequired,
   handleSignsByUser: PropTypes.func.isRequired,
   signsByUser: PropTypes.array.isRequired,
   supportsByUserPopulated: PropTypes.bool.isRequired,
   attachedToConsti: PropTypes.object.isRequired,
   location: PropTypes.object.isRequired,
}

export default PetitionBasicInfo
