import {
   Alert,
   Box,
   Button,
   FormControl,
   InputLabel,
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   MenuItem,
   Select,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import FolderIcon from '@mui/icons-material/Folder'

function AddExitPoll({
   handleAddExitPoll,
   agencies,
   exitPollsList,
   lastAgency,
   uploadingExitPoll,
   uploadedExitPoll,
   uploadingFailedExitPoll,
}) {
   const [selectedAgency, setSelectedAgency] = useState('')
   const [agencyDetails, setAgencyDetails] = useState({
      Agency_id: '',
      AgencyName: '',
      AgencyNameHindi: '',
      AgencyNameLocal: '',
      AgencyAbbreviation: '',
      AgencyLogo: '',
      WeblinkMain: '',
      Weblinks: '',
   })

   const handleChangeAgency = (e) => {
      e.preventDefault()

      setSelectedAgency(e.target.value)
   }

   useEffect(() => {
      console.log('Selected Agency : ', agencyDetails)
      if (selectedAgency !== '') {
         const agency = agencies.find((item) => item.Name === selectedAgency)
         // console.log('party flag', party.Flag)
         // console.log('party name', party.Name)
         setAgencyDetails({
            Agency_id: agency.id,
            AgencyName: agency.Name,
            AgencyNameHindi: agency.NameHindi,
            AgencyNameLocal: agency.NameLocal,
            AgencyAbbreviation: agency.Abbreviation,
            AgencyLogo: agency.Logo,
            WeblinkMain: agency.WeblinkMain,
            Weblinks: agency.Weblinks,
         })
      } else {
         setAgencyDetails({
            Agency_id: '',
            AgencyName: '',
            AgencyNameHindi: '',
            AgencyNameLocal: '',
            AgencyAbbreviation: '',
            AgencyLogo: '',
            WeblinkMain: '',
            Weblinks: '',
         })
      }
   }, [selectedAgency])

   useEffect(() => {
      console.log('Agency details on change of its value: ', agencyDetails)
   }, [agencyDetails])

   const handleSubmit = (e) => {
      e.preventDefault()

      if (agencyDetails.AgencyName.length > 1) {
         handleAddExitPoll(agencyDetails)
      }

      setAgencyDetails({
         Agency_id: '',
         AgencyName: '',
         AgencyNameHindi: '',
         AgencyNameLocal: '',
         AgencyAbbreviation: '',
         AgencyLogo: '',
         WeblinkMain: '',
         Weblinks: '',
      })
      setSelectedAgency('')
   }
   return (
      <Box
         sx={{
            //    mb: 1,
            m: 2,
            pb: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': { width: '15ch', mt: 1 },
            '& .MuiTextField-root': { width: '50ch', mb: 0.5, mt: 0 },
            // '& .MuiInputBase-root': { mb: 2 },
            '& .MuiFormControl-root': { width: '50ch', mb: 0.5 },
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <FormControl sx={{ m: 1 }} size="small" fullWidth>
            <InputLabel>Agency</InputLabel>
            <Select
               value={selectedAgency}
               label="Agency"
               onChange={(e) => {
                  handleChangeAgency(e)
               }}
               sx={{ backgroundColor: '#ffffff' }}
               fullWidth
            >
               <MenuItem value="">
                  <em>None</em>
               </MenuItem>
               {agencies &&
                  agencies.length > 0 &&
                  agencies.map((item) => (
                     <MenuItem key={item.Name} value={item.Name} sx={{ fontSize: 13 }}>
                        {item.Name} - <strong> {item.Abbreviation}</strong>
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
         <Box sx={{ mb: 2 }}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               onClick={(e) => {
                  handleSubmit(e)
               }}
               disabled={agencyDetails.AgencyName.length < 2}
            >
               Submit
            </Button>
         </Box>
         <Box sx={{ mb: 2 }}>
            {uploadingExitPoll && (
               <MyLoaderCircularGradient title={`Creating exit poll by ${lastAgency} ...`}></MyLoaderCircularGradient>
            )}
            {uploadedExitPoll && (
               <Alert variant="outlined" severity="success">
                  <strong>{lastAgency}</strong> listed successfully.
               </Alert>
            )}
            {uploadingFailedExitPoll && (
               <Alert variant="outlined" severity="error">
                  Error occured! <strong>{lastAgency}</strong> could not be listed.
               </Alert>
            )}
         </Box>
         <Box>
            <List dense>
               {exitPollsList &&
                  exitPollsList.length > 0 &&
                  exitPollsList.map((item) => (
                     <ListItem key={item.AgencyName}>
                        <ListItemIcon>
                           <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary={item.AgencyName} />
                     </ListItem>
                  ))}
            </List>
         </Box>
      </Box>
   )
}

AddExitPoll.propTypes = {
   handleAddExitPoll: PropTypes.func.isRequired,
   agencies: PropTypes.array.isRequired,
   exitPollsList: PropTypes.array.isRequired,
   lastAgency: PropTypes.string.isRequired,
   uploadingExitPoll: PropTypes.bool.isRequired,
   uploadedExitPoll: PropTypes.bool.isRequired,
   uploadingFailedExitPoll: PropTypes.bool.isRequired,
}

export default AddExitPoll
