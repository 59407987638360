import React, { useEffect, useState } from 'react'
import myStyles from './Edits.module.css'
import { Box, Button, TextField } from '@mui/material'
import PropTypes from 'prop-types'

function EditPriorityAndDescr({ handleEditPriorityAndDescr, item }) {
   //
   const [details, setDetails] = useState({
      Priority: item.Priority,
      Description: item.Description,
   })
   useEffect(() => {
      console.log('Party details on change of its value: ', details)
   }, [details])

   function handleDescriptionDetails(event) {
      setDetails({ ...details, [event.target.name]: event.target.value })
   }

   function handlePriorityDetails(e) {
      if (e.target.value !== '') {
         const newValue = parseInt(e.target.value, 10)

         newValue && !isNaN(newValue)
            ? setDetails({ ...details, Priority: newValue })
            : setDetails({ ...details, Priority: 0 })
      } else {
         setDetails({ ...details, Priority: 0 })
      }
   }

   function handleSubmit(e) {
      e.preventDefault()
      handleEditPriorityAndDescr(details)
      reset()
   }

   function reset() {
      setDetails({
         Priority: 0,
         Description: '',
      })
   }

   return (
      <Box sx={{ m: 2 }}>
         <Box sx={{ mt: 2 }}>
            <TextField
               onBlur={(event) => event}
               onChange={(e) => {
                  handlePriorityDetails(e)
               }}
               value={details.Priority}
               name="Priority"
               id="priority"
               label="Priority"
               variant="outlined"
               margin="dense"
               // required
               fullWidth
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </Box>
         <div>
            <TextField
               onBlur={(event) => event}
               onChange={handleDescriptionDetails}
               value={details.Description}
               name="Description"
               id="description"
               label="Description"
               variant="outlined"
               margin="dense"
               // required
               fullWidth
               multiline
               rows={4}
               size="small"
               sx={{ paddingBottom: 0, backgroundColor: '#ffffff', borderRadius: 1 }}
            ></TextField>
         </div>
         <div className={myStyles.margin_top_one}>
            <Button
               type="submit"
               align="center"
               variant="contained"
               color="primary"
               size="small"
               margin="dense"
               onClick={(e) => {
                  handleSubmit(e)
               }}
               disabled={details.Priority === ''}
            >
               Submit
            </Button>
         </div>
      </Box>
   )
}

EditPriorityAndDescr.propTypes = {
   handleEditPriorityAndDescr: PropTypes.func.isRequired,
   item: PropTypes.object.isRequired,
}

export default EditPriorityAndDescr
